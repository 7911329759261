import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService, AlertService, UserService } from '../services';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  submitted = false;
  comany_submited = false;
  loading = false;
  feildset1 = false;
  feildset2 = false;
  feildset3 = false;
  month_list = [];
  message: string;
  classmessages: string;
  emailverifyone: any;
  industries = [];
  all_industries = [];
  all_countries = [];
  incorp_years = [];
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  metatag: any;
  selected_country: any;
  ngOnInit() {
    this.userService.getAllIndustries().subscribe(industries => {
      this.all_industries = industries;
    });
    this.userService.getIncorpYearsANdCountries().subscribe(data => {
      this.all_countries = data.country;
      this.incorp_years = data.incorporation_year;
    });
    this.month_list = this.userService.month_list;
    this.titleService.setTitle('Kwik Look - Register');
    let current_fs, next_fs, previous_fs; // fieldsets
    let left, opacity, scale; // fieldset properties which we will animate
    let animating; // flag to prevent quick multi-click glitches
    let fieldid;

    $('.next,.nextbtn').click(function () {

      fieldid = $(this).closest('fieldset').attr('id');

      /* if (fieldid === 's2551') {
           handleClick();
          return false;
       } */

      if (animating) { return false; }
      animating = true;
      current_fs = $(this).parent();
      next_fs = $(this).parent().next();
      // activate next step on progressbar using the index of next_fs
      $('#progressbar li').eq($('fieldset').index(next_fs)).addClass('active');
      // show the next fieldset
      next_fs.show();
      // hide the current fieldset with style
      current_fs.animate({ opacity: 1 }, {
        step: function (now, mx) {
          // as the opacity of current_fs reduces to 0 - stored in "now"
          // 1. scale current_fs down to 80%
          scale = 1 - (1 - now) * 0.2;
          // 2. bring next_fs from the right(50%)
          left = (now * 50) + '%';
          // 3. increase opacity of next_fs to 1 as it moves in
          opacity = 1 - now;
          //    current_fs.css({'position': 'absolute'});
          // next_fs.css({'left': left, 'opacity': opacity});
        },
        duration: 50,
        complete: function () {
          current_fs.hide();
          animating = false;
        },
        // this comes from the custom easing plugin
        // easing: 'easeInOutBack'
      });
    });

    $('.previous').click(function () {
      if (animating) { return false; }
      animating = true;
      current_fs = $(this).parent();
      previous_fs = $(this).parent().prev();
      // de-activate current step on progressbar
      $('#progressbar li').eq($('fieldset').index(current_fs)).removeClass('active');
      // show the previous fieldset
      previous_fs.show();
      // hide the current fieldset with style
      current_fs.animate({ opacity: 1 }, {
        step: function (now, mx) {
          // as the opacity of current_fs reduces to 0 - stored in "now"
          // 1. scale previous_fs from 80% to 100%
          scale = 0.8 + (1 - now) * 0.2;
          // 2. take current_fs to the right(50%) - from 0%
          left = ((1 - now) * 50) + '%';
          // 3. increase opacity of previous_fs to 1 as it moves in
          opacity = 1 - now;
          // current_fs.css({'left': left});
          // previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
        },
        duration: 800,
        complete: function () {
          current_fs.hide();
          animating = false;
        },
        // this comes from the custom easing plugin
        // easing: 'easeInOutBack'
      });
    });

    // throw new Error("Method not implemented.");
  }
  // tslint:disable-next-line:member-ordering
  userForm: any; value: any;
  // tslint:disable-next-line:member-ordering
  userForm2: any;
  // tslint:disable-next-line:member-ordering
  countries: any;


  constructor(private formBuilder: FormBuilder, private router: Router,
    private userService: UserService,
    private titleService: Title,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private globals: GlobalsService) {

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'First Step in setting up a Kwiki Look Free Trial Account, to access the simple online Cash Flow Evaluation tool that allows SME entrepreneurs to quickly understand the potential profitability of their new business idea.',
    };
    this.meta.updateTag(this.metatag);


    this.userForm = this.formBuilder.group({
      'email': ['', [Validators.required, ValidationService.emailValidators], this.isEmailUnique.bind(this)],
      'password': ['', [Validators.required, ValidationService.passwordValidator]],
      'changepassword': ['', Validators.required],
      'CompanyName': ['', Validators.required],
      'companyindustry': ['', Validators.required],
      'country_sec': ['', Validators.required],
      'incorp_month': ['', Validators.required],
      'incorp_year': ['', Validators.required],
      'FirstName': ['', Validators.required],
      'LastName': ['', Validators.required],
      // 'Cityoptional': '',
      // 'Address': ['', Validators.required],
      // 'StateProvinceoptional': '',
      // 'postalcode': ['', Validators.required],
      // 'phone': ['', Validators.required],
      'other_country': [''],
      'country2': ['Singapore', Validators.required],
      'selected_country': ['']
    });
    this.emailverifyone = { email: '' };

    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value ;

      }
    });
  }

  isEmailUnique(control: FormControl) {
    this.emailverifyone.email = control.value;
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.userService.isEmailRegisterd(this.emailverifyone).subscribe(() => {
          resolve(null);
        }, () => { resolve({ 'isEmailUnique': true }); });
      }, 1000);
    });

    console.log ('sddsf', q);

    return q;
  }
  get f() { return this.userForm.controls; }

  readRainbow(event) {
    this.value = event.target.id;
  }
  handleClick() {
    //  alert('sdsdsdsd');
  }
  hideHolder(e: any) {
    $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
    $(e.target).attr('placeholder', '');
    return;
  }
  showHolder(e: any) {
    const txtval = $(e.target).val();
    if (txtval === '') {
      $(e.target).attr('placeholder', $(e.target).data('placeholder'));
    }
  }
  onSubmit() {
    this.message = '';
    this.classmessages = '';
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;

    this.userService.registerUser(this.userForm.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.id) {
            // this.userService.setUserObject(data);
            this.message = data.sucess;
            this.classmessages = 'success';
            setTimeout(() => this.router.navigate(['/user']), 60000);
          } else {
            this.message = data.error;
            this.loading = false;
            this.classmessages = 'error';
            $('.col-md-6.darkbule fieldset').hide();
            $('.col-md-6.darkbule fieldset:first-child').show();
          }
          setTimeout(() => this.message = '', 60000);
          // this.alertService.success('Registration successful', true);
          // this.router.navigate(['/login']);
          // this.loading = false;
          // this.submitted = false;
        },
        error => {
          //  this.alertService.error(error);
          this.message = error.error;
          this.loading = false;
          this.classmessages = 'error';
          $('.col-md-6.darkbule fieldset').hide();
          $('.col-md-6.darkbule fieldset:first-child').show();
          setTimeout(() => this.message = '', 20000);
          setTimeout(() => this.router.navigate(['/']), 5);
        });
  }

  onCountryChange(event) {
    const newVal = event.target.value;
    this.industries = (this.all_industries).filter((a) => (a.country === newVal) );
  }

  closeModal() {
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.userService.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['sing_up_trail'] + '?autoplay=1';
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }

}
