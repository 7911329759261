import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class Year5ReportService {
  report_data: any = {};
  constructor(private user: UserService) {
    this.report_data.revenue = {
      sales: [],
      tota_sales: [0, 0, 0, 0, 0],
      service_charge: [0, 0, 0, 0, 0],
      tota_sal_service_charge: [0, 0, 0, 0, 0],
      is_service_charge: false,
      other_revenue: [0, 0, 0, 0, 0],
      total_revenue: [0, 0, 0, 0, 0]
    };
    this.report_data.cogs = { value: [], total: [0, 0, 0, 0, 0] };
    this.report_data.fixed_cost = { value: [], total: [0, 0, 0, 0, 0] };
    this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0] };
  }

  // Set Variable Data
  setVariableCostReportData(data) {
    this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0] };
    console.log('setVariableCostReportData', this.report_data);
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners', 'historical'];
    const var_cos_arr = ['salaries', 'marketing', 'uv', 'other'];

    const var_data_thrid: any = {};
    let i = 0;
    const NewfirstYearsales = [0, 0, 0, 0, 0];

    // var_data_thrid.sales  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    //   { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    //    { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.sales = [];
    for (let x = 0; x < data.revenue.ps.length; x++) {
      var_data_thrid.sales.push({ value: 0 });
    }
    var_data_thrid.total = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.variable_cost_y5 = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];


    console.log(' ERROR--------', data.revenue.ps.length);
    for (const rev_totnew of data.revenue.ps) {
      let sum = 0;

      for (const rev_totnewmonth of rev_totnew.revenue_cost.sales) {
        sum = sum + rev_totnewmonth.value;
      }

      NewfirstYearsales[i] = sum;
      console.log('ERROR--------', i);
      var_data_thrid.sales[i].value = sum;
      i++;
    }

    var_data_thrid.total[0].value = this.otherArraySum(var_data_thrid.sales);
    for (const revenue_costall of data.revenue.ps) {
      let k = 1;
      for (const revenue_cost_single of revenue_costall.revenue_cost_y5.sales) {
        //  console.log('newelvee -', k , revenue_cost_single);
        var_data_thrid.total[k].value += revenue_cost_single.value;
        k++;
      }
    }



    // this.otherArraySum(
    let tppcdollar_value = false;
    let tppcdollar_key = 'Third Party Payment Charges $';
    let variable_cost_value = 0;
    // console.log('Newfirstyearsales', var_data_thrid);
    //  console.log('Newfirstyearsales', NewfirstYearsales);
    console.log('Third Party Payment Charges', data.variablecost);
    if (typeof data.variablecost.tppcdollar !== 'undefined') {
      if (data.variablecost.tppcdollar.variable_cost_y5.value) {
        var_data_thrid.variable_cost_y5 = data.variablecost.tppcdollar.variable_cost_y5.value;
      }
      if (data.variablecost.tppcdollar.value) {
        tppcdollar_value = data.variablecost.tppcdollar.value;
        tppcdollar_key = data.variablecost.tppcdollar.key;
      }
      if (data.variablecost.tppcdollar.variable_cost.value) {
        variable_cost_value = data.variablecost.tppcdollar.variable_cost.value;
      }
    }



    this.setVariableCostReportDataForObject('op_partners', data);
    this.setVariableCostReportDataForArray('salaries', data);
    // if (typeof data.variablecost.tot_emp_at_yearEnd !== 'undefined') {
    // this.setVariableCostReportDataForZeroObject('tot_emp_at_yearEnd', data);
    // }
    this.setVariableCostReportDataForArray('marketing', data);
    this.setVariableCostReportDataForArray('sales', data);
    this.setVariableCostReportDataForArray('service', data);
    this.setVariableCostReportDataForObject('logistics', data);

    this.setVariableCostReportDataForObject('cps', data);
    this.setVariableCostReportDataForObject('tad', data);
    this.setVariableCostReportDataForArray('uv', data);
    this.setVariableCostReportDataForObject('bte', data);
    this.setVariableCostReportDataForArray('other', data);
    this.setVariableCostReportDataForObject('historical', data);
    return this.report_data;
  }

  setVariableCostReportDataForArray(var_cost2, data) {
    for (const var_cost of data.variablecost[var_cost2]) {
      const temp_obj = { status: var_cost.value, key: var_cost.key, val: [0, 0, 0, 0, 0] };
      let count = 1;
      // const tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      let tem_sum = 0;
      if (var_cost.variable_cost) {
        tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      }
      this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);
      temp_obj.val[0] = tem_sum;
      // this.report_data.variable_cost.total[0] += tem_sum;
      if (var_cost.variable_cost_y5) {
        for (const vc of var_cost.variable_cost_y5.value) {
          const vcval = this.numOr0(vc.value);
          temp_obj.val[count] += vcval;
          this.report_data.variable_cost.total[count] += vcval;
          count++;
        }
      }

      this.report_data.variable_cost.value.push(temp_obj);
    }
  }
  setVariableCostReportDataForObject(var_cost, data) {
    const temp_obj = { status: data.variablecost[var_cost].value, key: data.variablecost[var_cost].key, val: [0, 0, 0, 0, 0] };
    let count = 1;
    // const tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    let tem_sum = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
      tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    }
    this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);
    temp_obj.val[0] = tem_sum;
    // this.report_data.variable_cost.total[0] += tem_sum;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
      for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        this.report_data.variable_cost.total[count] += vcval;
        count++;
      }
    }

    this.report_data.variable_cost.value.push(temp_obj);
  }
  // set variable Data
  setFixedCostReportData(data): Observable<{}> {
    this.report_data.fixed_cost = { value: [], total: [0, 0, 0, 0, 0] };
    // const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'citd'];
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'citd', 'wis', 'ed', 'uf', 'mvc'];

    for (const fixd_cost of fc_keys) {

      const temp_obj = {
        key: data.fixedcost[fixd_cost].key,
        status: data.fixedcost[fixd_cost].value,
        val: [0, 0, 0, 0, 0]
      };
      let count = 1;
      // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      let tem_sum = 0;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
        tem_sum = this.user.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      }

      this.report_data.fixed_cost.total[0] += this.user.numOr0(tem_sum);
      temp_obj.val[0] = tem_sum;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
        for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
          temp_obj.val[count] = this.user.numOr0(res.value);
          this.report_data.fixed_cost.total[count] += this.user.numOr0(res.value);
          count++;
        }
      }
      console.log('fc_keys ', fixd_cost, data, temp_obj);
      this.report_data.fixed_cost.value.push(temp_obj);
    }
    const fc_array_keys = ['citd', 'other', 'otherCE'];
    for (const key of fc_array_keys) {
      for (const other of data.fixedcost[key]) {

        if (other.value) {


          const temp_obj = {
            key: other.key,
            status: other.value,
            val: [0, 0, 0, 0, 0]
          };
          let count = 1;
          // const tem_sum = this.otherArraySum(other.fixed_cost.fc);
          let tem_sum = 0;
          if (other.fixed_cost) {
            tem_sum = this.user.otherArraySum(other.fixed_cost.fc);
          }
          this.report_data.fixed_cost.total[0] += this.user.numOr0(tem_sum);
          temp_obj.val[0] = tem_sum;
          if (other.fixed_cost_y5) {
            for (const fcy5 of other.fixed_cost_y5.val) {
              temp_obj.val[count] = this.user.numOr0(fcy5.value);
              this.report_data.fixed_cost.total[count] += this.user.numOr0(fcy5.value);
              count++;
            }
          }
          console.log('this.report_data.fixed_cost ', this.report_data.fixed_cost);
          this.report_data.fixed_cost.value.push(temp_obj);
        }
      }
    }

    const fc_keys1 = ['historical'];

    for (const fixd_cost of fc_keys1) {
      const temp_obj = {
        key: data.fixedcost[fixd_cost].key,
        status: data.fixedcost[fixd_cost].value,
        val: [0, 0, 0, 0, 0]
      };
      let count = 1;
      // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      let tem_sum = 0;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
        tem_sum = this.user.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      }
      this.report_data.fixed_cost.total[0] += this.user.numOr0(tem_sum);
      temp_obj.val[0] = tem_sum;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
        for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
          temp_obj.val[count] = this.user.numOr0(res.value);
          this.report_data.fixed_cost.total[count] += this.user.numOr0(res.value);
          count++;
        }
      }

      this.report_data.fixed_cost.value.push(temp_obj);
    }
    return this.report_data;
  }

  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }

  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }

}
