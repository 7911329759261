import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router , ActivatedRoute} from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-report-revenue-cogs-y1',
  templateUrl: './report-revenue-cogs-y1.component.html',
  styleUrls: ['./report-revenue-cogs-y1.component.css']
})
export class ReportRevenueCogsY1Component implements OnInit {
  variable_data: any = {};
  buttonClicked = 2;
  tooltip_data: any;
  month_order: any;
  c_user: any;
  user_scope = 0;
  total_revenue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; not_saved_yet = true;
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  hidetrailvideo: boolean;
  constructor(private route: ActivatedRoute,private router: Router, private user: UserService, private tooltip: TooltipService, private sanitizer: DomSanitizer) {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['dataentry'] + '/';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);

   }

  ngOnInit() {
    this.user.brcr_back = '/user/kwik-look-main';
    this.user.brcr_next = '/user/revenue-cogs/y5';
    this.tooltip_data = this.tooltip.getTooltips();

    this.c_user = this.user.getUserObject();

    if (this.c_user.plan_details.slug === 'free_trial' || this.c_user.plan_details.slug === '') {
    this.hidetrailvideo = true;
    }

    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        } else if (data.scope === 1) {
          this.user.brcr_next = '/user/fixed-cost/y1';
        }
      }
    );
    // this.user.getUserVaiableData().subscribe(
    //   data => {
    //     if (Object.keys(data).length && data.cogs)  {
    //       if (!data.cogs.type) {
    //         data.cogs.type = '1';
    //       }
    //       // console.log('getuservariable', data);
    //       // console.log('data.revenue', data.revenue);

    //       if (typeof data.revenue.rnotes === 'undefined') {

    //         const def_objnotes = {
    //           key: 'Notes', value: false,
    //           content: 'Notes',
    //           content_y5: 'Notes'
    //         };

    //         data.revenue.rnotes = def_objnotes;

    //        }

    //     //   console.log('getuservariable after data', data);
    //       this.user.varSelection = this.variable_data = data;
    //     } else {
    //       this.router.navigateByUrl('/user/variable-selection');
    //     }
    //   }
    // );

    this.user.getSharedReport(this.route.snapshot.params.id, 'revenue_cogs_y1').subscribe(
      data => {
        if (data.status) {
          // console.log(data.data);
          this.variable_data = data.data;
     
        }
      }
    );


    this.month_order = this.user.getMonthOrder();
    this.user.syncUserData(this.variable_data).subscribe();
  }
  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroRevenueY1(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }

  }
  print_fns() {
    this.user.print_fns();
  }

  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
  
 
}
