import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sensitivity-input',
  templateUrl: './sensitivity-input.component.html',
  styleUrls: ['./sensitivity-input.component.css']
})
export class SensitivityInputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
