import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class Year1ReportService {
  report_data: any = {};
  chartOptions: any;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  hidelinechart: boolean;
  fisc_inputs = { service_charge: 10 };
  fixed_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true
    }
  };
  total_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true,
    }
  };
  constructor(private user: UserService) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
  }


 getYear1BaseReportData(data) {
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: {
          display: true,
          boxWidth: 10
        },
        position: 'bottom'
      },
    };

    this.chartData = [
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cum Cash Flow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];

    this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.color_arr = [{
      // tslint:disable-next-line:max-line-length
      backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }];

    this.report_data = {};
    this.report_data.revenue = {};
    this.report_data.revenue.sales_product_service = [];
    this.report_data.revenue.total_sales_revenue = { value: [] };
    this.report_data.revenue.total_revenue = { arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 };
    this.report_data.cogs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.fixed_costs = { value: [], other: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.variable_costs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.base_case = {
      cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      cum_cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      max_exp: 9999999,
      max_exp_month: 0,
      break_even_period: 'More than 1 year'
    };




    this.setRevemueReportData(data);
    this.setCOGSReportData(data);
    this.setFixedCostReportData(data);
    this.setVariableCostReportData(data);
    // this.breakDownFixedCostGraphData();
    // this.breakDownTotalCostGraphData();
    this.setCashFlowData();
    this.roundReportValues(this.report_data);
    this.report_data.cogs.enable_cost = data.cogs.cost.value;
    this.report_data.cogs.enable_unitcost = data.cogs.unitcost.value;
    return this.report_data;
  }
  roundReportValues(report_data) {
    // console.log(this.report_data);
  }

  setCashFlowData() {
    let i = 0;
    const rd = this.report_data;
    let sum = 0;
    let cum_sum = 0;
    for (const fcval of this.report_data.fixed_costs.total.val) {
     // console.log( ' rd.variable_costs', rd.variable_costs);
     // console.log( ' rd.variable_costs.total.val[i]', rd.variable_costs.total.val[i]);
      const res = Math.round((rd.revenue.total_revenue.arr[i] - rd.cogs.total.val[i]) - (fcval + rd.variable_costs.total.val[i]));
      rd.base_case.cash_flow.val[i] = res;
      sum += res;
      cum_sum += sum;
      rd.base_case.cum_cash_flow.val[i] = sum;
      if (sum < this.report_data.base_case.max_exp) {
        this.report_data.base_case.max_exp = sum;
        // this.report_data.base_case.max_exp_month = i + 1 ;
      }
      i++;
    }
    rd.base_case.cash_flow.total = sum;
    rd.base_case.cum_cash_flow.total = cum_sum;


    this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];
    this.chartData[0].data = this.report_data.base_case.cash_flow.val;
    this.chartData[1].data = this.report_data.base_case.cum_cash_flow.val;

    if ((this.report_data.base_case.cash_flow.total !== 0) || (this.report_data.base_case.cum_cash_flow.total !== 0)) {
      this.hidelinechart = true;
    }
    this.report_data.base_case.max_exp_month = 0;
    for (const res of this.report_data.base_case.cash_flow.val) {
      if (res < 0) {
        this.report_data.base_case.max_exp_month++;
      }
    }
  }
  setVariableCostReportDataForObject(var_cost, data) {
    const temp_obj = {
      key: data.variablecost[var_cost].key,
      status: data.variablecost[var_cost].value,
      val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      percnt: 0,
    };
    let count = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].value && data.variablecost[var_cost].variable_cost) {
      for (const vc of data.variablecost[var_cost].variable_cost.val) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        temp_obj.total += vcval;
        this.report_data.variable_costs.total.val[count] += vcval;
        this.report_data.variable_costs.total.tot += vcval;
        count++;
      }
    }
    this.report_data.variable_costs.value.push(temp_obj);
  }
  setVariableCostReportDataForArray(var_cost2, data) {

    console.log('setVariableCostReportDataForArray-----------------------------', var_cost2, data) ;

    for (const var_cost of data.variablecost[var_cost2]) {
      const temp_obj = {
        key: var_cost.key,
        status: var_cost.value,
        val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        total: 0,
        percnt: 0,
      };
      let count = 0;
      if (var_cost.variable_cost && var_cost.value === true) {

        console.log('single data******************************', var_cost.value) ;
        for (const vc of var_cost.variable_cost.val) {
          const vcval = Math.round(this.numOr0(vc.value));
          temp_obj.val[count] += vcval;
          temp_obj.total += vcval;
          this.report_data.variable_costs.total.val[count] += vcval;
          this.report_data.variable_costs.total.tot += vcval;
          count++;
        }
      }

      if (var_cost.value === true) {
        this.report_data.variable_costs.value.push(temp_obj);
        console.log('*************************temp_obj', temp_obj);
      }
    }
  }
  setVariableCostReportData(data) {

    const var_data_thrid: any = {};

    var_data_thrid.total  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];


    console.log('setVariableCostReportDataForObject',data);

    this.setVariableCostReportDataForObject('op_partners', data);
    this.setVariableCostReportDataForArray('salaries', data);
    this.setVariableCostReportDataForArray('marketing', data);
    this.setVariableCostReportDataForArray('sales', data);
    this.setVariableCostReportDataForArray('service', data);
    this.setVariableCostReportDataForObject('logistics', data);


    // Third party charge
    let t_type = 1;
    if (data.variablecost.tppc.type) {
      t_type = data.variablecost.tppc.type;
    }

  let tppcdollar_value = false;
  let tppcdollar_key = 'Third Party Payment Charges $';
  let variable_cost_value = 0;

    if (typeof data.variablecost.tppcdollar !== 'undefined') {
      if (data.variablecost.tppcdollar.value) {
        tppcdollar_value = data.variablecost.tppcdollar.value;
        tppcdollar_key = data.variablecost.tppcdollar.key;
      }
      if (data.variablecost.tppcdollar.variable_cost.value) {
         variable_cost_value = data.variablecost.tppcdollar.variable_cost.value;
      }
    }


    const tppc_obj = {
      key: data.variablecost.tppc.key,
      type: t_type,
      status: data.variablecost.tppc.value,
      val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      percnt: 0,
    };

    const tppc_dollar = {
      key: tppcdollar_key,
      type: t_type,
      status: tppcdollar_value,
      val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      percnt: 0,
    };
    let tppc_count = 0;

    for (const revenu_sales of data.revenue.ps) {
        let e = 0;
        for (const revenu_sales_single of revenu_sales.revenue_cost.sales) {
          var_data_thrid.total[e].value += this.numOr0(revenu_sales_single.value);
          e++;
        }
     }
    // console.log('revenu_sales All values', var_data_thrid.total);
    // data.variablecost.tppc.variable_cost.value = 2;
    if (data.variablecost && data.variablecost.tppc && data.variablecost.tppc.value && data.variablecost.tppc.variable_cost) {
      for (const rev_tot of this.report_data.revenue.total_revenue.arr) {
        let tppc_sum = 0;
        let tppc_sum2 = 0;

        let tppc_sum3 = 0;
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);

    /*    tppc_sum2 = ( (var_data_thrid.sales1[tppc_count].value +
           var_data_thrid.sales2[tppc_count].value +
           var_data_thrid.sales3[tppc_count].value ) * data.variablecost.tppcdollar.variable_cost.value );

*/
          tppc_sum2 = var_data_thrid.total[tppc_count].value
          * variable_cost_value;

          /* console.log('tppc_sum22 -', tppc_count , tppc_sum2);
           console.log('total_sales_revenu -', tppc_count , var_data_thrid.total[tppc_count].value
           * data.variablecost.tppcdollar.variable_cost.value);*/


      /*
        if ( tppc_obj.type === 2) {
          tppc_sum = data.variablecost.tppc.variable_cost.value;
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        }
*/
       // tppc_sum =  tppc_sum + tppc_sum2;
       tppc_dollar.val[tppc_count] = tppc_sum2;
       tppc_dollar.total += tppc_sum2;
       tppc_sum3 = tppc_sum + tppc_sum2;

        tppc_obj.val[tppc_count] = tppc_sum;
        tppc_obj.total += tppc_sum;
        this.report_data.variable_costs.total.val[tppc_count] += tppc_sum3;
        this.report_data.variable_costs.total.tot += tppc_sum3;
        tppc_count++;
      }





/* jobin old code
if (data.variablecost && data.variablecost.tppc && data.variablecost.tppc.value && data.variablecost.tppc.variable_cost) {
  for (const rev_tot of this.report_data.revenue.total_revenue.arr) {
    let tppc_sum = 0;

    if ( tppc_obj.type === 2) {
      tppc_sum = data.variablecost.tppc.variable_cost.value;
    } else {
      tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
    }

    tppc_obj.val[tppc_count] = tppc_sum;
    tppc_obj.total += tppc_sum;
    this.report_data.variable_costs.total.val[tppc_count] += tppc_sum;
    this.report_data.variable_costs.total.tot += tppc_sum;
    tppc_count++;
  }*/


    }
   // console.log('Calc_Total_Rev_Sales_SC_Y1', this.report_data.revenue.total_revenue.arr);
   // console.log('tppc_obj', tppc_obj);
   // console.log('tppc_dollar', tppc_dollar);
    this.report_data.variable_costs.value.push(tppc_obj);
    this.report_data.variable_costs.value.push(tppc_dollar);

    this.setVariableCostReportDataForObject('cps', data);
    this.setVariableCostReportDataForObject('tad', data);
    this.setVariableCostReportDataForArray('uv', data);
    this.setVariableCostReportDataForObject('bte', data);
    this.setVariableCostReportDataForArray('other', data);
    this.setVariableCostReportDataForObject('historical', data);

  }
  setFixedCostReportData(data) {
    // const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'citd'];
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'historical'];

    for (const fixd_cost of fc_keys) {
      if (!data.fixedcost[fixd_cost] || !data.fixedcost[fixd_cost].fixed_cost) {
        break;
      }

        if(data.fixedcost[fixd_cost].value){
          const temp_obj = {
            status: data.fixedcost[fixd_cost].value,
            key: data.fixedcost[fixd_cost].key,
            val: data.fixedcost[fixd_cost].fixed_cost.fc,
            total: Math.round(this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc)),
            percnt: 0,
            type: fixd_cost
          };
          this.report_data.fixed_costs.value.push(temp_obj);
          let count = 0;
          for (const fc of data.fixedcost[fixd_cost].fixed_cost.fc) {
            this.report_data.fixed_costs.total.val[count] += Math.round(this.numOr0(fc.value));
            count++;
          }
       }
    }
    const fc_array_keys = ['citd','other', 'otherCE'];
    for (const key of fc_array_keys) {
      for (const other of data.fixedcost[key]) {
        if (!other.fixed_cost) {
          break;
        }
        if(other.value){
          const temp_obj = {
            key: other.key,
            status: other.value,
            val: other.fixed_cost.fc,
            total: Math.round(this.otherArraySum(other.fixed_cost.fc)),
            percnt: 0,
            type : key
          };
          this.report_data.fixed_costs.other.push(temp_obj);
          let count = 0;
          for (const fc of other.fixed_cost.fc) {
            this.report_data.fixed_costs.total.val[count] += Math.round(this.numOr0(fc.value));
            count++;
          }
        }
      }
    }

    console.log('data----->',data);
    this.report_data.fixed_costs.total.tot = this.report_data.fixed_costs.total.val.reduce((a, b) => a + b, 0);
  }
  setCOGSReportData(data) {
  //  console.log('caluculesetcogs', data.cogs.type);
    let grant_total = 0;
    for (const ps of data.revenue.ps) {
      const temp_obj = { status: ps.value, key: ps.key, val: {} };
      let count = 0;
      let cogs_tot = 0;
      const temp_cogs_val = { status: true, val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0, tot_percnt: 0 };
      if (data.cogs.type === 2 || data.cogs.type === '2') {
        if (ps.revenue_cost && ps.revenue_cost.monthly_cost) {
          for (const res of ps.revenue_cost.monthly_cost) {
            cogs_tot += this.numOr0(res.value);
            this.report_data.cogs.total.val[count] += Math.round(this.numOr0(res.value));
            grant_total += Math.round(this.numOr0(res.value));
            temp_cogs_val.val[count] += Math.round(this.numOr0(res.value));
            count++;
          }
        }

      } else {
        if (ps.revenue_cost && ps.revenue_cost.sales) {
          for (const sales of ps.revenue_cost.sales) {
            const tot = Math.round(this.numOr0(sales.value) * this.numOr0(ps.revenue_cost.unit_monthly_cost));
            cogs_tot += tot;
            this.report_data.cogs.total.val[count] += tot;
            grant_total += tot;
            temp_cogs_val.val[count] += tot;
            count++;
          }
        }
      }
      temp_cogs_val.tot = cogs_tot;
      temp_obj.val = temp_cogs_val;
      this.report_data.cogs.value.push(temp_obj);
    }
    this.report_data.cogs.total.tot = grant_total;
  }
  setRevemueReportData(data) {
    this.report_data.revenue.other = data.revenue.other.revenue_cost;
    this.report_data.revenue.service_charge = data.revenue.sc.value;
    let grant_total = 0;
    const total_revenue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (const ps of data.revenue.ps) {
      const temp_obj = { key: ps.key, status: ps.value, value: [], total: 0, percent_total: 0 };

      const sps_array = [];
      let total = 0;
      let count = 0;
      if (ps.revenue_cost && ps.revenue_cost.sales) {
        for (const sales of ps.revenue_cost.sales) {
          const sales_with_ser_charge = sales.value;
          total += sales_with_ser_charge * ps.revenue_cost.unit_price;
          grant_total += sales_with_ser_charge * ps.revenue_cost.unit_price;

          sps_array.push(Math.round(sales_with_ser_charge * ps.revenue_cost.unit_price));
          if (this.report_data.revenue.total_sales_revenue.value[count]) {
            this.report_data.revenue.total_sales_revenue.value[count] += sales_with_ser_charge * ps.revenue_cost.unit_price;
          } else {
            this.report_data.revenue.total_sales_revenue.value[count] = sales_with_ser_charge * ps.revenue_cost.unit_price;
          }
          count++;
        }
      }
      temp_obj.total = Math.round(total);
      temp_obj.value = sps_array;
      this.report_data.revenue.sales_product_service.push(temp_obj);
    }
    this.report_data.revenue.total_sales_revenue.grant_total = Math.round(grant_total);

    let othet_count = 0;
    if (data.revenue && data.revenue.other && data.revenue.other.revenue_cost) {
      this.report_data.revenue.other.status = data.revenue.other.value;
      for (const other of data.revenue.other.revenue_cost) {
        total_revenue[othet_count] += this.numOr0(other.value);
        this.report_data.revenue.total_revenue.sum += this.numOr0(other.value);
        othet_count++;
      }
    }
    let i = 0;
    for (const tr of total_revenue) {
      const tot_sal = this.numOr0(this.report_data.revenue.total_sales_revenue.value[i]);
      total_revenue[i] += Math.round(tot_sal);
      this.report_data.revenue.total_revenue.sum += tot_sal;
      if (data.revenue.sc.value) {
        total_revenue[i] += Math.round((tot_sal / 100) * this.fisc_inputs.service_charge);
        this.report_data.revenue.total_revenue.sum += (tot_sal / 100) * this.fisc_inputs.service_charge;
      }
      i++;
    }
    this.report_data.revenue.total_revenue.arr = total_revenue;
  }
  MathRoundCustVarCost(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.variable_costs.total.tot) * 100);
  }
  MathRoundCustFixCost(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.fixed_costs.total.tot) * 100);
  }
  MathRoundCustCOGS(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.cogs.total.tot) * 100);
  }
  MathRoundCust(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.revenue.total_sales_revenue.grant_total) * 100);
  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  otherArraySum(x) {
    return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
  }


















//-------------------- for Fixed Cost Year 1 ------------------------------------------
getYear1BaseReportDataForFixedCostY1(data) {
  this.hidelinechart = false;
  this.chartOptions = {
    responsive: true,
    legend: {
      onClick: null,
      labels: {
        display: true,
        boxWidth: 10
      },
      position: 'bottom'
    },
  };

  this.chartData = [
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cash Flow After Tax', fill: false },
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cum Cash Flow After Tax', fill: false, },
    //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
  ];

  this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

  this.color_arr = [{
    // tslint:disable-next-line:max-line-length
    backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
  }];

  this.report_data = {};
  this.report_data.revenue = {};
  this.report_data.revenue.sales_product_service = [];
  this.report_data.revenue.total_sales_revenue = { value: [] };
  this.report_data.revenue.total_revenue = { arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 };
  this.report_data.cogs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
  this.report_data.fixed_costs = { value: [], other: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
  this.report_data.variable_costs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
  this.report_data.base_case = {
    cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
    cum_cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
    max_exp: 9999999,
    max_exp_month: 0,
    break_even_period: 'More than 1 year'
  };




  this.setRevemueReportData(data);
  this.setCOGSReportData(data);
  this.setFixedCostReportData(data);
  this.setVariableCostReportDataForFixedCostY1(data);
  // this.breakDownFixedCostGraphData();
  // this.breakDownTotalCostGraphData();
  this.setCashFlowData();
  this.roundReportValues(this.report_data);
  this.report_data.cogs.enable_cost = data.cogs.cost.value;
  this.report_data.cogs.enable_unitcost = data.cogs.unitcost.value;
  return this.report_data;
}
setVariableCostReportDataForFixedCostY1(data) {

  const var_data_thrid: any = {};

  var_data_thrid.total  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];


  console.log('setVariableCostReportDataForObject',data);

  this.setVariableCostReportDataForObject('op_partners', data);
  this.setVariableCostReportDataForArray('salaries', data);
  this.setVariableCostReportDataForArray('marketing', data);
  this.setVariableCostReportDataForArray('sales', data);
  this.setVariableCostReportDataForArray('service', data);
  this.setVariableCostReportDataForObject('logistics', data);
  this.setVariableCostReportDataForObject('cps', data);
  this.setVariableCostReportDataForObject('tad', data);
  this.setVariableCostReportDataForArray('uv', data);
  this.setVariableCostReportDataForObject('bte', data);
  this.setVariableCostReportDataForArray('other', data);
  this.setVariableCostReportDataForObject('historical', data);

}
//-------------------- End For Fixed Cost Year 1 -------------------------------------







}
