import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-keybenefits',
  templateUrl: './keybenefits.component.html',
  styleUrls: ['./keybenefits.component.css']
})
export class KeybenefitsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      setTimeout(function() {
        if (fragment && document.getElementById(fragment) != null) {
          document.getElementById(fragment).scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    });
  }

}
