import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
@Component({
  selector: 'app-personal-expenses',
  templateUrl: './personal-expenses.component.html',
  styleUrls: ['./personal-expenses.component.css']
})
export class PersonalExpensesComponent implements OnInit, OnDestroy {
  personal_expense: any = {};
  tooltip_data: any;
  constructor(private user: UserService, private router: Router, private tooltip: TooltipService) { }

  ngOnInit() {
    this.user.brcr_back = '/user/variable-cost/y5';
    this.user.brcr_next = '/user/report-base/y1';
    this.tooltip_data = this.tooltip.getTooltips();
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    this.user.getUserPersonalExpense().subscribe(
      data => {
        if ((Object.keys(data).length)) {
          this.personal_expense = data;
        } else {
          this.personal_expense = this.fillDefaultPersonalExpenseValues();
          this.user.personalExpense = this.personal_expense;
        }
        // console.log(this.personal_expense);
      }
    );
  }
  ngOnDestroy() {
    this.personal_expense = this.manageInvalidInputs(this.personal_expense);
    this.user.saveUserPersonalExpense(this.personal_expense).subscribe();
  }
  fillDefaultPersonalExpenseValues() {
    const result = {
      personal_expense: {
        pse: {
          key: 'Period Self Employed',
          value: [
            { value: 12 }, { value: 12 }, { value: 12 }, { value: 12 }, { value: 12 }
          ]
        },
        mi: {
          key: 'Monthly Income (other than from your intended business)',
          value: [
            { key: 'Investments', value: 0, slug: 'invest' },

            { key: 'Other Income', value: 0, slug: 'other_in' }
          ]
        },
        me: {
          key: 'Monthly Expenses (include GST where applicable)',
          value: [
            { key: 'Mortgage / Rent', value: 0, slug: 'mortage' },
            { key: 'Utilities', value: 0, slug: 'utilities' },
            { key: 'Food', value: 0, slug: 'food' },
            { key: 'Household', value: 0, slug: 'household' },
            { key: 'School Fees / Education', value: 0, slug: 's_fees' },
            { key: 'Personal Loans / Commitments', value: 0, slug: 'p_loan' },
            { key: 'Miscellaneous', value: 0, slug: 'miscell' }
          ]
        },
      }
    };
    return result;
  }
  manageInvalidInputs(data) {
    data.personal_expense.pse.value = this.user.rePlaceNegativeNumbersWithZero(data.personal_expense.pse.value);
    data.personal_expense.mi.value = this.user.rePlaceNegativeNumbersWithZero(data.personal_expense.mi.value);
    data.personal_expense.me.value = this.user.rePlaceNegativeNumbersWithZero(data.personal_expense.me.value);
    return data;
  }
  saveAndNavigateBack(link) {
    // this.personal_expense = this.manageInvalidInputs(this.personal_expense);
    this.user.saveAndNavigateBackPE(link, this.personal_expense);
  }
  saveAndNavigateForward(link) {
    // this.personal_expense = this.manageInvalidInputs(this.personal_expense);
    this.user.saveAndNavigateForwardPE(link, this.personal_expense);
  }
  onKeydown(event, k, arr) {
    this.user.onKeydown(event, k, arr);
  }

}
