import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBraintreeModule } from 'ngx-braintree';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { OpportunityFramingComponent } from './opportunity-framing/opportunity-framing.component';
import { AboutComponent } from './about/about.component';
import { ProductServicesComponent } from './product-services/product-services.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { EvaluationMethodologyComponent } from './evaluation-methodology/evaluation-methodology.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { EvaluationConceptsComponent } from './evaluation-concepts/evaluation-concepts.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RegisterPlanComponent } from './register-plan/register-plan.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ControlMessageComponent } from './control-message/control-message.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard, OnlyLoggedInUsersGuard } from './_guards';
import { KwikInputMainComponent } from './kwik-input-main/kwik-input-main.component';
import { VariableSelectionComponent } from './kwik-input-forms/variable-selection/variable-selection.component';
import { KwikInputMainformComponent } from './kwik-input-forms/kwik-input-mainform/kwik-input-mainform.component';
import { KwikFormBreadcumComponent } from './kwik-input-forms/kwik-form-breadcum/kwik-form-breadcum.component';
import { PersonalExpensesComponent } from './kwik-input-forms/personal-expenses/personal-expenses.component';
// tslint:disable-next-line:max-line-length
import { ReportSensitivesYearOneFiveComponent } from './kwik-input-forms/report-sensitives-year-one-five/report-sensitives-year-one-five.component';
import { SensitivityInputComponent } from './kwik-input-forms/sensitivity-input/sensitivity-input.component';
import { SgInputComponent } from './kwik-input-forms/sg-input/sg-input.component';
import { RevenueAndCostsY1ToY5Component } from './kwik-input-forms/revenue-and-costs-y1-to-y5/revenue-and-costs-y1-to-y5.component';
import { FixedCostY1ToY5Component } from './kwik-input-forms/fixed-cost-y1-to-y5/fixed-cost-y1-to-y5.component';
import { VariableCostY1ToY5Component } from './kwik-input-forms/variable-cost-y1-to-y5/variable-cost-y1-to-y5.component';
import { RevenueCogsY1Component } from './kwik-input-forms/revenue-cogs-y1/revenue-cogs-y1.component';
import { FixedCostsY1Component } from './kwik-input-forms/fixed-costs-y1/fixed-costs-y1.component';
import { TrialPeriodExpiredComponent } from './kwik-input-forms/trial-period-expired/trial-period-expired.component';
import { VariableCostY1Component } from './kwik-input-forms/variable-cost-y1/variable-cost-y1.component';
import { ReportBaseYear1Component } from './kwik-input-forms/report-base-year1/report-base-year1.component';
import { ReportSensitivesYearOneComponent } from './kwik-input-forms/report-sensitives-year-one/report-sensitives-year-one.component';
import { ReportBaseY1ToY5Component } from './kwik-input-forms/report-base-y1-to-y5/report-base-y1-to-y5.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SettingsComponent } from './settings/settings.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MySubcriptionComponent } from './kwik-input-forms/my-subcription/my-subcription.component';
import { UpgradeSubscriptionComponent } from './kwik-input-forms/upgrade-subscription/upgrade-subscription.component';
import { SubscriptionPaymentComponent } from './kwik-input-forms/upgrade-subscription/subscription-payment.component';
import { WhyStartUpsFailComponent } from './why-start-ups-fail/why-start-ups-fail.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SumarrayPipe, SumarrayonedPipe, SanitizeHtmlPipe } from './pipes/sumarray.pipe';
import { ChartsModule } from 'ng2-charts';

import { ThankYouComponent } from './kwik-input-forms/thank-you/thank-you.component';
import { NumberOnlyDirective ,
  NumberOnlyWithCentDirective, NumberOnlyWithCent2Directive , InputvalueWithTwoDecimaldirective} from './directives/number-only.directive';
import { BaseY1Component } from './reports/base-y1/base-y1.component';
import { BaseY5Component } from './reports/base-y5/base-y5.component';
import { SensitiveY5Component } from './reports/sensitive-y5/sensitive-y5.component';
import { SensitiveY1Component } from './reports/sensitive-y1/sensitive-y1.component';
import { SharedSuccessfullyComponent } from './reports/shared-successfully/shared-successfully.component';
import { AllsharedreportComponent } from './kwik-input-forms/allsharedreport/allsharedreport.component';
import { AlreadyloginGuard } from './_guards/alreadylogin.guard';
import { VideoComponent } from './video/video.component';
import { IndustryComponent } from './industry/industry.component';
import { BlogComponent } from './blog/blog.component';
// import { SingleComponent } from './single/single.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { AdminReportsComponent } from './reports/admin-reports/admin-reports.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AdminSensitivityComponent } from './reports/admin-sensitivity/admin-sensitivity.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CovidLandingComponent } from './covid-landing/covid-landing.component';
// import { FocusinvalidDirective } from './directives/focusinvalid.directive';
import { ValuationComponent } from './kwik-input-forms/valuation/valuation.component';
import { KeybenefitsComponent } from './keybenefits/keybenefits.component';
import { EntrepreneursguideComponent } from './entrepreneursguide/entrepreneursguide.component';
import { ProductssolutionsComponent } from './productssolutions/productssolutions.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { CaseStudySingleComponent } from './case-study-single/case-study-single.component';
import { ValuationReportComponent } from './reports/valuation-report/valuation-report.component';
import { ReportRevenueCogsY1Component } from './reports/report-revenue-cogs-y1/report-revenue-cogs-y1.component';
import { ReportRevenueCogsY5Component } from './reports/report-revenue-cogs-y5/report-revenue-cogs-y5.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

const RECAPTCHA_V2_DUMMY_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //   canActivate: [AuthGuard],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      breadcrumb: 'Home',
    }
  },
    {
    path: 'keybenefits',
    component: KeybenefitsComponent,
    data: {
      breadcrumb: 'Key Benefits'
    }
   //   loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule)
    },
    {
      path: 'entrepreneursguide',
      component: EntrepreneursguideComponent,
      data: {
        breadcrumb: 'Entrepreneurs Guide'
      }
     //   loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule)
      },

      {
        path: 'products-solutions',
        component: ProductssolutionsComponent,
        data: {
          breadcrumb: 'Products & Solutions'
        }

       //   loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule)
        },
  {
    path: 'about',
    component: AboutUsComponent,
    data: {
      breadcrumb: 'About Us'
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      breadcrumb: 'Contact Us'
    }
  },
  {
    path: 'opportunity-framing',
    component: OpportunityFramingComponent,
    data: {
      breadcrumb: 'Opportunity Framing'
    }
  },

  { path: 'products-services', redirectTo: '/products-solutions', pathMatch: 'full' },
  {
    path: 'products-services',
    component: ProductServicesComponent,
    data: {
      breadcrumb: 'Products & Services'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AlreadyloginGuard ],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'login/:id/:email',
    component: LoginComponent,
    canActivate: [AlreadyloginGuard ],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent,
    data: {
      breadcrumb: 'Testimonials'
    }
  },
  {
    path: 'evaluation-methodology',
    component: EvaluationMethodologyComponent,
    data: {
      breadcrumb: 'Evaluation Methodology'
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      breadcrumb: 'Privacy Policy'
    }
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {
      breadcrumb: 'Frequently Asked Questions'
    }
  },
  {
    path: 'sitemap',
    component: SitemapComponent,
    data: {
      breadcrumb: 'Sitemap'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'free-trial',
    component: CovidLandingComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      breadcrumb: 'Terms & Conditions'
    }
  },
  {
    path: 'evaluation-concepts',
    component: EvaluationConceptsComponent,
    data: {
      breadcrumb: 'Evaluation Concepts'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AlreadyloginGuard],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'register-plan/:plan',
    component: RegisterPlanComponent,
    canActivate: [AlreadyloginGuard],
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [OnlyLoggedInUsersGuard],
    data: {
      breadcrumb: 'Dashboard'
    }
  },
  {
    path: 'forgot-password/:id/:email',
    component: ForgotPasswordComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'why-start-ups-fail',
    component: WhyStartUpsFailComponent,
    data: {
      breadcrumb: 'Why Startups Fail'
    }
  },
  {
    path: 'report/base/y1/:id',
    component: BaseY1Component,
    data: {
      breadcrumb: 'Report Base Year1'
    }
  },
  {
    path: 'report/base/y5/:id',
    component: BaseY5Component,
    data: {
      breadcrumb: 'Report Base Year 1 to Year 5'
    }
  },
  {
    path: 'adminreport/basey1toy5/y5/:id',
    component: AdminReportsComponent,
    data: {
      breadcrumb: 'Report Base Year 1 to Year 5'
    }
  },
  {
    path: 'adminreport/sensy1toy5/y5/:id',
    component: AdminSensitivityComponent,
    data: {
      breadcrumb: 'Report Base Year 1 to Year 5'
    }
  },
  {
    path: 'report/sensitive/y1/:id',
    component: SensitiveY1Component,
    data: {
      breadcrumb: 'Report Sensitivities Y1'
    }
  },
  {
    path: 'report/sensitive/y5/:id',
    component: SensitiveY5Component,
    data: {
      breadcrumb: 'Report Sensitivities Year 1 to Year 5'
    }
  },
  {
    path: 'report/valuation/:id',
    component: ValuationReportComponent,
    data: {
      breadcrumb: 'Valuation Report'
    }
  },
  {
    path: 'report/revenue-cogs-y1/:id',
    component: ReportRevenueCogsY1Component,
    data: {
      breadcrumb: 'Revenue & COGS Year 1'
    }
  },
  {
    path: 'report/revenue-cogs-y5/:id',
    component: ReportRevenueCogsY5Component,
    data: {
      breadcrumb: 'Revenue & COGS Year 1 to Year 5'
    }
  },
  {
    path: 'report/shared-successfully',
    component: SharedSuccessfullyComponent,
    data: {
      breadcrumb: ''
    }
  },

  /*{
  path: 'kwik-look-main',
  component: KwikInputMainComponent,
  canActivate: [OnlyLoggedInUsersGuard],
  data: { breadcrumb: ''  }
  },*/

  {
    path: 'user',
    //  component: KwikInputMainComponent,
    canActivate: [OnlyLoggedInUsersGuard],
    data: { breadcrumb: '' },
    children: [

      { path: '', redirectTo: 'kwik-look-main', pathMatch: 'full', data: { b_lev: 1 } },
      { path: 'kwik-look-main', component: KwikInputMainformComponent, data: { b_lev: 1, brcr: 'Dashboard' } },
      { path: 'variable-selection', component: VariableSelectionComponent, data: { b_lev: 2, brcr: 'Variable Selection' } },

      { path: 'personal-expenses', component: PersonalExpensesComponent, data: { b_lev: 3, per_exp: 1, brcr: 'Personal Expenses' } },

      { path: 'fixed-cost/y1', component: FixedCostsY1Component, data: { b_lev: 3, brcr: 'Fixed Cost y1' } },
      { path: 'fixed-cost/y5', component: FixedCostY1ToY5Component, data: { b_lev: 3, brcr: 'Fixed Cost Y1 to Y5' } },

      { path: 'revenue-cogs/y1', component: RevenueCogsY1Component, data: { b_lev: 3, brcr: 'Revenue & COGS Y1' } },
      { path: 'revenue-cogs/y5', component: RevenueAndCostsY1ToY5Component, data: { b_lev: 3, brcr: 'Revenue & COGS Y1 to Y5' } },

      { path: 'variable-cost/y1', component: VariableCostY1Component, data: { b_lev: 3, brcr: 'Variable Cost Y1' } },
      { path: 'variable-cost/y5', component: VariableCostY1ToY5Component, data: { b_lev: 3, brcr: 'Variable Cost Y1 to Y5' } },

      { path: 'report-base/y1', component: ReportBaseYear1Component, data: { b_lev: 4, brcr: 'Report Base Y1' } },
      { path: 'report-base/y5', component: ReportBaseY1ToY5Component, data: { b_lev: 4, brcr: 'Report Base Y1 to Y5' } },
      // tslint:disable-next-line:max-line-length
      { path: 'report-sensitive/y5', component: ReportSensitivesYearOneFiveComponent, data: { b_lev: 4, brcr: 'Report Sensitivities Y1 to Y5' } },
      { path: 'report-sensitive/y1', component: ReportSensitivesYearOneComponent, data: { b_lev: 4, brcr: 'Report Sensitivities Y1' } },

      { path: 'trial-expired', component: TrialPeriodExpiredComponent },

      { path: 'settings', component: SettingsComponent, data: { brcr: 'My Settings' } },
      { path: 'my-profile', component: MyProfileComponent, data: { brcr: 'My Profile' } },
      { path: 'my-profile/:sub', component: MyProfileComponent, data: { brcr: 'My Profile' } },
      { path: 'my-subscription', component: MySubcriptionComponent, data: { brcr: 'My Subscription' } },
      { path: 'upgrade-subscription', component: UpgradeSubscriptionComponent, data: { brcr: 'Subscription Plans' } },
      { path: 'subscription-payment/:plan', component: SubscriptionPaymentComponent, data: { brcr: 'Subscription Payment' } },
      { path: 'subscription-payment/:plan/:coupon', component: SubscriptionPaymentComponent, data: { brcr: 'Subscription Payment' } },
      { path: 'thank-you', component: ThankYouComponent, data: { brcr: 'Payment Confirmation' } },
      { path: 'reports', component: AllsharedreportComponent, data: { brcr: 'All Saved reports' } },
      { path: 'valuation', component: ValuationComponent, data: { b_lev: 4,  brcr: 'Valuation Report' } },

    ]
  },
  {
    path: 'videos',
    component: VideoComponent,
    data: {
    breadcrumb: 'Videos'
    }
    },
    {
    path: 'industrystatistic',
    component: IndustryComponent,
    data: {
    breadcrumb: 'Industry Statistics for COGS'
    }
    },
    {
      path: 'blog',
      component: BlogComponent,
      data: {
        breadcrumb: 'Blog'
      }
    },
    {
      path: 'case-study',
      component: CaseStudyComponent,
      data: {
        breadcrumb: 'Case Studies'
      }
    },
  {
      path: 'blog/:singleblog',
      component: SingleBlogComponent,
      data: {
        breadcrumb: ''
      }
    },
    {
      path: 'case-study/:singlecasestudy',
      component: CaseStudySingleComponent,
      data: {
        breadcrumb: ''
      }
    },
  {
    path: '**',
    component: SitemapComponent,
    data: {
      breadcrumb: 'Sitemap'
    }
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    OpportunityFramingComponent,
    AboutComponent,
    ProductServicesComponent,
    TestimonialsComponent,
    EvaluationMethodologyComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    SitemapComponent,
    TermsConditionsComponent,
    BreadcrumbComponent,
    EvaluationConceptsComponent,
    LoginComponent,
    RegisterComponent,
    RegisterPlanComponent,
    ControlMessageComponent,
    DashboardComponent,
    KwikInputMainComponent,
    VariableSelectionComponent,
    KwikInputMainformComponent,
    KwikFormBreadcumComponent,
    PersonalExpensesComponent,
    ReportSensitivesYearOneFiveComponent,
    SensitivityInputComponent,
    SgInputComponent,
    RevenueAndCostsY1ToY5Component,
    FixedCostY1ToY5Component,
    VariableCostY1ToY5Component,
    FixedCostsY1Component,
    RevenueCogsY1Component,
    TrialPeriodExpiredComponent,
    VariableCostY1Component,
    ReportBaseYear1Component,
    ReportSensitivesYearOneComponent,
    ReportBaseY1ToY5Component,
    ForgotPasswordComponent,
    SettingsComponent,
    MyProfileComponent,
    MySubcriptionComponent,
    UpgradeSubscriptionComponent,
    SubscriptionPaymentComponent,
    WhyStartUpsFailComponent,
    AboutUsComponent,
    SumarrayPipe,
    SumarrayonedPipe,
    SanitizeHtmlPipe,
    ThankYouComponent,
    NumberOnlyDirective,
    NumberOnlyWithCentDirective,
    NumberOnlyWithCent2Directive,
    InputvalueWithTwoDecimaldirective,
    BaseY1Component,
    BaseY5Component,
    SensitiveY5Component,
    SensitiveY1Component,
    SharedSuccessfullyComponent,
    AllsharedreportComponent,
    VideoComponent,
    IndustryComponent,
    BlogComponent,
    SingleBlogComponent,
    AdminReportsComponent,
    AdminSensitivityComponent,
    CovidLandingComponent,
    ValuationComponent,
    KeybenefitsComponent,
    EntrepreneursguideComponent,
    ProductssolutionsComponent,
    CaseStudyComponent,
    CaseStudySingleComponent,
    ValuationReportComponent,
    ReportRevenueCogsY1Component,
    ReportRevenueCogsY5Component
   // FocusinvalidDirective
  ],

  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    NgxBraintreeModule,
    TooltipModule.forRoot(),
    // RouterModule.forRoot(
    //   appRoutes , {
    //     anchorScrolling: 'enabled',
    //   }
    // ),

    RecaptchaV3Module,
    

    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 25], // cool option, or ideal option when you have a fixed header on top.
    }),
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    

  ],

  providers: [AuthGuard, OnlyLoggedInUsersGuard, Title, AlreadyloginGuard,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Ld3t9MoAAAAAMCyb7avBLagqvBe-vUjTtdsAtRF',
    }
  ],
})
export class AppModule { }
