import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService, AlertService, UserService } from '../services';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginForm: any;
  submitted: any = false;
  loading: any = false;
  classmessages: any;
  message: any;
  messages: any;
  metatag: any;
  remember_user = false;
  verifyform: {email: '', id: ''};
  countries: any;
  sel_country: any;
  selected_country: any;
  createSignUpURL: any;
  companyvariable: boolean;
  profilevariable: boolean;
  constructor(private formBuilder: FormBuilder, private router: Router, private titleService: Title,
    private userService: UserService,
    private alertService: AlertService,
    private route: ActivatedRoute, private meta: Meta,
    private globals: GlobalsService,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any
    ) {

      this.metatag = {
        name: 'description',
        // tslint:disable-next-line:max-line-length
        content: 'User login page for the Kwik Look simple online Cash Flow Evaluation tool, that allows SME entrepreneurs to quickly understand the potential profitability of their new business idea.',
      };
      this.meta.updateTag(this.metatag);

    this.loginForm = this.formBuilder.group({
      'email': ['', [Validators.required]],
      'password': ['', [Validators.required]],
      'selected_country': ['']
    });
    this.verifyform = {
      'email': '',
      'id': '',
    };
    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value ;

      }
    });

    this.globals.createRegisterPurchaseURL.subscribe(value => {
      if (value) {
        this.createSignUpURL = '../register-plan/' + value.registerSignUpURL ;  //    this.createSignUpURL = value.registerSignUpURL ;
      }
    });

    this.companyvariable = false;
    this.profilevariable = false;
  }

  ngOnInit() {

    this.verifyform.email = this.route.snapshot.params.email;
    this.verifyform.id =  this.route.snapshot.params.id;
    if ( this.verifyform.email != null && this.verifyform.id != null ) {
      this.userService.verfiyuser(this.verifyform).pipe(first()).subscribe(
        data => {
            if (data.status) {
                this.message = data.message;
                this.classmessages = 'success';
                setTimeout(() => this.message = '', 3000);
            } else {
                this.message = data.message;
                this.classmessages = 'error';
                setTimeout(() => this.message = '', 3000);
            }
        },
        error => {
          this.message = error.error;
          this.classmessages = 'error';
        }
      );
    }

    this.titleService.setTitle('Kwik Look - Login');
    const saved_user_det = this.userService.getUserSavedPassword();
    if (saved_user_det && saved_user_det.login && saved_user_det.password) {
      this.remember_user = true;
      this.loginForm.get('email').value = saved_user_det.login;
      this.loginForm.get('password').value = saved_user_det.password;

    }
  }

  hideHolder(e: any) {
    // console.log($(e.target));
    $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
    $(e.target).attr('placeholder', '');
    return;
  }
  showHolder(e: any) {
    const txtval = $(e.target).val();
            if (txtval === '') {
                $(e.target).attr('placeholder', $(e.target).data('placeholder'));
            }
  }
  onSubmit() {
    this.message = '';
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.loginUser(this.loginForm.value).pipe(first()).subscribe(
      data => {
        if (data.id) {
          if (this.remember_user) {
            this.userService.setUserSavedPassword(
              { login: this.loginForm.get('email').value, password: this.loginForm.get('password').value }
            );
          } else {
            this.userService.setUserSavedPassword({});
          }

          this.userService.setUserObject(data);
          this.message = data.sucess;
          this.classmessages = 'success';
          // tslint:disable-next-line:no-shadowed-variable
          this.globals.getCountries().subscribe( data => {
            this.countries = data.countries;
            this.sel_country = data.c_country;
            this.localStorage.setItem('kw_lang', this.sel_country);
            this.globals.setCountry(this.sel_country);

            Object.keys(this.countries).forEach(key => {
              const value = this.countries[key];
                if ( key === this.sel_country) {
                  this.globals.setcountrynameGlobal(value);
                }
              });



              if (!this.sel_country) {
                  this.globals.setcountrynameGlobal('Singapore');
                  this.globals.setCountry('SG');
                  this.localStorage.setItem('kw_lang', 'SG');
              }
           /* for (const [key, value] of Object.entries(this.countries)) {
            if ( key === this.sel_country) {
            this.globals.setcountrynameGlobal(value);
            }
            }*/

            });

            const c_user = this.userService.getUserObject();
            this.userService.getCompanyDetails({ 'cid': c_user.cid }).subscribe(
              userdata => {
                if (userdata.results[0].hear_about &&
                  userdata.results[0].permission_to_share &&
                  userdata.results[0].startup) {
                      this.companyvariable = true;
                }

                this.userService.getUserProfileData().subscribe(
                  userprofilrdata => {

                    if (userprofilrdata.results.address &&

                      userprofilrdata.results.country &&
                      userprofilrdata.results.first_name &&
                      userprofilrdata.results.gender &&
                      userprofilrdata.results.last_name &&
                      userprofilrdata.results.post_code &&

                      userprofilrdata.results.user_phone &&
                      userprofilrdata.results.dob.month
                      ) {
                        this.profilevariable = true;
                      }
                      if ( this.companyvariable && this.profilevariable) {
                        this.userService.profile_not_completed = false;
                      } else {
                        this.userService.profile_not_completed = true;
                      }
                      this.localStorage.setItem('kw_profile_not_completed', this.userService.profile_not_completed);
                      this.globals.setProfileStatus(this.userService.profile_not_completed);

                      setTimeout(() => {
                        this.loading = false;

                        if ( this.companyvariable && this.profilevariable || c_user.plan_details.slug  == 'free_trial'  ) {
                          this.router.navigate(['/user']);
                        } else {
                          this.router.navigate(['/user/my-profile']);
                        }
                      }, 1000);




                  });


              });



        } else {
          this.message = data.error;
          this.loading = false;
          this.classmessages = 'error';
        }
        //  this.alertService.success('Login successful', true);
      },
      error => {
        this.message = error.error;
        this.loading = false;
        this.classmessages = 'error';
      }
    );
  }

}
