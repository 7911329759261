import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  tooltip_data = {
    revenue: {
      sc: 'It is common for businesses in the hotel or food & beverage industry to impose a service charge (usually 10%).',
      // tslint:disable-next-line:max-line-length
      ps: 'Estimated number or quantity of monthly (Year 1), or yearly (Years 2 to 5) sales based on your industry research and unique competitive offering. For services, you will also need to take into consideration not only customer acquisition, but also customer loss and retention.',
      unit_price: `Estimated average sales price for each product or service category based on your
      industry research and unique competitive offering.
      EXCLUDE GST as this is passed onto the Government.`,
      other: 'Examples: Other revenue from activities that are not a core part of your business (ie. sponsorship).'
    },
    cogs: {
      // tslint:disable-next-line:max-line-length
      cost_m_y: `Any direct (purchase) costs associated with the products or services being sold.
      Input estimated average unit cost per product or service (ie. rate paid to drivers of a ride hailing service like Uber).`,
      unitcost: `Any direct (purchase) costs associated with the products or services being sold.
      Input estimated total monthly (Year 1) or yearly (Year 2 to 5) cost per product or service
      (ie. total cost per shipment or order etc).`
    },
    fixed_cost: {
      bsm: 'Examples: Registration of company and name, licences, ongoing company secretarial services, tax or accounting services etc',
      // tslint:disable-next-line:max-line-length
      rd: 'This can tie up a significant amount of capital, and is normally 1 month for each year of the lease. It is not a cost but needs to be factored into the cash flow. Often at the end of a lease, the deposit will help to offset any "restoration" costs etc',
      // tslint:disable-next-line:max-line-length
      rent: `Monthly (Year 1) or yearly  (Year 2 to 5) rental payable, based on your research. It is NOT advisable to enter into any tenancy agreement until you have done your homework and are confident you have a profitable business model.

      Consider exploring different rental payment approaches with your landlord, as this can help you to
      manage your cash flow. Check with a registered property agent on what is possible:
      (1) Usually it is possible to negotiate a "rent free" period for renovation and set up of your business.
      (2) Some landlords may be willing to accept a lower rent upfront in return for an element of profit share in the future.`,
      renovation: `As this is a key set up cost, it is recommend to clearly define what needs to be done and get a range of quotes.
      It is also recommended to include a "contingency" factor, as there are always unforseen complications and expenditures.
      Delays in completion and cost "blow outs" can significantly impact your short term cash flow, and longer term profitability.`,
      fe: 'Examples: Tables, chairs, kitchen equipment, manufacturing equipment etc',
      wis: 'Examples: Domain name, email, software, web design & development, web hosting etc',
      ed: 'Examples: Desktop computer, monitor, laptop, ipad or tablet, phone, printer, scanner etc',
      uf: 'Utilities which are billed regardless of usage (ie. fixed broadband subscription).',
      mvc: `Total costs associated with purchasing a vehicle.
      Do not include financing. This can be subsequently assessed using Pro Look.`,
      citd: `Capture any significant spend on custom IT projects here (ie. development of AI, custom apps/software etc).`,
      other: `Capture any other fixed costs specific to your business here.
      Examples: Minimum amount required to open and maintain a corporate
       bank account (this is not a cost but needs to be factored into the cash flow) etc`,
       otherCE: `Capture any other significant capital expenditures here.`
    },
    variable_cost: {
      wages: 'Salaries and benefits (including CPF etc) paid to staff.',
      // tslint:disable-next-line:max-line-length
      op_partners: `Salaries and benefits (including CPF etc) paid to "operating partners" (ie. partners that run the business vs "silent" partners).
      Distribution of profits to shareholders is usually done from corporate profits after tax.`,
      staff1: 'Salaries and benefits (including CPF etc) paid to staff.',
      // marketing: `Examples: Logo design, business cards, marketing materials (ie. fliers, banners, catalogues
      //    etc), conference booths, Facebook & Google Ad campaigns, discounts, samples or give aways etc`,
      marketing: `<b>KNOW:</b> Mobile desktop search, location, physical reminder, neighbor, FB group SEO,
      Advertising, referrals, signs, direct mail, outdoor, sticker<br/>
      <b>LIKE:</b> Website/mobile experience, incentive, content, social media engagement,
      Website promise, useful content, social media, fast load, forms, chat<br/>
      <b>TRUST:</b> Website visit, search reviews, ask friends, deep Google SEO,
      reviews, examples, signals, video, your people, PR, community`,
      logistics: 'Examples: Shipment, customs charges, delivery etc',
      sales: `<b>TRY:</b> Incentive, look for special offer, down time special - upgrade offer experience, mailing SMS, mobile app<br/>
      <b>BUY:</b> Drive in slow time, experience, upgrade, result,
      Service tech, new experience, survey, what time meter, greeter, menu`,
      service: `<b>REPEAT:</b> Find magnet/sticker, visit website, mobile app,
      review, flash sales, membership, re-engage, social media, events <br/>
      <b>REFER:</b> Get an offer, respond to being asked, they get asked, champion events, runtime mailings,
      strategic partner offers, community involvement, your brand - values`,
      // tslint:disable-next-line:max-line-length
      tppc: 'Third Party Payment Gateway Providers (ie. Braintree, Paypal, STRIPE) often charge a combination of % and fixed $/transaction.',
      // tslint:disable-next-line:max-line-length
      tppcdollar: 'Third Party Payment Gateway Providers (ie. Braintree, Paypal, STRIPE) often charge a combination of % and fixed $/transaction.',
      cps: 'Examples: Paying for specialist advice or expertise (ie. legal, human resources, marketing, management consulting etc).',
      tad: `Examples: Subscriptions to training portals, training programs or classes, individual coaching, learning materials etc.
      It is important to invest in coaching and training (for self and staff) to develop the skills
       required to turn your business idea into a successful reality.`,
      uv: 'Utilities which are billed based on usage (ie. electricity, phone etc).',
      bte: 'Examples: Hotel, airfare, petrol, parking, taxi, public transport etc',
      other: `Capture any other variable costs specific to your business here.
      Examples: Stationary, miscellaneous, contingency etc`
    },
    personal_expence: {
      pse: 'Months per year that you anticipate being self employed.',
      invest: 'Examples: Dividends, interest, net rental income etc',
      other_in: 'Examples: Any other regular income etc',
      mortage: 'Examples: Rental, mortage (principle & interest) etc',
      utilities: ' Examples: Phone, electricity, gas, internet, TV subscription etc',
      food: ' Examples: Groceries, restaurants/eating out etc',
      household: 'Examples: General house hold items, maid salary etc',
      s_fees: ' Examples: School fees, extra curricular activities or tuition, learning materials etc',
      // tslint:disable-next-line:max-line-length
      p_loan: 'Examples: Personal loans (ie. car), installment plans, "propping up" non-profitable investments, supporting family etc',
      miscell: 'Examples: Health insurance, clothing, recreation, hobbies, holidays, medical expenses, subscriptions etc'

    }

  };
  constructor() { }
  getTooltips() {
    return this.tooltip_data;
  }
}
