import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'KIWI LOOK';
  onActivate(event) {
    let top = document.getElementById('kwik_header');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}
