import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  countrycode: any;
  constructor(private _user: UserService) {


    /*this.globals.getCountries().subscribe(data => {
      this.countries  =   data.countries;

      Object.keys(this.countries).forEach(key => {
        console.log('getcountry', key);
      });
    });*/




   }
  getTax(capital_allowance_parameters, _rental_depost, _totalcosts, _total_revenue) {

     console.log('_rental_depost total cost _total_revenue',_rental_depost , _totalcosts , _total_revenue);
    const country = (this._user.fiscal_inputs['phase_2']['country']).toLowerCase();
    console.log('others section country', country);
    if (country === 'singapore' ) {
      this.countrycode = 'SG';
    } else if (country === 'malaysia') {
      this.countrycode = 'MY';
    } else if (country === 'australia') {
      this.countrycode = 'AU';
    } else if (country === 'indonesia') {
      this.countrycode = 'ID';
    } else if (country === 'philippines') {
      this.countrycode = 'PH';
    } else if (country === 'brunei') {
      this.countrycode = 'BN';
    } else if (country === 'thailand') {
      this.countrycode = 'TH';
    } else if ( country === 'canada') {
      this.countrycode = 'CA';
    } else if (country === 'new zealand') {
      this.countrycode = 'NZ';
    } else if (country === 'other') {
      this.countrycode = 'OTHER';
    } else if (country === 'china') {
      this.countrycode = 'CN';
    } else if (country === 'uk') {
      this.countrycode = 'UK';
    } else if (country === 'us') {
      this.countrycode = 'US';
    } else if (country === 'asia') {
      this.countrycode = 'ASIA';
    } else if (country === 'eu') {
      this.countrycode = 'EU';
    }  else if (country === 'india') {
      this.countrycode = 'IN';
    } else if (country === 'russia') {
      this.countrycode = 'RU';
    } else if (country === 'middle east') {
      this.countrycode = 'MIDDLEEAST';
    } else if (country === 'africa') {
      this.countrycode = 'AFRICA';
    }  else if (country === 'singapore existing business') {
      this.countrycode = 'SGEB';
    } 
    
    	

    
    else {
      this.countrycode = 'SG';
    }
   // console.clear();
    // console.log('now countrycode', this.countrycode);
    const incorporation_year = this.numOr0(this._user.fiscal_inputs['phase_2']['incorporation_year']);

    // tslint:disable-next-line:max-line-length
    const capital_allowance_period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Cap_Allowance_Period']);
    const tax_payable_delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Tax_Payable_Delay']);
    const disallowed_cost = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Disallowed_Costs']);



    // tslint:disable-next-line:max-line-length
    const Input_CIT_SG_Rebate_Cap = this.getParamsByIncorpYear(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Rebate_Cap']);
    // tslint:disable-next-line:max-line-length
    const Input_CIT_SG_Rebate_Rate = this.getParamsByIncorpYear(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Rebate_Rate']);
    // console.log('incorporation_year', incorporation_year);
    // console.log('capital_allowance_period', capital_allowance_period);
    // console.log('tax_payable_delay', tax_payable_delay);
    // console.log('disallowed_cost', Input_CIT_SG_Rebate_Cap);
    // console.log('disallowed_cost', Input_CIT_SG_Rebate_Rate);

    const year_of_tax_assesment_flag = this.getYearOfTaxAssessmentFlag(incorporation_year, tax_payable_delay);
    const total_capital_assets = this.getTotalCapitalAssets(capital_allowance_parameters);
    const depreciation_years = this.getDepreciationYearValues(capital_allowance_period, total_capital_assets);
    const total_capital_allowance = this.getTotalCapitalAllowance(depreciation_years);
    const taxable_income_in_YOA = this.getTaxableIncomeYOA(_total_revenue, _rental_depost, disallowed_cost, total_capital_assets,
      total_capital_allowance, _totalcosts, tax_payable_delay);
    const country_based_tax = this.getCountryBasedTax(country, taxable_income_in_YOA, _total_revenue, tax_payable_delay);
    const tax_after_rebate = this.getTaxAfterRebate(country_based_tax, Input_CIT_SG_Rebate_Cap, Input_CIT_SG_Rebate_Rate);
    const actual_tax_paid = this.getActualTaxPaid(tax_after_rebate, year_of_tax_assesment_flag);
    console.log('tax_after_rebate', tax_after_rebate);
    console.log('actual tax', actual_tax_paid);
     console.log('capital_allowance_parameters', capital_allowance_parameters);
     console.log('incorporation_year', incorporation_year );
     console.log('capital_allowance_period', capital_allowance_period );
     console.log('tax_payable_delay', tax_payable_delay );
     console.log('disallowed_cost', disallowed_cost );
     console.log('Input_CIT_SG_Rebate_Cap', Input_CIT_SG_Rebate_Cap );
     console.log('Input_CIT_SG_Rebate_Rate', Input_CIT_SG_Rebate_Rate );
     console.log('year_of_tax_assesment_flag', year_of_tax_assesment_flag );
     console.log('depreciation_years', depreciation_years );
     console.log('total_capital_assets>>>>>', total_capital_assets );
    console.log('total_capital_allowance>>>>>>', total_capital_allowance );
      console.log('taxable_income_in_YOA', taxable_income_in_YOA );
     console.log('country_based_tax', country_based_tax );
     console.log('tax_after_rebate', tax_after_rebate );
     console.log('actual_tax_paid', actual_tax_paid );


    return actual_tax_paid;
  }
  getTaxold(capital_allowance_parameters, _rental_depost, _totalcosts, _total_revenue) { // jobins get tax

    const country = (this._user.fiscal_inputs['phase_2']['country']).toLowerCase();

    const incorporation_year = this.numOr0(this._user.fiscal_inputs['phase_2']['incorporation_year']);

    const capital_allowance_period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_Cap_Allowance_Period']);
    const tax_payable_delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_Tax_Payable_Delay']);
    const disallowed_cost = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_Disallowed_Costs']);

    const Input_CIT_SG_Rebate_Cap = this.getParamsByIncorpYear(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rebate_Cap']);
    const Input_CIT_SG_Rebate_Rate = this.getParamsByIncorpYear(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rebate_Rate']);

    const year_of_tax_assesment_flag = this.getYearOfTaxAssessmentFlag(incorporation_year, tax_payable_delay);
    const total_capital_assets = this.getTotalCapitalAssets(capital_allowance_parameters);
    const depreciation_years = this.getDepreciationYearValues(capital_allowance_period, total_capital_assets);
    const total_capital_allowance = this.getTotalCapitalAllowance(depreciation_years);
    const taxable_income_in_YOA = this.getTaxableIncomeYOA(_total_revenue, _rental_depost, disallowed_cost, total_capital_assets,
      total_capital_allowance, _totalcosts, tax_payable_delay);
    const country_based_tax = this.getCountryBasedTax(country, taxable_income_in_YOA, _total_revenue, tax_payable_delay);
    const tax_after_rebate = this.getTaxAfterRebate(country_based_tax, Input_CIT_SG_Rebate_Cap, Input_CIT_SG_Rebate_Rate);
    const actual_tax_paid = this.getActualTaxPaid(tax_after_rebate, year_of_tax_assesment_flag);
    // console.log('country_based_tax', country_based_tax );
    return actual_tax_paid;
  }
  getActualTaxPaid(tax_after_rebate, year_of_tax_assesment_flag) {


    const tax_payable_delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Tax_Payable_Delay']);
    const Input_CIT_PH_Tax_Payable_Delay =
     this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_PH_Tax_Payable_Delay']);


    const total = [0, 0, 0, 0, 0];
    for (let i = 0; i < 5; i++) {

// new adjusting tax content

      if (this.countrycode === 'PH' ) { // for philliphine calucation
        if ((i) === tax_payable_delay && tax_payable_delay === 0) {
          year_of_tax_assesment_flag[0] = 0;
        }
        if ((i - 1) === tax_payable_delay && i === 1) { // for indo calucation
          total[i] = // Math.round(
            this.AdddecimaltoArray(tax_after_rebate[i] + tax_after_rebate[i - 1]);
         // );
          continue;
        }

      } else if ( this.countrycode === 'ID') {

        if ((i) === tax_payable_delay && tax_payable_delay === 0) {
           year_of_tax_assesment_flag[0] = 0;
        }
        if ((i - 1) === tax_payable_delay && i === 1 && tax_payable_delay === 0) { // for indo calucation
          total[i] = // Math.round(
            this.AdddecimaltoArray(tax_after_rebate[i] + tax_after_rebate[i - 1]);
          // );
          continue;
        }
        if ((i) === 0 && tax_payable_delay === 1) {
          year_of_tax_assesment_flag[0] = 1;
        }


      } else {

        const _Tax_Payable_Delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Tax_Payable_Delay']);
        const tax_Startup_Period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + this.countrycode + '_Startup_Period']);

        if ((_Tax_Payable_Delay + tax_Startup_Period) === 0) {

            if ((i) === tax_payable_delay && tax_payable_delay === 0) {
              year_of_tax_assesment_flag[0] = 0;
            }
            if ((i - 1) === tax_payable_delay && i === 1) { // for indo calucation
              total[i] = // Math.round(
                this.AdddecimaltoArray(tax_after_rebate[i] + tax_after_rebate[i - 1]);
              // );
              continue;
            }

        }

      }



// END new adjusting tax content

    //  console.log('year_of_tax_assesment_flag', year_of_tax_assesment_flag);
      if (parseInt(year_of_tax_assesment_flag[i], 10) === 0) {
        if (i > 0) {
          if (i === 1) {
            total[i] = // Math.round(
              tax_after_rebate[i] + tax_after_rebate[i - 1];
          //  );
          } else {
            total[i] = // Math.round(
              tax_after_rebate[i];
            // );
          }
        }
      } else {
        total[i] = // Math.round(
          tax_after_rebate[i];
       //  );
      }
    }
    return total;
  }


  getActualTaxPaidold(tax_after_rebate, year_of_tax_assesment_flag) {

    const total = [0, 0, 0, 0, 0];
    for (let i = 0; i < 5; i++) {
      if (parseInt(year_of_tax_assesment_flag[i], 10) === 0) {
        if (i > 0) {
          if (i === 1) {
            total[i] = tax_after_rebate[i] + tax_after_rebate[i - 1];
          } else {
            total[i] = tax_after_rebate[i];
          }
        }
      } else {
        total[i] = tax_after_rebate[i];
      }
    }
    return total;
  }
  getTaxAfterRebate(country_based_tax, Input_CIT_SG_Rebate_Cap, Input_CIT_SG_Rebate_Rate) {
    const total = [0, 0, 0, 0, 0];
    for (let i = 0; i < 5; i++) {
      if (country_based_tax[i] > Input_CIT_SG_Rebate_Cap[i] / (Input_CIT_SG_Rebate_Rate[i] / 100)) {
        total[i] = // Math.round(
          this.AdddecimaltoArray(country_based_tax[i] - Input_CIT_SG_Rebate_Cap[i]);
        //  );
      } else {
        total[i] = // Math.round(
          this.AdddecimaltoArray(country_based_tax[i] * (1 - (Input_CIT_SG_Rebate_Rate[i] / 100)));
        //  );
      }
    }
    //  console.log('asdasdsdasdasdd', total );
    return total;
  }
  getCountryBasedTax(country, taxable_income_in_YOA, _total_revenue, tax_payable_delay) {

    switch (country) {

      case 'singapore': {
        return this.getTaxSingapore(taxable_income_in_YOA);
      }

      case 'philippines': {
        return this.getTaxPhilippines(this.countrycode, _total_revenue, taxable_income_in_YOA);
      }

      case 'brunei' : {
        return this.getTaxBrunei(this.countrycode, _total_revenue, taxable_income_in_YOA);
      }

      case 'indonesia': {
        return this.getTaxIndonesia(this.countrycode, _total_revenue, taxable_income_in_YOA);
      }
      default: {
      //  console.log('elvee entered');
    //    return  this.getTaxGeneralnew(_total_revenue, taxable_income_in_YOA, tax_payable_delay);
        return this.getTaxCommonCountry(this.countrycode, _total_revenue, taxable_income_in_YOA);
      }
    }
  }


  getTaxCommonCountry(countrycode, _total_revenue, taxable_income_in_YOA) {
    // console.log('getTaxCommonCountrygetTaxCommonCountry', countrycode);
    const Input_CIT_SG_Startup_Period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Startup_Period']);
    const Input_CIT_Tax_Payable_Delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Tax_Payable_Delay']);
    const Input_CIT_SG_Startup_Threshold1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold1']));
    const Input_CIT_SG_Startup_Threshold2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold2']));
    const Input_CIT_GEN_Rate1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate1']));
    const Input_CIT_GEN_Rate2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate2']));
    const Input_CIT_GEN_Rate3
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate3']));

const Input_CIT_GEN_Threshold1 =
this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold1']));

const Input_CIT_GEN_Threshold2 =
this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold2']));

    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const startup_total = [0, 0, 0, 0, 0];
    const generalbracket1 = [0, 0, 0, 0, 0];
    const generalbracket2 = [0, 0, 0, 0, 0];
    const generalbracket3 = [0, 0, 0, 0, 0];
    const generalbracket4 = [0, 0, 0, 0, 0];

  //   console.log('sdssss', Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay);
  //   console.log('Input_CIT_Gen_Threshold1', Input_CIT_GEN_Threshold1);
  //   console.log('Input_CIT_Gen_Threshold2', Input_CIT_GEN_Threshold2);
  //   console.log('Calc_Taxable_Income_YA_Adj', taxable_income_in_YOA);
  //  console.log('xxxInput_CIT_Gen_Rate1', Input_CIT_GEN_Rate1);
  //   console.log('Input_CIT_Gen_Rate2', Input_CIT_GEN_Rate2);
  //  console.log('Input_CIT_Gen_Rate3', Input_CIT_GEN_Rate3);

    for (let i = 1; i < 6; i++) {
      generalbracket1[i - 1] = 0;
      generalbracket2[i - 1] = 0;
      generalbracket3[i - 1] = 0;
      const period_cond = (i <= (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));
      const period_cond2 = (i > (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));

      if (period_cond2) {
        // General Bracket 1
        if (taxable_income_in_YOA[i - 1] < Input_CIT_GEN_Threshold1[i - 1]) {
          const th = taxable_income_in_YOA[i - 1];
          generalbracket1[i - 1] = // Math.round(
            (th) * (Input_CIT_GEN_Rate1[i - 1] * 0.01);
            // );
        } else {
          const th = Input_CIT_GEN_Threshold1[i - 1];
          generalbracket1[i - 1] = // Math.round(
            (th) * (Input_CIT_GEN_Rate1[i - 1] * 0.01);
        //    );
        }

        // General Bracket 2

        if (taxable_income_in_YOA[i - 1] < Input_CIT_GEN_Threshold1[i - 1]) {
          generalbracket2[i - 1] = 0;
        } else {

          if (taxable_income_in_YOA[i - 1] < Input_CIT_GEN_Threshold2[i - 1]) {
            const th = taxable_income_in_YOA[i - 1] - Input_CIT_GEN_Threshold1[i - 1];
            generalbracket2[i - 1] = // Math.round(
              (th) * (Input_CIT_GEN_Rate2[i - 1] * 0.01);
            //  );
          } else {
            const th = Input_CIT_GEN_Threshold2[i - 1] - Input_CIT_GEN_Threshold1[i - 1];
            generalbracket2[i - 1] = // Math.round(
              (th) * (Input_CIT_GEN_Rate2[i - 1] * 0.01);
            //  );
          }

        }


         // General Bracket 3
        if (taxable_income_in_YOA[i - 1] > Input_CIT_GEN_Threshold2[i - 1]) {
          const th = taxable_income_in_YOA[i - 1] - Input_CIT_GEN_Threshold2[i - 1];
          generalbracket3[i - 1] = // Math.round(
            (th) * (Input_CIT_GEN_Rate3[i - 1] * 0.01);
          //  );
        }

      }

    generalbracket4[i - 1] = generalbracket1[i - 1]  + generalbracket2[i - 1] + generalbracket3[i - 1];
    startup_total[i - 1] = bracket1[i - 1] + bracket2[i - 1] + bracket3[i - 1] + generalbracket4[i - 1];

    }

    // console.log('generalbracket1', generalbracket1);
    // console.log('generalbracket2', generalbracket2);
    // console.log('generalbracket3', generalbracket3);
    // console.log('startup_total', startup_total);

    return startup_total;


  }



  getTaxIndonesia(countrycode, _total_revenue, taxable_income_in_YOA) {


    const Input_CIT_Indo_Rev_Threshold1 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold1']));
    const Input_CIT_Indo_Rev_Threshold2 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold2']));

    const Input_CIT_Indo_Rev_Rate1 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate1']));
    const Input_CIT_Indo_Rev_Rate2 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate2']));
    const Input_CIT_Indo_Rev_Rate3 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate3']));


    // console.log('Input_CIT_Indo_Rev_Threshold1', Input_CIT_Indo_Rev_Threshold1);
    // console.log('Input_CIT_Indo_Rev_Threshold2', Input_CIT_Indo_Rev_Threshold2);
    // console.log('Input_CIT_Indo_Rev_Rate1', Input_CIT_Indo_Rev_Rate1);
    // console.log('Input_CIT_Indo_Rev_Rate2', Input_CIT_Indo_Rev_Rate2);
    // console.log('Input_CIT_Indo_Rev_Rate3', Input_CIT_Indo_Rev_Rate3);

    // console.log('_total_revenue', _total_revenue);
    // console.log('taxable_income_in_YOA', taxable_income_in_YOA);
    const section1 = [0, 0, 0, 0, 0];
    const section2 = [0, 0, 0, 0, 0];
    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const startup_total = [0, 0, 0, 0, 0];


    for (let i = 1; i < 6; i++) {

      // Bracket 1
      if (_total_revenue[i - 1] < Input_CIT_Indo_Rev_Threshold1[i - 1] ) {
        const  th = _total_revenue[i - 1];
        bracket1[i - 1] =  Math.round(th * (Input_CIT_Indo_Rev_Rate1[i - 1] * 0.01));
      } else {
        bracket1[i - 1] = 0;
      }



      // Bracket 2
      if (_total_revenue[i - 1] < Input_CIT_Indo_Rev_Threshold1[i - 1] ) {
        bracket2[i - 1] = 0;
      } else {

          if (_total_revenue[i - 1] < Input_CIT_Indo_Rev_Threshold2[i - 1] ) {


      const divide1 = ( _total_revenue[i - 1] * taxable_income_in_YOA[i - 1] * Input_CIT_Indo_Rev_Rate2[i - 1] * 0.01);
      if (divide1 > 0) {
        section1[i - 1] = Math.round(Input_CIT_Indo_Rev_Threshold1[i - 1] / divide1);

      } else {
        section1[i - 1] = 0;
      }



     const  th2 = ( (_total_revenue[i - 1] - Input_CIT_Indo_Rev_Threshold1[i - 1]) );
     const divide2 = Math.round(_total_revenue[i - 1] * taxable_income_in_YOA[i - 1]  * Input_CIT_Indo_Rev_Rate3[i - 1] * 0.01);
      if (divide2 > 0) {
         section2[i - 1] = (th2 / divide2 );
      } else {
        section2[i - 1] =  0;
      }

            bracket2[i - 1] =   Math.round(section1[i - 1] + section2[i - 1]);

          } else {
            bracket2[i - 1] = 0;
          }

      }


      // Bracket 3

        //  console.log('_total_revenue', i , _total_revenue[i - 1]);
        //  console.log('Input_CIT_Indo_Rev_Threshold2', i  , Input_CIT_Indo_Rev_Threshold2[i - 1]);
        //  console.log('taxable_income_in_YOA', i  , taxable_income_in_YOA[i - 1]);
      if (_total_revenue[i - 1] >= Input_CIT_Indo_Rev_Threshold2[i - 1] ) {
        const  th = taxable_income_in_YOA[i - 1];
        bracket3[i - 1] =  Math.round(th * (Input_CIT_Indo_Rev_Rate3[i - 1] * 0.01));
      }


      startup_total[i - 1] = bracket1[i - 1] + bracket2[i - 1] + bracket3[i - 1];

    }
    // console.log('ID bracket', bracket1);
    // console.log('ID bracket2', bracket2);
    // console.log('ID bracket3', bracket3);
    // console.log('bracket', bracket1, bracket2, bracket3);
    return startup_total;



  }



  getTaxBrunei(countrycode, _total_revenue, taxable_income_in_YOA) {

  // console.log('_total_revenue', _total_revenue);
  // console.log('taxable_income_in_YOA', taxable_income_in_YOA);
    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const startup_total = [0, 0, 0, 0, 0];
    const Input_CIT_BN_Taxfree_Rev_Threshold
    // tslint:disable-next-line:max-line-length
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Taxfree_Rev_Threshold']));

    const Input_CIT_Brunei_Threshold1 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold1']));
    const Input_CIT_Brunei_Threshold2 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Threshold2']));

    const Input_CIT_Brunei_Rate1 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate1']));
    const Input_CIT_Brunei_Rate2 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate2']));
    const Input_CIT_Brunei_Rate3 =
    this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate3']));

    // console.log('Input_CIT_BN_Taxfree_Rev_Threshold', Input_CIT_BN_Taxfree_Rev_Threshold);
    // console.log('Input_CIT_Brunei_Threshold1', Input_CIT_Brunei_Threshold1);
    // console.log('Input_CIT_Brunei_Threshold2', Input_CIT_Brunei_Threshold2);
    // console.log('Input_CIT_Brunei_Rate1', Input_CIT_Brunei_Rate1);
    // console.log('Input_CIT_Brunei_Rate2', Input_CIT_Brunei_Rate2);
    // console.log('Input_CIT_Brunei_Rate3', Input_CIT_Brunei_Rate3);

    for (let i = 1; i < 6; i++) {

       // bracket1

     /*  =IF(Calc_Total_Rev_SC_Y1Y5<Input_CIT_Brunei_Taxfree_Rev_Threshold,
         0,
         IF(Calc_Taxable_Income_YA_Adj<Input_CIT_Brunei_Threshold1,
          Calc_Taxable_Income_YA_Adj*Input_CIT_Brunei_Rate1,
          Input_CIT_Brunei_Threshold1*Input_CIT_Brunei_Rate1))*/

      if (_total_revenue[i - 1] < Input_CIT_BN_Taxfree_Rev_Threshold[i - 1] ) {
        bracket1[i - 1] = 0;
      } else {
          if (taxable_income_in_YOA[i - 1] < Input_CIT_Brunei_Threshold1[i - 1]) {
            const  th = taxable_income_in_YOA[i - 1];
            bracket1[i - 1] = // Math.round(
              this.AdddecimaltoArray(th * (Input_CIT_Brunei_Rate1[i - 1] * 0.01));
              // );
          } else {
            const  th = Input_CIT_Brunei_Threshold1[i - 1];
            bracket1[i - 1] = // Math.round(
              this.AdddecimaltoArray(th * (Input_CIT_Brunei_Rate1[i - 1] * 0.01));
             // );
          }

      }

      // bracket2

      if (_total_revenue[i - 1] < Input_CIT_BN_Taxfree_Rev_Threshold[i - 1] ) {
          bracket2[i - 1] = 0;
      } else {

          if (taxable_income_in_YOA[i - 1] < Input_CIT_Brunei_Threshold1[i - 1] ) {
            bracket2[i - 1] = 0;
          } else {
            if (taxable_income_in_YOA[i - 1] < Input_CIT_Brunei_Threshold2[i - 1] ) {
              const  th = taxable_income_in_YOA[i - 1]  - Input_CIT_Brunei_Threshold1[i - 1];
              bracket2[i - 1] = // Math.round(
                this.AdddecimaltoArray(th * (Input_CIT_Brunei_Rate2[i - 1] * 0.01));
                // );
            } else {
              const  th = Input_CIT_Brunei_Threshold2[i - 1]  - Input_CIT_Brunei_Threshold1[i - 1];
              bracket2[i - 1] = // Math.round(
                this.AdddecimaltoArray(th * (Input_CIT_Brunei_Rate2[i - 1] * 0.01));
               // );
            }
          }


      }


      

      if (_total_revenue[i - 1] < Input_CIT_BN_Taxfree_Rev_Threshold[i - 1] ) {
        bracket3[i - 1] = 0;
      }
      if (taxable_income_in_YOA[i - 1] < Input_CIT_Brunei_Threshold2[i - 1] ) {
        bracket3[i - 1] = 0;
      } else {
        const  th = taxable_income_in_YOA[i - 1]  - Input_CIT_Brunei_Threshold2[i - 1];
        bracket3[i - 1] =  // Math.round(
          this.AdddecimaltoArray(th * (Input_CIT_Brunei_Rate3[i - 1] * 0.01));
      //    );
      }

      startup_total[i - 1] = bracket1[i - 1] + bracket2[i - 1] + bracket3[i - 1];
    }

    // console.log('bracket1', bracket1);
    // console.log('bracket2', bracket2);
    // console.log('bracket3', bracket3);
    // console.log('startup_total', startup_total);
    return startup_total;

  }
  AdddecimaltoArray(value) {
    let decimalresult = 0;
      decimalresult = parseFloat(value.toFixed(2));

    return decimalresult;
  }


  getTaxPhilippines(countrycode, _total_revenue, taxable_income_in_YOA) {

    const Input_CIT_SG_Startup_Period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Startup_Period']);
    const Input_CIT_Tax_Payable_Delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Tax_Payable_Delay']);
    const Input_CIT_PH_Cap_Allowance_Period =
     this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Cap_Allowance_Period']);
    const Input_CIT_Phil_MCIT_Start_Yr = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_MCIT_Start_Yr']);
    const Input_CIT_Phil_MCIT_Rate = this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_MCIT_Rate']);
    const Input_CIT_Phil_Rate = this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_' + countrycode + '_Rate']);
    const startup_total = [0, 0, 0, 0, 0];
    const Calc_CIT_Payable_Phil_MCIT = [0, 0, 0, 0, 0];
    const Calc_CIT_Payable_Phil_CIT = [0, 0, 0, 0, 0];

    for (let i = 1; i < 6; i++) {
//  const period_cond = (i >= (Input_CIT_Phil_MCIT_Start_Yr + Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));

    const period_cond = (i >= (Input_CIT_Phil_MCIT_Start_Yr));
          if (period_cond) {
          const  th = _total_revenue[i - 1];
          Calc_CIT_Payable_Phil_MCIT[i - 1] =  // Math.round(
            th * (Input_CIT_Phil_MCIT_Rate[i - 1] * 0.01);
            // );
          }
          Calc_CIT_Payable_Phil_CIT[i - 1] = // Math.round(
            taxable_income_in_YOA[i - 1] * (Input_CIT_Phil_Rate[i - 1] * 0.01);
            // );

          if (Calc_CIT_Payable_Phil_CIT[i - 1] < Calc_CIT_Payable_Phil_MCIT[i - 1] ) {
            startup_total[i - 1] = Calc_CIT_Payable_Phil_MCIT[i - 1];

          } else {
            startup_total[i - 1] = Calc_CIT_Payable_Phil_CIT[i - 1];
          }


      }
      // console.log('Calc_CIT_Payable_Phil_MCIT', Calc_CIT_Payable_Phil_MCIT);
      // console.log('Calc_CIT_Payable_Phil_CIT', Calc_CIT_Payable_Phil_CIT);
      // console.log('startup_total', startup_total);


      return startup_total;
  }


  getTaxGeneral(_total_revenue, taxable_income_in_YOA, Input_CIT_Tax_Payable_Delay) {
    const Input_CIT_SG_Startup_Period = 3;

    const Input_CIT_Gen_Threshold1 = this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Threshold1'];
    const Input_CIT_Gen_Threshold2 = this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Threshold2'];

    const Input_CIT_Gen_Rate1 = this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate1'];
    const Input_CIT_Gen_Rate2 = this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate2'];
    const Input_CIT_Gen_Rate3 = this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate3'];

    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const total = [0, 0, 0, 0, 0];
    for (let i = 0; i < 5; i++) {
      if ((i + 1) > (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay)) {
        if (taxable_income_in_YOA[i] < Input_CIT_Gen_Threshold1[i]) {
          bracket1[i] = taxable_income_in_YOA[i] * (Input_CIT_Gen_Rate1[i] / 100);
        } else {
          bracket1[i] = Input_CIT_Gen_Threshold1[i] * (Input_CIT_Gen_Rate1[i] / 100);
          if (taxable_income_in_YOA[i] < Input_CIT_Gen_Threshold2[i]) {
            bracket2[i] = (taxable_income_in_YOA[i] - Input_CIT_Gen_Threshold1[i]) * (Input_CIT_Gen_Rate2[i] / 100);
          } else {
            bracket2[i] = (Input_CIT_Gen_Threshold2[i] - Input_CIT_Gen_Threshold1[i]) * (Input_CIT_Gen_Rate2[i] / 100);
          }
        }

        if (taxable_income_in_YOA[i] >= Input_CIT_Gen_Threshold2[i]) {
          bracket3[i] = (taxable_income_in_YOA[i] - Input_CIT_Gen_Threshold2[i]) * (Input_CIT_Gen_Rate3[i] / 100);
        }
      }
      total[i] = Math.round(bracket1[i] + bracket2[i] + bracket3[i]);
    }
    return total;
  }

  getTaxGeneralold(_total_revenue, taxable_income_in_YOA, Input_CIT_Tax_Payable_Delay) {
    const Input_CIT_SG_Startup_Period = 3;

    const Input_CIT_Gen_Threshold1 = this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Threshold1'];
    const Input_CIT_Gen_Threshold2 = this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Threshold2'];

    const Input_CIT_Gen_Rate1 = this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Rate1'];
    const Input_CIT_Gen_Rate2 = this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Rate2'];
    const Input_CIT_Gen_Rate3 = this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Rate3'];

    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const total = [0, 0, 0, 0, 0];
    for (let i = 0; i < 5; i++) {
      if ((i + 1) > (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay)) {
        if (taxable_income_in_YOA[i] < Input_CIT_Gen_Threshold1[i]) {
          bracket1[i] = taxable_income_in_YOA[i] * (Input_CIT_Gen_Rate1[i] / 100);
        } else {
          bracket1[i] = Input_CIT_Gen_Threshold1[i] * (Input_CIT_Gen_Rate1[i] / 100);
          if (taxable_income_in_YOA[i] < Input_CIT_Gen_Threshold2[i]) {
            bracket2[i] = (taxable_income_in_YOA[i] - Input_CIT_Gen_Threshold1[i]) * (Input_CIT_Gen_Rate2[i] / 100);
          } else {
            bracket2[i] = (Input_CIT_Gen_Threshold2[i] - Input_CIT_Gen_Threshold1[i]) * (Input_CIT_Gen_Rate2[i] / 100);
          }
        }

        if (taxable_income_in_YOA[i] >= Input_CIT_Gen_Threshold2[i]) {
          bracket3[i] = (taxable_income_in_YOA[i] - Input_CIT_Gen_Threshold2[i]) * (Input_CIT_Gen_Rate3[i] / 100);
        }
      }
      total[i] = Math.round(bracket1[i] + bracket2[i] + bracket3[i]);
    }
    return total;
  }

  getParamsByIncorpYear(param) {
    if (param.constructor === Array && param.length === 8 ) {
     const start_year = 2021;
     const incor_year = this.numOr0( this._user.fiscal_inputs['phase_2']['incorporation_year'] ); // CURRENT INCORP YEAR
    // const incor_year = 2018;
     const shift_diff = ( incor_year - start_year );
      param.splice(0, shift_diff);
      return param;
    } else {
      return param;
    }
  }

  make1DarNum(arr) {
    return arr.map((a) => this.numOr0(a));
  }

  getTaxSingaporeold(taxable_income_in_YOA) { // jobin get tax singapoure
    const Input_CIT_SG_Startup_Period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Period']);
    const Input_CIT_Tax_Payable_Delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_Tax_Payable_Delay']);
    const Input_CIT_SG_Startup_Threshold1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Threshold1']));
    const Input_CIT_SG_Startup_Threshold2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Threshold2']));
    const Input_CIT_SG_Startup_Rate1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Rate1']));
    const Input_CIT_SG_Startup_Rate2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Rate2']));
    const Input_CIT_Gen_Rate3
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_Gen_Rate3']));

    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const startup_total = [0, 0, 0, 0, 0];
    for (let i = 1; i < 6; i++) {
      const period_cond = (i <= (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));
      if (period_cond) {
        const cond = taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold1[i - 1];
        const val1 = taxable_income_in_YOA[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        const val2 = Input_CIT_SG_Startup_Threshold1[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        bracket1[i - 1] = Math.round(cond ? val1 : val2);
        // bracket2 calculaton starts -------------
        if (taxable_income_in_YOA[i - 1] > Input_CIT_SG_Startup_Threshold1[i - 1]) {
          if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold2) {
            const th = (taxable_income_in_YOA[i - 1] - Input_CIT_SG_Startup_Threshold1[i - 1]);
            bracket2[i - 1] = Math.round(th * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01));
          } else {
            const th = Input_CIT_SG_Startup_Threshold2[i - 1] - Input_CIT_SG_Startup_Threshold1[i - 1];
            bracket2[i - 1] = Math.round((th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01));
          }
        }
        // bracket3 calculaton starts -------------
        if (taxable_income_in_YOA[i - 1] > Input_CIT_SG_Startup_Threshold2[i - 1]) {
          const rem_amt = (taxable_income_in_YOA[i - 1] - Input_CIT_SG_Startup_Threshold2[i - 1]);
          bracket3[i - 1] = Math.round(rem_amt * (Input_CIT_Gen_Rate3[i - 1] * 0.01));
        }
        // bracket3 calculaton ends -------------
      }
      startup_total[i - 1] = bracket1[i - 1] + bracket2[i - 1] + bracket3[i - 1];

    }
    // console.log('bracket', bracket1, bracket2, bracket3);
    return startup_total;
  }
  getTaxSingapore(taxable_income_in_YOA) {


    const Input_CIT_SG_Startup_Period = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Period']);
    const Input_CIT_Tax_Payable_Delay = this.numOr0(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Tax_Payable_Delay']);
    const Input_CIT_SG_Startup_Threshold1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Threshold1']));
    const Input_CIT_SG_Startup_Threshold2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Threshold2']));
    const Input_CIT_SG_Startup_Rate1
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Rate1']));
    const Input_CIT_SG_Startup_Rate2
    = this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Startup_Rate2']));

const Input_CIT_SG_Threshold1 =
this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Threshold1']));

const Input_CIT_SG_Threshold2 =
this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Threshold2']));


const Input_CIT_Gen_Rate1
= this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate1']));

const Input_CIT_Gen_Rate2
= this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate2']));

const Input_CIT_Gen_Rate3
= this.getParamsByIncorpYear(this.make1DarNum(this._user.fiscal_inputs['phase_2']['Input_CIT_SG_Rate3']));


// console.log('Input_CIT_SG_Startup_Threshold1', Input_CIT_SG_Startup_Threshold1);
// console.log('Input_CIT_SG_Startup_Threshold2', Input_CIT_SG_Startup_Threshold2);

// console.log('Input_CIT_SG_Startup_Rate1', Input_CIT_SG_Startup_Rate1);
// console.log('Input_CIT_SG_Startup_Rate2', Input_CIT_SG_Startup_Rate2);


    const bracket1 = [0, 0, 0, 0, 0];
    const bracket2 = [0, 0, 0, 0, 0];
    const bracket3 = [0, 0, 0, 0, 0];
    const startup_total = [0, 0, 0, 0, 0];
    const generalbracket1 = [0, 0, 0, 0, 0];
    const generalbracket2 = [0, 0, 0, 0, 0];
    const generalbracket3 = [0, 0, 0, 0, 0];
    const generalbracket4 = [0, 0, 0, 0, 0];


    console.log('taxable_income_in_YOA------>', taxable_income_in_YOA);
    console.log('Input_CIT_SG_Startup_Threshold1------>', Input_CIT_SG_Startup_Threshold1);
    console.log('Input_CIT_SG_Startup_Threshold2------>', Input_CIT_SG_Startup_Threshold2);
    console.log('Input_CIT_SG_Startup_Rate2------>', Input_CIT_SG_Startup_Rate2);
    console.log('Input_CIT_SG_Startup_Rate2------>', Input_CIT_SG_Startup_Rate2);

    for (let i = 1; i < 6; i++) {
      generalbracket1[i - 1] = 0;
      generalbracket2[i - 1] = 0;
      generalbracket3[i - 1] = 0;
      const period_cond = (i <= (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));
      const period_cond2 = (i > (Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay));
      if (period_cond) {


      /*  const cond = taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold1[i - 1];
        const val1 = taxable_income_in_YOA[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        const val2 = Input_CIT_SG_Startup_Threshold1[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        bracket1[i - 1] = cond ? val1 : val2;*/

        if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold1[i - 1]) {

       // tslint:disable-next-line:max-line-length
       //   console.log('sdasdasdds i', i , taxable_income_in_YOA[i - 1] , Input_CIT_SG_Startup_Threshold1[i - 1] , Input_CIT_SG_Startup_Rate1[i - 1]);
          bracket1[i - 1] =  taxable_income_in_YOA[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        } else {

          bracket1[i - 1] = Input_CIT_SG_Startup_Threshold1[i - 1] * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        }



        //  );Input_CIT_SG_Startup_Threshold1)*Input_CIT_SG_Startup_Rate2)),0)
        // bracket2 calculaton starts -------------

      //   =IF(
      //     Period_Y1Y5 <= ( Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay ),
      //     IF(
      //         Calc_Taxable_Income_YA_Adj < Input_CIT_SG_Startup_Threshold1,
      //         0,
      //         IF(
      //             Calc_Taxable_Income_YA_Adj < Input_CIT_SG_Startup_Threshold2,
      //             ( Calc_Taxable_Income_YA_Adj - Input_CIT_SG_Startup_Threshold1 ) * Input_CIT_SG_Startup_Rate2,
      //             ( Input_CIT_SG_Startup_Threshold2 - Input_CIT_SG_Startup_Threshold1 ) * Input_CIT_SG_Startup_Rate2
      //         )
      //     ),
      //     0
      // )

    
      if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold1[i - 1]) {
        bracket2[i - 1] = 0;
      } else {

        if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold2[i - 1]) {

          const th = (taxable_income_in_YOA[i - 1] - Input_CIT_SG_Startup_Threshold1[i - 1]);

          bracket2[i - 1] = // Math.round(
            th * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
            // );
        } else {

          const th = Input_CIT_SG_Startup_Threshold2[i - 1] - Input_CIT_SG_Startup_Threshold1[i - 1];

          bracket2[i - 1] = // Math.round(
            (th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
            // );
        }

      }




        // bracket3 calculaton starts -------------
 

        if (taxable_income_in_YOA[i - 1] > Input_CIT_SG_Startup_Threshold2[i - 1]) {
          const rem_amt = (taxable_income_in_YOA[i - 1] - Input_CIT_SG_Startup_Threshold2[i - 1]);
          bracket3[i - 1] = // Math.round(
            rem_amt * (Input_CIT_Gen_Rate3[i - 1] * 0.01);
            // );
        }
        // bracket3 calculaton ends -------------
      }



      if (period_cond2) {


       /*
       old code
       if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold1[i - 1]) {
          const th = taxable_income_in_YOA[i - 1];
          generalbracket1[i - 1] =
            (th) * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        } else {
          const th = Input_CIT_SG_Threshold1[i - 1];
          generalbracket1[i - 1] =
            (th) * (Input_CIT_SG_Startup_Rate1[i - 1] * 0.01);
        }
        =IF(
        Period_Y1Y5 > ( Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay ),
        IF(
        Calc_Taxable_Income_YA_Adj < Input_CIT_Gen_Threshold1,
        Calc_Taxable_Income_YA_Adj * Input_CIT_Gen_Rate1,
        Input_CIT_Gen_Threshold1 * Input_CIT_Gen_Rate1
        ),
        0
        )

        */

       if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold1[i - 1]) {
        const th = taxable_income_in_YOA[i - 1];
        generalbracket1[i - 1] =
          (th) * (Input_CIT_Gen_Rate1[i - 1] * 0.01);
      } else {
        const th = Input_CIT_SG_Threshold1[i - 1];
        generalbracket1[i - 1] =
          (th) * (Input_CIT_Gen_Rate1[i - 1] * 0.01);
      }

        /*** Edited General bracket 2 calculations ***/


      /* 
  old code
      if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold1[i - 1]) {
          generalbracket2[i - 1] =  0;
        } else {
          if ( taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold2[i - 1] ) {
            const th = taxable_income_in_YOA[i - 1] - Input_CIT_SG_Threshold1[i - 1];
            generalbracket2[i - 1] =   (th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
          } else {
            const th = Input_CIT_SG_Threshold2[i - 1] - Input_CIT_SG_Threshold1[i - 1];
            generalbracket2[i - 1] = (th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
          }
        }


         =IF(
          Period_Y1Y5 > ( Input_CIT_SG_Startup_Period + Input_CIT_Tax_Payable_Delay ),
          IF(
              Calc_Taxable_Income_YA_Adj < Input_CIT_Gen_Threshold1,
              0,
              IF(
                  Calc_Taxable_Income_YA_Adj < Input_CIT_Gen_Threshold2,
                  ( Calc_Taxable_Income_YA_Adj - Input_CIT_Gen_Threshold1 ) * Input_CIT_Gen_Rate2,
                  ( Input_CIT_Gen_Threshold2 - Input_CIT_Gen_Threshold1 ) * Input_CIT_Gen_Rate2
              )
          ),
          0
      )
      */


        if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold1[i - 1]) {
          generalbracket2[i - 1] =  0;
        } else {
          if ( taxable_income_in_YOA[i - 1] < Input_CIT_SG_Threshold2[i - 1] ) {
            const th = taxable_income_in_YOA[i - 1] - Input_CIT_SG_Threshold1[i - 1];
            generalbracket2[i - 1] =   (th) * (Input_CIT_Gen_Rate2[i - 1] * 0.01);
          } else {
            const th = Input_CIT_SG_Threshold2[i - 1] - Input_CIT_SG_Threshold1[i - 1];
            generalbracket2[i - 1] = (th) * (Input_CIT_Gen_Rate2[i - 1] * 0.01);
          }
        }
        /*** Edited General bracket 2 calculations ***/

        // if (taxable_income_in_YOA[i - 1] < Input_CIT_SG_Startup_Threshold1[i - 1]) {
        //   const th = taxable_income_in_YOA[i - 1] - Input_CIT_SG_Threshold1[i - 1];

        //   console.log('THHHHHHHHHHH>>>>>>', th);
        //   generalbracket2[i - 1] = // Math.round(
        //     (th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
        //    // );
        // } else {
        //   const th = Input_CIT_SG_Threshold2[i - 1] - Input_CIT_SG_Threshold1[i - 1];

        //   console.log('T22222222222>>>>>>', th);
        //   generalbracket2[i - 1] = // Math.round(
        //     (th) * (Input_CIT_SG_Startup_Rate2[i - 1] * 0.01);
        //   //  );
        // }




        if (taxable_income_in_YOA[i - 1] > Input_CIT_SG_Startup_Threshold2[i - 1]) {
          const th = taxable_income_in_YOA[i - 1] - Input_CIT_SG_Threshold2[i - 1];
          generalbracket3[i - 1] = // Math.round(
            (th) * (Input_CIT_Gen_Rate3[i - 1] * 0.01);
            // );
        }


    }



    generalbracket4[i - 1] = this.AdddecimaltoArray(generalbracket1[i - 1])  +
     this.AdddecimaltoArray(generalbracket2[i - 1]) +
     this.AdddecimaltoArray(generalbracket3[i - 1]);


      startup_total[i - 1] = this.AdddecimaltoArray(bracket1[i - 1]) +
      this.AdddecimaltoArray(bracket2[i - 1]) +
      this.AdddecimaltoArray(bracket3[i - 1]) +
      this.AdddecimaltoArray(generalbracket4[i - 1]);

    }


    console.log('bracket1', bracket1);
    console.log('bracket2', bracket2);
    console.log('bracket3', bracket3);
    console.log('generalbracket1', generalbracket1);
    console.log('generalbracket2', generalbracket2);
    console.log('generalbracket3', generalbracket3);
    console.log('bracket', generalbracket4);
    return startup_total;
  }




  getTaxableIncomeYOA(_total_revenue, _rental_depost, disallowed_cost, total_capital_assets,
    total_capital_allowance, _totalcosts, tax_payable_delay) {

     

    const tax_deduction_open_balance = [];
    const tax_deduction_addition = [];
    const tax_deduction_submited = [];
    const computed_taxable_income = [];
    const taxable_income = [];
    const utilized_deductions_carry_forward = [];
    const tax_income_in_yoa = [];

    for (let i = 0; i < 5; i++) {
      tax_deduction_open_balance[i] = (i > 0) ? this.AdddecimaltoArray(utilized_deductions_carry_forward[i - 1]) : 0;
      tax_deduction_addition[i] = // Math.round(
        this.AdddecimaltoArray(  (_totalcosts[i] - _rental_depost[i] - total_capital_assets[i]) *
        (1 - (disallowed_cost / 100)) +
        total_capital_allowance[i] );
        // );
      tax_deduction_submited[i] = this.AdddecimaltoArray( tax_deduction_open_balance[i] + tax_deduction_addition[i] );
      computed_taxable_income[i] =  this.AdddecimaltoArray( _total_revenue[i] - tax_deduction_submited[i]);
      taxable_income[i] = (computed_taxable_income[i] > 0) ?  this.AdddecimaltoArray(computed_taxable_income[i]) : 0;
      utilized_deductions_carry_forward[i] =  this.AdddecimaltoArray( taxable_income[i] - computed_taxable_income[i]);
      tax_income_in_yoa[i] = (i < tax_payable_delay) ? 0 : this.AdddecimaltoArray(taxable_income[i - tax_payable_delay]);
    }

    // console.log('>>>>>>>>total_capital_allowance', total_capital_allowance);
    // console.log('>>>>>>>>> _totalcosts', _totalcosts);
    // console.log('>>>>>>>>>_rental_depost', _rental_depost);
    // console.log('_total_revenue', _total_revenue);
   
    // console.log('disallowed_cost', disallowed_cost);
    // console.log('total_capital_assets', total_capital_assets);
    // console.log('tax_payable_delay', tax_payable_delay);
    
     
    
    // console.log('tax_deduction_open_balance', tax_deduction_open_balance);
    // console.log('tax_deduction_addition', tax_deduction_addition);
    // console.log('tax_deduction_submited', tax_deduction_submited);
    // console.log('computed_taxable_income', computed_taxable_income);
    // console.log('taxable_income', taxable_income);
    // console.log('utilized_deductions_carry_forward', utilized_deductions_carry_forward);


    return tax_income_in_yoa;
  }



  getYearOfTaxAssessmentFlag(incorporation_year, tax_payable_delay) {
    const result = [0, 0, 0, 0, 0];
    //  console.log('incorporation_year', incorporation_year);
    //  console.log('tax_payable_delay', tax_payable_delay);
    result.forEach(function (value, i) {
      if ((incorporation_year + i) >= incorporation_year + tax_payable_delay) {
        result[i] = 1;
      }
    });

   // console.log('getYearOfTaxAssessmentFlag', result);
    return result;
  }

  rowSumMDArray(arr) {
    const result = [0, 0, 0, 0, 0];
    arr.forEach(function (value, i) {
      // console.log( 'value', arr );
      value.forEach(function (elem, j) {
        result[j] += elem;
      });
    });
    return result;
  }

  getTotalCapitalAssets(capital_allowance_parameters) {
    return this.rowSumMDArray(capital_allowance_parameters);
  }

  getDepreciationYearValues(capital_allowance_period, total_capital_assets) {
    const depreciation_y = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ];
    for (let j = 0; j < 5; j++) {
      const upper_limit = ((capital_allowance_period + j) > 5) ? 5 : (capital_allowance_period + j);
      for (let i = 0 + j; i < upper_limit; i++) {
        depreciation_y[j][i] = this.AdddecimaltoArray(total_capital_assets[j] / capital_allowance_period);
      }
    }
    return depreciation_y;
  }

  getTotalCapitalAllowance(depreciation_years) {
    return this.rowSumMDArray(depreciation_years);
  }

  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
}
