import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-opportunity-framing',
  templateUrl: './opportunity-framing.component.html',
  styleUrls: ['./opportunity-framing.component.css']
})
export class OpportunityFramingComponent implements OnInit {
page_content: any;
metatag: any;
  constructor(private cms: CmsService, private meta: Meta) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'The Kwik Look website also provides useful tips on writing a Business Plan (ie. market research, SWOT analysis, identifying the competitive advantage of your product or service, marketing strategy).',
    };
    this.meta.updateTag(this.metatag);
   }

  ngOnInit() {
	   this.cms.getCmsByPage('cms/opportunuty_framing')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
  }

}
