import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';

@Component({
  selector: 'app-evaluation-methodology',
  templateUrl: './evaluation-methodology.component.html',
  styleUrls: ['./evaluation-methodology.component.css']
})
export class EvaluationMethodologyComponent implements OnInit {
 page_content: any;
  constructor(private cms: CmsService) { }

  ngOnInit() {
	   this.cms.getCmsByPage('cms/evaluation_methodology')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
  }

}
