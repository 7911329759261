import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
page_content: any;
metatag: any;
  constructor(private cms: CmsService, private meta: Meta) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Sitemap for the Kwik Look simple online Cash Flow Evaluation tool, that allows SME entrepreneurs to quickly understand the potential profitability of their new business idea.',
    };
    this.meta.updateTag(this.metatag);
   }

  ngOnInit() {
    this.cms.getCmsByPage('cms/sitemap')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
  }

}
