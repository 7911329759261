import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Year1ReportService } from '../../services/year1-report.service';
 
@Component({
  selector: 'app-fixed-costs-y1',
  templateUrl: './fixed-costs-y1.component.html',
  styleUrls: ['./fixed-costs-y1.component.css']
})
export class FixedCostsY1Component implements OnInit, OnDestroy {
  variable_data: any = {};
  contacts: any;
  tooltip_data: any;
  month_order: any;
  Object = Object;
  c_user: any;
  user_scope = 0;
  all_null = true;
  not_saved_yet = true;
  video_modal_src: any;
  autoplayvalue: boolean; 
  video_url: string;
  hidetrailvideo: boolean;
  report_data: any = {};
  constructor(private router: Router, private user: UserService, private tooltip: TooltipService,
     private sanitizer: DomSanitizer,private y1_report: Year1ReportService) {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['dataentry'] + '/';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.report_data.revenue = {};
    this.report_data.revenue.sales_product_service = [];
    this.report_data.revenue.total_sales_revenue = { value: [] };
    this.report_data.revenue.total_revenue = { arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 };
    this.report_data.cogs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.fixed_costs = { value: [], other: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.variable_costs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.base_case = {
      cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      cum_cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      max_exp: 9999999,
      max_exp_month:  0,
      break_even_period: 'More than 1 year'
    };
   }

  ngOnInit() {
    this.user.brcr_back = '/user/revenue-cogs/y5';
    this.user.brcr_next = '/user/fixed-cost/y5';
    this.tooltip_data = this.tooltip.getTooltips();
    this.c_user = this.user.getUserObject();

    if (this.c_user.plan_details.slug === 'free_trial' || this.c_user.plan_details.slug === '') {
    this.hidetrailvideo = true;
    }

    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        } else if (data.scope === 1) {
          this.user.brcr_back = '/user/revenue-cogs/y1';
          this.user.brcr_next = '/user/variable-cost/y1';
        }
      }
    );
    this.user.getUserVaiableData().subscribe(
      data => {


        this.report_data = this.y1_report.getYear1BaseReportData(data);
       
        if (data.fixedcost && data.fixedcost.bsm && data.fixedcost.bsm.fixed_cost) {
          this.not_saved_yet = false;
        }


        if (!(data.fixedcost.historical)
        || data.fixedcost.historical === 'undefined') {  // initalize historical


          data.fixedcost.historical = {
            key: 'Historical Costs', value: false,
            fixed_cost: {
              fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
              { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
            },
            fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
          };
        }



        if (Object.keys(data).length) {
          const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc' , 'historical'];
          for (const service of fc_keys) {
            if (data.fixedcost[service].value) {
              this.all_null = false;
            }
            if (typeof data.fixedcost[service].fixed_cost === 'undefined') {
              const def_obj = {
                fc: [{ value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 }]
              };
              data.fixedcost[service].fixed_cost = def_obj;
            }
          }
          for (const service of data.fixedcost.other) {
            if (service.value) {
              this.all_null = false;
            }
            if (typeof service.fixed_cost === 'undefined') {
              const def_obj = {
                fc: [{ value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 }]
              };
              service.fixed_cost = def_obj;
            }
            console.log('service.fixed_cost', service.fixed_cost);

          }
          for (const service of data.fixedcost.citd) {
            if (service.value) {
              this.all_null = false;
            }
            if (typeof service.fixed_cost === 'undefined') {
              const def_obj = {
                fc: [{ value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 }]
              };
              service.fixed_cost = def_obj;
            }
            console.log('service.fixed_cost', service.fixed_cost);

          }
          for (const service of data.fixedcost.otherCE) {
            if (service.value) {
              this.all_null = false;
            }
            if (typeof service.fixed_cost === 'undefined') {
              const def_obj = {
                fc: [{ value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 }]
              };
              service.fixed_cost = def_obj;
            }
            console.log('service.fixed_cost', service.fixed_cost);

          }

          if ( typeof data.fixedcost.fxnotes === 'undefined') {

            const def_objnotes = {
                  key: 'Notes', value: false,
                  content: 'Notes',
                  content_y5: 'Notes',
                  fixed_cost: {
                  fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                  { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
                  },
                  fixed_cost_y5: {
                  val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
                  },
            }
          data.fixedcost.fxnotes = def_objnotes;


          }
          console.log(data);
          this.user.varSelection = this.variable_data = data;
        }

        console.log(data.fixedcost.fxnotes);


        /*if ( typeof data.fixedcost.fxnotes === 'undefined') {

          const def_objnotes = {
              key: 'Notes', value: false,
              content: 'Notes',
              fixed_cost: {
                fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
              },
              fixed_cost_y5: {
                val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
              },
          const def_objnotes = {
          key: 'Notes', value: false,
          content: 'Notes',
          content_y5: 'Notes',
          fc: [{ value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 }]
          };

                  data.fixedcost.fxnotes = def_objnotes;
               }  */


      }
    );
    this.month_order = this.user.getMonthOrder();
  }

  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY1(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }
  }

  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
  saveAndNavigateBack(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY1(this.variable_data);
    this.user.saveAndNavigateBack(link, this.variable_data);
  }
  saveAndNavigateForward(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY1(this.variable_data);
    this.user.saveAndNavigateForward(link, this.variable_data);
  }
  onKeydown(event, k, arr ,key) {
    console.log('this.variable_data',this.variable_data);
    
    
    if(key !== 'historical'){
      this.user.onKeydown(event, k, arr);
    }
   
    this.getReportdata(this.variable_data);
  }
  
  async getReportdata(data){
    await this.user.delay(50);
    const y1_report  = this.y1_report.getYear1BaseReportData(data);
    this.report_data = y1_report;
    
  }

  closeModal() {
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['dataentry'] + '?autoplay=1&start=11&end=119';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }

}
