import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { Year1ReportService } from '../../services/year1-report.service';
@Injectable({
  providedIn: 'root'
})
export class Y1calcService {

  report_base: any = {};
  month_order: any;
  fisc_inputs = { service_charge: 10 };
  rep_sen_data_keys = ['base_case', 'sal_rev_a', 'sal_rev_m', 'sal_rev_acc_a', 'sal_rev_acc_m', 'cogs', 'staf_sal', 'renovat',
    'furnt_equp', 'logistics', 'citd', 'website_it_sw', 'marketing', 'spare1', 'sal_rev1', 'sal_rev2', 'spare12'];
  rep_sen_data = {
    base_case: {
      label: 'Base Case',
      value: '',
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_a: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_m: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_acc_a: {
      label: 'Sales Revenue Accelerate',
      value: 1,
      unit: 'Month(s)',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_acc_m: {
      label: 'Sales Revenue Delay',
      value: 3,
      unit: ' Month(s)',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    cogs: {
      label: 'COGS ',
      value: +0.2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    staf_sal: {
      label: 'Staff Salaries ',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    renovat: {
      label: 'Renovation ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    furnt_equp: {
      label: 'Equipment',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    citd: {
      label: 'Customised IT Development ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    logistics: {
      label: 'Logistics ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    website_it_sw: {
      label: 'Website/IT/Software ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    marketing: {
      label: 'Marketing ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    spare1: {
      label: 'Spare ',
      value: 1,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev1: {
      label: 'Sales Rev Delay ',
      value: 3,
      unit: 'Mths PLUS Cost inc of Reno & Furn/Equipmt',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev2: {
      label: 'Sales Rev Delay',
      value: 3, unit: 'Mths PLUS Cost Inc of Staff Salaries',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    spare12: {
      label: 'Spare ',
      value: 2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    }
  };
  constructor(private user: UserService, private router: Router, private y1_report: Year1ReportService) {
  }
  getY1SensitiveReport(data) {
    if (this.user.fiscal_inputs['sensitives']) {
      for (const val of this.user.fiscal_inputs['sensitives']) {
        if (this.rep_sen_data[val.sensitive_key]) {
          const fisval = this.numOr0(val.fiscal_value);
          this.rep_sen_data[val.sensitive_key].value = this.numOr0(fisval);
        }
      }
    }
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    const report_data = this.y1_report.getYear1BaseReportData(data);
    this.report_base = report_data;
    this.rep_sen_data.base_case.total = report_data.base_case.cash_flow.total;

    this.setBaseCaseToAllFields(report_data);
    this.calculateSalesRevenue(data);
    this.SalesRevenueAccelerate(report_data, data);
    this.SalesRevenueDelay(report_data, data);
    this.SalesRevenueDelayPlusRenoFurn(report_data, data);
    this.SalesRevenueDelayPlusStafSal(report_data, data);

    this.calculateCumCashflow();
    this.checkPropertyIsEnablesInVarSel(data);
    return this.rep_sen_data;
  }
  checkPropertyIsEnablesInVarSel(data) {
    this.rep_sen_data.staf_sal['var_sel_disable'] = false;
    this.rep_sen_data.renovat['var_sel_disable'] = false;
    this.rep_sen_data.logistics['var_sel_disable'] = false;
    this.rep_sen_data.website_it_sw['var_sel_disable'] = false;
    this.rep_sen_data.marketing['var_sel_disable'] = false;
    this.rep_sen_data.cogs['var_sel_disable'] = false;
    this.rep_sen_data.base_case['var_other_sel_disable'] = false;
    this.rep_sen_data.base_case['var_sales_sel_disable'] = false;
    this.rep_sen_data.base_case['var_sel_disable'] = false;

    for (const sal of data.variablecost.salaries) {  // Staff Salaries
      if (sal.value === true) {
        this.rep_sen_data.staf_sal['var_sel_disable'] = true;
      }
    }

    if (data.fixedcost.renovation.value === true) { // Renovation
      this.rep_sen_data.renovat['var_sel_disable'] = true;
    }

    if (data.fixedcost.fe.value === true) { // Furniture / Equipment
      this.rep_sen_data.furnt_equp['var_sel_disable'] = true;
    }

    if (data.variablecost.logistics.value === true) { // Logistics
      this.rep_sen_data.logistics['var_sel_disable'] = true;
    }

    if (data.fixedcost.wis.value === true) { // website / it
      this.rep_sen_data.website_it_sw['var_sel_disable'] = true;
    }

    for (const sal of data.variablecost.marketing) {  // Marketing
      if (sal.value === true) {
        this.rep_sen_data.marketing['var_sel_disable'] = true;
      }
    }
    if (data.cogs.cost.value === true || data.cogs.unitcost.value === true) { // cogs
      this.rep_sen_data.cogs['var_sel_disable'] = true;
    }
    if (data.revenue.other.value === true) { // Other revenue
      this.rep_sen_data.base_case['var_other_sel_disable'] = true;

    }
    for (const sal of data.revenue.ps) {  // Sales: Products/Services
      if (sal.value === true) {
        this.rep_sen_data.base_case['var_sales_sel_disable'] = true;
      }
    }
    if (this.rep_sen_data.base_case['var_sales_sel_disable'] === true || this.rep_sen_data.base_case['var_other_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sel_disable'] = true;
    }

    if (this.rep_sen_data.base_case['var_sel_disable'] === true ||
      this.rep_sen_data.renovat['var_sel_disable'] === true ||
      this.rep_sen_data.furnt_equp['var_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sale_reno_fur_disable'] = true;
    }

    if (this.rep_sen_data.base_case['var_sel_disable'] === true ||
      this.rep_sen_data.staf_sal['var_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sale_staff_disable'] = true;
    }
    // console.log('reportsensiticve', this.rep_sen_data);
  }
  showMinVal(arr) {
    return Math.round(Math.min.apply(Math, arr));
  }
  showMinValIndex(arr) {
    let result = 0;
    for (const res of arr) {
      if (res < 0) {
        result++;
      }
    }
    return result;
  }
  revenueAvgYear2(data) {
    const total = { rev: 0, cogs: 0 };
    for (const revenue of data.revenue.ps) {
      if (revenue.revenue_cost_y5) {
        total.rev += revenue.revenue_cost_y5.sales[0].value * revenue.revenue_cost_y5.unit_price[0].value;
        if (data.cogs.type === '2' || data.cogs.type === 2) {
          total.cogs += revenue.revenue_cost.monthly_cost[0].value; // revenue.revenue_cost_y5.unit_yearly_cost[0].value;
        } else {
          total.cogs += revenue.revenue_cost_y5.sales[0].value * revenue.revenue_cost_y5.unit_yearly_cost[0].value;
        }
      }
    }
    total.rev = total.rev / 12;
    total.cogs = total.cogs / 12;
    return total;
  }
  SalesRevenueDelay(report, data) {
    const acc_dur = this.rep_sen_data.sal_rev_acc_m.value;
    const tar_res = this.rep_sen_data.sal_rev_acc_m.result;
    for (let i = 0; i < 12; i++) {
      if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
        let tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        let tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppcnew = tppcold =  data.variablecost.tppc.variable_cost.value;
        }
        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev_acc_m.total += tar_res[i];
        } else {
          tar_res[i] = this.rep_sen_data.base_case.result[i];
          this.rep_sen_data.sal_rev_acc_m.total += tar_res[i];
        }
      }

    }
    // sal_rev1
  }
  SalesRevenueDelayPlusRenoFurn(report, data) {
    const rep_sen_data = this.rep_sen_data;
    // console.log('this.rep_sen_data.sal_rev1', this.rep_sen_data.sal_rev1);
    const acc_dur = this.rep_sen_data.sal_rev1.value;
    const tar_res = this.rep_sen_data.sal_rev1.result;
    if (data.variablecost.tppc.variable_cost && data.variablecost.tppc.variable_cost.value) {
      for (let i = 0; i < 12; i++) {
        let tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        let tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppcnew = tppcold =  data.variablecost.tppc.variable_cost.value;
        }
        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev1.total += tar_res[i];
        } else {
          tar_res[i] = this.rep_sen_data.base_case.result[i];
          this.rep_sen_data.sal_rev1.total += tar_res[i];
        }
      }
    }

    // Renovation +0.5
    {
      let k = 0;
      // const ren = data.fixedcost.renovation.fixed_cost.fc;
      let ren = [];
      if (data.fixedcost.renovation && data.fixedcost.renovation.fixed_cost) {
        ren = data.fixedcost.renovation.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.renovat.value;
        rep_sen_data.sal_rev1.result[k] -= Math.round(ren_persnt);
        rep_sen_data.sal_rev1.total -= Math.round(ren_persnt);
        k++;
      }
    }
    // Furniture.
    {
      let k = 0;
      // const ren = data.fixedcost.fe.fixed_cost.fc;
      let ren = [];
      if (data.fixedcost.fe && data.fixedcost.fe.fixed_cost) {
        ren = data.fixedcost.fe.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.furnt_equp.value;
        rep_sen_data.sal_rev1.result[k] -= Math.round(ren_persnt);
        rep_sen_data.sal_rev1.total -= Math.round(ren_persnt);
        k++;
      }
    }
  }
  SalesRevenueDelayPlusStafSal(report, data) {
    const rep_sen_data = this.rep_sen_data;
    const acc_dur = this.rep_sen_data.sal_rev2.value;
    const tar_res = this.rep_sen_data.sal_rev2.result;
    if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
      for (let i = 0; i < 12; i++) {
        const tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        const tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev2.total += tar_res[i];
        } else {
          tar_res[i] = this.rep_sen_data.base_case.result[i];
          this.rep_sen_data.sal_rev2.total += tar_res[i];
        }
      }
    }

    // Staff Salaries +0.2
    {
      const ren = data.variablecost.salaries;
      for (const sals of ren) {
        let k = 0;
        if (sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.staf_sal.value);
            rep_sen_data.sal_rev2.result[k] -= Math.round(ren_persnt);
            rep_sen_data.sal_rev2.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }
  }
  SalesRevenueAccelerate(report, data) {
    const acc_dur = this.rep_sen_data.sal_rev_acc_a.value;
    const rvavg_y2 = this.revenueAvgYear2(data);
    for (let i = 0; i < 12; i++) {
      if (report.revenue.total_revenue.arr[i + acc_dur]) {
        this.rep_sen_data.sal_rev_acc_a.result[i] =
          (report.revenue.total_revenue.arr[i + acc_dur] - report.cogs.total.val[i + acc_dur]) -
          (report.fixed_costs.total.val[i] + report.variable_costs.total.val[i]);
        if (data.revenue.sc.value) {
          // tslint:disable-next-line:max-line-length
          this.rep_sen_data.sal_rev_acc_a.result[i] += Math.round((report.revenue.total_revenue.arr[i + acc_dur] / 100) * this.fisc_inputs.service_charge);
        }

        // substract third party charges.
        this.rep_sen_data.sal_rev_acc_a.result[i] -=
          (((report.revenue.total_revenue.arr[i + acc_dur]) / 100) * 2) - (((report.revenue.total_revenue.arr[i]) / 100) * 2);

        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];

      } else if ((i + acc_dur) > 11) {
        this.rep_sen_data.sal_rev_acc_a.result[i] =
          (rvavg_y2.rev - rvavg_y2.cogs) -
          (report.fixed_costs.total.val[i] + report.variable_costs.total.val[i]);
        this.rep_sen_data.sal_rev_acc_a.result[i] -=
          (((rvavg_y2.rev) / 100) * 2) - (((report.revenue.total_revenue.arr[i]) / 100) * 2);

        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];

      } else {
        this.rep_sen_data.sal_rev_acc_a.result[i] = this.rep_sen_data.base_case.result[i];
        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];
      }
    }
  }
  calculateCumCashflow() {
    for (const key of this.rep_sen_data_keys) {
      let total = 0;
      let i = 0;
      for (const val of this.rep_sen_data[key].result) {
        total += val;
        this.rep_sen_data[key].ccf_result[i] += Math.round(total);
        i++;
      }
    }
  }
  setBaseCaseToAllFields(report) {
    for (const key of this.rep_sen_data_keys) {
      if (key === 'sal_rev_acc_a' || key === 'sal_rev_acc_m' || key === 'sal_rev1' || key === 'sal_rev2') {
        continue;
      }
      for (let i = 0; i < 12; i++) {
        this.rep_sen_data[key].result[i] += report.base_case.cash_flow.val[i];
      }
      this.rep_sen_data[key].total = report.base_case.cash_flow.total;
    }
  }
  calculateSalesRevenue(data) {
    let i = 0;
    const rep_sen_data = this.rep_sen_data;

    let tppc = 0;
    if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
      tppc = data.variablecost.tppc.variable_cost.value;
    }
    // rep_sen_data.base_case.result[count];
    for (const ps of data.revenue.ps) {
      let j = 0;
      if (ps.revenue_cost) {
        for (const sales of ps.revenue_cost.sales) {

          // Sales Revenue +0.5 && -0.5
          let sales_inc = (this.numOr0(sales.value) * rep_sen_data.sal_rev_m.value) * ps.revenue_cost.unit_price;
          if (data.revenue.sc.value) {
            sales_inc += (sales_inc / 100) * this.fisc_inputs.service_charge;
          }
          sales_inc = (sales_inc - ((sales_inc * (tppc / 100))));
          if (this.numOr0(data.cogs.type) === 1) {
            sales_inc -= (ps.revenue_cost.unit_monthly_cost) * (this.numOr0(sales.value) * rep_sen_data.sal_rev_m.value);
          }
          rep_sen_data.sal_rev_a.result[j] += sales_inc;
          rep_sen_data.sal_rev_m.result[j] -= sales_inc;
          rep_sen_data.sal_rev_a.total += sales_inc;
          rep_sen_data.sal_rev_m.total -= sales_inc;

          // Sales Revenue Accelerate X Month(s)
          // Sales Revenue Delay X Months(s)

          // COGS +0.2
          let cogs_incs = 0;
          if (data.cogs.type === '1') {
            cogs_incs = (ps.revenue_cost.unit_monthly_cost * rep_sen_data.cogs.value) * (this.numOr0(sales.value));
          } else {
            cogs_incs = (ps.revenue_cost.monthly_cost[j].value * rep_sen_data.cogs.value);
          }
          rep_sen_data.cogs.result[j] -= Math.round(cogs_incs);
          rep_sen_data.cogs.total -= Math.round(cogs_incs);
          j++;
        }
      }

      i++;
    }
    // Renovation +0.5
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.renovation && data.fixedcost.renovation.fixed_cost) {
        ren = data.fixedcost.renovation.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.renovat.value;
        rep_sen_data.renovat.result[k] -= Math.round(ren_persnt);
        rep_sen_data.renovat.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Website/IT/Software +0.5
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.wis && data.fixedcost.wis.fixed_cost) {
        ren = data.fixedcost.wis.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.website_it_sw.value;
        rep_sen_data.website_it_sw.result[k] -= Math.round(ren_persnt);
        rep_sen_data.website_it_sw.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Furniture.
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.fe && data.fixedcost.fe.fixed_cost) {
        ren = data.fixedcost.fe.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.furnt_equp.value;
        rep_sen_data.furnt_equp.result[k] -= Math.round(ren_persnt);
        rep_sen_data.furnt_equp.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Staff Salaries +0.2
    {

      let ren = [];
      if (data.variablecost) {
        ren = data.variablecost.salaries;
      }
      for (const sals of ren) {
        let k = 0;
        if (sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.staf_sal.value);
            rep_sen_data.staf_sal.result[k] -= Math.round(ren_persnt);
            rep_sen_data.staf_sal.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }

    // Logistics +0.5
    {
      // const ren = data.variablecost.logistics.variable_cost.val;
      let ren = [];
      if (data.variablecost.logistics && data.variablecost.logistics.variable_cost) {
        ren = data.variablecost.logistics.variable_cost.val;
      }
      let k = 0;
      for (const logistics of ren) {
        const ren_persnt = (logistics.value * rep_sen_data.logistics.value);
        rep_sen_data.logistics.result[k] -= Math.round(ren_persnt);
        rep_sen_data.logistics.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Marketing  +0.5
    {

      let ren = [];
      if (data.variablecost) {
        ren = data.variablecost.marketing;
      }
      for (const sals of ren) {
        let k = 0;
        if (sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.marketing.value);
            rep_sen_data.marketing.result[k] -= Math.round(ren_persnt);
            rep_sen_data.marketing.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }


    // Marketing +0.5
    {
      // const ren = data.variablecost.marketing;
      let ren = [];
      if (data.variablecost.marketing && data.variablecost.marketing.variable_cost) {
        ren = data.variablecost.marketing.variable_cost.val;
      }
      for (const sals of ren) {
        let k = 0;
        for (const sal of sals.variable_cost.val) {
          // + ( (sal.value / 100) * 2 )
          const ren_persnt = (sal.value * rep_sen_data.marketing.value);
          rep_sen_data.marketing.result[k] -= Math.round(ren_persnt);
          rep_sen_data.marketing.total -= Math.round(ren_persnt);
          k++;
        }
      }
    }

  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
}
