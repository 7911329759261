import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '../../services/report.service';
import { SensitiveY1Service } from '../../services/sensitive-y1.service';
import { Year1ReportService } from '../../services/year1-report.service';

@Component({
  selector: 'app-sensitive-y5',
  templateUrl: './sensitive-y5.component.html',
  styleUrls: ['./sensitive-y5.component.css']
})
export class SensitiveY5Component implements OnInit {

  hidelinechart: boolean;
  chartOptions: any;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  chartOptions2: any;
  chartData2: any;
  chartLabels2: any;
  chartData3: any;
  chartData4: any;
  chartColors: any;
  report_data: any = {};
  living_expense = [0, 0, 0, 0, 0];
  fisc_inputs = { service_charge: 0 };

  report_base: any = {};
  rep_sen_data_keys = ['base_case', 'sal_rev_a', 'sal_rev_m', 'sal_rev_acc_a', 'sal_rev_acc_m', 'cogs', 'staf_sal', 'renovat',
    'furnt_equp', 'logistics', 'citd', 'website_it_sw', 'marketing', 'spare1', 'sal_rev1', 'sal_rev2', 'spare12'];
  rep_sen_data_y5 = {
    base_case: {
      label: 'Base Case',
      value: '',
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_a: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_m: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_a: {
      label: 'Sales Revenue Accelerate',
      value: 1,
      unit: 'Month(s)',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_m: {
      label: 'Sales Revenue Delay',
      value: 3,
      unit: ' Months',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    cogs: {
      label: 'COGS',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    staf_sal: {
      label: 'Staff Salaries ',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    renovat: {
      label: 'Renovation ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    furnt_equp: {
      label: 'Furniture & Equipment',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    citd: {
      label: 'Customised IT Development ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    logistics: {
      label: 'Logistics ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    website_it_sw: {
      label: 'Website/IT/Software ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    marketing: {
      label: 'Marketing, Sales & Service ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare1: {
      label: 'Spare ',
      value: 1,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev1: {
      label: 'Sales Rev Delay ',
      value: 3,
      unit: 'Mths PLUS Cost inc of Reno & Furn/Equipmt',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev2: {
      label: 'Sales Rev Delay',
      value: 3,
      unit: 'Mths PLUS Cost Inc of Staff Salaries',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare12: {
      label: 'Spare ',
      value: 2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    }
  };
  constructor(private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private report_service: ReportService,
    private sensitive_y1: SensitiveY1Service,
    private y1_report: Year1ReportService
  ) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };
    this.chartData = [
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];
    /*   this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May']; */
    this.chartLabels = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];

    this.chartOptions2 = {
      responsive: true,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };
    this.chartData2 = [
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },

      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];
    /*  this.chartLabels2 = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May']; */
    this.chartLabels2 = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];

    this.chartColors = [
      { // first color

        backgroundColor: '#ff638485',
        borderColor: '#ff6384',
        pointBackgroundColor: '#ff6384',
        pointBorderColor: '#ff6384',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ff6384'
      },
      { // second color
        backgroundColor: '#36a2eb85',
        borderColor: '#36a2eb',
        pointBackgroundColor: '#36a2eb',
        pointBorderColor: '#36a2eb',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#36a2eb'
      },
      { // second color
        backgroundColor: '#ffe4a185',
        borderColor: '#ffe4a1',
        pointBackgroundColor: '#ffe4a1',
        pointBorderColor: '#ffe4a1',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ffe4a1'
      },
      { // second color
        backgroundColor: '#edeff285',
        borderColor: '#edeff2',
        pointBackgroundColor: '#edeff2',
        pointBorderColor: '#edeff2',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#edeff2'
      },
      { // second color
        backgroundColor: '#93d9d985',
        borderColor: '#93d9d9',
        pointBackgroundColor: '#93d9d9',
        pointBorderColor: '#93d9d9',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#93d9d9'
      },
      { // second color
        backgroundColor: '#97bbcd85',
        borderColor: '#97bbcd',
        pointBackgroundColor: '#97bbcd',
        pointBorderColor: '#97bbcd',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#97bbcd'
      },
      { // second color
        backgroundColor: '#0062b385',
        borderColor: '#0062b3',
        pointBackgroundColor: '#0062b3',
        pointBorderColor: '#0062b3',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#0062b3'
      },
      { // second color
        backgroundColor: '#ff650085',
        borderColor: '#ff6500',
        pointBackgroundColor: '#ff6500',
        pointBorderColor: '#ff6500',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ff6500'}];
  }

  ngOnInit() {
    this.user.getSharedReport(this.route.snapshot.params.id, 'sensitive_y5').subscribe(
      data => {
        if (data.status) {
          this.rep_sen_data_y5 = data.data;
          this.setGraphData();
        }
      }
    );
    this.user.getUsepersonalDataForReport(this.route.snapshot.params.id).subscribe(
      data => {
        if (Object.keys(data).length) {
          this.living_expense = this.user.setEstimatedLiving(data);
        }

      }
    );
  }
  setGraphData() {
    const repdat = this.rep_sen_data_y5;
    this.chartData3 = [];
    this.chartData4 = [];
    const chartData3constant  = [
      { data: repdat.base_case.result, label: 'Base Case', fill: false , hidegraph: true },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_a.result, label: repdat.sal_rev_a.label + ' + ' + (repdat.sal_rev_a.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_m.result, label: repdat.sal_rev_m.label + ' - ' + (repdat.sal_rev_m.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_a.result, label: repdat.sal_rev_acc_a.label + repdat.sal_rev_acc_a.value + repdat.sal_rev_acc_a.unit, fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_m.result, label: repdat.sal_rev_acc_m.label + ' -' + repdat.sal_rev_acc_m.value + repdat.sal_rev_acc_m.unit, fill: false , hidegraph: repdat.base_case['var_sel_disable']},
    ];
    for (const singlerepadpated of chartData3constant) {
      if (singlerepadpated.hidegraph === true) {
        this.chartData3.push(singlerepadpated);  // chart variable
      }
  }
    const chartData4constant = [
      { data: repdat.base_case.result, label: 'Base Case', fill: false , hidegraph: true},
      // tslint:disable-next-line:max-line-length
      { data: repdat.cogs.result, label: repdat.cogs.label + '+' + (repdat.cogs.value * 100) + '%', fill: false, hidegraph: repdat.cogs['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.staf_sal.result, label: repdat.staf_sal.label + '+' + (repdat.staf_sal.value * 100) + '%', fill: false , hidegraph: repdat.staf_sal['var_sel_disable']},
      // tslint:disable-next-line:max-line-length
      { data: repdat.renovat.result, label: repdat.renovat.label + '+' + (repdat.renovat.value * 100) + '%' + repdat.renovat.unit, fill: false , hidegraph: repdat.renovat['var_sel_disable']},
      // tslint:disable-next-line:max-line-length
      { data: repdat.furnt_equp.result, label: repdat.furnt_equp.label + '+' + (repdat.furnt_equp.value * 100) + '%' + repdat.furnt_equp.unit, fill: false, hidegraph: repdat.furnt_equp['var_sel_disable']  },
      // tslint:disable-next-line:max-line-length
      { data: repdat.logistics.result, label: repdat.logistics.label + '+' + (repdat.logistics.value * 100) + '%' + repdat.logistics.unit, fill: false , hidegraph: repdat.logistics['var_sel_disable']},
      // tslint:disable-next-line:max-line-length
      { data: repdat.citd.result, label: repdat.citd.label + '+' + (repdat.citd.value * 100) + '%' + repdat.citd.unit, fill: false , hidegraph: repdat.citd['var_sel_disable']},
      // tslint:disable-next-line:max-line-length
      { data: repdat.marketing.result, label: repdat.marketing.label + '+' + (repdat.marketing.value * 100) + '%', fill: false , hidegraph: repdat.marketing['var_sel_disable']},
    ];
      for (const singlerepadpated of chartData4constant) {
          if (singlerepadpated.hidegraph === true) {
            this.chartData4.push(singlerepadpated);  // chart variable
          }
      }

  }

}
