import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-kwik-input-main',
  templateUrl: './kwik-input-main.component.html',
  styleUrls: ['./kwik-input-main.component.css']
})
export class KwikInputMainComponent implements OnInit {
  hidedivornot: any;
  user_scopes: any;
  constructor(private user: UserService, private titleService: Title, ) {
  }

  ngOnInit() {
    this.hidedivornot = 4;
    this.user_scopes = this.user.getUserScopes();
    // console.log(this.user_scopes);
  }


}
