import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Year1ReportService } from '../../services/year1-report.service';
@Component({
  selector: 'app-variable-cost-y1',
  templateUrl: './variable-cost-y1.component.html',
  styleUrls: ['./variable-cost-y1.component.css']
})
export class VariableCostY1Component implements OnInit, OnDestroy {
  variable_data: any = {};
  report_data: any = {};
  month_order: any;
  tooltip_data: any;
  user_scope = 0;
  all_null = true;
  not_saved_yet = true;
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  c_user: any;
  hidetrailvideo: boolean;
  newvariablevalue: any;
  constructor(private user: UserService, private router: Router, private tooltip: TooltipService,
     private sanitizer: DomSanitizer,private y1_report: Year1ReportService) {
    const vurls = this.user.getVideoUrls();
    // this.tooltip_data.variable_cost.tppcdollar = '';
    this.video_url = 'https://www.youtube.com/embed/' + vurls['dataentry'] + '/';
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);

    this.report_data.revenue = {};
    this.report_data.revenue.sales_product_service = [];
    this.report_data.revenue.total_sales_revenue = { value: [] };
    this.report_data.revenue.total_revenue = { arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 };
    this.report_data.cogs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.fixed_costs = { value: [], other: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.variable_costs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.base_case = {
      cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      cum_cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      max_exp: 9999999,
      max_exp_month:  0,
      break_even_period: 'More than 1 year'
    };


  }

  ngOnInit() {
    this.user.brcr_back = '/user/fixed-cost/y5';
    this.user.brcr_next = '/user/variable-cost/y5';
    this.tooltip_data = this.tooltip.getTooltips();
    this.c_user = this.user.getUserObject();
   this.newvariablevalue =  this.tooltip_data.variable_cost.tppcdollar;
    if (this.c_user.plan_details.slug === 'free_trial' || this.c_user.plan_details.slug === '') {
      this.hidetrailvideo = true;
    }

    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        } else if (data.scope === 1) {
          this.user.brcr_back = '/user/fixed-cost/y1';
          this.user.brcr_next = '/user/report-base/y1';
        }
      }
    );
    this.user.getUserVaiableData().subscribe(
      data => {

        this.report_data = this.y1_report.getYear1BaseReportDataForFixedCostY1(data);
        

        if (data.variablecost && data.variablecost.bte && data.variablecost.bte.variable_cost) {
          this.not_saved_yet = false;
        }
        if (Object.keys(data).length) {
          const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners','historical'];
          const var_cos_arr = ['salaries', 'marketing', 'sales','service', 'uv', 'other'];
            if (!(data.variablecost.tppc.variable_cost)) {
              data.variablecost.tppc.variable_cost = { value: '' };
            }

          // console.log('sssdasd', data);

            if (!(data.variablecost.tppcdollar)
            || data.variablecost.tppcdollar === 'undefined') {

              data.variablecost.tppcdollar = {
                key: 'Third Party Payment Charges $', value: false, type: 1,
                variable_cost: { value: 0 },
                variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
              };
            }

            //---------------- phase 4 Sales

            if (!(data.variablecost.sales)
            || data.variablecost.sales === 'undefined') {  // initalize sales

              data.variablecost.sales = [{
                key: 'Sales 1', value: false,
                variable_cost: {
                val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
                },
                variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
                }];
            }
            if (!(data.variablecost.service)
            || data.variablecost.service === 'undefined') {  // initalize service
                data.variablecost.service = [{
                key: 'Service 1', value: false,
                variable_cost: {
                  val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                  { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
                },
                variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
              }];
            }

            if (!(data.variablecost.historical)
            || data.variablecost.historical === 'undefined') {  // initalize historical service
              data.variablecost.historical = [{
                key: 'Historical Cost', value: false,
                variable_cost: {
                  val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                  { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
                },
                variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
              }];
            }

            //---------------- phase 4 Service

          if (data.variablecost['tppcdollar'].value) {
            this.all_null = false;
          }

          if (data.variablecost['tppc'].value) {
            this.all_null = false;
          }
          for (const service of var_cos_ob_arr) {
            if (data.variablecost[service].value) {
              this.all_null = false;
            }

            if (!(data.variablecost[service].variable_cost)) {
              const months_array = [{ value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' }];
              data.variablecost[service].variable_cost = { val: months_array };
            }
          }
          for (const service of var_cos_arr) {

            data.variablecost[service].map(a => {
              if (a.value) {
                this.all_null = false;
              }
              if (!(a.variable_cost)) {
                const mon_array = [{ value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' }];
                a.variable_cost = { val: mon_array };
              }
            });
          }
          if (typeof data.variablecost.vcnotes === 'undefined') {

            const def_objnotes = {
              key: 'Notes', value: false,
              content: 'Notes',
              content_y5: 'Notes',
            };

            data.variablecost.vcnotes = def_objnotes;

          }
          this.user.varSelection = this.variable_data = data;
          // console.log('ssssss', this.variable_data);
        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );
    this.month_order = this.user.getMonthOrder();
  }

  saveAndNavigateBack(link) {
    // this.validateInputs();
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY1(this.variable_data);
    this.user.saveAndNavigateBack(link, this.variable_data);
  }
  saveAndNavigateForward(link) {
    // this.validateInputs();
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY1(this.variable_data);
    this.user.saveAndNavigateForward(link, this.variable_data);
  }

  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.validateInputs();
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY1(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }
  }

  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
  validateInputs() {
    const tppc = this.user.numOr0(this.variable_data.variablecost.tppc.variable_cost.value);
    if (tppc < 0) {
      this.variable_data.variablecost.tppc.variable_cost.value = 0;
    } else if (tppc > 100) {
      if (!(this.variable_data.variablecost.tppc.type && this.variable_data.variablecost.tppc.type === 2)) {
        this.variable_data.variablecost.tppc.variable_cost.value = 100;
      } else {
        this.variable_data.variablecost.tppc.variable_cost.value = tppc;
      }
    } else {
      this.variable_data.variablecost.tppc.variable_cost.value = tppc;
    }
  }
  onKeydown(event, k, arr) {
    this.user.onKeydown(event, k, arr);
    this.getReportdata(this.variable_data);
  }

  closeModal() {
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['dataentry'] + '?autoplay=1&start=120&end=185';
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }

  async getReportdata(data){
    await this.user.delay(50);
    const y1_report  = this.y1_report.getYear1BaseReportDataForFixedCostY1(data);
    this.report_data = y1_report;
    
  }


  setTwoNumberDecimal($event) {
    this.variable_data.variablecost.tppcdollar.variable_cost.value = $event.target.value = parseFloat($event.target.value).toFixed(2);
  }

}
