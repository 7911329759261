import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SubscriptionService } from '../../services/subscription.service';
import { UserService } from '../../services/user.service';

import { GlobalsService } from '../../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.css']
})
export class SubscriptionPaymentComponent implements OnInit, AfterViewInit {
  clientTokenURL: any;
  createPurchaseURL: any;
  plan: any;
  coupon: any;
  paymentResponse: any;
  chargeAmount = 55.55;
  coupon_discount  = 0;
  amountPayed = 0;
  message: any;
  profile: any;
  plan_details: any;
  cmessage: any;
  is_applied = false;
  btenabledStyle = {
    'font-size': '16px',
    'cursor': 'pointer',
    'width': '100%',
    'background': '#0062b3',
    'border-radius': '50px',
    'display': 'inline-table',
    'color': '#fff !important',
    'border': '1px solid #0062b3',
    'text-transform': 'capitalize !important',
    'background-color': '#000000',
  };
  btdisabledStyle = {
    'font-size': '16px',
    'width': '100%',
    'background': 'lightgray',
    'border-radius': '50px',
    'display': 'inline-table',
    'color': '#fff !important',
    'border': '1px solid lightgray',
    'text-transform': 'capitalize !important',
    'cursor': 'not-allowed'
  };
  select_country  = 'SG';
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private subscription: SubscriptionService,
    private user: UserService, @Inject(WINDOW) private window: Window, @Inject(LOCAL_STORAGE)
    private localStorage: any, private globals: GlobalsService) {
    console.log('Route details ', this.route);
    this.plan = this.route.snapshot.params.plan;
    this.coupon = this.route.snapshot.params.coupon;
    console.log('ssss', this.coupon);
    if (!this.coupon) {
      this.globals.setRegisterCoupon('');
    }
    this.select_country  = this.localStorage.getItem('kw_lang');
    const c_user = this.user.getUserObject();
    this.clientTokenURL = environment.API_URL + 'bt/getclienttoken';
    this.createPurchaseURL = environment.API_URL + 'bt/subscription/' + this.plan + '/' + c_user.id + '/' + this.coupon;
    console.log('inside construct', this.createPurchaseURL);
    this.globals.setPurchaseURL(this.plan, this.coupon);

    this.profile = {
      'first_name': '',
      'last_name': '',
      'user_email': '',
      'address': '',
      'city': '',
      'state': '',
      'post_code': '',
      'country': '',
      'user_phone': '',
      'subscriber': '1',
    };
    this.plan_details = {
      'id': '',
      'name': '',
      'subtitle': '',
      'description': '',
      'slug': '',
      'price': '',
      'onetime_fee': '',
      'onetime_text': '',
      'btree_id': ''
    };
    this.globals.setRouterPlanDetails(this.plan, this.coupon);

    this.globals.createPurchaseURL.subscribe(value => {
      if (value) {
        console.log('inside subscribe', this.createPurchaseURL);
        this.createPurchaseURL = value ;
      }
    });

    this.globals.plan_details.subscribe(value => {
      if (value) {

        this.plan_details = value.plan ;
        this.chargeAmount =  value.amount ;
        this.coupon_discount =  value.discount ;
        this.amountPayed      = this.chargeAmount - this.coupon_discount;

        this.is_applied = value.is_valid_coupon;
        if ( this.is_applied ) {
          this.cmessage = 'Coupon applied. A discount of ' + this.plan_details.currency_code + ' ' +
          this.plan_details.currency_symbol + value.discount +
                          ' has been applied to the Once Off Sign-Up Fee of ' +
                          this.plan_details.currency_code + ' ' + this.plan_details.currency_symbol + this.plan_details.onetime_fee;

        }

      }
    });

  }
  ngAfterViewInit() {
    if (document.getElementById('braintree-dropin')) {
      document.getElementById('braintree-dropin').innerHTML = '';
    }
  }
  ngOnInit() {
    this.user.getUserProfileData().subscribe(
      data => {
        const p = data.results;
        if (!(p.first_name && p.last_name && p.address && p.post_code && p.country && p.user_phone &&
          p.gender && p.dob.month
          )) {
          this.user.profile_not_completed = true;
          this.router.navigate(['/user/my-profile/']);
        }
        if (data.status === 1) {
          this.profile = data.results;
        }
      },
    );
    this.subscription.getPlanDetails(this.plan, this.coupon).subscribe(
      data => {
        if (data.status === 1) {
          this.plan_details = data.results[0];
          this.chargeAmount = this.plan_details.onetime_fee;
          this.coupon_discount = data.discount;
          this.amountPayed  = this.chargeAmount  - this.coupon_discount;
          console.log('this.chargeAmount', this.chargeAmount);
          console.log('this.coupon_discount', this.coupon_discount);
          console.log('this.amountPayed', this.amountPayed);
          this.is_applied = data.is_valid_coupon;
          if ( this.is_applied ) {
            this.cmessage = 'Coupon applied. A discount of ' + data.results[0].currency_code + ' ' +
                            data.results[0].currency_symbol + data.discount +
                            ' has been applied to the Once Off Sign-Up Fee of ' +
                            data.results[0].currency_code + ' ' + data.results[0].currency_symbol + data.results[0].onetime_fee;
          }
          this.globals.setRouterPlanDetails(this.plan, this.coupon);

        }
      },
      //   error => { }
    );
  }
  onPaymentStatus(event) {
    if (event.status === true) {
      this.subscription.setPlanDetails(event);
      this.paymentResponse = ''; // Subscription successfull
      this.user.plan_notfn = 'Thank you for subscribing. You are now able to access Kwik-Look’s reporting tools.';
      this.router.navigate(['/user/thank-you']);
      this.coupon = '';
      this.globals.setRegisterCoupon('');
    } else {
      this.paymentResponse = event.message;
      this.globals.setRegisterCoupon('');
    }
  }

  CheckCouponValid(plan, coupon) {

    if ( coupon ) {
      this.subscription.checkCouponValid(plan, coupon).subscribe(
        data => {
          if (data.status === 1) {

            this.globals.setPurchaseURL(plan, coupon);
            this.globals.setRouterPlanDetails(plan, coupon);
            this.is_applied = true;
            this.cmessage = 'Coupon applied. A discount of ' + data.plan.country_code + ' ' +
                            data.plan.currency_symbol + data.plan.discount +
                            ' has been applied to the Once Off Sign-Up Fee of ' +
                            data.plan.country_code + ' ' + data.plan.currency_symbol + data.plan.onetime_fee;
          } else {
            this.cmessage = data.message;
          }
        },
        //   error => { }
      );
    } else {
      this.cmessage = 'Please add your coupon code';
    }

  }
  removeCoupon(plan) {
    this.coupon = '';
    this.cmessage = '';
    this.is_applied = false;
    this.globals.setPurchaseURL(plan, '');
    this.globals.setRouterPlanDetails(plan, '');

  }

  hideHolder(e: any) {
    // console.log($(e.target));
    $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
    $(e.target).attr('placeholder', '');
    return;
  }
  showHolder(e: any) {
    const txtval = $(e.target).val();
            if (txtval === '') {
                $(e.target).attr('placeholder', $(e.target).data('placeholder'));
            }
  }
}
