import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { environment  } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient) { }

  get_http_request (url_slug, auth= false): Observable<any> {
    return this.http.get(environment.API_URL + url_slug);
  }
  post_http_request (url_slug, body, auth= false): Observable<any> {
    return this.http.post(environment.API_URL + url_slug, body);
  }

  get_blog_http_request (url_slug, auth= false): Observable<any> {
    return this.http.get(environment.BLOG_URL + url_slug);
  }

  post_blog_http_request (url_slug, body, auth= false): Observable<any> {
    return this.http.post(environment.BLOG_URL + url_slug, body);
  }
}
