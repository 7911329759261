import { Component, OnInit, ɵConsole } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { Year1ReportService } from '../../services/year1-report.service';
import { reduce } from 'rxjs/operators';

@Component({
  selector: 'app-report-sensitives-year-one',
  templateUrl: './report-sensitives-year-one.component.html',
  styleUrls: ['./report-sensitives-year-one.component.css']
})
export class ReportSensitivesYearOneComponent implements OnInit {
  buttonClicked = 2;
  report_base: any = {};
  month_order: any;
  fisc_inputs = { service_charge: 10 };
  rep_sen_data_keys = ['base_case', 'sal_rev_a', 'sal_rev_m', 'sal_rev_acc_a', 'sal_rev_acc_m', 'cogs', 'staf_sal', 'renovat',
    'furnt_equp', 'logistics', 'citd', 'website_it_sw', 'marketing', 'spare1', 'sal_rev1', 'sal_rev2', 'spare12'];
  rep_sen_data = {
    base_case: {
      label: 'Base Case',
      value: '',
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_a: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_m: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_acc_a: {
      label: 'Sales Revenue Accelerate',
      value: 1,
      unit: 'Month(s)',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev_acc_m: {
      label: 'Sales Revenue Delay',
      value: 3,
      unit: ' Month(s)',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    cogs: {
      label: 'COGS ',
      value: +0.2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    staf_sal: {
      label: 'Staff Salaries ',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    renovat: {
      label: 'Renovation ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    furnt_equp: {
      label: 'Furniture & Equipment ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    logistics: {
      label: 'Logistics ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    website_it_sw: {
      label: 'Website/IT/Software ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    marketing: {
      label: 'Marketing, Sales & Service ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    citd: {
      label: 'Customised IT Development ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    spare1: {
      label: 'Spare ',
      value: 1,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev1: {
      label: 'Sales Rev Delay ',
      value: 3,
      unit: 'Mths PLUS Cost inc of Reno & Furn/Equipmt',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    sal_rev2: {
      label: 'Sales Rev Delay',
      value: 3, unit: 'Mths PLUS Cost Inc of Staff Salaries',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    },
    spare12: {
      label: 'Spare ',
      value: 2,
      unit: '',
      result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      ccf_result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ccf_total: 0
    }
  };
  hidelinechart: boolean;
  chartOptions: any;
  chartData: any;
  chartLabels: any;
  chartOptions2: any;
  chartData2: any;
  chartLabels2: any;
  chartColors: any;
  constructor(private user: UserService, private router: Router, private y1_report: Year1ReportService) {
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };
    this.chartData = [];
    const cd1 = [];
    this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.chartOptions2 = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };

     // tslint:disable-next-line:no-unused-expression
     this.chartColors = [
      { // first color

        backgroundColor: 'rgba(255, 99, 132, .85)',
        borderColor: '#ff6384',
        pointBackgroundColor: '#ff6384',
        pointBorderColor: '#ff6384',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ff6384'
      },
      { // second color
        backgroundColor: 'rgba(54, 162, 235, .85)',
        borderColor: '#36a2eb',
        pointBackgroundColor: '#36a2eb',
        pointBorderColor: '#36a2eb',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#36a2eb'
      },
      { // second color
        backgroundColor: 'rgba(255, 228, 161, .85)',
        borderColor: '#ffe4a1',
        pointBackgroundColor: '#ffe4a1',
        pointBorderColor: '#ffe4a1',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ffe4a1'
      },
      { // second color
        backgroundColor: 'rgba(237, 239, 242, .85)',
        borderColor: '#edeff2',
        pointBackgroundColor: '#edeff2',
        pointBorderColor: '#edeff2',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#edeff2'
      },
      { // second color
        backgroundColor: 'rgba(147, 217, 217, .85)',
        borderColor: '#93d9d9',
        pointBackgroundColor: '#93d9d9',
        pointBorderColor: '#93d9d9',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#93d9d9'
      },
      { // second color
        backgroundColor: 'rgba(151, 187, 205, .85)',
        borderColor: '#97bbcd',
        pointBackgroundColor: '#97bbcd',
        pointBorderColor: '#97bbcd',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#97bbcd'
      },
      { // second color
        backgroundColor: 'rgba(0, 98, 179, .85)',
        borderColor: '#0062b3',
        pointBackgroundColor: '#0062b3',
        pointBorderColor: '#0062b3',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#0062b3'
      },
      { // second color
        backgroundColor: 'rgba(255, 101, 0, .85)',
        borderColor: '#ff6500',
        pointBackgroundColor: '#ff6500',
        pointBorderColor: '#ff6500',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#ff6500'
      }
    ];
    this.chartData2 = [];
    this.chartLabels2 = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];
  }

  ngOnInit() {
    this.user.brcr_back = '/user/report-base/y5';
    this.user.brcr_next = '/user/report-sensitive/y5';
    this.user.getUserScopes().subscribe(data => {
      this.user.setScopeUserObject(data);
      if (data.scope < 2) {
        this.router.navigate(['/user/my-subscription']);
      }
    });
    if (this.user.fiscal_inputs['sensitives']) {
      this.doNgInitAfterGettingFiscals();
    } else {
      this.user.setFiscalVariables().subscribe(
        data => {
          this.user.setFiscalDataFromResponse(data);
          this.doNgInitAfterGettingFiscals();
        }
      );
    }
  }
  doNgInitAfterGettingFiscals() {
    this.chartLabels2 = this.chartLabels = this.month_order = this.user.getMonthOrder();
    if (this.user.fiscal_inputs['sensitives']) {
      for (const val of this.user.fiscal_inputs['sensitives']) {
        if (this.rep_sen_data[val.sensitive_key]) {
          const fisval = this.numOr0(val.fiscal_value);
          this.rep_sen_data[val.sensitive_key].value = this.numOr0(fisval);
        }
      }
    }
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.user.getUserVaiableData().subscribe(
      // this.user.getUserRreportAndData('base_y1').subscribe(
      data => {
        if (Object.keys(data).length) {
          const report_data = this.y1_report.getYear1BaseReportData(data);
          console.log('report_data_all',report_data);
          this.report_base = report_data;
          // this.rep_sen_data.base_case.result = report_data.base_case.cash_flow.val;
          this.rep_sen_data.base_case.total = report_data.base_case.cash_flow.total;

          this.setBaseCaseToAllFields(report_data);
          this.calculateSalesRevenue(data);
          this.SalesRevenueAccelerate(report_data, data);
       //  console.log('123-->--->', data);
          this.SalesRevenueDelay(report_data, data);
          this.SalesRevenueDelayPlusRenoFurn(report_data, data);
          this.SalesRevenueDelayPlusStafSal(report_data, data);

          this.customizedItPhase2(data);

          this.checkPropertyIsEnablesInVarSel(data);
          this.calculateCumCashflow();
          this.setGraphData();
        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );

  }
  customizedItPhase2(data) {
    let k = 0;
    const rep_sen_data = this.rep_sen_data;
    let ren = [];
    if (data.fixedcost.citd && data.fixedcost.citd.fixed_cost) {
      ren = data.fixedcost.citd.fixed_cost.fc;
    }

    // if(data.fixedcost.citd.length){

    //   let count = 0;
    //   for (const oce of data.fixedcost.citd) {
    //     if(data.fixedcost.citd[count].value){
    //       ren[count] =  data.fixedcost.citd[count].fixed_cost.fc
    //     }
    //     count++;
    //   }
    // }



    {
      const ren = data.fixedcost.citd;
      for (const sals of ren) {
        let k = 0;
        if (sals.fixed_cost) {
          for (const sal of sals.fixed_cost.fc) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.citd.value);
            rep_sen_data.citd.result[k] -= Math.round(ren_persnt);
            rep_sen_data.citd.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }

    // console.log('new development value',ren, rep_sen_data.citd);
    // for (const renval of ren) {
    //   const ren_persnt = renval.value * rep_sen_data.citd.value;
    //   console.log('renval.value',renval.value,rep_sen_data.citd.value);
    //   rep_sen_data.citd.result[k] -= Math.round(ren_persnt);
    //   rep_sen_data.citd.total -= Math.round(ren_persnt);
    //   k++;
    // }







  }
  checkPropertyIsEnablesInVarSel(data) {
    this.rep_sen_data.staf_sal['var_sel_disable'] = false;
    this.rep_sen_data.renovat['var_sel_disable'] = false;
    this.rep_sen_data.logistics['var_sel_disable'] = false;
    this.rep_sen_data.website_it_sw['var_sel_disable'] = false;
    this.rep_sen_data.marketing['var_sel_disable'] = false;
    this.rep_sen_data.cogs['var_sel_disable'] = false;
    this.rep_sen_data.base_case['var_other_sel_disable'] = false;
    this.rep_sen_data.base_case['var_sales_sel_disable'] = false;
    this.rep_sen_data.base_case['var_sel_disable'] = false;
    this.rep_sen_data.base_case['citd'] = false;

    for (const sal of data.variablecost.salaries) {  // Staff Salaries
      if (sal.value === true) {
        this.rep_sen_data.staf_sal['var_sel_disable'] = true;
      }
    }

    if (data.fixedcost.renovation.value === true) { // Renovation
      this.rep_sen_data.renovat['var_sel_disable'] = true;
    }

    for (const sal of data.fixedcost.citd) {  // customised it
      if (sal.value === true) {
        this.rep_sen_data.citd['var_sel_disable'] = true;
      }
    }
    /* if (data.fixedcost.citd.value === true || data.fixedcost.citd.length > 0) { // citd
      this.rep_sen_data.citd['var_sel_disable'] = true;
    } */

    if (data.fixedcost.fe.value === true) { // Furniture / Equipment
      this.rep_sen_data.furnt_equp['var_sel_disable'] = true;
    }

    if (data.variablecost.logistics.value === true) { // Logistics
      this.rep_sen_data.logistics['var_sel_disable'] = true;
    }

    if (data.fixedcost.wis.value === true) { // website / it
      this.rep_sen_data.website_it_sw['var_sel_disable'] = true;
    }

    /** Marketing sales or service */
    for (const sal of data.variablecost.marketing) {  // Marketing
      if (sal.value === true) {
        this.rep_sen_data.marketing['var_sel_disable'] = true;
      }
    }
    for (const sal of data.variablecost.sales) {  // Sales
      if (sal.value === true) {
        this.rep_sen_data.marketing['var_sel_disable'] = true;
      }
    }
    for (const sal of data.variablecost.service) {  // Service
      if (sal.value === true) {
        this.rep_sen_data.marketing['var_sel_disable'] = true;
      }
    }
    /** End Marketing sales or service */

    if (data.cogs.cost.value === true || data.cogs.unitcost.value === true) { // cogs
      this.rep_sen_data.cogs['var_sel_disable'] = true;
    }
    if (data.revenue.other.value === true) { // Other revenue
      this.rep_sen_data.base_case['var_other_sel_disable'] = true;

    }
    for (const sal of data.revenue.ps) {  // Sales: Products/Services
      if (sal.value === true) {
        this.rep_sen_data.base_case['var_sales_sel_disable'] = true;
      }
    }
    if (this.rep_sen_data.base_case['var_sales_sel_disable'] === true || this.rep_sen_data.base_case['var_other_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sel_disable'] = true;
    }

    if (this.rep_sen_data.base_case['var_sel_disable'] === true ||
      this.rep_sen_data.renovat['var_sel_disable'] === true ||
      this.rep_sen_data.furnt_equp['var_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sale_reno_fur_disable'] = true;
    }

    if (this.rep_sen_data.base_case['var_sel_disable'] === true ||
      this.rep_sen_data.staf_sal['var_sel_disable'] === true) {
      this.rep_sen_data.base_case['var_sale_staff_disable'] = true;
    }
    // console.log('reportsensiticve', this.rep_sen_data);
  }
  shareReport() {
    this.user.shareUserReport(this.rep_sen_data, 'sensitive_y1').subscribe(
      data => {
        if (data.status) {
          this.user.report_notfn = data.slug;
          this.router.navigate(['/report/shared-successfully']);
        }
      }
    );
  }
  showMinVal(arr) {
    let  min_val_negative = 0;
    const minvalue = Math.round(Math.min.apply(Math, arr));
    if (minvalue > 0) {
      min_val_negative = 0;
    } else {
      min_val_negative = minvalue;
    }
    return min_val_negative;
  }
  showMinValIndex(arr) {
    let result = 0;
    for (const res of arr) {
      if (res < 0) {
        result++;
      }
    }
    return result;
  }
  setGraphData() {
    const repdat = this.rep_sen_data;
    this.chartData = [];
    this.chartData2 = [];
    const chartDataconstant = [
      { data: repdat.base_case.ccf_result, label: 'Base Case', fill: false, hidegraph: true },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_a.ccf_result, label: repdat.sal_rev_a.label + ' + ' + (repdat.sal_rev_a.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_m.ccf_result, label: repdat.sal_rev_m.label + ' - ' + (repdat.sal_rev_m.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_a.ccf_result, label: repdat.sal_rev_acc_a.label + repdat.sal_rev_acc_a.value + repdat.sal_rev_acc_a.unit, fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_m.ccf_result, label: repdat.sal_rev_acc_m.label + ' -' + repdat.sal_rev_acc_m.value + repdat.sal_rev_acc_m.unit, fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
    ];

    for (const singlerepadpated of chartDataconstant) {
      if (singlerepadpated['hidegraph'] === true) {
        this.chartData.push(singlerepadpated);  // chart variable
      }
    }

    const chartData2constant = [
      { data: repdat.base_case.ccf_result, label: 'Base Case', fill: false, hidegraph: true },
      // tslint:disable-next-line:max-line-length
      { data: repdat.cogs.ccf_result, label: repdat.cogs.label + '+' + (repdat.cogs.value * 100) + '%', fill: false, hidegraph: repdat.cogs['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.staf_sal.ccf_result, label: repdat.staf_sal.label + '+' + (repdat.staf_sal.value * 100) + '%', fill: false, hidegraph: repdat.staf_sal['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.renovat.ccf_result, label: repdat.renovat.label + '+' + (repdat.renovat.value * 100) + '%' + repdat.renovat.unit, fill: false, hidegraph: repdat.renovat['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.furnt_equp.ccf_result, label: repdat.furnt_equp.label + '+' + (repdat.furnt_equp.value * 100) + '%' + repdat.furnt_equp.unit, fill: false, hidegraph: repdat.furnt_equp['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.logistics.ccf_result, label: repdat.logistics.label + '+' + (repdat.logistics.value * 100) + '%' + repdat.logistics.unit, fill: false, hidegraph: repdat.logistics['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.citd.ccf_result, label: repdat.citd.label + '+' + (repdat.citd.value * 100) + '%' + repdat.citd.unit, fill: false, hidegraph: repdat.citd['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.marketing.ccf_result, label: repdat.marketing.label + '+' + (repdat.marketing.value * 100) + '%' + repdat.marketing.unit, fill: false, hidegraph: repdat.marketing['var_sel_disable'] },
    ];

    for (const singlerepadpated of chartData2constant) {
      if (singlerepadpated['hidegraph'] === true) {
        this.chartData2.push(singlerepadpated);  // chart variable
      }
    }



  }
  revenueAvgYear2(data) {
    const total = { rev: 0, cogs: 0 };
    for (const revenue of data.revenue.ps) {
      if (revenue.revenue_cost_y5) {
        total.rev += revenue.revenue_cost_y5.sales[0].value * revenue.revenue_cost_y5.unit_price[0].value;
        if (data.cogs.type === '2' || data.cogs.type === 2) {
          total.cogs += revenue.revenue_cost.monthly_cost[0].value; // revenue.revenue_cost_y5.unit_yearly_cost[0].value;
        } else {
          total.cogs += revenue.revenue_cost_y5.sales[0].value * revenue.revenue_cost_y5.unit_yearly_cost[0].value;
        }
      }
    }
    total.rev = total.rev / 12;
    total.cogs = total.cogs / 12;
    return total;
  }
  SalesRevenueDelay(report, data) {

    const acc_dur = this.rep_sen_data.sal_rev_acc_m.value;
    const tar_res = this.rep_sen_data.sal_rev_acc_m.result;

    // console.log('report.revenue.total_revenue.arr', report.revenue.total_revenue.arr);
    // console.log('report.cogs.total.val', report.cogs.total.val);
    // console.log('report.fixed_costs.total.val', report.fixed_costs.total.val);
    // console.log('report.variable_costs.total.val', report.variable_costs.total.val);
    // console.log('this.rep_sen_data.sal_rev_acc_m.total', this.rep_sen_data.sal_rev_acc_m.total);


    for (let i = 0; i < 12; i++) {

      if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
        let tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        let tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppcnew = tppcold = data.variablecost.tppc.variable_cost.value;
        }


          // console.log('tppcnew', i ,  tppcnew);
          // console.log('tppcold', i , tppcold);


        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev_acc_m.total += tar_res[i];
    //      console.log('tar_res', tar_res);
        } else {

          //  tar_res[i] = this.rep_sen_data.base_case.result[i];
       //   if (i === 2 ) {

            let  revenuetotal = report.revenue.total_revenue.arr[i - acc_dur];
            let  cogstotal = report.cogs.total.val[i - acc_dur];
            let tppcoldlates = tppcold;
            if (!revenuetotal) {
              revenuetotal = 0;
            }
            if (!cogstotal) {
              cogstotal = 0;
            }
            if (!tppcoldlates) {
              tppcoldlates = 0;
            }
            // console.log('report.revenue.total_revenue.arr[i - acc_dur]', revenuetotal);
            // console.log('report.cogs.total.val[i - acc_dur]', cogstotal);
            // console.log('report.fixed_costs.total.val[i]', report.fixed_costs.total.val[i]);
            // console.log('report.variable_costs.total.val[i]', report.variable_costs.total.val[i]);
            // console.log('tppcnew', tppcnew);

            tar_res[i] = (revenuetotal - cogstotal) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcoldlates)
            );
       //   }
          this.rep_sen_data.sal_rev_acc_m.total += tar_res[i];
        }
      }

    }
    // sal_rev1
  }
  SalesRevenueDelayPlusRenoFurn(report, data) {
    const rep_sen_data = this.rep_sen_data;
    // console.log('this.rep_sen_data.sal_rev1', this.rep_sen_data.sal_rev1);
    const acc_dur = this.rep_sen_data.sal_rev1.value;
    const tar_res = this.rep_sen_data.sal_rev1.result;
    if (data.variablecost.tppc.variable_cost && data.variablecost.tppc.variable_cost.value) {
      for (let i = 0; i < 12; i++) {
        let tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        let tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppcnew = tppcold = data.variablecost.tppc.variable_cost.value;
        }
        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev1.total += tar_res[i];
        } else {
         // old jobin code tar_res[i] = this.rep_sen_data.base_case.result[i];

         let  revenuetotal = report.revenue.total_revenue.arr[i - acc_dur];
         let  cogstotal = report.cogs.total.val[i - acc_dur];
         let tppcoldlates = tppcold;
         if (!revenuetotal) {
           revenuetotal = 0;
         }
         if (!cogstotal) {
           cogstotal = 0;
         }
         if (!tppcoldlates) {
           tppcoldlates = 0;
         }

         tar_res[i] = (revenuetotal - cogstotal) -
         (report.fixed_costs.total.val[i] +
           ((report.variable_costs.total.val[i] - tppcnew) + tppcoldlates)
         );

          this.rep_sen_data.sal_rev1.total += tar_res[i];
        }
      }
    }

    // Renovation +0.5
    {
      let k = 0;
      // const ren = data.fixedcost.renovation.fixed_cost.fc;
      let ren = [];
      if (data.fixedcost.renovation && data.fixedcost.renovation.fixed_cost) {
        ren = data.fixedcost.renovation.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.renovat.value;
        rep_sen_data.sal_rev1.result[k] -= Math.round(ren_persnt);
        rep_sen_data.sal_rev1.total -= Math.round(ren_persnt);
        k++;
      }
    }
    // Furniture.
    {
      let k = 0;
      // const ren = data.fixedcost.fe.fixed_cost.fc;
      let ren = [];
      if (data.fixedcost.fe && data.fixedcost.fe.fixed_cost) {
        ren = data.fixedcost.fe.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.furnt_equp.value;
        rep_sen_data.sal_rev1.result[k] -= Math.round(ren_persnt);
        rep_sen_data.sal_rev1.total -= Math.round(ren_persnt);
        k++;
      }
    }
  }
  SalesRevenueDelayPlusStafSal(report, data) {
    const rep_sen_data = this.rep_sen_data;
    const acc_dur = this.rep_sen_data.sal_rev2.value;
    const tar_res = this.rep_sen_data.sal_rev2.result;
    if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
      for (let i = 0; i < 12; i++) {
        let tppcnew = (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);
        let tppcold = (((report.revenue.total_revenue.arr[i - acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value);
        if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppcnew = tppcold = data.variablecost.tppc.variable_cost.value;
        }
        if (i >= acc_dur) {
          tar_res[i] =
            (report.revenue.total_revenue.arr[i - acc_dur] - report.cogs.total.val[i - acc_dur]) -
            (report.fixed_costs.total.val[i] +
              ((report.variable_costs.total.val[i] - tppcnew) + tppcold)
            );
          this.rep_sen_data.sal_rev2.total += tar_res[i];
        } else {
          // tar_res[i] = this.rep_sen_data.base_case.result[i];


          let  revenuetotal = report.revenue.total_revenue.arr[i - acc_dur];
          let  cogstotal = report.cogs.total.val[i - acc_dur];
          let tppcoldlates = tppcold;
          if (!revenuetotal) {
            revenuetotal = 0;
          }
          if (!cogstotal) {
            cogstotal = 0;
          }
          if (!tppcoldlates) {
            tppcoldlates = 0;
          }

          tar_res[i] = (revenuetotal - cogstotal) -
          (report.fixed_costs.total.val[i] +
            ((report.variable_costs.total.val[i] - tppcnew) + tppcoldlates)
          );



          this.rep_sen_data.sal_rev2.total += tar_res[i];
        }
      }
    }

    // Staff Salaries +0.2
    {
      const ren = data.variablecost.salaries;
      for (const sals of ren) {
        let k = 0;
        if (sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.staf_sal.value);
            rep_sen_data.sal_rev2.result[k] -= Math.round(ren_persnt);
            rep_sen_data.sal_rev2.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }
  }
  SalesRevenueAccelerate(report, data) {
    const acc_dur = this.rep_sen_data.sal_rev_acc_a.value;
  //  console.log('acc_dur', acc_dur);
    const rvavg_y2 = this.revenueAvgYear2(data);
    for (let i = 0; i < 12; i++) {

      if (report.revenue.total_revenue.arr[i + acc_dur]) {
        this.rep_sen_data.sal_rev_acc_a.result[i] =
          (report.revenue.total_revenue.arr[i + acc_dur] - report.cogs.total.val[i + acc_dur]) -
          (report.fixed_costs.total.val[i] + report.variable_costs.total.val[i]);
        if (data.revenue.sc.value) {
          // tslint:disable-next-line:max-line-length
          this.rep_sen_data.sal_rev_acc_a.result[i] += Math.round((report.revenue.total_revenue.arr[i + acc_dur] / 100) * this.fisc_inputs.service_charge);
        }

        // substract third party charges.
        this.rep_sen_data.sal_rev_acc_a.result[i] -=
          (((report.revenue.total_revenue.arr[i + acc_dur]) / 100) * data.variablecost.tppc.variable_cost.value)
          - (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);


        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];

      } else if ((i + acc_dur) > 11) {
        this.rep_sen_data.sal_rev_acc_a.result[i] =
          (rvavg_y2.rev - rvavg_y2.cogs) -
          (report.fixed_costs.total.val[i] + report.variable_costs.total.val[i]);
        this.rep_sen_data.sal_rev_acc_a.result[i] -=
          (((rvavg_y2.rev) / 100) * data.variablecost.tppc.variable_cost.value) -
          (((report.revenue.total_revenue.arr[i]) / 100) * data.variablecost.tppc.variable_cost.value);

        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];

      } else {
        this.rep_sen_data.sal_rev_acc_a.result[i] = this.rep_sen_data.base_case.result[i];
        this.rep_sen_data.sal_rev_acc_a.total += this.rep_sen_data.sal_rev_acc_a.result[i];
      }

   //   console.log('this.rep_sen_data.sal_rev_acc_a.result', this.rep_sen_data.sal_rev_acc_a.result);
    }
  }
  calculateCumCashflow() {
    for (const key of this.rep_sen_data_keys) {
      let total = 0;
      let i = 0;
      for (const val of this.rep_sen_data[key].result) {
        total += val;
        this.rep_sen_data[key].ccf_result[i] += total;
        i++;
      }
    }
    // console.log('ccf', this.rep_sen_data);
  }
  setBaseCaseToAllFields(report) {
    for (const key of this.rep_sen_data_keys) {
      if (key === 'sal_rev_acc_a' || key === 'sal_rev_acc_m' || key === 'sal_rev1' || key === 'sal_rev2') {
        continue;
      }
      for (let i = 0; i < 12; i++) {

        this.rep_sen_data[key].result[i] += report.base_case.cash_flow.val[i];
      }


      this.rep_sen_data[key].total = report.base_case.cash_flow.total;
    }
  }
  calculateSalesRevenue(data) {
    let i = 0;
    const rep_sen_data = this.rep_sen_data;

    let tppc = 0;
    if (data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
      tppc = data.variablecost.tppc.variable_cost.value;
    }
    // rep_sen_data.base_case.result[count];
    for (const ps of data.revenue.ps) {
      let j = 0;
      if (ps.revenue_cost) {
        for (const sales of ps.revenue_cost.sales) {

          // Sales Revenue +0.5
          let sales_inc = (this.numOr0(sales.value) * rep_sen_data.sal_rev_a.value) * ps.revenue_cost.unit_price;
          if (data.revenue.sc.value) {
            sales_inc += (sales_inc / 100) * this.fisc_inputs.service_charge;
          }
          if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
            sales_inc = (sales_inc - tppc);
          } else {
            sales_inc = (sales_inc - ((sales_inc * (tppc / 100))));
          }
          if (this.numOr0(data.cogs.type) === 1) {
            sales_inc -= (ps.revenue_cost.unit_monthly_cost) * (this.numOr0(sales.value) * rep_sen_data.sal_rev_a.value);
          }
          rep_sen_data.sal_rev_a.result[j] += sales_inc;
          rep_sen_data.sal_rev_a.total += sales_inc;
          // Sales Revenue -0.5
          sales_inc = (this.numOr0(sales.value) * rep_sen_data.sal_rev_m.value) * ps.revenue_cost.unit_price;
          if (data.revenue.sc.value) {
            sales_inc += (sales_inc / 100) * this.fisc_inputs.service_charge;
          }
          if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
            sales_inc = (sales_inc - tppc);
          } else {
            sales_inc = (sales_inc - ((sales_inc * (tppc / 100))));
          }
          if (this.numOr0(data.cogs.type) === 1) {
            sales_inc -= (ps.revenue_cost.unit_monthly_cost) * (this.numOr0(sales.value) * rep_sen_data.sal_rev_m.value);
          }
          rep_sen_data.sal_rev_m.result[j] -= sales_inc;
          rep_sen_data.sal_rev_m.total -= sales_inc;

          // Sales Revenue Accelerate X Month(s)
          // Sales Revenue Delay X Months(s)

          // COGS +0.2
          let cogs_incs = 0;
          if (this.numOr0(data.cogs.type) === 1) {
            cogs_incs = (ps.revenue_cost.unit_monthly_cost * rep_sen_data.cogs.value) * (this.numOr0(sales.value));
          } else {
            cogs_incs = (ps.revenue_cost.monthly_cost[j].value * rep_sen_data.cogs.value);
          }
          rep_sen_data.cogs.result[j] -= Math.round(cogs_incs);
          rep_sen_data.cogs.total -= Math.round(cogs_incs);
          j++;
        }
      }

      i++;
    }
    // Renovation +0.5
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.renovation && data.fixedcost.renovation.fixed_cost) {
        ren = data.fixedcost.renovation.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.renovat.value;
        rep_sen_data.renovat.result[k] -= Math.round(ren_persnt);
        rep_sen_data.renovat.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Website/IT/Software +0.5
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.wis && data.fixedcost.wis.fixed_cost) {
        ren = data.fixedcost.wis.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.website_it_sw.value;
        rep_sen_data.website_it_sw.result[k] -= Math.round(ren_persnt);
        rep_sen_data.website_it_sw.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Furniture.
    {
      let k = 0;
      let ren = [];
      if (data.fixedcost.fe && data.fixedcost.fe.fixed_cost) {
        ren = data.fixedcost.fe.fixed_cost.fc;
      }
      for (const renval of ren) {
        const ren_persnt = renval.value * rep_sen_data.furnt_equp.value;
        rep_sen_data.furnt_equp.result[k] -= Math.round(ren_persnt);
        rep_sen_data.furnt_equp.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Staff Salaries +0.2
    {

      let ren = [];
      if (data.variablecost) {
        ren = data.variablecost.salaries;
      }
      for (const sals of ren) {
        let k = 0;
        if (sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.staf_sal.value);
            rep_sen_data.staf_sal.result[k] -= Math.round(ren_persnt);
            rep_sen_data.staf_sal.total -= Math.round(ren_persnt);
            k++;
          }
        }

      }
    }

    // Logistics +0.5
    {
      // const ren = data.variablecost.logistics.variable_cost.val;
      let ren = [];
      if (data.variablecost.logistics && data.variablecost.logistics.variable_cost) {
        ren = data.variablecost.logistics.variable_cost.val;
      }
      let k = 0;
      for (const logistics of ren) {
        const ren_persnt = (logistics.value * rep_sen_data.logistics.value);
        console.log('Logistics values are',ren_persnt);
        rep_sen_data.logistics.result[k] -= Math.round(ren_persnt);
        rep_sen_data.logistics.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // Marketing  +0.5
    {
      const salessresult = rep_sen_data.marketing.result;
      let marketing, sales, service;
      const month_total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (data.variablecost) {
        marketing  = data.variablecost.marketing;
        sales  = data.variablecost.sales;
        service  = data.variablecost.service;
      }

      for (const sals of marketing) {
        let k = 0;
        if (sals.value === true && sals.variable_cost) {
          for (const sal of sals.variable_cost.val) {
            month_total[k] += sal.value;
            k++;
          }
        }

      }
      for (const sals of sales) {
        let k = 0;
        if (sals.value === true && sals.variable_cost) {

          for (const sal of sals.variable_cost.val) {
            month_total[k] += sal.value;
            k++;
          }
        }
      }
      /**Service */
      for (const sals of service) {
        let k = 0;
        if (sals.value === true && sals.variable_cost) {

          for (const sal of sals.variable_cost.val) {
            month_total[k] += sal.value;
            k++;
          }
        }

      }
      let k = 0;
      for ( const markTotal of month_total) {
        const ren_persnt = (markTotal * ( rep_sen_data.marketing.value ));
        rep_sen_data.marketing.result[k] -= Math.round(ren_persnt);
        rep_sen_data.marketing.total -= Math.round(ren_persnt);
        k++;
      }
    }

    // console.log('REN========================', rep_sen_data.marketing);

    // Marketing +0.5
    /*{
      // const ren = data.variablecost.marketing;
      let ren = [];
      if (data.variablecost.marketing && data.variablecost.marketing.variable_cost && data.variablecost.marketing.value === true  ) {
        ren = data.variablecost.marketing.variable_cost.val;

        for (const sals of ren) {
          let k = 0;
          for (const sal of sals.variable_cost.val) {
            // + ( (sal.value / 100) * 2 )
            const ren_persnt = (sal.value * rep_sen_data.marketing.value);
            rep_sen_data.marketing.result[k] -= Math.round(ren_persnt);
            rep_sen_data.marketing.total -= Math.round(ren_persnt);
            k++;
          }
        }
      }
    }*/


    // console.log('rep_sen_data============================',rep_sen_data);
  }
  captureScreen() {
    this.user.captureScreen('ReportSensitivityYearOne.pdf');
  }
  async captureScreenNew() {
    this.buttonClicked = 1;
    await this.user.delay(50);
    await this.user.captureScreenNew('ReportSensitivityYearOne.pdf');
    this.buttonClicked = 2;
  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  print_fns() {
    this.user.print_fns();
  }

}
