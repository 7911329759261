import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../services/user.service';
import { TooltipService } from '../../services/tooltip.service'

@Component({
  selector: 'app-report-revenue-cogs-y5',
  templateUrl: './report-revenue-cogs-y5.component.html',
  styleUrls: ['./report-revenue-cogs-y5.component.css']
})
export class ReportRevenueCogsY5Component implements OnInit {

  variable_data: any = {};
  tooltip_data: any;
  buttonClicked = 2;
  constructor(private route: ActivatedRoute, private router: Router, private _location: Location,
    private user: UserService, private tooltip: TooltipService) { }

  ngOnInit() {
    this.user.brcr_back = '/user/revenue-cogs/y1';
    this.user.brcr_next = '/user/fixed-cost/y1';
    this.tooltip_data = this.tooltip.getTooltips();

    this.user.getSharedReport(this.route.snapshot.params.id, 'revenue_cogs_y5').subscribe(
      data => {
        if (data.status) {
          // console.log(data.data);
          this.variable_data = data.data;
        
        }
      }
    );
   
  }
  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
}
 
