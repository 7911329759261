import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ValidationService, AlertService, UserService } from '../services';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  accounteditvalue: boolean;
  accountsetup: any;
  email: any;
  message: any;
  classmessages: string;
  validationmessages: string;
  validations_form: FormGroup;
  submitted: boolean;
  passwordmismatch: string;

  constructor(private user: UserService , private router: Router, private formBuilder: FormBuilder) {

    this.accounteditvalue = true;
    this.submitted = false;
    this.accountsetup = {
      'email': '',
      'currentpassword': '',
      'newpassword': '',
      'retypepassword': '',
    };
    this.validations_form = this.formBuilder.group({
      currentpassword: new FormControl('', Validators.required),
      newpassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      retypepassword: new FormControl('', Validators.required),
      email: new FormControl('')
    });
    this.validations_form.get('currentpassword').disable();
    this.validations_form.get('newpassword').disable();
    this.validations_form.get('retypepassword').disable();

  }

  ngOnInit() {
    this.email = this.user.getUserObject();
    this.accountsetup.email  = this.email.username;
    this.validations_form.get('email').setValue(this.email.username);
  }

  get f() { return this.validations_form.controls; }

  onSubmit() {
    this.message = '';
    this.classmessages = '';
    this.submitted = true;
     /* if (this.accountsetup.newpassword.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/)) {
        this.validationmessages = 'Password must contain at least one digit/lowercase/uppercase letter and be at least six characters long';
      } else {
        this.validationmessages = '';
      } */

      this.accountsetup.email = this.validations_form.value.email;
      this.accountsetup.newpassword = this.validations_form.value.newpassword;
      this.accountsetup.retypepassword = this.validations_form.value.retypepassword;
      this.accountsetup.currentpassword = this.validations_form.value.currentpassword;



      if (this.validations_form.invalid) {
          return;
      }


    if (this.accountsetup.newpassword === this.accountsetup.retypepassword ) {

      this.user.ChangePassword(this.accountsetup).subscribe(
      data =>  {
        if (data.status === 1) {
          this.accounteditvalue = true;
          this.message = data.message;
          this.classmessages = 'success';
          this.user.logout();
          setTimeout(() => this.router.navigate(['/login']), 3000);

        } else {

          this.message = data.message;
          this.classmessages = 'error';


        }
      },
      error => {}
      );

    }
  }


  editpassword() {

    this.accounteditvalue = false;
    this.validations_form.get('currentpassword').enable();
    this.validations_form.get('newpassword').enable();
    this.validations_form.get('retypepassword').enable();

  }





}
