import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';
import { NetworkService } from './network.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private userObject = '';
  public userFormData = {};
  public varSelection = {};
  public personalExpense = {};
  public CompanyDetails = {};

  imgWidth: any;
  pageHeight: any;
  imgHeight: any;
  heightLeft: any;
  position: any;
  pdf: any;
  y: any;
  printpdf: any;
  printdata: any;
  printContents: any;
  popupWin: any;
  contentDataURLpdf: any;
  setprint: boolean;
  savepdf: boolean;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, private network: NetworkService, private router: Router , private user: UserService) { }
  getUserObject() {
    return this.user.getUserObject();
    /* if (this.userObject) {
      return this.userObject;
    } else if (localStorage.getItem('currentUser')) {
      const c_u = localStorage.getItem('currentUser');
      // this.setUserObject(c_u);
      return JSON.parse(c_u);
    } else {
      return '';
    } */
  }
  setUserObject(userObject) {
    return this.user.setUserObject(userObject);
    /* localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.userObject = userObject; */
  }

  getPlanDetails(plan,coupon) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('subscription/plan', { 'id': c_user.id,'plan': plan,'coupon':coupon });
  }
  getUserPlanDetails(user) {
    return this.network.post_http_request('subscription/userplan', { 'user': user });
  }
  unsubscribe(user) {
    return this.network.post_http_request('bt/unsubscribe', { 'user': user });
  }
  setPlanDetails(details) {
    let c_user;
    c_user = this.getUserObject();
    c_user.plan_details = details.plan_details;
    this.setUserObject(c_user);
  }

  NewsetPlanDetails(details) {
    let c_user;
    c_user = this.getUserObject();
    c_user.plan_details = details.plan_details[0];
    this.setUserObject(c_user);
  }

  checkCouponValid(plan, coupon, email = '' ) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('subscription/checkcouponvalid',
    {'id': c_user.id, 'plan': plan, 'coupon': coupon, 'email': email });
  }

}

