import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { CmsService } from '../cms.service';
import { GlobalsService } from '../services/globals.service';
import { Observable, of, Subject } from 'rxjs';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  brdcrumbString: any;
  showBreadCrumb = true;
  brdcrumbStringold: any;
  selected_country: any;
  invokebreadcrumb: Subject<any> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private cms: CmsService,
    private globals: GlobalsService, @Inject(LOCAL_STORAGE) private localStorage: any
  ) {
    this.showBreadCrumb = true;
  }

  ngOnInit() {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
      const label = this.activatedRoute.root;
      this.brdcrumbString = label.children[0].data['value'].breadcrumb;
      this.brdcrumbStringold = this.brdcrumbString;
      if (this.brdcrumbString) {
          this.titleService.setTitle('Kwik Look - Home - ' + this.brdcrumbString);
      }
      if (this.brdcrumbString === 'Entrepreneurs Guide' ||
          this.brdcrumbString === 'Products & Solutions' ||
          this.brdcrumbString === 'Key Benefits') {
        this.showBreadCrumb = false;
      } else {
        this.showBreadCrumb = true;
      }

      this.globals.invokebreadcrumb.subscribe(value => {
        if (value) {
          if ( this.brdcrumbStringold === 'Contact Us' || this.brdcrumbStringold === 'Products & Services' ) {
            this.brdcrumbString = value + ' ' + this.brdcrumbStringold ;
          }
        }
    });


    });
  }

}
