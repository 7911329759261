import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import {ViewEncapsulation} from '@angular/core';
import {  Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-about',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  metatag: any;

  constructor(private cms: CmsService, private meta: Meta) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Kwik Look is about helping SME startups and entrepreneurs answer the question, "Can My New Business Idea Make Money?"',
    };
    this.meta.updateTag(this.metatag);

  }

  page_content: any;
  ngOnInit() {
    this.cms.getCmsByPage('cms/about')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
  }

}
