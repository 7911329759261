import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/report.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
@Component({
  selector: 'app-base-y5',
  templateUrl: './base-y5.component.html',
  styleUrls: ['./base-y5.component.css']
})
export class BaseY5Component implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: false,
  };
  
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0], label: 'TOTAL REVENUE' },
    { data: [0, 0, 0, 0, 0], label: 'TOTAL COSTS' }
  ];
  barChartColors:Array<any> = [
    { 
      backgroundColor: '#008000',
      borderColor: '#008000',
      pointBackgroundColor: '#008000',
      pointBorderColor: '#008000',
      pointHoverBackgroundColor: '#008000',
      pointHoverBorderColor: '#008000'
    },
    {
      backgroundColor: '#FF0000',
      borderColor: '#FF0000',
      pointBackgroundColor: '#FF0000',
      pointBorderColor: '#FF0000',
      pointHoverBackgroundColor: '#FF0000',
      pointHoverBorderColor: '#FF0000'
    },
   
  ];
  chartOptions = {
    responsive: true,
    legend: {
      onClick: null,
      labels: {
        display: true,
        boxWidth: 10
      },
      position: 'bottom'
    },
  };
  OlaValue: boolean;
  barChartLabels = ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'];
  report_data: any = {};
  living_expense = [0, 0, 0, 0, 0];
  fisc_inputs = { service_charge: 0 };
  base_case = {
    cf_b_tax: [0, 0, 0, 0, 0],
    corp_tax: [0, 0, 0, 0, 0],
    total_cost: [0, 0, 0, 0, 0],
    cf_a_tax: [0, 0, 0, 0, 0],
    ccf: [0, 0, 0, 0, 0],
    break_even_year: '',
    break_even_month: 'NA',
  };
 
  hidelinechart: boolean;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  constructor(private route: ActivatedRoute, private router: Router, private user: UserService, private report_service: ReportService) {
    
    this.report_data.revenue = {
      sales: [],
      tota_sales: [0, 0, 0, 0, 0],
      service_charge: [0, 0, 0, 0, 0],
      tota_sal_service_charge: [0, 0, 0, 0, 0],
      is_service_charge: false,
      other_revenue: [0, 0, 0, 0, 0],
      total_revenue: [0, 0, 0, 0, 0]
    };
    this.report_data.cogs = { value: [], total: [0, 0, 0, 0, 0] };
    this.report_data.fixed_cost = { 
      value: [],
      total: [0, 0, 0, 0, 0],
      general_fixed_costs : { value: [] , total :[0,0,0,0,0]},
      it_fixed_costs: { value: [] , total :[0,0,0,0,0]},
      other_fixed_costs: { value: [] , total :[0,0,0,0,0]},
     };
    this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0],
      manpower_variable_costs : { value: [] , total :[0,0,0,0,0]},
      mss_variable_costs : { value: [] , total :[0,0,0,0,0]},
      other_variable_costs : { value: [] , total :[0,0,0,0,0]},
    };

    
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: {
          display: true,
          boxWidth: 10
        },
        position: 'bottom'
      },
    };

    this.chartData = [
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cum Cashflow After Tax', fill: false },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cashflow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];
    this.chartLabels = ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'];
    // this.chartLabels = ['June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.color_arr = [{
      // tslint:disable-next-line:max-line-length
      backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }];


  }

  ngOnInit() {
    this.user.getSharedReport(this.route.snapshot.params.id, 'base_y5').subscribe(
      data => {
        if (data.status) {


          if(data.data.fixed_cost.hasOwnProperty('general_fixed_costs')){
              this.OlaValue = false;
          } else {
              this.OlaValue = true;
              console.log('general_fixed_costs error');
          }
          console.log('general_fixed_costs ----**');
           console.log('calculateBreakEvenMonthYear',data);
          this.report_data = data.data;
          this.chartLabels = ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'];
         // this.setFixedCostReportData(data.data);
          // this.chartLabels = [' ', ' ', ' ', ' ', ' '];
          this.chartData[0].data = this.report_data.base_case.ccf;
          this.chartData[1].data = this.report_data.base_case.cf_a_tax;
          console.log('calculateBreakEvenMonthYear2',  data.data.base_case);
          this.base_case.cf_b_tax = data.data.base_case.cf_b_tax;

          this.base_case.corp_tax = data.data.base_case.corp_rounded;
          this.base_case.cf_a_tax = data.data.base_case.cf_a_tax;
          this.base_case.ccf = data.data.base_case.ccf;
          this.base_case.corp_tax = data.data.base_case.corp_tax;
          this.base_case.total_cost = data.data.base_case.total_cost;
          this.barChartData = [
            { data: this.report_data.revenue.total_revenue, label: 'TOTAL REVENUE' },
            { data:  data.data.base_case.total_cost, label: 'TOTAL COSTS' }
          ];  


         
          
        }
      }
    );

  
    this.user.getUsepersonalDataForReport(this.route.snapshot.params.id).subscribe(
      data => {
        if (Object.keys(data).length) {
          this.living_expense = this.user.setEstimatedLiving(data);
        }

      }
    );
  }

  
  // calculateBreakEvenMonthYear() {
  //   // base_case.ccf;
  //   let count = 0;
  //   let last_neg = 0;
  //   let break_even_year: any = 0;
  //   for (const val of this.base_case.ccf) {
  //     if (val < 0) {
  //       break_even_year++;
  //       last_neg = count;
  //     }
  //     count++;
  //   }
  //   this.base_case.break_even_year = '' + break_even_year;
  //   if (break_even_year > 4) {
  //     this.base_case.break_even_year = 'More than 5 Years';
  //   }
  //   let inc_index = 1;
  //   if (inc_index > 4) {
  //     inc_index = 0;
  //   }
  //   if (break_even_year <= 4 && break_even_year >= 1) {
  //     const a = this.base_case.ccf[last_neg];
  //     const b = this.base_case.cf_a_tax[last_neg + inc_index];
  //     let breakeven_mnth = Math.round((a / b) * 12);
  //     if (breakeven_mnth < 0) {
  //       breakeven_mnth = breakeven_mnth * (-1);
  //     }
  //     this.base_case.break_even_month = '' + breakeven_mnth;
  //   }
  // }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }
  // setFixedCostReportData(data) {

  //   console.log('setFixedCostReportData',data.fixed_cost.value);
  //   // const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'citd'];
  //   const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'citd', 'wis', 'ed', 'uf', 'mvc' , 'historical' ];
   
  //   for (const fixd_cost of fc_keys) {
  //     const temp_obj = {
  //       key: data.fixedcost[fixd_cost].key,
  //       status: data.fixedcost[fixd_cost].value,
  //       val: [0, 0, 0, 0, 0]
  //     };
  //     let count = 1;
  //     // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
  //     let tem_sum = 0;
  //     if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
  //       tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
  //     }
  //     this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);


  //     // Sub-Total General & It Fixed Costs
  //     if(fixd_cost == 'wis'){
  //       this.report_data.fixed_cost.it_fixed_costs.total[0] += this.numOr0(tem_sum);
  //     } else {
  //       this.report_data.fixed_cost.general_fixed_costs.total[0] += this.numOr0(tem_sum);
  //     }
  //     // End Sub-Total General & It Fixed Costs


  //     console.log('general_total_cots ',fixd_cost,this.numOr0(tem_sum) , this.report_data.fixed_cost.general_fixed_costs.total[0]);
  //     temp_obj.val[0] = tem_sum;
  //     if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
  //       for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
  //         temp_obj.val[count] = this.numOr0(res.value);
  //         this.report_data.fixed_cost.total[count] += this.numOr0(res.value);


  //         // Sub-Total General & It Fixed Costs
  //         if(fixd_cost == 'wis'){
  //           this.report_data.fixed_cost.it_fixed_costs.total[count] += this.numOr0(res.value);
  //         } else {
  //           this.report_data.fixed_cost.general_fixed_costs.total[count] += this.numOr0(res.value);
            
  //         }
  //         // End Sub-Total General & It Fixed Costs
  //         count++;
  //       }
  //     }


  //     if(fixd_cost == 'wis'){
  //       this.report_data.fixed_cost.it_fixed_costs.value.push(temp_obj);
  //     } else {
  //       this.report_data.fixed_cost.general_fixed_costs.value.push(temp_obj);
  //     }
  //     this.report_data.fixed_cost.value.push(temp_obj);
  //   }


  //   const fc_array_keys = ['citd','other', 'otherCE'];
  //   for (const key of fc_array_keys ) {
  //     for (const other of data.fixedcost[key]) {

  //       if(other.value){


  //       const temp_obj = {
  //         key: other.key,
  //         status: other.value,
  //         val: [0, 0, 0, 0, 0]
  //       };
  //       let count = 1;
  //       // const tem_sum = this.otherArraySum(other.fixed_cost.fc);
  //       let tem_sum = 0;
  //       if (other.fixed_cost) {
  //         tem_sum = this.otherArraySum(other.fixed_cost.fc);
  //       }
  //       this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
  //       if(key =='citd' ){
  //         this.report_data.fixed_cost.it_fixed_costs.total[0] += this.numOr0(tem_sum);
  //       } else {
  //         this.report_data.fixed_cost.other_fixed_costs.total[0] += this.numOr0(tem_sum);
  //       }

  //       temp_obj.val[0] = tem_sum;
  //       if (other.fixed_cost_y5) {
  //         for (const fcy5 of other.fixed_cost_y5.val) {
  //           temp_obj.val[count] = this.numOr0(fcy5.value);
  //           this.report_data.fixed_cost.total[count] += this.numOr0(fcy5.value);

  //           if(key =='citd' ){
  //             this.report_data.fixed_cost.it_fixed_costs.total[count] += this.numOr0(fcy5.value);
  //           } else {
  //             this.report_data.fixed_cost.other_fixed_costs.total[count] += this.numOr0(fcy5.value);
  //           }


  //           count++;
  //         }
  //       }


  //       if(key =='citd' ){
  //         this.report_data.fixed_cost.it_fixed_costs.value.push(temp_obj);
  //         ;
  //       } else {
  //         this.report_data.fixed_cost.other_fixed_costs.value.push(temp_obj);
  //       }

        

  //       this.report_data.fixed_cost.value.push(temp_obj);
  //       }
  //     }
  //   }

  //   // const fc_keys1 = ['historical'];

  //   // for (const fixd_cost of fc_keys1) {
  //   //   const temp_obj = {
  //   //     key: data.fixedcost[fixd_cost].key,
  //   //     status: data.fixedcost[fixd_cost].value,
  //   //     val: [0, 0, 0, 0, 0]
  //   //   };
  //   //   let count = 1;
  //   //   // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
  //   //   let tem_sum = 0;
  //   //   if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
  //   //     tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
  //   //   }
  //   //   this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
  //   //   temp_obj.val[0] = tem_sum;
  //   //   if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
  //   //     for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
  //   //       temp_obj.val[count] = this.numOr0(res.value);
  //   //       this.report_data.fixed_cost.total[count] += this.numOr0(res.value);
  //   //       count++;
  //   //     }
  //   //   }

  //   //   this.report_data.fixed_cost.value.push(temp_obj);
  //   // }
  // }

  ArrayTotal(array){
    var sumNumber = array.reduce((acc, cur) => acc + Number(cur), 0)
   
    return sumNumber;
  }
  MultipleArrayHasValue(array){
    if(array){
      for (let prop of array){ // own properties, you might use
        // for (let prop in obj)
        let total = this.ArrayTotal(prop.val)
        if(total > 0){
          return 1;
        }
      }
    }
    return 0;

  }


  AppendPercentage(value , divded){

    
    if(value != 0 && value > 0 && divded !=0){
      let Percentage =  ((value*1 / divded*1)*100);
      return  Math.round(Percentage) + '%';
    } else if(divded == 0 || divded == '0'){
      return 'NA';
    } else {
      return '0%';
    } 
      
    }

}
