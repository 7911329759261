import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { UserService } from '../services/user.service';
import { DomSanitizer , Meta } from '@angular/platform-browser';

import { GlobalsService } from '../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-product-services',
  templateUrl: './product-services.component.html',
  styleUrls: ['./product-services.component.css']
})
export class ProductServicesComponent implements OnInit {
  page_content: any;
  myElement: any;
  urls: any;
  userobject: any;
  video_url: string;
  video_modal_src: any;
  autoplayvalue: boolean;
  metatag: any;
  selected_country: any;
  plans: any;
  createSignUpURL: any;
  createBusinessViabilitySignUpURL: any;
  EvaluationToolSignUpURL: string;
  EvaluationServiceSignUpURl: string;
  constructor(private cms: CmsService , private user: UserService , private sanitizer: DomSanitizer, private meta: Meta,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private globals: GlobalsService) {

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'The Kwik Look Free Trial helps SME startups understand, "How Much Money Do I Need to Start My Business?" (ie. What is My Maximum Exposure?).',
    };
    this.meta.updateTag(this.metatag);

    this.userobject = this.user.getUserObject();
    if (this.userobject.id) {
      this.urls = '/user/my-subscription';
    } else {
        this.urls = '/login';
    }

    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value ;
      }
    });

    this.globals.plans.subscribe(value => {
      if (value) {
        this.plans = value ;
      }
    });

    this.globals.createRegisterPurchaseURL.subscribe(value => {
      if (value) {
        this.createSignUpURL = '../register-plan/screening_tool_' + value.country ;
        this.createBusinessViabilitySignUpURL = '../register-plan/business_viability_assessment';
        this.EvaluationToolSignUpURL = '../register-plan/evaluation_tool';
        this.EvaluationServiceSignUpURl = '../register-plan/evaluation_service';
      }
  });


   }

  ngOnInit() {
      this.cms.getCmsByPage('cms/products_and_services')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
      this.cms.getPlans().subscribe( data => {
        this.plans = data.results;
        console.log('plans---------->', this.plans);
      });
  }
  gotobottom(id) {
    let hid = 'kwiklookfree';
    if ( id === '1' ) {
      hid = 'kwiklookfree';
    } else  if (id === '2') {
      hid = 'kwiklookscreeningtool';
    } else  if (id === '3') {
      hid = 'evalutiontools';
    } else  if (id === '4') {
      hid = 'preiumlookevalution';
    } else  if (id === '5') {
      hid = 'businessviabilityassessment';
    }
    const el = document.getElementById(hid);
    el.scrollIntoView();
  }

  gotobottomkwikscreening() {
    const el = document.getElementById('kwiklookscreeningtool');
    el.scrollIntoView();
  }
  gotobottomprolooktool() {
    const el = document.getElementById('evalutiontools');
    el.scrollIntoView();
  }
  gotobottompremiumlookeval() {
    const el = document.getElementById('preiumlookevalution');
    el.scrollIntoView();
  }

  openModal(dataclick) {
    // this.video_modal_title = data;
    const vurls = this.user.getVideoUrls();
    // console.log('datavalues', dataclick);
    switch (dataclick) {
      case 'freetrial':
        this.video_url = 'https://www.youtube.com/embed/' + vurls['sing_up_trail'] + '?autoplay=1';
        break;
      case 'overview': this.video_url = 'https://www.youtube.com/embed/' + vurls['home_page'] + '?autoplay=1';
        break;

    }

    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);

    this.autoplayvalue = true;
  }
  closeModal() {
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.autoplayvalue = false;
  }
  hideHolder(e: any) {
    // console.log($(e.target));
    $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
    $(e.target).attr('placeholder', '');
    return;
  }
  showHolder(e: any) {
    const txtval = $(e.target).val();
            if (txtval === '') {
                $(e.target).attr('placeholder', $(e.target).data('placeholder'));
            }
  }

}
