import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { UserService } from '../services/user.service';
import { DomSanitizer , Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-productssolutions',
  templateUrl: './productssolutions.component.html',
  styleUrls: ['./productssolutions.component.css']
})
export class ProductssolutionsComponent implements OnInit {

  myElement: any;
  urls: any;
  userobject: any;
  metatag: any;
  selected_country: any;
  plans: any;
  createSignUpURL: any;
  createBusinessViabilitySignUpURL: any;
  EvaluationToolSignUpURL: string;
  EvaluationServiceSignUpURl: string;
  userlogged: boolean;
  constructor(private cms: CmsService , private user: UserService , private sanitizer: DomSanitizer, private meta: Meta,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,private router: Router,
    private globals: GlobalsService) {
      this.metatag = {
        name: 'description',
        // tslint:disable-next-line:max-line-length
        content: 'The Kwik Look Free Trial helps SME startups understand, "How Much Money Do I Need to Start My Business?" (ie. What is My Maximum Exposure?).',
      };
      this.meta.updateTag(this.metatag);
      this.userobject = this.user.getUserObject();
      if (this.userobject.id) {
        this.urls = '/user/my-subscription';
      } else {
        this.urls = '/login';
      }
      this.globals.current_country.subscribe(value => {
        if (value) {
          this.selected_country = value ;
        }
      });
      this.globals.plans.subscribe(value => {
        if (value) {
          this.plans = value ;
        }
      });
      this.globals.createRegisterPurchaseURL.subscribe(value => {
        if (value) {
          this.createSignUpURL = '../register-plan/screening_tool_' + value.country ;
          this.createBusinessViabilitySignUpURL = '../register-plan/business_viability_assessment' ;
          this.EvaluationToolSignUpURL = '../register-plan/evaluation_tool';
          this.EvaluationServiceSignUpURl = '../register-plan/evaluation_service';
        }
    });
  }

  ngOnInit() {
    this.cms.getPlans().subscribe( data => {
      this.plans = data.results;
      console.log('plans---------->', this.plans);
    });

    const c_user = this.user.getUserObject();
    if (c_user) {
      this.userlogged = true;
    }

  }

}
