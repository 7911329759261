import { Component, OnInit , OnDestroy } from '@angular/core';
import {CmsService} from '../cms.service';
import { Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { Observable, of, Subject } from 'rxjs';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit, OnDestroy  {
  blog_data: any;
  morenumber: number;
  loading: boolean;
  metatag: any;
  invokeBlog: Subject<any> = new Subject();
  processing: Boolean;
  selected_country: any;
  showmore: boolean;
  constructor(private cms: CmsService, private meta: Meta, private globals: GlobalsService) {
    this.morenumber = 0;
    this.loading = true;
    this.showmore = true;
    this.processing = false;
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'The Kwik Look Blog includes useful information on registering a company, government grants for startups and SME\'s, tax incentives for startups etc',
    };
    this.meta.updateTag(this.metatag);
  }

  ngOnInit() {
  /*  this.cms.getAllblogs('kwiklook/blogs')
      .subscribe(page_content => { this.blog_data = JSON.parse(page_content);
      this.morenumber = this.blog_data[0].more;
      } );*/

      this.globals.current_country.subscribe(value => {
        if (value) {
          this.selected_country = value ;
          this.processing = true;
        //  console.log('blog country', this.selected_country);
          this.cms.getAllblogsbyCountry('kwiklook/blogs_country', this.selected_country)
          .subscribe(page_content => {

            this.processing = false;
            const dummy_data = JSON.parse(page_content);
            this.showmore = dummy_data[0].show_more;
            console.log(this.showmore, dummy_data[0]);

            // console.log('globalservice blog contetnt', this.selected_country , dummy_data);
            this.cms.displayBlogContent(page_content);

        /*

           this.blog_data = JSON.parse(page_content);
           this.morenumber =  this.blog_data[0].more;
            if (this.morenumber) {
              this.cms.displayBlogContent(page_content);
              this.loading = true;
              if (this.blog_data[0].more < this.blog_data[0].defaultmore) {
                this.loading = false;
              }
            } else {
              this.blog_data = null;
              this.cms.displayBlogContent(this.blog_data);
              this.loading = false;
            }
*/

          } );
        }
      });
    //  this.cms.invokeEvent.unsubscribe();
      this.cms.invokeBlog.subscribe(value => {
          if (value) {

            this.blog_data =  JSON.parse(value);
          //  console.log('invokeBlog blog contetnt', this.blog_data);

         //   this.blog_data = JSON.parse(page_content);
            if (this.blog_data !== null) {
              this.morenumber =  this.blog_data[0].more;
            } else {
              this.morenumber = 0;
            }

             if (this.morenumber) {
              //  this.cms.displayBlogContent(page_content);
               this.loading = true;
               if (this.blog_data[0].more < this.blog_data[0].defaultmore) {
                 this.loading = false;
               }
             } else {
               this.blog_data = null;
        //       this.cms.displayBlogContent(this.blog_data);
               this.loading = false;
             }





          }
        });


     /* console.log('newnew', this.selected_country);
      this.cms.getAllblogsbyCountry('kwiklook/blogs_country', this.selected_country)
      .subscribe(page_content => {
        console.log('page_content', page_content);

        this.blog_data = JSON.parse(page_content);
        this.morenumber = this.blog_data[0].more;
      } );*/

  }
  ngOnDestroy() {
  //  this.cms.invokeEvent.unsubscribe();
  }
  morebutton() {
    this.processing = true;
    this.loading = false;
    this.cms.getmoreblogs(this.morenumber, this.selected_country).subscribe(
      page_content => {
        this.processing = false;
        this.blog_data = JSON.parse(page_content);
        this.morenumber = this.blog_data[0].more;
        this.showmore = this.blog_data[0].show_more;

        if (this.morenumber === 0) {
          this.loading = false;
        } else {
          this.loading = true;
        }
  } );

  }

}
