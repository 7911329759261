import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { Year5ReportService } from '../../services/year5-report.service';
@Component({
  selector: 'app-fixed-cost-y1-to-y5',
  templateUrl: './fixed-cost-y1-to-y5.component.html',
  styleUrls: ['./fixed-cost-y1-to-y5.component.css']
})
export class FixedCostY1ToY5Component implements OnInit, OnDestroy {
  variable_data: any = {};
  tooltip_data: any;
  all_null = true;
  report_data: any = {};
  fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'citd', 'wis', 'ed', 'uf', 'mvc' ,'historical'];
  constructor(private router: Router, private user: UserService, private tooltip: TooltipService,
    private y5_report: Year5ReportService
    ) { }

 
  ngOnInit() {

     
    this.report_data.fixed_cost = { value: [], total: [0, 0, 0, 0, 0] };
     

    this.user.brcr_back = '/user/fixed-cost/y1';
    this.user.brcr_next = '/user/variable-cost/y1';
    this.tooltip_data = this.tooltip.getTooltips();
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    this.user.getUserVaiableData().subscribe(
      data => {
        const y5_report =  this.y5_report.setFixedCostReportData(data);
        this.report_data = y5_report;
       // console.log('user data', data);
        if (Object.keys(data).length) {
          this.fc_keys.map(a => {
            if (data.fixedcost[a].value) {
              this.all_null = false;
            }
            if (typeof data.fixedcost[a].fixed_cost_y5 === 'undefined') {
              const revenue_cost_y5 = {
                val: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' }
                ]
              };
              data.fixedcost[a].fixed_cost_y5 = revenue_cost_y5;
            }
          });
          data.fixedcost.other.map(a => {
            if (a.value) {
              this.all_null = false;
            }
            if (typeof a.fixed_cost_y5 === 'undefined') {
              const revenue_cost_y5 = {
                val: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' }
                ]
              };
              a.fixed_cost_y5 = revenue_cost_y5;
            }
          });
          data.fixedcost.citd.map(a => {
            if (a.value) {
              this.all_null = false;
            }
            if (typeof a.fixed_cost_y5 === 'undefined') {
              const revenue_cost_y5 = {
                val: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' }
                ]
              };
              a.fixed_cost_y5 = revenue_cost_y5;
            }
          });

          data.fixedcost.otherCE.map(a => {
            if (a.value) {
              this.all_null = false;
            }
            if (typeof a.fixed_cost_y5 === 'undefined') {
              const revenue_cost_y5 = {
                val: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' }
                ]
              };
              a.fixed_cost_y5 = revenue_cost_y5;
            }
          });

          if (!(data.fixedcost.historical)
          || data.fixedcost.historical === 'undefined') {  // initalize historical
            data.fixedcost.historical = {
              key: 'Historical Costs', value: false,
              fixed_cost: {
                fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
              },
              fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            };
          }

          if (!(data.fixedcost.fxnotes) || typeof data.fixedcost.fxnotes === 'undefined') {

              const def_objnotes = {
                  key: 'Notes', value: false,
                  content: 'Notes',
                  content_y5: 'Notes',
                  fixed_cost: {
                  fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
                  { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
                  },
                  fixed_cost_y5: {
                  val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
                  },
              }

            data.fixedcost.fxnotes = def_objnotes;

           } else {

              if (typeof data.fixedcost.fxnotes.content === 'undefined') {
                data.fixedcost.fxnotes.content = 'Notes';
              }
              if (typeof data.fixedcost.fxnotes.content_y5 === 'undefined') {
                data.fixedcost.fxnotes.content_y5 = 'Notes';
              }

           }



          this.user.varSelection = this.variable_data = data;

           console.log('---------->',data);
        }
      }
    );
  }
  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }

  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY5(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }
  }

  saveAndNavigateBack(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY5(this.variable_data);
    this.user.saveAndNavigateBack(link, this.variable_data);
  }
  saveAndNavigateForward(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroFCY5(this.variable_data);
    this.user.saveAndNavigateForward(link, this.variable_data);
  }
  onKeydown(event, k, arr) {
   
   
    this.user.onKeydown(event, k, arr);
    
    
    this.getReportdata(this.variable_data);
  }

  async getReportdata(data){
    await this.user.delay(50);
    const y5_report =  this.y5_report.setFixedCostReportData(data);
    this.report_data = y5_report;
  }

  
}
