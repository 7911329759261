import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Year1ReportService } from '../../services/year1-report.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-base-y1',
  templateUrl: './base-y1.component.html',
  styleUrls: ['./base-y1.component.css']
})
export class BaseY1Component implements OnInit {
  c_user: any;
  user_scope = 0;
  report_data: any = {};
  chartOptions: any;
  month_order: any;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  hidelinechart: boolean;
  fisc_inputs = { service_charge: 0 };
  fixed_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true
    }
  };
  total_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true,
    }
  };
  constructor(private route: ActivatedRoute, private router: Router, private user: UserService, private y1_report: Year1ReportService) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: {
          display: true,
          boxWidth: 10
        },
        position: 'bottom'
      },
    };

    this.chartData = [
      { data: [], label: 'Cashflow After Tax', fill: false },
      { data: [], label: 'Cum Cashflow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];

    this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.color_arr = [{
      // tslint:disable-next-line:max-line-length
      backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }];


  }

  ngOnInit() {
    this.user.getSharedReport(this.route.snapshot.params.id, 'base_y1').subscribe(
      data => {
        if (data.status) {
          this.report_data = data.data;
          this.breakDownFixedCostGraphData();
          this.breakDownTotalCostGraphData();
          this.chartData[0].data = this.report_data.base_case.cash_flow.val;
          this.chartData[1].data = this.report_data.base_case.cum_cash_flow.val;
          this.hidelinechart = true;
          // console.log(this.report_data);
        }
      }
    );
    this.month_order = this.user.getMonthOrder();
  }
  breakDownTotalCostGraphData() {
    this.total_cost_breakdown.pieChartData.push(
      this.report_data.cogs.total.tot,
      this.report_data.variable_costs.total.tot,
      this.report_data.fixed_costs.total.tot
    );
    this.total_cost_breakdown.pieChartLabels.push('Total COGS', 'Total Variable Costs', 'Total Fixed Costs');
    this.total_cost_breakdown.pieChartOptions.backgroundColor.push('#FFC236', '#4071C1', '#F57F3B');
  }
  breakDownFixedCostGraphData() {
    let count = 0;
    for (const val of this.report_data.fixed_costs.value) {
      this.fixed_cost_breakdown.pieChartData.push(val.total);
      this.fixed_cost_breakdown.pieChartLabels.push(val.key);
      this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
      count++;
    }

    /* Start new code arya */
    const fc_other_array = {
      'citd': {
        name : 'Customised IT Development',
        total : 0
      },
      'other': {
          name : 'Other',
          total : 0
      },
      'otherCE': {
          name : 'Other Capital Expenditure',
          total : 0
      }
    };
    for (const val of this.report_data.fixed_costs.other) {
      fc_other_array[val.type]['total'] += val.total;
    }
    for (const key in fc_other_array) {
      if (fc_other_array.hasOwnProperty(key)) {
        // code here
        this.fixed_cost_breakdown.pieChartData.push(fc_other_array[key]['total']);
        this.fixed_cost_breakdown.pieChartLabels.push(fc_other_array[key]['name']);
        this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
        count++;
      }
    }

    /* End new code arya*/

    /** Old code
    for (const val of this.report_data.fixed_costs.other) {
      this.fixed_cost_breakdown.pieChartData.push(val.total);
      this.fixed_cost_breakdown.pieChartLabels.push(val.key);
      this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
      count++;
    }*/
  }
  MathRoundCust(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.revenue.total_sales_revenue.grant_total) * 100);
  }
  otherArraySum(x) {
    return (x.map(a => a.value).reduce((a, b) => this.user.numOr0(a) + this.user.numOr0(b)));
  }
  MathRoundCustCOGS(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.cogs.total.tot) * 100);
  }
  MathRoundCustFixCost(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.fixed_costs.total.tot) * 100);
  }
  MathRoundCustVarCost(x) {
    if (x === 0) { return 0; }
    return Math.round((parseInt(x, 10) / this.report_data.variable_costs.total.tot) * 100);
  }
  AppendPercentage(value , divded){

    
    if(value != 0 && value > 0 && divded !=0){
      let Percentage =  ((value*1 / divded*1)*100);
      return  Math.round(Percentage) + '%';
    } else if(divded == 0 || divded == '0'){
      return 'NA';
    } else {
      return 0;
    } 
      
    }

}
