import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CmsService } from '../cms.service';
import {ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-footer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  urlslug: any;
  page_content: any;
  myDate: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private cms: CmsService,
    private router: Router) { }

  ngOnInit() {

    this.myDate = new Date();
    console.log('zzzzzzzzzzzzzzzzzz',this.myDate );

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
      this.urlslug = this.activatedRoute.root.children[0]['_routerState'].snapshot.url;
      if (this.urlslug === '/login' || this.urlslug === '/register') { this.urlslug = ''; }
    });

    this.cms.getCmsByPage('cms/footer')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );
  }

}
