import { Injectable , Inject } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { NetworkService } from './services/network.service';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  invokeEvent: Subject<any> = new Subject();
  invokeEvent2: Subject<any> = new Subject();
  invokeBlog: Subject<any> = new Subject();
  invokeEventPrivacy:Subject<any> = new Subject();
  constructor(private network: NetworkService,@Inject(LOCAL_STORAGE) private localStorage: any) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  getAllFaq (country) {
   // console.log('country', country);
    return this.network.post_http_request('cms/faqs', {'country': country });
    }

  getcountrybasedpage (country, page) {
    return this.network.post_http_request('cms/getallnewpagebasedcountry', {'country': country , 'page': page });
  }

  getCmsByPage (url_slug) {
   // console.log('country', url_slug);
    return this.network.get_http_request(url_slug);
  }

  getAllblogs(url_slug) {
    return this.network.get_blog_http_request(url_slug);
  }
  getAllblogsbyCountry(url_slug, country) {
    return this.network.post_blog_http_request(url_slug, {'country': country });
    }


  getSingleblog(blog_slug) {
    return this.network.post_blog_http_request('kwiklook/singleblog', blog_slug);
  }

  getmoreblogs(more_number, country) {
    return this.network.post_blog_http_request('kwiklook/moreblogs', { 'more': more_number, 'country': country });
  }

  getmorecasestudy(more_number, country) {
    return this.network.post_blog_http_request('kwiklook/morecasestudy', { 'more': more_number, 'country': country });
  }
  getRelatedblogs(url_slug) {
    return this.network.get_blog_http_request(url_slug);
  }

  getSingleblogbyCountry(url_slug , country) {
    return this.network.post_blog_http_request('kwiklook/singleblog', { 'url': url_slug, 'country': country });
  }

  getSingleCasestudybyCountry(url_slug , country) {
    return this.network.post_blog_http_request('kwiklook/singlecase-study', { 'url': url_slug, 'country': country });
  }

  getSingleblogmeta(blog_slug) {
    return this.network.post_blog_http_request('kwiklook/metatag', blog_slug);
  }

  getClientIp() {
    // return console.log('ccccc', );\
    return this.network.get_http_request('cms/country');
    }


    displayCmsContent(data) {
    return this.invokeEvent.next(data);
    }
    displayCmsContentprivacy(data) {
      return this.invokeEventPrivacy.next(data);
      }

    displayFaqContent(data) {
      return this.invokeEvent2.next(data);
    }
    displayBlogContent(blogcontent) {
      return this.invokeBlog.next(blogcontent);
      }

      getPlans() {
        const selected_country  = this.localStorage.getItem('kw_lang');
        return this.network.post_http_request('subscription/get_plans', { 'country': selected_country });
      }

      

}
