import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService, AlertService, UserService } from '../services';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { GlobalsService } from '../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
@Component({
  selector: 'app-covid-landing',
  templateUrl: './covid-landing.component.html',
  styleUrls: ['./covid-landing.component.css']
})
export class CovidLandingComponent implements OnInit {
  contactForm: any;
  pageScrollDuration: any;
  submitted: any = false;
  loading: any = false;
  message: string;
  classmessages: string;
  constructor(private cms: CmsService, private formBuilder: FormBuilder,
    private userService: UserService, private router: Router, private alertService: AlertService, private meta: Meta ,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private globals:GlobalsService) { 

      this.contactForm = this.formBuilder.group({
        'conct_name': ['', Validators.required],
    //    'conct_lastname': ['', Validators.required],
        'conct_email': ['', [Validators.required, Validators.email]],
        'conct_phone': '',
        'conct_message': ['']
  
      });

    }

  get f() { return this.contactForm.controls; }

  ngOnInit() {
  }
  gotobottom() {
    const el = document.getElementById('freetrail_section');
    el.scrollIntoView({ behavior: 'smooth' });
    this.pageScrollDuration = 200;
  }
  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }


    this.loading = true;

    //console.log('covid form', this.contactForm.value);
    
   
  


    this.userService.LandingContactForm(this.contactForm.value).subscribe(
      data => {
        if (data.status === 1) {
         // this.contactForm.reset();
         this.submitted = false;
          this.message = data.msg;
          this.classmessages = 'success';
          this.loading = false;
          setTimeout(() => this.message = ''  , 3000);
          this.contactForm.reset();
         // console.log('covid form-->', this.contactForm.value);
        } else {
          this.message = data.msg;
          this.classmessages = 'error';
          this.loading = false;
          setTimeout(() => this.message = ''  , 3000);
        }
      }
      );



  }

}
