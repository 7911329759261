import { Pipe, PipeTransform } from '@angular/core';
import { Year1ReportService } from '../services/year1-report.service';
import { UserService } from '../services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sumarray',
  pure: false
})
export class SumarrayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    function numOr0(n) {
      return parseFloat(n) ? parseFloat(n) : 0;
    }
    return Math.round((value.map(a => a.value).reduce((a, b) => numOr0(a) + numOr0(b))).toFixed(2));
  }

}

@Pipe({
  name: 'sumarrayOneD',
  pure: false
})
export class SumarrayonedPipe implements PipeTransform {
  fisc_inputs = { service_charge: 10 };
  constructor(private report_service: Year1ReportService, private user: UserService) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
  }
  transform(value: any, indx: number, mode: number, args?: any): any {
    let result = 0;
    if (mode === 1) {
      result = this.getRevenueValueForY1(value, indx);
    } else if (mode === 2) {
      result = this.getRevenueValueForY5(value, indx);
    } else if (mode === 3) {
      result = this.getCOGSValueForY1(value, indx);
    } else if (mode === 4) {
      result = this.getCOGSValueForY5(value, indx);
    }

    return Math.round(result);
    // return Math.round((value.map(a => a).reduce((a, b) => numOr0(a) + numOr0(b))).toFixed(2));
  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  getCOGSValueForY5(value, indx) {
    let result = 0;
    for (const revenue of value.revenue.ps) {
      const x = revenue.revenue_cost_y5.sales;
      if (indx < 4) {
        if (value.cogs.cost.value && !(value.cogs.unitcost.value)) {
          result += this.numOr0(revenue.revenue_cost_y5.yearly_cost[indx].value);
        } else {
          result += this.numOr0(x[indx].value) * this.numOr0(revenue.revenue_cost_y5.unit_yearly_cost[indx].value);
        }
      }
    }
    return result;
  }
  getCOGSValueForY1(value, indx) {
    let result = 0;
    for (const revenue of value.revenue.ps) {
      const x = revenue.revenue_cost.sales;
      if (indx < 12) {
        if (value.cogs.cost.value && !(value.cogs.unitcost.value)) {
          result += this.numOr0(revenue.revenue_cost.monthly_cost[indx].value);
        } else {
          result += this.numOr0(x[indx].value) * this.numOr0(revenue.revenue_cost.unit_monthly_cost);
        }
      } else if (indx === 12) {
        let count = 0;
        for (const val of x) {
          if (value.cogs.cost.value && !(value.cogs.unitcost.value)) {
            result += this.numOr0(revenue.revenue_cost.monthly_cost[count].value);
          } else {
            result += this.numOr0(x[count].value) * this.numOr0(revenue.revenue_cost.unit_monthly_cost);
          }
          count++;
        }
      }
    }
    return result;
  }
  getRevenueValueForY5(value, indx) {
    console.log('value, indx',value, indx);
    let result = 0;
    for (const revenue of value.revenue.ps) {
      const x = revenue.revenue_cost_y5.sales;
      if (indx < 4) {
        let row_val = this.numOr0(x[indx].value) * this.numOr0(revenue.revenue_cost_y5.unit_price[indx].value);
        if (value.revenue.sc.value && value.revenue.sc.value !== 'false') {
          row_val += (row_val / 100) * this.numOr0(this.fisc_inputs.service_charge);
        }
        result += row_val;
      }
    }
    result += this.numOr0(value.revenue.other.revenue_cost_y5[indx].value);
    return result;
  }
  getRevenueValueForY1(value, indx) {
    let result = 0;
    if (indx < 12) {
      for (const revenue of value.revenue.ps) {
        const x = revenue.revenue_cost.sales;
        let row_val = this.numOr0(x[indx].value) * this.numOr0(revenue.revenue_cost.unit_price);
        if (value.revenue.sc.value && value.revenue.sc.value !== 'false') {
          row_val += (row_val / 100) * this.numOr0(this.fisc_inputs.service_charge);
        }
        result += row_val;
      }
      result += this.numOr0(value.revenue.other.revenue_cost[indx].value);
    } else if (indx === 12) {
      let outer_count = 0;
      for (const revenue of value.revenue.ps) {
        const x = revenue.revenue_cost.sales;
        let count = 0;
        for (const sales of x) {
          let row_val = this.numOr0(x[count].value) * this.numOr0(revenue.revenue_cost.unit_price);
          if (value.revenue.sc.value && value.revenue.sc.value !== 'false') {
            row_val += (row_val / 100) * this.numOr0(this.fisc_inputs.service_charge);
          }
          result += row_val;
          if (outer_count === 0) {
            result += this.numOr0(value.revenue.other.revenue_cost[count].value);
          }
          count++;
        }
        outer_count++;
      }
    }
    return result;
  }
}

/* @Pipe({
  name: 'format_num',
  pure: false
})
export class FormatNumberWIthComma implements PipeTransform {
  transform(value: any, args?: any): any {
    return value * 100;
  }
} */

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}
