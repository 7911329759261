import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../services/user.service';
import { count } from 'rxjs/operators';
import { TooltipService } from '../../services/tooltip.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-variable-selection',
  templateUrl: './variable-selection.component.html',
  styleUrls: ['./variable-selection.component.css']
})
export class VariableSelectionComponent implements OnInit, OnDestroy {

  c_user: any;
  tooltip_data: any;
  next_state: any;
  variable_data: any = {};
  var_status = '';
  user_scope = 0;
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  hidetrailvideo: boolean;
  constructor(private tooltip: TooltipService, private router: Router, private _location: Location, private user: UserService,
    private sanitizer: DomSanitizer
  ) {

    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['variable_selection'] + '/';
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
  //  this.setDefalutServiceValues();
  }

  ngOnInit() {
    this.user.brcr_back = '/user/kwik-look-main';
    this.user.brcr_next = '/user/revenue-cogs/y1';
    this.tooltip_data = this.tooltip.getTooltips();
    this.c_user = this.user.getUserObject();
    if (this.c_user.plan_details.slug === 'free_trial' || this.c_user.plan_details.slug === '') {
      this.hidetrailvideo = true;
    }
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );

    if (this.user.userFormData['level3_slug']) {
      this.next_state = this.user.userFormData['level3_slug'];
    }
    this.user.getUserVaiableData().subscribe(
      data => {
        if (Object.keys(data).length) {
          // console.log(data);
          data.revenue.sc.value = JSON.parse(data.revenue.sc.value);
          this.variable_data = data;

           this.variable_data.variablecost.tppc.key = 'Third Party Payment Charges %';

          this.user.varSelection = data;

          console.log('this.user.varSelection',this.user.varSelection);

        } else {
          this.variable_data = this.setDefalutServiceValues();
        }
        this.checkForPhase2Params();
      }
    );
  }
  checkForPhase2Params() {

    if (!this.variable_data.fixedcost.citd) {
      this.variable_data.fixedcost.citd = [{
        key: 'Customised IT Development', value: true,
        fixed_cost: {
          fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
          { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        },
        fixed_cost_y5: {
          val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
        },
      }];
    }
    if (this.variable_data.fixedcost.citd && typeof this.variable_data.fixedcost.citd.length === 'undefined' ) {

     const newCitd  = [{
        key: 'Customised IT Development', value: true,
        fixed_cost: {
          fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
          { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        },
        fixed_cost_y5: {
          val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
        },
      }];
      newCitd[0].fixed_cost = this.variable_data.fixedcost.citd.fixed_cost;
      newCitd[0].fixed_cost_y5 = this.variable_data.fixedcost.citd.fixed_cost_y5;
      this.variable_data.fixedcost.citd = newCitd;
    }

    if (!this.variable_data.fixedcost.otherCE) {
      this.variable_data.fixedcost.otherCE = [{
        key: 'Other Capital Expenditure1', value: true,
        fixed_cost: {
          fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
          { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
        },
        fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
      }];
    }


    if (!this.variable_data.fixedcost.historical) {
      this.variable_data.fixedcost.historical = {
        key: 'Historical Costs', value: true,
        fixed_cost: {
          fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
          { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
        },
        fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
      };
    }



    if (!this.variable_data.variablecost.tppcdollar) {

      this.variable_data.variablecost.tppcdollar = {
        key: 'Third Party Payment Charges $', value: true, type: 1,
        variable_cost: { value: 0 },
        variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
      };
    }



    if (!this.variable_data.variablecost.tppc.type) {
      this.variable_data.variablecost.tppc['type'] = 1;
    }

//------------- Phase 4 sales

if (!this.variable_data.variablecost.sales) {
  this.variable_data.variablecost.sales = [{
    key: 'Sales 1', value: false,
    variable_cost: {
      val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
    },
    variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
  }];
}

if (!this.variable_data.variablecost.service) {
  this.variable_data.variablecost.service = [{
    key: 'Service 1', value: false,
    variable_cost: {
      val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
    },
    variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
  }];
}

if (!this.variable_data.variablecost.historical) {
  this.variable_data.variablecost.historical = {
    key: 'Historical Costs', value: true,
    variable_cost: {
      val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
    },
    variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
  };
}




  }



  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroRevenueY1(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }

  }
  /*  jsonParse( x ) {
     return JSON.parse(x);
   } */
  setDefalutServiceValues() {
    const var_data: any = {};
    var_data.revenue = {};
    var_data.revenue.sc = { key: 'Service Charge', value: false };
    var_data.revenue.ps = [{
      key: 'Products/Services 1',
      value: true,
      revenue_cost: {
        monthly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        sales: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_monthly_cost: 0,
        unit_price: 0
      },
      revenue_cost_y5: {
        yearly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        sales: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_yearly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_price: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      }
    }];
    var_data.revenue.other = {
      key: 'Other Revenue',
      value: true,
      revenue_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      revenue_cost_y5: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
    };

    var_data.cogs = {};
    var_data.cogs.unitcost = { key: 'Unit Cost', value: true };
    var_data.cogs.cost = { key: 'Monthly or Yearly Cost', value: false };
    var_data.cogs.type = 1;

    var_data.fixedcost = {};
    var_data.fixedcost.bsm = {
      key: 'Business Set Up & Maintenance', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
    };
    var_data.fixedcost.rd = {
      key: 'Rental Deposit', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      },
      fixed_cost_y5: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      }
    };
    var_data.fixedcost.rent = {
      key: 'Rent', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      },
      fixed_cost_y5: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
    };
    var_data.fixedcost.renovation = {
      key: 'Renovation', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      },
      fixed_cost_y5: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
    };
    var_data.fixedcost.fe = {
      key: 'Furniture / Equipment', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
    };



// ---------------

var_data.fixedcost.citd = [{
  key: 'Customised IT Development', value: true,
  fixed_cost: {
    fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
  },
  fixed_cost_y5: {
    val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
  },
}];

// ---------------------

    var_data.fixedcost.wis = {
      key: 'Website / General IT / Software', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.fixedcost.ed = {
      key: 'Electronic Devices', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.fixedcost.uf = {
      key: 'Utilities (Fixed)', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.fixedcost.mvc = {
      key: 'Motor Vehicle (Cash)', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.fixedcost.other = [{
      key: 'Other 1', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];



    var_data.fixedcost.historical = {
      key: 'Historical Costs', value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };


    // --------------------------------------------------------------------------------

    var_data.variablecost = {};
    var_data.variablecost.op_partners = {
      key: 'Salaries: Operating Partner', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.variablecost.salaries = [{
      key: 'Salaries: Staff 1', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];
    var_data.variablecost.marketing = [{
      key: 'Marketing 1', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];


    //---------------- phase 4 Sales
     var_data.variablecost.sales = [{
      key: 'Sales 1', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];


    var_data.variablecost.historical = {
      key: 'Historical Costs', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
     //---------------- phase 4 Service
     var_data.variablecost.service = [{
      key: 'Service 1', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];


    var_data.variablecost.logistics = {
      key: 'Logistics', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };


    var_data.variablecost.tppc = {
      key: 'Third Party Payment Charges %', value: true, type: 1,
      variable_cost: { value: 0 },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };


    var_data.variablecost.tppcdollar = {
      key: 'Third Party Payment Charges $', value: true, type: 1,
      variable_cost: { value: 0 },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };


    var_data.variablecost.cps = {
      key: 'Consultants and Professional Services', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.variablecost.tad = {
      key: 'Training & Development', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.variablecost.uv = [{
      key: 'Utilities (Variable)', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];
    var_data.variablecost.bte = {
      key: 'Business Travel Expenses', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    };
    var_data.variablecost.other = [{
      key: 'Other 1', value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    }];
    return var_data;
  }
  addNewServe() {
    const cnt = (this.variable_data.revenue.ps).length;
    this.variable_data.revenue.ps.push({
      key: 'Products/Services ' + (cnt + 1),
      value: true,
      revenue_cost: {
        monthly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        sales: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_monthly_cost: 0,
        unit_price: 0
      },
      revenue_cost_y5: {
        yearly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        sales: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_yearly_cost: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        unit_price: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      }
    });
  }
  addNewFCOther() {
    const cnt = (this.variable_data.fixedcost.other).length;
    this.variable_data.fixedcost.other.push({
      key: 'Other ' + (cnt + 1),
      value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }

  addNewFCCitd(){
    console.log('this.variable_data.fixedcost',this.variable_data.fixedcost);
    const cnt = (this.variable_data.fixedcost.citd).length;
    this.variable_data.fixedcost.citd.push({
      key: 'Customised IT Development ' + (cnt + 1),
      value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }

  addNewFCOtherCE() {
    const cnt = (this.variable_data.fixedcost.otherCE).length;
    this.variable_data.fixedcost.otherCE.push({
      key: 'Other Capital Expenditure ' + (cnt + 1),
      value: true,
      fixed_cost: {
        fc: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      fixed_cost_y5: { val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }
  addNewVCSalary() {
    const cnt = (this.variable_data.variablecost.salaries).length;
    this.variable_data.variablecost.salaries.push({
      key: 'Salaries: Staff ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }
  addNewVCMarketing() {
    const cnt = (this.variable_data.variablecost.marketing).length;
    this.variable_data.variablecost.marketing.push({
      key: 'Marketing ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }
//---------------- Phase 4
  addNewVCSales() {
    const cnt = (this.variable_data.variablecost.sales).length;
    this.variable_data.variablecost.sales.push({
      key: 'Sales ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }

  addNewVCService() {
    const cnt = (this.variable_data.variablecost.service).length;
    this.variable_data.variablecost.service.push({
      key: 'Service ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }

  //---------------- Phase 4
  addNewVCUtility() {
    const cnt = (this.variable_data.variablecost.uv).length;
    this.variable_data.variablecost.uv.push({
      key: 'Utilities - Variable ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }
  addNewVCOther() {
    const cnt = (this.variable_data.variablecost.other).length;
    this.variable_data.variablecost.other.push({
      key: 'Other ' + (cnt + 1),
      value: true,
      variable_cost: {
        val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
        { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
      },
      variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
    });
  }


  goToNextForm(url_slug) {
    this.router.navigateByUrl(url_slug);
  }
  backClicked() {
    this.user.saveUserVaiableData(this.variable_data).subscribe(
      data => {
        this.var_status = 'Your data has been saved successfully.';
        const cur_obj = this;
        setTimeout(function () {
          cur_obj.var_status = '';
          cur_obj.router.navigateByUrl('/user/kwik-look-main');
        }, 1000);
      }
    );
    this.user.setVariableSelectionStatus(1);
  }
  nextClicked() {
    this.user.saveUserVaiableData(this.variable_data).subscribe(
      data => {
        this.var_status = 'Your data has been saved successfully.';
        const cur_obj = this;
        setTimeout(function () {
          cur_obj.var_status = '';
          if (cur_obj.next_state) {
            cur_obj.router.navigateByUrl(cur_obj.next_state);
          }
        }, 1000);

        if (this.user_scope < 2 && !cur_obj.next_state) {
          this.router.navigateByUrl('/user/kwik-look-main');
        }
      }
    );

    this.user.setVariableSelectionStatus(1);
    this.c_user.variable_selection = 1;
    // Save Details.
  }
  cogsToggle(type, value) {
    /* console.log(type);
    console.log(value);
    console.log('unitcost', this.variable_data.cogs.unitcost.value);
    console.log('cost', this.variable_data.cogs.cost.value); */
    setTimeout(() => {
      if (!value || value) {
        if (type === 1) {
          if (this.variable_data.cogs.unitcost.value) {
            this.variable_data.cogs.cost.value = false;
          }
        } else {
          if (this.variable_data.cogs.cost.value) {
            this.variable_data.cogs.unitcost.value = false;
          }
        }
      }
      /* console.log('unitcost', this.variable_data.cogs.unitcost.value);
      console.log('cost', this.variable_data.cogs.cost.value); */
    }, 200);
  }

  closeModal() {
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['variable_selection'] + '?autoplay=1';
    this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }


}
