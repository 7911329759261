import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { Year5ReportService } from '../../services/year5-report.service';
@Component({
  selector: 'app-variable-cost-y1-to-y5',
  templateUrl: './variable-cost-y1-to-y5.component.html',
  styleUrls: ['./variable-cost-y1-to-y5.component.css']
})
export class VariableCostY1ToY5Component implements OnInit, OnDestroy {
  variable_data: any = {};
  report_data: any = {};
  tooltip_data: any;
  all_null = true;
  temp_ob_keys = ['logistics', 'cps', 'tad', 'bte' , 'historical'];
  temp_arr_keys = ['marketing', 'salaries', 'sales', 'service', 'uv', 'other'];
  user_scope = 0;
  constructor(private user: UserService, private router: Router, private tooltip: TooltipService,
    private y5_report: Year5ReportService) { }

  ngOnInit() {
    this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0] };
    this.user.brcr_back = '/user/variable-cost/y1';
    this.user.brcr_next = '/user/personal-expenses';
    this.tooltip_data = this.tooltip.getTooltips();
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    this.user.getUserVaiableData().subscribe(
      data => {
        if (Object.keys(data).length) {
          console.log('variable data', data);

          this.getReportdata(data);
          if (!(data.variablecost.tppcdollar)
          || data.variablecost.tppcdollar === 'undefined') {

            data.variablecost.tppcdollar = {
              key: 'Third Party Payment Charges $', value: false, type: 1,
              variable_cost: { value: 0 },
              variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            };

          }

           //---------------- phase 4 Sales

          if (!(data.variablecost.historical)
           || data.variablecost.historical === 'undefined') {  // initalize sales

            data.variablecost.historical = [{
              key: 'Historical Cost', value: false,
              variable_cost: {
              val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
              { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
              },
              variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            }];
          }

          if (!(data.variablecost.sales)
          || data.variablecost.sales === 'undefined') {  // initalize sales

             data.variablecost.sales = [{
            key: 'Sales 1', value: false,
            variable_cost: {
            val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
            { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
            },
            variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            }];
          }
          if (!(data.variablecost.service)
          || data.variablecost.service === 'undefined') {  // initalize service
            data.variablecost.service = [{
            key: 'Service 1', value: false,
            variable_cost: {
              val: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
              { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }]
            },
            variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            }];
          }


          console.log('elveeeeeeeeeeeeeee--->',data.variablecost.tot_emp_at_yearEnd,
          data.variablecost.tot_emp_at_yearEnd,data.variablecost['salaries'],data.variablecost['op_partners']);

          if ((!(data.variablecost.tot_emp_at_yearEnd)
          || data.variablecost.tot_emp_at_yearEnd === 'undefined' )
          ||
          (!this.HasValueTrue(data.variablecost['salaries']) &&  !data.variablecost['op_partners'].value)) {

            data.variablecost.tot_emp_at_yearEnd = {
              key: 'Total No. of Employees at Year End', value: true,
              variable_cost: { value: 0 },
              variable_cost_y5: { value: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] },
            };

          }

          if (data.variablecost['tppcdollar'].value) {
            this.all_null = false;
          }

          if (data.variablecost['tppc'].value) {
            this.all_null = false;
          }
          const tppc_vc = data.variablecost['tppc'].variable_cost_y5;
          if (!(tppc_vc) || !(tppc_vc.value) || (tppc_vc.value.constructor !== Array)) {
            data.variablecost['tppc'].variable_cost_y5 = { value: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] };
          }

          if (data.variablecost['op_partners'].value) {
            this.all_null = false;
          }
          if (!(data.variablecost['op_partners'].variable_cost_y5)) {
            data.variablecost['op_partners'].variable_cost_y5 = { value: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] };
          }
          this.temp_ob_keys.map(a => {
            if (data.variablecost[a].value) {
              this.all_null = false;
            }
            if (!(data.variablecost[a].variable_cost_y5)) {
              data.variablecost[a].variable_cost_y5 = { value: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] };
            }
          });
          this.temp_arr_keys.map(a => {
            data.variablecost[a].map(b => {
              if (b.value) {
                this.all_null = false;
              }
              if (!(b.variable_cost_y5)) {
                b.variable_cost_y5 = { value: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] };
              }
            });
          });

          if (typeof data.variablecost.vcnotes === 'undefined') {

            const def_objnotes = {
              key: 'Notes', value: false,
              content: 'Notes',
              content_y5: 'Notes',
            };

            data.variablecost.vcnotes = def_objnotes;

           }

          this.user.varSelection = this.variable_data = data;
          // console.log(data);
        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );
  }


  HasValueTrue(array){
    if(array){
      for (let prop of array){ // own properties, you might use
       

        if(prop.value == true){
          console.log('HasValueTrue',prop);
          return 1;
        }
        // for (let prop in obj)
        // let total = this.ArrayTotal(prop.val)
        // if(total > 0){
        //   return 1;
        // }
      }
    }
    return 0;

  }


  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.validateInputs();
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY5(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }
  }

  saveAndNavigateBack(link) {
    // this.validateInputs();
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY5(this.variable_data);
    this.user.saveAndNavigateBack(link, this.variable_data);
  }
  saveAndNavigateForward(link) {
    // this.validateInputs();
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroVCY5(this.variable_data);
    this.user.saveAndNavigateForward(link, this.variable_data);
  }
  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
  validateInputs() {
    let count = 0;
    for (const val of this.variable_data.variablecost.tppc.variable_cost_y5.value) {
      const tppc = this.user.numOr0(val.value);
      if (tppc < 0) {
        this.variable_data.variablecost.tppc.variable_cost_y5.value[count].value = 0;
      } else if (tppc > 100) {
        if (!(this.variable_data.variablecost.tppc.type && this.variable_data.variablecost.tppc.type === 2)) {
          this.variable_data.variablecost.tppc.variable_cost_y5.value[count].value = 100;
        } else {
          this.variable_data.variablecost.tppc.variable_cost.value = tppc;
        }
      } else {
     //   this.variable_data.variablecost.tppc.variable_cost.value = tppc;
      }
      count++;
    }

    // initalizing tppcdollor
  }
  onKeydown(event, k, arr) {
    this.user.onKeydown(event, k, arr);
    this.getReportdata(this.variable_data);
  }

  async getReportdata(data){
    await this.user.delay(50);
    const y5_report =  this.y5_report.setVariableCostReportData(data);
    console.log('y5_report',y5_report);
    this.report_data = y5_report;
  }

}
