import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  metatag: any;
  constructor(private cms: CmsService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private meta: Meta) {
    const vurls = this.userService.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['sing_up_trail'] + '/';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Practical and short video tutorials on Setting up a Free Trial Account, Overview of the Kwik Look Tool, Variable Selection (Step1), Data Entry (Step2), Reports & Results (Step3).',
    };
    this.meta.updateTag(this.metatag);

  }
  page_content: any;
  video_content: any;

  ngOnInit() {

 /* this.cms.getCmsByPage('cms/video_testimonials')

  https://img.youtube.com/vi/DxwrdB7A6-I/maxresdefault.jpg
    .subscribe(video_content => { this.video_content = video_content;

    console.log('video content section', video_content);
    } );*/
  }
  openModal(data) {
    console.log('ssss0', data);
    // this.video_modal_title = data;
    const vurls = this.userService.getVideoUrls();
    switch (data) {
   //   case 'freetrial': this.video_url = '../../assets/videos/testimonial_2.mp4?autoplay=1';
   case 'freetrial': this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['sing_up_trail'] + '?autoplay=1&rel=0';
      break;
      case 'overview': this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['home_page'] + '?autoplay=1&rel=0';
        break;
      case 'variable': this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['variable_selection'] + '?autoplay=1&rel=0';
        break;
      case 'data': this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['dataentry'] + '?autoplay=1&rel=0';
        break;
      case 'reports': this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['report'] + '?autoplay=1&rel=0';
      break;
      case 'testimonial':  this.video_url = 'https://www.youtube-nocookie.com//embed/' + vurls['testimonial'] + '?autoplay=1&rel=0';

    }
  //  this.video_url = '../assets/videos/Kwik Look Free Trial Account Set Up.mp4';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
  // alert(this.video_modal_src);
  console.log('video urls', this.video_url);
    this.autoplayvalue = true;
  }
  closeModal() {
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.autoplayvalue = false;
  }

}
