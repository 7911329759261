import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-kwik-input-mainform',
  templateUrl: './kwik-input-mainform.component.html',
  styleUrls: ['./kwik-input-mainform.component.css']
})
export class KwikInputMainformComponent implements OnInit, AfterViewInit {
  c_user: any;
  comp_status: any;
  company_details: any = {};
  companyForm: FormGroup;
  variable_data: any = {};
  constructor(private router: Router, private user: UserService, private titleService: Title) {
    this.company_details.months = this.user.month_list;

    this.companyForm = new FormGroup({
      'incorpyear': new FormControl(null),
      'incorpmonth': new FormControl(null)
    }, this.validateMealType); // <-- see here is your custom function

  }
  ngAfterViewInit() {
    if ( !(this.c_user.dash_notf) && !(this.c_user.variable_selection) ) {
      document.getElementById('triggermodal').click();
      this.c_user.dash_notf = 1;
      this.user.setScopeUserObject(this.c_user);
    }

  }
  ngOnInit() {
    this.titleService.setTitle('Kwik Look - Dashboard');
    this.user.getUserScopes().subscribe(
      data => {
      //  console.log('scope data', data);
        this.user.setScopeUserObject(data);
      }
    );
    this.c_user = this.user.getUserObject();
    this.company_details.name = this.c_user.company;
    this.company_details.cat = '';
    this.company_details.sel_month = '';
    this.company_details.sel_year = '';

    const body = { 'cid': this.c_user.cid };

    this.user.getUserVaiableData().subscribe(
      data => {
        this.variable_data = data;


//  new code for creatingthrid party  dollar section
if ((typeof  this.variable_data.variablecost !== 'undefined')) {
  if ((typeof  this.variable_data.variablecost.tppcdollar) === 'undefined' &&
  (typeof this.variable_data.variablecost.tppc) !== 'undefined') {
    this.router.navigateByUrl('user/variable-selection');
  }

  if ((typeof  this.variable_data.variablecost.sales) === 'undefined'){
     this.router.navigateByUrl('user/variable-selection');
  }

}
// End new code for dollar

      }
    );

    this.user.getCompanyDetails(body).subscribe(
      data => {
        if (data.status && data.results.length) {
          this.company_details.cat = data.results[0].cat;
          this.company_details.sel_month = data.results[0].sel_month;
          this.company_details.sel_year = data.results[0].sel_year;
          this.companyForm.patchValue({
            incorpyear: data.results[0].sel_year
          });
          this.user.CompanyDetails = data;
        }
      },
      error => {
      }
    );
  }

  validateMealType(findForm: AbstractControl): { [key: string]: boolean } {
    let value: any = findForm.value['incorpyear'] || '';
    const month: any = findForm.value['incorpmonth'] || '';
    let valid = value.match(/^\d{4}$/);
    const curyeararray = [];
    if (valid) {
      value = parseInt(value, 10);
      if (value > new Date().getFullYear() || value < 1500) {
        valid = false;
      } else if (new Date().getFullYear() === value) {
        const monthno = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august',
          'september', 'october', 'november', 'december'].indexOf(month.toLowerCase());
        if (new Date().getMonth() < monthno) {
          valid = false;
        }
      }
    }
    return valid ? null : { incorpyear: true };
  }

  ssnValidatorMonth(control: FormControl): { [key: string]: any } {
    const value: any = control.value || '';
    const monthno = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august',
      'september', 'october', 'november', 'december'].indexOf(value.toLowerCase());
    let valid = true;
    if (new Date().getFullYear() === 2199) {
      if (new Date().getMonth() < monthno) {
        valid = false;
      }
    }
    return valid ? null : { incorpyear: true };
  }

  get curYear() { return this.companyForm.get('incorpyear'); }


  ssnValidator(control: FormControl): { [key: string]: any } {
    let value: any = control.value || '';
    let valid = value.match(/^\d{4}$/);
    const curyeararray = [];
    if (valid) {
      value = parseInt(value, 10);
      if (value > new Date().getFullYear() || value < 1500) {
        valid = false;
      } else if (new Date().getFullYear() === value) {
      }
    }
    return valid ? null : { incorpyear: true };
  }

  goToNextForm(url_slug) {
    this.router.navigateByUrl(url_slug);
  }
  inputNext(url_slug) {
  //  console.log('user section', this.c_user);
  //  console.log('dfsdfdf', this.variable_data);


    const res_urls = ['/personal-expenses', '/revenue-cogs/y5', '/fixed-cost/y5', '/variable-cost/y5',
      '/report-base/y5', '/report-sensitive/y1', '/report-sensitive/y5'];
    this.user.userFormData['level3_slug'] = '/user' + url_slug;
    if ((!this.c_user.plan_scope)) {
      this.router.navigateByUrl('user/my-subscription');
    } else if ((res_urls.indexOf(url_slug) > -1) && (!this.c_user.plan_scope || (this.c_user.plan_scope < 2))) {
      this.router.navigateByUrl('user/my-subscription');
    } else if (this.c_user && this.c_user.variable_selection) {
      this.router.navigateByUrl(this.user.userFormData['level3_slug']);
    } else {
      this.router.navigateByUrl('user/variable-selection');
    }
  }
  saveCompanyDetails(month, year) {
    if (this.companyForm.errors) {
      return false;
    }
    const body = { 'month': month, 'year': year, 'id': this.c_user.id };
    const current = this;
    this.user.saveCompanyDetails(body).subscribe(data => {
      this.comp_status = data.msg;
      this.user.saveIncorpMonth(month);
      setTimeout(function () {
        current.comp_status = '';
      }, 6000);
    },
      error => {
        // console.log(error);
      });
  }
}

