import { Component, OnInit , AfterViewInit } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { UserService } from '../../services/user.service';
import { CmsService } from '../../cms.service';
import { GlobalsService } from '../../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-my-subcription',
  templateUrl: './my-subcription.component.html',
  styleUrls: ['./my-subcription.component.css']
})
export class MySubcriptionComponent implements OnInit, AfterViewInit {
  paymentResponseSuccess: any;
  paymentResponse: any;
  clientTokenURL: any;
  createPurchaseURL: any;
  amountPayed = 0;
  user_details: any;
  plan_details: any;
  plan_notfn = '';
  sub_btn_text = '';
  next_billing_date = '';
  subscription_end_date = '';
  country_details: any;

  blockMsgStatus  = 1;
  btenabledStyle = {
    'font-size': '16px',
    'cursor': 'pointer',
    'width': '100%',
    'background': '#0062b3',
    'border-radius': '50px',
    'display': 'inline-table',
    'color': '#fff !important',
    'border': '1px solid #0062b3',
    'text-transform': 'capitalize !important',
    'background-color': '#000000',
  };
  btdisabledStyle = {
    'font-size': '16px',
    'width': '100%',
    'background': 'lightgray',
    'border-radius': '50px',
    'display': 'inline-table',
    'color': '#fff !important',
    'border': '1px solid lightgray',
    'text-transform': 'capitalize !important',
    'cursor': 'not-allowed'
  };

  constructor(private subscription: SubscriptionService, private user: UserService, private cms: CmsService,
    private globals: GlobalsService,
   ) {
    this.user_details = this.user.getUserObject();
    this.plan_details = this.user_details.plan_details;
    console.log(this.user_details, this.plan_details);
    const c_user = this.user.getUserObject();
    this.clientTokenURL = environment.API_URL + 'bt/getclienttoken';
    this.createPurchaseURL = environment.API_URL + 'bt/subscription/updatecard/' + c_user.id ;
  }
  ngAfterViewInit() {
    this.SetUserPlanonsubscription();
  }
  ngOnInit() {


 //   this.SetUserPlanonsubscription();

    this.sub_btn_text = 'Unsubscribe';
    this.user_details = this.user.getUserObject();
    console.log(45, this.user_details);
    this.plan_details = this.user_details.plan_details;

    console.log('JJJJJJJJJJJJJ', this.plan_details);

    this.plan_notfn = this.user.plan_notfn;

   // console.log('plan scope', this.plan_details.plan_scope);

    if (!this.plan_details.plan_status && this.plan_details.slug === 'free_trial') { // checking current free trail exprie or not
      if (this.user_details.plan_scope === 0) {
        this.plan_details.plan_status = 2;
      } else {
        this.plan_details.plan_status = 1;
      }

      // this.globals.current_country.subscribe(value => {
      //   if (value) {
      //       if (value === 'MY') {
      //         this.plan_details.currency_code = 'RM';
      //       } else {
      //         this.plan_details.currency_code = value;
      //       }

      //     // this.SetUserPlanonsubscription();
      //     }
      //   });

    }

     if (!this.plan_details.currency_code) {

       this.plan_details.currency_code = 'SG';
    }


     if (!this.plan_details.currency_symbol && this.plan_details.currency_code !== 'RM') {

       this.plan_details.currency_symbol = '$';
     }

    if ( (this.user.numOr0(this.plan_details.plan_status) === 1 || this.user.numOr0(this.plan_details.plan_status) === 3)
    && this.plan_details.slug !== 'free_trial') {
      this.user.getSubscriptionEndDateNew(this.user_details.id, this.plan_details.subscription_id ).subscribe(
        data => {
        //  console.log(data);
          if (data) {
            this.next_billing_date =  data;
            if ( new Date() > new Date(this.next_billing_date) ) {
              this.blockMsgStatus = 2;
            }
          }
        }
      );
    }
    setTimeout(() => {
      this.plan_notfn = this.user.plan_notfn = '';
    }, 6000);
  }

  onPaymentStatus(event) {
    console.log(event);
    this.paymentResponse =  '';
    this.paymentResponseSuccess = '';
    if (event.status === true) {
      this.paymentResponseSuccess = event.message; // Subscription successfull
    } else {
      this.paymentResponse =  event.message;
    }
  }

  SetUserPlanonsubscription() {
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setonsubscriptionScopeUserObject(data);
        this.ngOnInit();
      }
    );
  }
  unsubscribe() {
    this.sub_btn_text = 'Loading...';
    this.next_billing_date = '';
    this.subscription.unsubscribe(this.user_details.id).subscribe(
      data => {
        if (data.proceed === 1) {
          this.subscription.setPlanDetails(data);
          this.user_details = this.user.getUserObject();
          this.plan_details = this.user_details.plan_details;
          console.log('unsubscribe', this.plan_details);
        }
        this.ngOnInit();
        // console.log('Clicked Unscribed Button', data);
      },
      //   error => { }
    );
  }

}
