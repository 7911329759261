import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import {CmsService} from '../cms.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  metatag: any;
  selected_country: any;
  constructor(private meta: Meta , private globals: GlobalsService, private cms: CmsService) {

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Kwik Look is about helping SME startups and entrepreneurs answer the question, "Can My New Business Idea Make Money?"',
    };
    this.meta.updateTag(this.metatag);

   }

  ngOnInit() {
    /* this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value;

        console.log('selected_country', this.selected_country);
      }
    }); */
  }

}
