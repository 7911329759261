import { Component, OnInit } from '@angular/core';
import {Router, RouterModule , ActivatedRoute} from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ValidationService, AlertService, UserService } from '../services';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  validations_form: any;
  newpsw: {};
  new_validations_form: any;
  formvalue: any;
  hideforgotform: boolean;
  newpassword_validations_form: FormGroup;
  submitted: boolean;
  message: any;
  classmessages: string;
  constructor(private user: UserService ,
    private route: ActivatedRoute,
    private routing: Router,
    private formBuilder: FormBuilder) {
    this.hideforgotform = false;
    this.validations_form = this.formBuilder.group({
      forgot_pswd: new FormControl('', [ValidationService.emailValidators])
    });

    // this.route.params.subscribe( params => console.log('parameter', params) );
    this.new_validations_form = this.formBuilder.group({
      first_password : new FormControl('', Validators.required),
      second_password : new FormControl('', Validators.required),
      email: new FormControl(''),
      id: new FormControl(''),
    });

    this.newpassword_validations_form = this.formBuilder.group({
      new_pswd : new FormControl('', Validators.required),
      retype_pswd : new FormControl('', Validators.required),
    });




    this.formvalue = {
      'email': '',
      'id': '',
      'newpassword': '',
      'retypepassword': '',
    };

    this.formvalue.email = this.route.snapshot.paramMap.get('email');
    this.formvalue.id =  this.route.snapshot.paramMap.get('id');



   }

  ngOnInit() {

      if ( this.formvalue.email != null ||  this.formvalue.id != null) {

        this.hideforgotform = true;

      }

  }




  onSubmit() {

        if (this.validations_form.invalid) {
            return;
        }

        this.user.ForgotPassword(this.validations_form.value).subscribe(
          data => {
              if (data.status === 1) {
              this.message = data.message;
              this.classmessages = 'success';
              } else {
              this.message = data.message;
              this.classmessages = 'error';
              }

          }
        );

  }

  get f() { return this.newpassword_validations_form.controls; }

  onForgotpassword() {

    this.submitted = true;
      if (this.newpassword_validations_form.invalid) {
          return;
      }
      this.formvalue.newpassword = this.newpassword_validations_form.value.new_pswd;
      this.formvalue.retypepassword =  this.newpassword_validations_form.value.retype_pswd;

      if (this.formvalue.newpassword === this.formvalue.retypepassword) {
          this.user.Forgotpasswordchange(this.formvalue).subscribe(
                data =>  {

                  if (data.status === 1) {
                      this.message = data.message;
                      this.classmessages = 'success';
                      setTimeout(() => this.routing.navigate(['/login']), 3000);
                  } else {
                      this.message = data.message;
                      this.classmessages = 'error';
                  }

                }
          );
      }

  }

}
