import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../services/user.service';
import { TooltipService } from '../../services/tooltip.service';

@Component({
  selector: 'app-revenue-and-costs-y1-to-y5',
  templateUrl: './revenue-and-costs-y1-to-y5.component.html',
  styleUrls: ['./revenue-and-costs-y1-to-y5.component.css']
})
export class RevenueAndCostsY1ToY5Component implements OnInit, OnDestroy {
  variable_data: any = {};
  tooltip_data: any;
  buttonClicked = 2;
  constructor(private router: Router, private _location: Location,
    private user: UserService, private tooltip: TooltipService) { }

  ngOnInit() {
    this.user.brcr_back = '/user/revenue-cogs/y1';
    this.user.brcr_next = '/user/fixed-cost/y1';
    this.tooltip_data = this.tooltip.getTooltips();
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    this.user.getUserVaiableData().subscribe(
      data => {
        if (Object.keys(data).length) {
          if (!data.cogs.type) {
            data.cogs.type = '1';
          }
          this.user.varSelection = this.variable_data = data;
          data['revenue']['ps'].map(a => {
            const revenue_cost_y5 = {
              sales: [{ value: '' },
              { value: '' },
              { value: '' },
              { value: '' }
              ],
              unit_price: [{ value: '' },
              { value: '' },
              { value: '' },
              { value: '' }
              ],
              unit_yearly_cost: [{ value: '' },
              { value: '' },
              { value: '' },
              { value: '' }
              ],
              yearly_cost: [{ value: '' },
              { value: '' },
              { value: '' },
              { value: '' }
              ],
            };
            if (typeof a.revenue_cost_y5 === 'undefined') {
              a.revenue_cost_y5 = revenue_cost_y5;
            }
          });
          if (!(data['revenue']['other']['revenue_cost_y5'])) {
            data['revenue']['other']['revenue_cost_y5'] = [{ value: '' },
            { value: '' },
            { value: '' },
            { value: '' }];
          }

          if (typeof data.revenue.rnotes === 'undefined') {
            const def_objnotes = {
              key: 'Notes', value: false,
              content: data.revenue.rnotes.content,
              content_y5: 'Notes'
            };
            data.revenue.rnotes = def_objnotes;
           }

           console.log(data);
        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );
  }
  showHeadingIfContentAvailable(ps) {
    return this.user.showHeadingIfContentAvailable(ps);
  }
  ngOnDestroy() {
    if (Object.keys(this.variable_data).length) {
      this.variable_data = this.user.rePlaceNegativeNumbersWithZeroRevenueY5(this.variable_data);
      this.user.saveUserVaiableData(this.variable_data).subscribe();
    }
  }
  saveAndNavigateBack(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroRevenueY5(this.variable_data);
    this.user.saveAndNavigateBack(link, this.variable_data);
  }
  saveAndNavigateForward(link) {
    // this.variable_data = this.user.rePlaceNegativeNumbersWithZeroRevenueY5(this.variable_data);
    console.log('after save', this.variable_data);
    this.user.saveAndNavigateForward(link, this.variable_data);
  }
  onKeydown(event, k, arr) {
    this.user.onKeydown(event, k, arr);
  }


  async captureScreenNew() {
    this.buttonClicked = 1;
    await this.user.delay(50);
    const val = await this.user.captureScreenNew('RevenueAndCogsYearOneToFive.pdf');
    this.buttonClicked = val;
  }
  shareReport() {
    this.user.shareUserReport(this.variable_data, 'revenue_cogs_y5').subscribe(
      data => {
        if (data.status) {
          this.user.report_notfn = data.slug;
          this.router.navigate(['/report/shared-successfully']);
        }
      }
    );
  }
  print_fns() {
    this.user.print_fns();
  }
}
