import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { NetworkService } from './network.service';
import { of , BehaviorSubject , Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GlobalsService {
  public current_country = new BehaviorSubject('SG');
  public invokebreadcrumb = new BehaviorSubject('Singapore');
  public plans            =   new BehaviorSubject('');
  public plan_details     =   new BehaviorSubject({'plan': '', 'amount': 0, 'discount': 0 , 'is_valid_coupon': false});
  public routePlan          =   new BehaviorSubject({'plan_id': '', 'coupon': ''});
  public createPurchaseURL  =   new BehaviorSubject('');
  public createRegisterPurchaseURL  =   new BehaviorSubject({'registerPurchaseURL': '', 'registerSignUpURL': '', 'country': ''});
  public registerPlanCoupon = new BehaviorSubject('');
  public profileStatus  = new BehaviorSubject(false);
  plan_id: string;
  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private network: NetworkService,
    private router: Router ,
    private http: HttpClient,
    private user: UserService) {

      this.profileStatus.next(localStorage.getItem('kw_profile_not_completed'));

    }

  getCountries() {
    let c_user_id = '';
    const c_user = this.user.getUserObject();
    if (c_user) {
      c_user_id = c_user.id;
    }
    return this.network.post_http_request('cms/allCountry', {'id': c_user_id});
  }

  getStartupPlatformPrograms() {
    return this.network.post_http_request('bt/getStartupPlatformPrograms', {});
  }


  setCountry(country) {
    this.current_country.next(country);
  }
  setcountrynameGlobal(country_name) {
    this.invokebreadcrumb.next(country_name);

  }

  setPlans(plans) {
    this.plans.next(plans);
  }
  setPlanDetails( plan, amount: number, discount: number , is_valid_coupon: boolean ) {
    this.plan_details.next( {'plan': plan, 'amount': amount, 'discount': discount, 'is_valid_coupon': is_valid_coupon });
  }
  setRouterPlanDetails( plan_id , coupon ) {
    this.routePlan.next({'plan_id': plan_id, 'coupon': coupon});
  }

  setPurchaseURL(plan, coupon) {
    let c_user_id = '';
    const c_user: any = this.user.getUserObject();
    if (c_user) {
      c_user_id = c_user.id;
    }
    let url = environment.API_URL + 'bt/subscription/' + plan + '/' + c_user_id;
    if ( coupon ) {
      url   = url + '/' + coupon ;
    } else {
      url   = url + '/' + 0 ;
    }
    this.createPurchaseURL.next( url  );
  }
  // setRegisterPurchaseURL(plan, country) {
  //   this.createRegisterPurchaseURL.next({
  //     'registerPurchaseURL': environment.API_URL + 'bt/subscription/' + plan + '/new/undefined',
  //     'registerSignUpURL':  plan });
  // }


  setRegisterPurchaseURL(lowercasecountry, country , plan_id) {
    console.log('lowercasecountry----->', lowercasecountry , plan_id);
    if (plan_id === 5) {
        this.plan_id = 'business_viability_assessment_' + lowercasecountry;
    } else {
      this.plan_id = 'screening_tool_' + lowercasecountry;
    }

    this.createRegisterPurchaseURL.next({
      'registerPurchaseURL': environment.API_URL + 'bt/subscription/' + lowercasecountry + '/new/undefined',
      'registerSignUpURL':  this.plan_id,
      'country': lowercasecountry });
  }


  setRegisterCoupon(coupon) {
    this.registerPlanCoupon.next(coupon);
  }
  setProfileStatus(status) {
    this.profileStatus.next(status);
  }

}


