import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TooltipService } from '../../services/tooltip.service';
import { ReportService } from '../../services/report.service';
import { SensitiveY1Service } from '../../services/sensitive-y1.service';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';


@Component({
  selector: 'app-valuation-report',
  templateUrl: './valuation-report.component.html',
  styleUrls: ['./valuation-report.component.css']
})
export class ValuationReportComponent implements OnInit {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  inverstorsEquity: any = 0;
  valuation_report: any = {};
  irrValue: any = 0;
  irrExitValue: any = 0;
  tooltip_data: any;
  valuationReport: any;
  c_user: any;
  incorpyear: Number;
  report_data: any = {};
  investorvalue: any;
  companyVal: any = 0;
  companyValuation: any;
  postmoneyvalution: any;
  cashonreturn : any;
  cashonCashReturn : any;
  company_form: FormGroup;
  living_expense = [0, 0, 0, 0, 0];
  fisc_inputs = { service_charge: 10 };
  base_case = {
    cf_b_tax: [0, 0, 0, 0, 0],
    corp_tax: [0, 0, 0, 0, 0],
    total_cost: [0, 0, 0, 0, 0],
    cf_a_tax: [0, 0, 0, 0, 0],
    ccf: [0, 0, 0, 0, 0],
    break_even_year: '',
    break_even_month: 'NA',
  };
  userid: number;
  user_country: any;

  constructor(
    private user: UserService,
    private router: Router,
    private tooltip: TooltipService,
    private report_service: ReportService,
    private sensitive_y1: SensitiveY1Service,
    private route: ActivatedRoute,
  ) {

    this.user_country  = {
      'id': '-',
      'first_name': '-',
      'last_name': '-',
      'user_email': '-',
      'address': '-',
      'city': '-',
      'state': '-',
      'post_code': '-',
      'country': '-',
      'user_phone': '-',
      'company_country': '-',
      'company_name': '-',
      'industryname': '-',
      'other_country': '-',
      'incorp_month': '-',
      'incorp_year': '-',
      'company_id': ''
    };

  }

  alertSubmit() {
    console.log('company details ', this.company_form.value );
  }

  ngOnInit() {

    this.user.getSharedReport(this.route.snapshot.params.id, 'valuation_report').subscribe(
      data => {
        if (data.status) {
          this.valuationReport = this.valuation_report = data.data;
          this.userid = data.user_id;
          this.user.getUserDetailsbyReportid(this.userid ).subscribe(
            userInfo => {
              this.user_country = userInfo;
              this.incorpyear = (this.user_country.incorp_year * 1 + 5);
              this.getValuationreport(this.valuationReport);

              this.report_data.revenue = {
                sales: [],
                tota_sales: [0, 0, 0, 0, 0],
                service_charge: [0, 0, 0, 0, 0],
                tota_sal_service_charge: [0, 0, 0, 0, 0],
                is_service_charge: false,
                other_revenue: [0, 0, 0, 0, 0],
                total_revenue: [0, 0, 0, 0, 0]
              };
              this.report_data.cogs = { value: [], total: [0, 0, 0, 0, 0] };
              this.report_data.fixed_cost = { value: [], total: [0, 0, 0, 0, 0] };
              this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0] };

              if (this.user.fiscal_inputs['sensitives']) {
                this.doNgInitAfterGettingFiscals(this.userid);
              } else {

                this.user.adminreportsetFiscalVariables(this.userid).subscribe(
                  data => {

                    this.user.setFiscalDataFromResponse(data);
                    this.report_service.setFiscalDataFromResponse(data);
                    this.doNgInitAfterGettingFiscals(this.userid);
                  }
                );
              }

            }
          );
        }
      }
    );

  }
  doNgInitAfterGettingFiscals(userid) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.user.admingetUserVaiableData(userid).subscribe(
      data => {
        if (Object.keys(data).length) {
          this.setRevemueReportData(data);
          this.setCOGSReportData(data);
          this.setFixedCostReportData(data);
          this.setVariableCostReportData(data);
          this.calculateBaseCase(data);
          this.calculateTotal(data);
          this.calculateBreakEvenMonthYear(data);

          this.report_data.cogs.enable_cost = data.cogs.cost.value;
          this.report_data.cogs.enable_unitcost = data.cogs.unitcost.value;


          console.log('this.report_data', this.report_data);

        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );
    this.user.admingetUsepersonalData(this.userid).subscribe(
      data => {
        if (data !== true ) {
          this.living_expense = this.user.setEstimatedLiving(data);
        }
      }
    );
  }
  calculateTotal(data) {
    let count = 0;
    for (const ps of data.revenue.ps) {
      this.report_data.revenue.sales[count].status = ps.value;
      this.report_data.cogs.value[count].status = ps.value;
      count++;
    }
  }
  calculateBreakEvenMonthYear(data) {
    // base_case.ccf;
    let count = 0;
    let last_neg = 0;
    let break_even_year: any = 0;
    let break_even_month: any = 0;
    const y1_report = this.sensitive_y1.getSensitiveYearOneReport(data);

    for (const val of this.base_case.ccf) {
      if (val < 0) {
        break_even_year++;
        last_neg = count;
      }
      count++;
    }

    this.base_case.break_even_year = '' + break_even_year;
    if (break_even_year > 4) {
      this.base_case.break_even_year = 'More than 5 Years';
    }

    let inc_index = 1;
    if (inc_index > 4) {
      inc_index = 0;
    }



    if (break_even_year <= 4 && break_even_year >= 1) {
      const a = this.base_case.ccf[last_neg];
      const b = this.base_case.cf_a_tax[last_neg + inc_index];
      let breakeven_mnth = Math.round((a / b) * 12);
      if (breakeven_mnth < 0) {
        breakeven_mnth = breakeven_mnth * (-1);
      }
      this.base_case.break_even_month = '' + breakeven_mnth;
    } else if (break_even_year === 0) {

      this.base_case.break_even_month = '' + this.CalculateBreakEvenArray(y1_report.base_case.ccf_result);

    } else if (break_even_year < 0 || isNaN(break_even_year)) {
      this.base_case.break_even_year = 'NA';
    }


    break_even_month = this.base_case.break_even_month;
    if (break_even_month > 12) {
      this.base_case.break_even_month = '12';
    } else if (break_even_month < 0 || isNaN(break_even_month)) {
      this.base_case.break_even_month = 'NA';
    }
  }
  CalculateBreakEvenArray(ListofArray) {

    let nums = 0;
    for (const val of ListofArray) {
      if (val < 0) {
        nums++;
      }
    }

    return nums;
  }
  getFixedCostParamValue(arr, key, y1array) {
    const result = [];
    let i = 1;
    result[0] = this.otherArraySum(y1array);
    for (const res of arr) {
      result[i] = res[key];
      i++;
    }
    return result;
  }
  calculateBaseCase(data) {

    console.log('before tax ', data);
    const Base_caseReportService = this.report_service.calculateTAXY1Y5(data);

    //  console.log('All basecase section', Base_caseReportService);
    this.base_case.cf_b_tax = Base_caseReportService.cf_b_tax;
    // this.base_case.corp_tax = Base_caseReportService.corp_tax;
    this.base_case.corp_tax = Base_caseReportService.corp_rounded;
    this.base_case.cf_a_tax = Base_caseReportService.cf_a_tax;
    this.base_case.ccf = Base_caseReportService.ccf;
    console.log('this.base_case.cf_a_tax', this.base_case.cf_a_tax[4]);
    this.report_data.base_case = this.base_case;



    //---------------------- company valuation -----------------------
    this.companyValuation = this.base_case.cf_a_tax[4] > 0 ? this.base_case.cf_a_tax[4] : 0;
    this.getValuationreport(this.valuationReport);
    //---------------------- company valuation -----------------------


  }

  addPrevCOGSData(data, temp_obj, ps) {
    if (data.cogs.type === '2' || data.cogs.type === 2) {
      if (ps.revenue_cost && ps.revenue_cost.monthly_cost) {
        for (const res of ps.revenue_cost.monthly_cost) {
          temp_obj.val[0] += this.numOr0(res.value);
          this.report_data.cogs.total[0] += this.numOr0(res.value);
        }
      }

    } else {
      if (ps.revenue_cost && ps.revenue_cost.sales) {
        for (const sales of ps.revenue_cost.sales) {
          temp_obj.val[0] += this.numOr0(sales.value) * this.numOr0(ps.revenue_cost.unit_monthly_cost);
          this.report_data.cogs.total[0] += this.numOr0(sales.value) * this.numOr0(ps.revenue_cost.unit_monthly_cost);
        }
      }

    }
    return temp_obj;
  }
  setCOGSReportData(data) {
    if (data.cogs.type === '2' || data.cogs.type === 2) {
      for (const ps of data.revenue.ps) {
        let temp_obj = { key: ps.key, val: [0, 0, 0, 0, 0] };
        let count = 1;
        if (ps.revenue_cost_y5 && ps.revenue_cost_y5.yearly_cost) {
          for (const res of ps.revenue_cost_y5.yearly_cost) {
            temp_obj.val[count] = this.numOr0(res.value);
            this.report_data.cogs.total[count] += this.numOr0(res.value);
            count++;
          }
        }

        temp_obj = this.addPrevCOGSData(data, temp_obj, ps);
        this.report_data.cogs.value.push(temp_obj);
      }
    } else {
      for (const ps of data.revenue.ps) {

        let temp_obj = { key: ps.key, val: [0, 0, 0, 0, 0] };
        let count = 1;
        if (ps.revenue_cost_y5 && ps.revenue_cost_y5.sales) {
          for (const sales of ps.revenue_cost_y5.sales) {
            const sum = this.numOr0(sales.value) * this.numOr0(ps.revenue_cost_y5.unit_yearly_cost[count - 1].value);
            temp_obj.val[count] = sum;
            this.report_data.cogs.total[count] += sum;
            count++;
          }
        }

        temp_obj = this.addPrevCOGSData(data, temp_obj, ps);
        this.report_data.cogs.value.push(temp_obj);
      }
    }
  }

  setVariableCostReportDataForObject(var_cost, data) {
    const temp_obj = { status: data.variablecost[var_cost].value, key: data.variablecost[var_cost].key, val: [0, 0, 0, 0, 0] };
    let count = 1;
    // const tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    let tem_sum = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
      tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    }
    this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);
    temp_obj.val[0] = tem_sum;
    // this.report_data.variable_cost.total[0] += tem_sum;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
      for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        this.report_data.variable_cost.total[count] += vcval;
        count++;
      }
    }

    this.report_data.variable_cost.value.push(temp_obj);
  }
  setVariableCostReportDataForZeroObject(var_cost, data) {
    let status_bol = data.variablecost[var_cost].value;
    if (!data.variablecost['salaries'].value && !data.variablecost['op_partners'].value) {
      status_bol = false;
    }
    const temp_obj = { status: status_bol, key: data.variablecost[var_cost].key, val: [0, 0, 0, 0, 0] };
    let count = 1;
    // const tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    let tem_sum = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
      tem_sum = data.variablecost[var_cost].variable_cost.value;
    }
    //  this.report_data.variable_cost.total[0] += 0;
    temp_obj.val[0] = tem_sum;
    // this.report_data.variable_cost.total[0] += tem_sum;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
      for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        // this.report_data.variable_cost.total[count] += 0;
        count++;
      }
    }

    this.report_data.variable_cost.value.push(temp_obj);
  }

  setVariableCostReportDataForArray(var_cost2, data) {
    for (const var_cost of data.variablecost[var_cost2]) {
      const temp_obj = { status: var_cost.value, key: var_cost.key, val: [0, 0, 0, 0, 0] };
      let count = 1;
      // const tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      let tem_sum = 0;
      if (var_cost.variable_cost) {
        tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      }
      this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);
      temp_obj.val[0] = tem_sum;
      // this.report_data.variable_cost.total[0] += tem_sum;
      if (var_cost.variable_cost_y5) {
        for (const vc of var_cost.variable_cost_y5.value) {
          const vcval = this.numOr0(vc.value);
          temp_obj.val[count] += vcval;
          this.report_data.variable_cost.total[count] += vcval;
          count++;
        }
      }

      this.report_data.variable_cost.value.push(temp_obj);
    }
  }
  setVariableCostReportData(data) {
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners', 'historical'];
    const var_cos_arr = ['salaries', 'marketing', 'uv', 'other'];

    console.log('Elvee data---->', data);
    const var_data_thrid: any = {};
    let i = 0;
    const NewfirstYearsales = [0, 0, 0, 0, 0];

    // var_data_thrid.sales = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    // { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    // { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];

    var_data_thrid.sales  = [];
    for (let x = 0; x < data.revenue.ps.length; x++) {
      var_data_thrid.sales.push({ value: 0 });
    }

    var_data_thrid.total = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.variable_cost_y5 = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];

    for (const rev_totnew of data.revenue.ps) {
      let sum = 0;

      for (const rev_totnewmonth of rev_totnew.revenue_cost.sales) {
        sum = sum + rev_totnewmonth.value;
      }

      NewfirstYearsales[i] = sum;
      var_data_thrid.sales[i].value = sum;
      i++;
    }

    var_data_thrid.total[0].value = this.otherArraySum(var_data_thrid.sales);
    for (const revenue_costall of data.revenue.ps) {
      let k = 1;
      for (const revenue_cost_single of revenue_costall.revenue_cost_y5.sales) {
        //  console.log('newelvee -', k , revenue_cost_single);
        var_data_thrid.total[k].value += revenue_cost_single.value;
        k++;
      }
    }



    // this.otherArraySum(
    let tppcdollar_value = false;
    let tppcdollar_key = 'Third Party Payment Charges $';
    let variable_cost_value = 0;
    // console.log('Newfirstyearsales', var_data_thrid);
    //  console.log('Newfirstyearsales', NewfirstYearsales);

    if (typeof data.variablecost.tppcdollar !== 'undefined') {
      if (data.variablecost.tppcdollar.variable_cost_y5.value) {
        var_data_thrid.variable_cost_y5 = data.variablecost.tppcdollar.variable_cost_y5.value;
      }
      if (data.variablecost.tppcdollar.value) {
        tppcdollar_value = data.variablecost.tppcdollar.value;
        tppcdollar_key = data.variablecost.tppcdollar.key;
      }
      if (data.variablecost.tppcdollar.variable_cost.value) {
        variable_cost_value = data.variablecost.tppcdollar.variable_cost.value;
      }
    }



    this.setVariableCostReportDataForObject('op_partners', data);
    this.setVariableCostReportDataForArray('salaries', data);
    // if (typeof data.variablecost.tot_emp_at_yearEnd !== 'undefined') {
    // this.setVariableCostReportDataForZeroObject('tot_emp_at_yearEnd', data);
    // }
    this.setVariableCostReportDataForArray('marketing', data);
    this.setVariableCostReportDataForArray('sales', data);
    this.setVariableCostReportDataForArray('service', data);
    this.setVariableCostReportDataForObject('logistics', data);
    this.setVariableCostReportDataForObject('historical', data);

    // Third party charge
    let tp_type = 1;
    if (data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
      tp_type = 2;
    }


    const tppc_obj = {
      status: data.variablecost.tppc.value,
      key: data.variablecost.tppc.key,
      type: tp_type,
      val: [0, 0, 0, 0, 0],
      total: [0, 0, 0, 0, 0],
    };


    const tppc_dollar = {
      status: tppcdollar_value,
      key: tppcdollar_key,
      type: tp_type,
      val: [0, 0, 0, 0, 0],
      total: [0, 0, 0, 0, 0],
    };

    let tppc_count = 0;


    for (const rev_tot of this.report_data.revenue.total_revenue) {
      // const tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
      let tppc_sum = 0;
      let tppc_sum2 = 0;



      if (tppc_count === 0) {
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
        }

        
      if (tppc_count === 0) {

        /* tppc_sum2 = ( (NewfirstYearsales[0] +
           NewfirstYearsales[1] +
           NewfirstYearsales[2]) * data.variablecost.tppcdollar.variable_cost.value ); */
        tppc_sum2 = ((var_data_thrid.total[tppc_count].value) * variable_cost_value);

      } else {
        //  tppc_sum = Math.round((rev_tot / 100) * var_data_thrid.variable_cost_y5[tppc_count - 1].value);
        /*     tppc_sum2 = ( (var_data_thrid.sales1[tppc_count - 1].value +
             var_data_thrid.sales2[tppc_count - 1].value +
             var_data_thrid.sales3[tppc_count - 1].value ) * var_data_thrid.variable_cost_y5[tppc_count - 1].value );
         */
        tppc_sum2 = ((var_data_thrid.total[tppc_count].value) * var_data_thrid.variable_cost_y5[tppc_count - 1].value);


      }

      //     console.log('dfsasdsd', tppc_sum2);

      // console.log('sssssss', var_data_thrid.total[tppc_count - 1].value * data.variablecost.tppcdollar.variable_cost.value);


      /* Jobin old code
       if (tppc_count === 0 && data.variablecost.tppc.variable_cost) {
        if ( tppc_obj.type === 2 ) {
          tppc_sum = data.variablecost.tppc.variable_cost.value;
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        }
      } else if (data.variablecost.tppc && data.variablecost.tppc.variable_cost_y5) {
        // console.log('data.variablecost.tppc.variable_cost_y5', data.variablecost.tppc.variable_cost_y5);
        if ( tppc_obj.type === 2 ) {
          tppc_sum = data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value;
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
        }
      }*/


      tppc_dollar.val[tppc_count] = tppc_sum2;

      // tppc_dollar.total += tppc_sum2;





      tppc_obj.val[tppc_count] = tppc_sum;
      this.report_data.variable_cost.total[tppc_count] += tppc_sum + tppc_sum2;
      tppc_count++;
    }

    // console.log('tppc_dollar', var_data_thrid);
    // console.log('var_data_thrid', var_data_thrid);
    // console.log('var_data_thrid.variable_cost_y5', var_data_thrid.variable_cost_y5);
    this.report_data.variable_cost.value.push(tppc_obj);
    this.report_data.variable_cost.value.push(tppc_dollar);
    // Third party charge ends

    this.setVariableCostReportDataForObject('cps', data);
    this.setVariableCostReportDataForObject('tad', data);
    this.setVariableCostReportDataForArray('uv', data);
    this.setVariableCostReportDataForObject('bte', data);
    this.setVariableCostReportDataForArray('other', data);
  }
  setFixedCostReportData(data) {
    // const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'citd'];
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'citd', 'wis', 'ed', 'uf', 'mvc', 'historical'];

    for (const fixd_cost of fc_keys) {
      const temp_obj = {
        key: data.fixedcost[fixd_cost].key,
        status: data.fixedcost[fixd_cost].value,
        val: [0, 0, 0, 0, 0]
      };
      let count = 1;
      // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      let tem_sum = 0;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
        tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      }
      this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
      temp_obj.val[0] = tem_sum;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
        for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
          temp_obj.val[count] = this.numOr0(res.value);
          this.report_data.fixed_cost.total[count] += this.numOr0(res.value);
          count++;
        }
      }

      this.report_data.fixed_cost.value.push(temp_obj);
    }
    const fc_array_keys = ['other', 'otherCE'];
    for (const key of fc_array_keys) {
      for (const other of data.fixedcost[key]) {
        const temp_obj = {
          key: other.key,
          status: other.value,
          val: [0, 0, 0, 0, 0]
        };
        let count = 1;
        // const tem_sum = this.otherArraySum(other.fixed_cost.fc);
        let tem_sum = 0;
        if (other.fixed_cost) {
          tem_sum = this.otherArraySum(other.fixed_cost.fc);
        }
        this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
        temp_obj.val[0] = tem_sum;
        if (other.fixed_cost_y5) {
          for (const fcy5 of other.fixed_cost_y5.val) {
            temp_obj.val[count] = this.numOr0(fcy5.value);
            this.report_data.fixed_cost.total[count] += this.numOr0(fcy5.value);
            count++;
          }
        }
        this.report_data.fixed_cost.value.push(temp_obj);
      }
    }
  }
  setRevemueReportData(data) {
    this.report_data.revenue.other_status = data.revenue.other.value;
    this.report_data.revenue.is_service_charge = data.revenue.sc.value;
    this.report_data.revenue.other = data.revenue.other.revenue_cost_y5;
    const pss = data.revenue.ps;
    for (const pro_ser of pss) {
      // const y5 = pro_ser.revenue_cost_y5.sales;
      let y5 = [];
      if (pro_ser.revenue_cost_y5) {
        y5 = pro_ser.revenue_cost_y5.sales;
      }
      const temp_obj = { key: pro_ser.key, val: [0, 0, 0, 0, 0] };
      let count = 1;

      let tem_sum = 0;
      if (pro_ser.revenue_cost) {
        tem_sum = this.otherArraySum(pro_ser.revenue_cost.sales) * this.numOr0(pro_ser.revenue_cost.unit_price);
      }

      temp_obj.val[0] = tem_sum;
      this.report_data.revenue.tota_sales[0] += tem_sum;
      this.report_data.revenue.total_revenue[0] += tem_sum;
      if (this.report_data.revenue.is_service_charge) {
        // this.report_data.revenue.total_revenue[1] += (this.numOr0(tem_sum) * this.fisc_inputs.service_charge) / 100;
        this.report_data.revenue.total_revenue[0] += Math.round((this.numOr0(tem_sum) * this.fisc_inputs.service_charge) / 100);
      }

      for (const sal_val of y5) {
        temp_obj.val[count] = this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        this.report_data.revenue.tota_sales[count] += this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        this.report_data.revenue.total_revenue[count] += this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        if (this.report_data.revenue.is_service_charge) {
          // other_revenue
          // tslint:disable-next-line:max-line-length
          const sc = Math.round((this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value * this.fisc_inputs.service_charge) / 100);
          this.report_data.revenue.total_revenue[count] += sc;
        }
        count++;
      }
      this.report_data.revenue.sales.push(temp_obj);
    }
    let count2 = 1;
    const oth_sum = this.otherArraySum(data.revenue.other.revenue_cost);
    this.report_data.revenue.other_revenue[0] = oth_sum;
    this.report_data.revenue.total_revenue[0] += oth_sum;
    if (data.revenue.other && data.revenue.other.revenue_cost_y5) {
      for (const other of data.revenue.other.revenue_cost_y5) {
        this.report_data.revenue.other_revenue[count2] = other.value;
        this.report_data.revenue.total_revenue[count2] += this.numOr0(other.value);
        count2++;
      }
    }

  }
  fillDefaultPersonalExpenseValues() {
    const result = {
      valuation_report: {
        ei: {
          key: 'Existing Investment',
          value: [
            { key: 'Existing Investment', value: 0, slug: 'invest' },
          ]
        },
        cr: {
          key: 'Current Round',
          value: [
            { key: 'VC/Angel Investment', value: 0, slug: 'mortage' },
            { key: 'Pre-Money Valuation', value: 0, slug: 'utilities' },
            // { key: 'Post Money Valuation', value: 0, slug: 'food' },
            // { key: 'Investors Interest', value: 0, slug: 'household' },
          ]
        },
        exitafter5y: {
          key: 'Exit After 5 Years',
          value: [
            // { key: 'Exit Year', value: 0, slug: 'mortage' },
            { key: 'Price to Cash Flow Ratio', value: 0, slug: 'utilities' },
            // { key: 'Company Valuation', value: 0, slug: 'food' },
            // { key: 'Investment Value', value: 0, slug: 'household' },
            // { key: 'IRR', value: 0, slug: 'household' },
            // { key: 'Cash on Cash Return', value: 0, slug: 'household' },
          ]
        },
        ccfa: {
          key: 'Company Cash Flow After Tax',
          value: [
            { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }
          ]
        },
        icf: {
          key: 'Investors Cash Flow',
          value: [
            { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }
          ]
        },

        aicf: {
          key: 'Investors Cash Flow',
          value: [
            { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }
          ]
        },
      }
    };
    return result;
  }
  manageInvalidInputs(data) {
    console.log('manageInvalidInputs', data);
    data.valuation_report.ei.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.ei.value);
    data.valuation_report.cr.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.cr.value);
    data.valuation_report.exitafter5y.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.exitafter5y.value);
    data.valuation_report.ccfa.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.ccfa.value);
    data.valuation_report.icf.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.icf.value);
    data.valuation_report.aicf.value = this.user.rePlaceNegativeNumbersWithZero(data.valuation_report.aicf.value);
    return data;

  }

  getValuationreport(data) {

    const  Rpt_Invest_Funds_Current =  parseInt(data.valuation_report.cr.value[0].value,10);
    let count = 0;
    const Calc_CF_After_Tax_Y5  = this.base_case.cf_a_tax[4] ? this.base_case.cf_a_tax[4]  : 0;
    const Rpt_Founders_Funds = parseInt(data.valuation_report.ei.value[0].value,10);
    // Investors Cash Flow
    let Rpt_Invest_Funds_Current_Equity = (
      (+ Rpt_Invest_Funds_Current) / ((+ Rpt_Invest_Funds_Current) + (+ data.valuation_report.cr.value[1].value))) * 100 ;
      Rpt_Invest_Funds_Current_Equity = Rpt_Invest_Funds_Current_Equity ? Rpt_Invest_Funds_Current_Equity : 0;
    for (const val of data.valuation_report.icf.value) {

      if (count === 0) {
        data.valuation_report.icf.value[count].value = Rpt_Invest_Funds_Current * -1;
      } else if (count === (data.valuation_report.icf.value.length - 1)) {

        data.valuation_report.icf.value[count].value =
        ( ( Rpt_Invest_Funds_Current_Equity * data.valuation_report.exitafter5y.value[0].value * Calc_CF_After_Tax_Y5 ) / 100 ).toFixed(0);
      } else {
        data.valuation_report.icf.value[count].value = 0;
      }
      count++;
    }
    const cashonCashReturn =    (Rpt_Invest_Funds_Current > 0 ?

    (( this.companyValuation * this.valuation_report.valuation_report.exitafter5y.value[0].value *
      ( (+ this.valuation_report.valuation_report.cr.value[0].value) /
    ((+ this.valuation_report.valuation_report.cr.value[0].value) + (+ this.valuation_report.valuation_report.cr.value[1].value))

    )) / this.valuation_report.valuation_report.cr.value[0].value)
      : 0 );
    this.cashonCashReturn = isNaN(cashonCashReturn) ? 0 : cashonCashReturn.toFixed(2);
    // Investors Cash Flow

    // Company Cash Flow After Tax
    count = 0;
    for (const val of data.valuation_report.ccfa.value) {
      if (count === 0) {

        data.valuation_report.ccfa.value[count].value =
        (+Rpt_Invest_Funds_Current) + (+Rpt_Founders_Funds) + (+this.base_case.cf_a_tax[count]);
      } else {
        data.valuation_report.ccfa.value[count].value =  this.base_case.cf_a_tax[count];
      }
        count++;
    }

    // Company Cash Flow After Tax
    // Alernative  Investors Cash Flow
    count = 0;
    for (const val of data.valuation_report.aicf.value) {

      if (count === 0) {

        data.valuation_report.aicf.value[count].value =
        ((+Rpt_Invest_Funds_Current * -1) + (+data.valuation_report.ccfa.value[count].value) *
        (Rpt_Invest_Funds_Current_Equity / 100)).toFixed(0);
      } else if (count === (data.valuation_report.icf.value.length - 1)) {
        data.valuation_report.aicf.value[count].value =
         (((Calc_CF_After_Tax_Y5) * data.valuation_report.exitafter5y.value[0].value * (Rpt_Invest_Funds_Current_Equity) /100)).toFixed(0);

      } else {
        data.valuation_report.aicf.value[count].value =
        (((+data.valuation_report.ccfa.value[count].value ?
          data.valuation_report.ccfa.value[count].value : 0 ) * (Rpt_Invest_Funds_Current_Equity) / 100)).toFixed(0);
      }
        count++;
    }

    // Alernative  Investors Cash Flow
    /*START IRR EXIT CALCULATION */
    const icf_array = [];

    for (const icf of data.valuation_report.icf.value) {
      icf_array.push(icf.value);
    }

    const irrExit = this.IRR(icf_array, .1) * 100;
    this.irrExitValue  = isNaN(irrExit) ? 'NA' : irrExit.toFixed(1);
    /*END IRR EXIT CALCULATION */

    /*IRR calculation */

    const aicf_array = [];
    for (const aicf of data.valuation_report.aicf.value) {
      aicf_array.push(aicf.value);
    }

    const irr = this.IRR(aicf_array, .1) * 100;
    this.irrValue  = isNaN(irr) ? 'NA' : irr.toFixed(1) ;
    /*End IRR calculation */

    // Cash on Cash Return
    console.log('Rpt_Invest_Funds_Current', data.valuation_report.aicf.value ,Rpt_Invest_Funds_Current );
    const cashonreturn =   ((
      (this.otherArraySum(data.valuation_report.aicf.value) + Rpt_Invest_Funds_Current)  ?
      (this.otherArraySum(data.valuation_report.aicf.value) + Rpt_Invest_Funds_Current) : 0
         ) / Rpt_Invest_Funds_Current)
    this.cashonreturn = isNaN(cashonreturn) ? 0 : cashonreturn.toFixed(2);


    console.log('data.valuation_report', data.valuation_report);
    const inverstorsEquity = ((
      (+ data.valuation_report.cr.value[0].value) /
      ((+ data.valuation_report.cr.value[0].value) + (+ data.valuation_report.cr.value[1].value))
    ) * 100 )  ;

    this.inverstorsEquity  = isNaN(inverstorsEquity) ? 0 : inverstorsEquity.toFixed(1);
    console.log( 'inverstorsEquity' , inverstorsEquity, this.inverstorsEquity );

    const companyVal = this.companyValuation * data.valuation_report.exitafter5y.value[0].value;

    this.companyVal = companyVal;
  }

  IRR(cashflow, initialGuess) {
    const maxTries = 10000;
    const delta = 0.001;
    let guess = initialGuess;
    const multiplier = this.NPV(cashflow, guess) > 0 ? 1 : -1;
    let i = 0;
    while ( i < maxTries ) {
      const guessedNPV = this.NPV(cashflow, guess);
      if ( multiplier * guessedNPV > delta ) {
        guess += (multiplier * delta);
        i += 1;
      } else { break; }
    }
    return i === 10000 ? '#NUM' : guess;
  }

  NPV = (cashflow, discountRate) => cashflow
  .reduce((acc, val, i) => acc + val / Math.pow((1 + discountRate), i), 0);

  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }

}
