import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';
import { NetworkService } from './network.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ReportImagesService } from './report-images.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import html2PDF from 'jspdf-html2canvas';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  // @BlockUI() blockUI: NgBlockUI;
  private userObject = '';
  public userFormData = {};
  public varSelection = {};
  public personalExpense = {};
  public valuationReport = {};
  public CompanyDetails = {};
  public plan_notfn = '';
  report_notfn = '';
  public profile_not_completed = false;
  public month_list = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'];

  imgWidth: any;
  pageHeight: any;
  imgHeight: any;
  heightLeft: any;
  position: any;
  pdf: any;
  y: any;
  printpdf: any;
  printdata: any;
  printContents: any;
  popupWin: any;
  contentDataURLpdf: any;
  setprint: boolean;
  savepdf: boolean;
  month_order = [];
  fiscal_inputs = {};

  brcr_back = '/user/kwik-look-main';
  brcr_next = '/user/kwik-look-main';

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private spinnerService: Ng4LoadingSpinnerService,
    private img_service: ReportImagesService,
    private network: NetworkService,
    private router: Router) { }




    admingetUserVaiableData( userid ) {
      if ((Object.keys(this.varSelection).length)) {
        return of(this.varSelection);
      }
      return this.network.post_http_request(`user/getvarsel?v=${new Date().getTime()}`, { 'id': userid });
    }

    adminreportsetFiscalVariables(c_userid) {
      const body = { 'user_id': c_userid };
      return this.network.post_http_request('user/getfiscals', body);
    }

    admingetUsepersonalData(c_userid) {
      // const c_user = this.getUserObject();
      return this.network.post_http_request('user/getpersonalval', { 'id': c_userid });
    }

  setFiscalVariables() {
    const c_user = this.getUserObject();
    const body = { 'user_id': c_user.id };
    return this.network.post_http_request('user/getfiscals', body);
  }


  setFiscalDataFromResponse(data) {
    if (data.status) {
      this.fiscal_inputs['service_charge'] = this.numOr0(data.results['service_charge']);
      this.fiscal_inputs['taxfreeallowance'] = data.results['taxfreeallowance'];
      this.fiscal_inputs['Input_Corp_Tax_Rate'] = data.results['Input_Corp_Tax_Rate'];
      this.fiscal_inputs['Input_Corp_Tax_Rebate_Rate'] = data.results['Input_Corp_Tax_Rebate_Rate'];
      this.fiscal_inputs['Input_Corp_Tax_Rebate_Cap'] = data.results['Input_Corp_Tax_Rebate_Cap'];

      this.fiscal_inputs['Input_CA_Reno_Pct_Qualify'] = data.results['renovation_percentage'];
      this.fiscal_inputs['Input_CA_Reno_Allow_Period'] = data.results['renovation_period'];
      this.fiscal_inputs['Input_CA_Reno_Allow_Cap'] = data.results['renovation_capital'];
      this.fiscal_inputs['changingyear'] = data.results['motor_vehicle_period'];
      this.fiscal_inputs['Furniturechangingyear'] = data.results['furniture_equip_period'];
      this.fiscal_inputs['sensitives'] = data.results['sensitives'];
      if (data.results['phase_2']) {
        this.fiscal_inputs['phase_2'] = data.results['phase_2'];
      }
    }
  }
  getAllIndustries() {
    return this.network.get_http_request('user/getAllIndustries');
  }
  getIndustries() {
    // return this.network.get_http_request('user/getindustries');
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getindustries', { 'id': c_user.id });
  }
  getIncorpYearsANdCountries() {
    return this.network.get_http_request('user/contry_years');
  }

  getMonthOrder() {
    let default_arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (this.month_order.length) {
      return this.month_order;
    } else {
      const c_user = this.getUserObject();
      if (c_user.inco_month) {
        const mnth_list = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august',
          'september', 'october', 'november', 'december'];
        const a_index = mnth_list.indexOf(c_user.inco_month);
        if (a_index >= 0) {
          const p1 = default_arr.slice(0, a_index);
          const p2 = default_arr.slice(a_index);
          default_arr = p2.concat(p1);
        }
      }
    }
    return this.month_order = default_arr;
  }
  registerUser(body) {
    return this.network.post_http_request('user/add', body);
  }
  loginUser(body) {
    return this.network.post_http_request('user/login', body);
  }
  contactUser(body) {
    return this.network.post_http_request('user/contact', body);
  }

  LandingContactForm(body) {
    return this.network.post_http_request('user/landingcontact', body);
  }
  getUserSavedPassword() {
    if (this.localStorage.getItem('savedUser')) {
      return JSON.parse(this.localStorage.getItem('savedUser'));
    } else {
      return '';
    }
  }
  setUserSavedPassword(userObject) {
    this.localStorage.setItem('savedUser', JSON.stringify(userObject));
  }
  getUserObject() {
    if (this.userObject) {
      return this.userObject;
    } else if (this.localStorage.getItem('currentUser')) {
      const c_u = this.localStorage.getItem('currentUser');
      // this.setUserObject(c_u);
      return JSON.parse(c_u);
    } else {
      return '';
    }
  }
  setUserObject(userObject) {
    this.localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.userObject = userObject;
  }
  logout() {
    this.localStorage.removeItem('currentUser');
    this.localStorage.removeItem('kw_lang');
    this.localStorage.removeItem('kw_countries');
    this.userObject = '';
    this.window.location.reload();
  }
  saveIncorpMonth(month) {
    const c_user = this.getUserObject();
    if (month !== c_user.inco_month) {
      c_user.inco_month = month;
      this.setUserObject(c_user);
      this.month_order = [];
    }
  }
  getSubscriptionEndDate(c_user_id) {
    return this.network.post_http_request('user/getplanexp', { 'id': c_user_id });
  }
  getSubscriptionEndDateNew(c_user_id, sub_id) {
    return this.network.post_http_request('user/getplanexpnew', { 'id': c_user_id, sub_id: sub_id });
  }
  saveCompanyDetails(body) {
    this.CompanyDetails = {};
    return this.network.post_http_request('user/savecompany', body);
  }
  saveCompanyDetailsFromProfile(body) {
    this.fiscal_inputs = {};
    this.setFiscalVariables();
    return this.network.post_http_request('user/savecompany_profile', body);
  }

  getCompanyDetails(body) {
    if ((Object.keys(this.CompanyDetails).length)) {
      return of(this.CompanyDetails);
    }
    return this.network.post_http_request('user/getcompany', body);
  }
  getCompanyDetailsAdmin(body) {
    /*if ((Object.keys(this.CompanyDetails).length)) {
      return of(this.CompanyDetails);
    }*/
    return this.network.post_http_request('user/getcompany', body);
  }
  removeNullKeys(property) {
    property = property.filter(function (arr) {
      return (arr.key !== '' && arr.value === true);
    });
    return property;
  }
  getUserPersonalExpense() {
    if ((Object.keys(this.personalExpense).length)) {
      return of(this.personalExpense);
    }
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getperexp', { 'id': c_user.id });
  }
  saveUserPersonalExpense(personal_expense) {
    if (!(Object.keys(personal_expense).length)) {
      return of(personal_expense);
    }
    const c_user = this.getUserObject();
    const body = { 'user_id': c_user.id, 'value': personal_expense };
    return this.network.post_http_request('user/setperexp', body);
  }
  getUserValutionReport() {
    if ((Object.keys(this.valuationReport).length)) {
      return of(this.valuationReport);
    }
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getvalutionreport', { 'id': c_user.id });
  }
  getUserValutionReportAdmin(id) {
    return this.network.post_http_request('user/getvalutionreport', { 'id': id });
  }
  saveUserValutionReport(valution_report) {
    if (!(Object.keys(valution_report).length)) {
      return of(valution_report);
    }
    const c_user = this.getUserObject();
    const body = { 'user_id': c_user.id, 'value': valution_report };
    return this.network.post_http_request('user/setvalutionreport', body);
  }
  rePlaceNegativeNumbersWithZero(arr) {
    let count = 0;
    for (const val of arr) {
      if (this.numOr0(arr[count].value) >= 0) {
        arr[count].value = this.numOr0(arr[count].value);
      } else {
        arr[count].value = 0;
      }
      count++;
    }
    return arr;
  }
  showHeadingIfContentAvailable(ps) {
    for (const val of ps) {
      if (val.value) {
        return true;
      }
    }
    return false;
  }
  rePlaceNegativeNumbersWithZeroRevenueY1(data) {
    // Revenue Other.
    if (data.revenue && data.revenue.other && data.revenue.other.value) {
      if (data.revenue.other.revenue_cost) {
        data.revenue.other.revenue_cost = this.rePlaceNegativeNumbersWithZero(data.revenue.other.revenue_cost);
      }
    }

    // revenue.ps
    let pscount = 0;
    for (const ps of data.revenue.ps) {
      const varps = data.revenue.ps[pscount];
      if (ps.value && varps) {
        varps.revenue_cost.monthly_cost = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost.monthly_cost);
        varps.revenue_cost.sales = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost.sales);
        if (!(this.numOr0(varps.revenue_cost.unit_price) >= 0)) {
          varps.revenue_cost.unit_price = 0;
        }
        if (!(this.numOr0(varps.revenue_cost.unit_monthly_cost) >= 0)) {
          varps.revenue_cost.unit_monthly_cost = 0;
        }

      }
      pscount++;
    }
    return data;
  }
  rePlaceNegativeNumbersWithZeroRevenueY5(data) {
    // Revenue Other.
    if (data.revenue.other.value) {
      if (data.revenue.other.revenue_cos_y5) {
        data.revenue.other.revenue_cos_y5 = this.rePlaceNegativeNumbersWithZero(data.revenue.other.revenue_cos_y5);
      }
    }

    // revenue.ps
    let pscount = 0;
    for (const ps of data.revenue.ps) {
      const varps = data.revenue.ps[pscount];
      if (ps.value && varps) {
        varps.revenue_cost_y5.yearly_cost = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost_y5.yearly_cost);
        varps.revenue_cost_y5.sales = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost_y5.sales);
        varps.revenue_cost_y5.unit_yearly_cost = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost_y5.unit_yearly_cost);
        varps.revenue_cost_y5.unit_price = this.rePlaceNegativeNumbersWithZero(varps.revenue_cost_y5.unit_price);

      }
      pscount++;
    }
    return data;
  }
  rePlaceNegativeNumbersWithZeroFCY1(data) {
    // Fixed Cost
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'citd', 'fe', 'wis', 'ed', 'uf', 'mvc'];
    for (const val of fc_keys) {
      if (data.fixedcost[val].value) {
        data.fixedcost[val].fixed_cost.fc = this.rePlaceNegativeNumbersWithZero(data.fixedcost[val].fixed_cost.fc);
        // data.fixedcost[val].fixed_cost_y5.val = this.rePlaceNegativeNumbersWithZero(data.fixedcost[val].fixed_cost_y5.val);
      }
    }
    let count1 = 0;
    for (const other of data.fixedcost.other) {
      if ((other.value)) {
        data.fixedcost.other[count1].fixed_cost.fc = this.rePlaceNegativeNumbersWithZero(other.fixed_cost.fc);
        // data.fixedcost.other[count1].fixed_cost_y5.val = this.rePlaceNegativeNumbersWithZero(other.fixed_cost_y5.val);
      }
      count1++;
    }
    return data;
  }
  rePlaceNegativeNumbersWithZeroFCY5(data) {
    // Fixed Cost
    console.log('worked',data);
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'citd', 'fe', 'wis', 'ed', 'uf', 'mvc' ,'historical'];
    for (const val of fc_keys) {
      if (data.fixedcost[val].value) {
        // data.fixedcost[val].fixed_cost.fc = this.rePlaceNegativeNumbersWithZero(data.fixedcost[val].fixed_cost.fc);
        data.fixedcost[val].fixed_cost_y5.val = this.rePlaceNegativeNumbersWithZero(data.fixedcost[val].fixed_cost_y5.val);
      }
    }
    const other_keys = ['other', 'citd','otherCE'];
    for (const key of other_keys) {
      let count1 = 0;
      for (const other of data.fixedcost[key]) {
        if ((other.value)) {
          // data.fixedcost.other[count1].fixed_cost.fc = this.rePlaceNegativeNumbersWithZero(other.fixed_cost.fc);
          data.fixedcost[key][count1].fixed_cost_y5.val = this.rePlaceNegativeNumbersWithZero(other.fixed_cost_y5.val);
        }
        count1++;
      }
    }

    return data;
  }
  rePlaceNegativeNumbersWithZeroVCY1(data) {
    // variable Cost
    if ((data.variablecost.tppc.value)) {
      if (!(this.numOr0(data.variablecost.tppc.variable_cost.value) >= 0)) {
        data.variablecost.tppc.variable_cost.value = 0;
      }
      // data.variablecost.tppc.variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(data.variablecost.tppc.variable_cost_y5.value);
    }

    if ((data.variablecost.tppcdollar.value)) {
      if (!(this.numOr0(data.variablecost.tppcdollar.variable_cost.value) >= 0)) {
        data.variablecost.tppcdollar.variable_cost.value = 0;
      }
      // data.variablecost.tppc.variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(data.variablecost.tppc.variable_cost_y5.value);
    }
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners','historical'];
    const var_cos_arr = ['salaries', 'marketing', 'uv', 'other' ,'sales','service'];
    for (const val of var_cos_ob_arr) {
      if (data.variablecost[val].value) {
        data.variablecost[val].variable_cost.val = this.rePlaceNegativeNumbersWithZero(data.variablecost[val].variable_cost.val);
        // tslint:disable-next-line:max-line-length
        // data.variablecost[val].variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(data.variablecost[val].variable_cost_y5.value);
      }
    }
    for (const val of var_cos_arr) {
      let count = 0;
      for (const res of data.variablecost[val]) {
        if (res.value) {
          data.variablecost[val][count].variable_cost.val = this.rePlaceNegativeNumbersWithZero(res.variable_cost.val);
          // data.variablecost[val][count].variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(res.variable_cost_y5.value);
        }
        count++;
      }
    }
    return data;
  }
  rePlaceNegativeNumbersWithZeroVCY5(data) {
    // variable Cost
    if ((data.variablecost.tppc.value)) {
      data.variablecost.tppc.variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(data.variablecost.tppc.variable_cost_y5.value);
    }
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners'];
    const var_cos_arr = ['salaries', 'marketing', 'uv', 'other'];
    for (const val of var_cos_ob_arr) {
      if (data.variablecost[val].value) {
        data.variablecost[val].variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(data.variablecost[val].variable_cost_y5.value);
      }
    }
    for (const val of var_cos_arr) {
      let count = 0;
      for (const res of data.variablecost[val]) {
        if (res.value) {
          data.variablecost[val][count].variable_cost_y5.value = this.rePlaceNegativeNumbersWithZero(res.variable_cost_y5.value);
        }
        count++;
      }
    }
    return data;
  }
  makeArrayWithValueIndexNull(arr) {
    let count = 0;
    for (const val of arr) {
      arr[count].value = 0;
      count++;
    }
    return arr;
  }
  cleanVariableDataWithVariableSelection(data) {
    // Third party type
    if (data.variablecost.tppc && data.variablecost.tppc.type && parseInt(data.variablecost.tppc.type, 10) === 2) {
      data.variablecost.tppc.type = 2;
    } else {
      data.variablecost.tppc.type = 1;
    }
    // Revenue Other.
    if (!data.revenue.other.value) {
      if (data.revenue.other.revenue_cost) {
        data.revenue.other.revenue_cost = this.makeArrayWithValueIndexNull(data.revenue.other.revenue_cost);
      }
      if (data.revenue.other.revenue_cos_y5) {
        data.revenue.other.revenue_cos_y5 = this.makeArrayWithValueIndexNull(data.revenue.other.revenue_cos_y5);
      }
    }
    // service charge - confirm type to boolean.
    if (data.revenue && data.revenue.sc && data.revenue.sc.value) {
      if (data.revenue.sc.value === 'true') {
        data.revenue.sc.value = true;
      } else if (data.revenue.sc.value === 'false') {
        data.revenue.sc.value = false;
      }
    }


    // COGS TYPE
    if (data.cogs.unitcost.value) {
      data.cogs.type = 1;
    } else if (data.cogs.cost.value) {
      data.cogs.type = 2;
    } else {
      data.cogs.type = 1;
    }


    // COGS
    if (!data.cogs.unitcost.value) {
      let cogscnt = 0;
      for (const ps of data.revenue.ps) {
        data.revenue.ps[cogscnt].revenue_cost.unit_monthly_cost = 0;
        data.revenue.ps[cogscnt].revenue_cost_y5.unit_yearly_cost
          = this.makeArrayWithValueIndexNull(data.revenue.ps[cogscnt].revenue_cost_y5.unit_yearly_cost);
        cogscnt++;
      }
    }
    if (!data.cogs.cost.value) {
      let cogscnt = 0;
      for (const ps of data.revenue.ps) {
        data.revenue.ps[cogscnt].revenue_cost.monthly_cost
          = this.makeArrayWithValueIndexNull(data.revenue.ps[cogscnt].revenue_cost.monthly_cost);
        data.revenue.ps[cogscnt].revenue_cost_y5.yearly_cost
          = this.makeArrayWithValueIndexNull(data.revenue.ps[cogscnt].revenue_cost_y5.yearly_cost);
        cogscnt++;
      }
    }



    // revenue.ps
    let pscount = 0;
    for (const ps of data.revenue.ps) {
      const varps = data.revenue.ps[pscount];
      if (!ps.value && varps) {
        varps.revenue_cost.monthly_cost = this.makeArrayWithValueIndexNull(varps.revenue_cost.monthly_cost);
        varps.revenue_cost.sales = this.makeArrayWithValueIndexNull(varps.revenue_cost.sales);
        varps.revenue_cost.unit_price = varps.revenue_cost.unit_monthly_cost = 0;

        varps.revenue_cost_y5.yearly_cost = this.makeArrayWithValueIndexNull(varps.revenue_cost_y5.yearly_cost);
        varps.revenue_cost_y5.sales = this.makeArrayWithValueIndexNull(varps.revenue_cost_y5.sales);
        varps.revenue_cost_y5.unit_yearly_cost = this.makeArrayWithValueIndexNull(varps.revenue_cost_y5.unit_yearly_cost);
        varps.revenue_cost_y5.unit_price = this.makeArrayWithValueIndexNull(varps.revenue_cost_y5.unit_price);

      }
      pscount++;
    }

    // Fixed Cost
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'citd', 'fe', 'wis', 'ed', 'uf', 'mvc','historical'];
    for (const val of fc_keys) {
      if (!data.fixedcost[val].value && data.fixedcost[val].fixed_cost && data.fixedcost[val].fixed_cost_y5.val) {
        data.fixedcost[val].fixed_cost.fc = this.makeArrayWithValueIndexNull(data.fixedcost[val].fixed_cost.fc);
        data.fixedcost[val].fixed_cost_y5.val = this.makeArrayWithValueIndexNull(data.fixedcost[val].fixed_cost_y5.val);
      }
    }
    const other_keys = ['other', 'citd','otherCE'];
    for (const key of other_keys) {
      let count1 = 0;
      for (const other of data.fixedcost[key]) {
        if (!(other.value)) {
          data.fixedcost[key][count1].fixed_cost.fc = this.makeArrayWithValueIndexNull(other.fixed_cost.fc);
          data.fixedcost[key][count1].fixed_cost_y5.val = this.makeArrayWithValueIndexNull(other.fixed_cost_y5.val);
        }
        count1++;
      }
    }

    // variable Cost
    if (!(data.variablecost.tppc.value)) {
      data.variablecost.tppc.variable_cost.value = 0;
      data.variablecost.tppc.variable_cost_y5.value = this.makeArrayWithValueIndexNull(data.variablecost.tppc.variable_cost_y5.value);
    }
    if (!(data.variablecost.tppcdollar.value)) {
      data.variablecost.tppcdollar.variable_cost.value = 0;
      data.variablecost.tppcdollar.variable_cost_y5.value =
      this.makeArrayWithValueIndexNull(data.variablecost.tppcdollar.variable_cost_y5.value);
    }
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners','historical'];
    const var_cos_arr = ['salaries', 'marketing', 'uv', 'other' ,'sales','service'];
    for (const val of var_cos_ob_arr) {
      if (!data.variablecost[val].value && data.variablecost[val].variable_cost && data.variablecost[val].variable_cost_y5) {
        data.variablecost[val].variable_cost.val = this.makeArrayWithValueIndexNull(data.variablecost[val].variable_cost.val);
        data.variablecost[val].variable_cost_y5.value = this.makeArrayWithValueIndexNull(data.variablecost[val].variable_cost_y5.value);
      }
    }
    for (const val of var_cos_arr) {
      let count = 0;
      for (const res of data.variablecost[val]) {
        if (!res.value && data.variablecost[val][count].variable_cost && data.variablecost[val][count].variable_cost_y5) {
          data.variablecost[val][count].variable_cost.val = this.makeArrayWithValueIndexNull(res.variable_cost.val);
          data.variablecost[val][count].variable_cost_y5.value = this.makeArrayWithValueIndexNull(res.variable_cost_y5.value);
        }
        count++;
      }
    }
// ------------------------ set default Zero for Total No. of Employees at Year End


    const var_cos_not_array = ['salaries', 'marketing'];
    let noofempolyee = 0;
    for (const val of var_cos_not_array) {
      let count = 0;
      for (const res of data.variablecost[val]) {
        if (res.value) {
        noofempolyee++;
        continue;
        }
        count++;
      }
    }

    if (noofempolyee === 0) {
      let countnew = 0;
      for (const res of data.variablecost['tot_emp_at_yearEnd']) {
        // tslint:disable-next-line:max-line-length
        data.variablecost['tot_emp_at_yearEnd'][0].variable_cost.value = 0;
        // tslint:disable-next-line:max-line-length
        data.variablecost['tot_emp_at_yearEnd'][countnew].variable_cost_y5.value = this.makeArrayWithValueIndexNull(res.variable_cost_y5.value);
        countnew++;
      }
    }


 // ------------------------- set default Zero for Total No. of Employees at Year End
    return data;
  }

  saveUserVaiableData(variable_data) {
    if (!(Object.keys(variable_data).length)) {
      return of(variable_data);
    }
    /* variable_data.revenue.ps = this.removeNullKeys(variable_data.revenue.ps);
    variable_data.fixedcost.other = this.removeNullKeys(variable_data.fixedcost.other);
    variable_data.variablecost.salaries = this.removeNullKeys(variable_data.variablecost.salaries);
    variable_data.variablecost.marketing = this.removeNullKeys(variable_data.variablecost.marketing);
    variable_data.variablecost.uv = this.removeNullKeys(variable_data.variablecost.uv);
    variable_data.variablecost.other = this.removeNullKeys(variable_data.variablecost.other); */
    variable_data = this.cleanVariableDataWithVariableSelection(variable_data);

    const c_user = this.getUserObject();
    const body = { 'user_id': c_user.id, 'value': variable_data };
    this.varSelection = variable_data;
    this.setVariableSelectionStatus(1);
    return this.network.post_http_request('user/setvarsel', body);
  }

  getUserVaiableData() {
    if ((Object.keys(this.varSelection).length)) {
      return of(this.varSelection);
    }
    const c_user = this.getUserObject();
    return this.network.post_http_request(`user/getvarsel?v=${new Date().getTime()}`, { 'id': c_user.id });
  }
  setVariableSelectionStatus(status) {
    let c_user;
    c_user = this.getUserObject();
    if (this.numOr0(c_user.variable_selection) !== this.numOr0(status)) {
      c_user.variable_selection = status;
      this.setUserObject(c_user);
    }

  }

  getUserProfileData() {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getprofiledata', { 'id': c_user.id });
  }
  syncUserData(variable_data) {
    return this.saveUserVaiableData(variable_data);
  }

  SaveUserProfileData(profile_data) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/saveprofiledata', { 'id': c_user.id, 'value': profile_data });
  }
  getUserScopes() {
    const c_user = this.getUserObject();
    const country = this.localStorage.getItem('kw_lang');
    return this.network.post_http_request('subscription/scopes', { 'user': c_user.id, 'country': country });
  }
  setScopeUserObject(data) {
    let c_user;
    c_user = this.getUserObject();
    c_user.plan_scope = data.scope;
    this.setUserObject(c_user);
  }

  setonsubscriptionScopeUserObject(data) {
    let c_user;
    c_user = this.getUserObject();
    c_user.plan_scope = data.scope;
    c_user.plan_details = data.plan_details[0];
    this.setUserObject(c_user);
  }
  ChangePassword(password_data) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/changepassword', { 'id': c_user.id, 'value': password_data });
  }
  saveAndNavigateBack(link, variable_data) {
    this.router.navigateByUrl(link);
    return false;
    /* this.getUserScopes().subscribe(
      data => {
        this.setScopeUserObject(data);
        this.saveUserVaiableData(variable_data).subscribe();
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        } else {
          this.router.navigateByUrl(link);
        }
        this.router.navigateByUrl(link);
      }
    ); */
  }
  saveAndNavigateForward(link, variable_data) {
    // this.saveUserVaiableData(variable_data).subscribe();
    this.router.navigateByUrl(link);
  }
  saveAndNavigateBackPE(link, personal_expense) {
    // this.saveUserPersonalExpense(personal_expense).subscribe();
    this.router.navigateByUrl(link);
  }
  saveAndNavigateForwardPE(link, personal_expense) {
    // this.saveUserPersonalExpense(personal_expense).subscribe();
    this.router.navigateByUrl(link);
  }


  ForgotPassword(forgot_data) {
    return this.network.post_http_request('user/forgot', { 'value': forgot_data });
  }
  Forgotpasswordchange(forgot_data) {
    return this.network.post_http_request('user/forgotchange', { 'value': forgot_data });
  }
  shareUserReport(data, type) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/save_share_report',
      {
        'report_data': data,
        'user_id': c_user.id,
        'report_type': type,
      }
    );
  }
  saveUserRreport(data, type) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/savereport', { 'value': data, 'user_id': c_user.id, 'type': type });
  }
  getUserRreport(type) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getreport', { 'user_id': c_user.id, 'type': type });
  }
  getSharedReport(id, type) {
    return this.network.post_http_request('user/getsharedreport', { 'id': id, 'type': type });
  }
  getUserRreportAndData(type) {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getreport_data', { 'user_id': c_user.id, 'type': type });
  }
  captureScreen(filename = 'Report.pdf') {
    this.spinnerService.show();
    const data = document.getElementById('contentToConvert');

    this.pdf = new jspdf('p', 'mm', 'a4', 1);
    let top = document.getElementById('kwik_header');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    html2canvas(data).then(canvas => {
      this.imgWidth = 150;
      this.pageHeight = 580;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const conts645 = this.img_service.conts645;
      this.pdf.addImage(conts645, 'PNG', 30, 90, this.imgWidth, 100, '', 'SLOW');
      const data2 = document.getElementById('pfd_page_1');
      html2canvas(data2).then(canvas2 => {
        const contentDataURL2 = canvas2.toDataURL('image/png');
        this.pdf.addPage();
        this.imgHeight = canvas2.height * 191 / canvas2.width;
        this.pdf.addImage(contentDataURL2, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
        const data3 = document.getElementById('pfd_page_2');
        if (data3) {
          html2canvas(data3).then(canvas3 => {
            this.pdf.addPage();
            const contentDataURL3 = canvas3.toDataURL('image/png');
            this.imgHeight = canvas3.height * 191 / canvas3.width;
            this.pdf.addImage(contentDataURL3, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
            const data4 = document.getElementById('pfd_page_3');
            if (data4) {
              html2canvas(data4).then(canvas4 => {
                this.pdf.addPage();
                const contentDataURL4 = canvas4.toDataURL('image/png');
                this.imgHeight = canvas4.height * 191 / canvas4.width;
                this.pdf.addImage(contentDataURL4, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                const data5 = document.getElementById('pfd_page_4');
                if (data5) {
                  html2canvas(data5).then(canvas5 => {
                    this.pdf.addPage();
                    const contentDataURL5 = canvas5.toDataURL('image/png');
                    this.imgHeight = canvas5.height * 191 / canvas5.width;
                    this.pdf.addImage(contentDataURL5, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                    const data6 = document.getElementById('pfd_page_5');
                    if (data6) {
                      html2canvas(data6).then(canvas6 => {
                          this.pdf.addPage();
                          const contentDataURL6 = canvas6.toDataURL('image/png');
                          this.imgHeight = canvas6.height * 191 / canvas5.width;
                          this.pdf.addImage(contentDataURL6, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');  
                         
                          const data7 = document.getElementById('pfd_page_6');
                         if(data7){
                          html2canvas(data7).then(canvas7 => {
                            this.pdf.addPage();
                            const contentDataURL7 = canvas7.toDataURL('image/png');
                            this.imgHeight = canvas7.height * 191 / canvas7.width;
                            this.pdf.addImage(contentDataURL7, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');  
                            this.pdf.save(filename);
                            this.spinnerService.hide();
                          });  
                         }else {
                          this.pdf.save(filename);
                          this.spinnerService.hide();
                        }
                         
                      });
                    }else {
                      this.pdf.save(filename);
                      this.spinnerService.hide();
                    }
                    
                   
                  });
                } else {
                  this.pdf.save(filename);
                  this.spinnerService.hide();
                }
              });
            } else {
              this.pdf.save(filename);
              this.spinnerService.hide();
            }
          });
        } else {
          this.pdf.save(filename);
          this.spinnerService.hide();
        }

      });
    });
  }
  async captureScreenNew(filename = 'Report.pdf') {
    this.spinnerService.show();
    const pages = document.getElementsByClassName('pdf_page');
    await html2PDF(pages, {
      html2canvas: {
        scrollX: 0,
        scrollY: 0,
        scale: 1,
        imageTimeout: 15000,
        logging: true,
        useCORS: false,
      },
      imageQuality: 1,
      jsPDF: {
        unit: 'px',
        format: 'a4',
      },
      margin: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
      },
      imageType: 'image/jpeg',
      output: filename,
      init: function( pdf ) {
          const img = new Image();
          img.src = '../../../assets/images/Kwik_reportscover.png';

          const width = pdf.internal.pageSize.width;
          const height = pdf.internal.pageSize.height;
          const imgWidth = 310;
          const imgHeight = 205;
          const widthLeft = width / 2 - imgWidth / 2;
          const heightLeft = height / 2 - imgHeight / 2;
          pdf.addImage(img, 'PNG', widthLeft, heightLeft, imgWidth, imgHeight, '', 'SLOW');
          pdf.addPage();

      },
      success: function(pdf) {
        console.log(pdf);
        pdf.save(this.output);
      }
    });
    this.spinnerService.hide();
    return 2;
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }

  captureScreenbaseyear5(filename = 'Report.pdf') {

    this.spinnerService.show();
    const data = document.getElementById('contentToConvert');

    this.pdf = new jspdf('p', 'mm', 'a4');
    let top = document.getElementById('kwik_header');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    html2canvas(data).then(canvas => {
      this.imgWidth = 150;
      this.pageHeight = 580;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const conts645 = this.img_service.conts645;
      this.pdf.addImage(conts645, 'PNG', 30, 90, this.imgWidth, 100, '', 'SLOW');
      const data2 = document.getElementById('pfd_page_1');
      html2canvas(data2).then(canvas2 => {
        this.imgHeight = canvas2.height * 191 / canvas2.width;
        console.log('pfd_page_1 this.imgHeight ', this.imgHeight );
        if (this.imgHeight > 300) {
          const datainner1 = document.getElementById('reveune_id');
          if (datainner1) {
            html2canvas(datainner1).then(datainnercanvas1 => {
                  this.pdf.addPage();
                  const contentDatainnerURL1 = datainnercanvas1.toDataURL('image/png');
                  console.log('contentDatainnerURL1', contentDatainnerURL1);
                  this.imgHeight = datainnercanvas1.height * 191 / datainnercanvas1.width;
                  this.pdf.addImage(contentDatainnerURL1, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');


                  const datainner2 = document.getElementById('costofgoods');
                  if (datainner2) {
                    html2canvas(datainner2).then(datainnercanvas2 => {
                      this.pdf.addPage();
                      const contentDatainnerURL2 = datainnercanvas2.toDataURL('image/png');
                      console.log('contentDatainnerURL2', contentDatainnerURL2);
                      this.imgHeight = datainnercanvas2.height * 191 / datainnercanvas2.width;
                      this.pdf.addImage(contentDatainnerURL2, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');


                      const datainner3 = document.getElementById('fixedcostnew');
                      if (datainner3) {

                        html2canvas(datainner3).then(datainnercanvas3 => {
                          this.pdf.addPage();
                          const contentDatainnerURL3 = datainnercanvas3.toDataURL('image/png');
                          console.log('contentDatainnerURL2', contentDatainnerURL3);
                          this.imgHeight = datainnercanvas3.height * 191 / datainnercanvas3.width;
                          this.pdf.addImage(contentDatainnerURL3, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');

// New things added on site
        const data3 = document.getElementById('pfd_page_2');
        if (data3) {
          html2canvas(data3).then(canvas3 => {
            this.pdf.addPage();
            const contentDataURL3 = canvas3.toDataURL('image/png');
            this.imgHeight = canvas3.height * 191 / canvas3.width;
            this.pdf.addImage(contentDataURL3, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');

            console.log('pfd_page_2 this.imgHeight ', this.imgHeight );

            const data4 = document.getElementById('pfd_page_3');
            if (data4) {
              html2canvas(data4).then(canvas4 => {
                this.pdf.addPage();
                const contentDataURL4 = canvas4.toDataURL('image/png');
                this.imgHeight = canvas4.height * 191 / canvas4.width;

                console.log('pfd_page_3 this.imgHeight ', this.imgHeight );

                this.pdf.addImage(contentDataURL4, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                const data5 = document.getElementById('pfd_page_4');
                if (data5) {
                  html2canvas(data5).then(canvas5 => {
                    this.pdf.addPage();
                    const contentDataURL5 = canvas5.toDataURL('image/png');
                    this.imgHeight = canvas5.height * 191 / canvas5.width;

                    console.log('pfd_page_4 this.imgHeight ', this.imgHeight );

                    this.pdf.addImage(contentDataURL5, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                    this.pdf.save(filename);
                    this.spinnerService.hide();
                  });
                } else {
                this.pdf.save(filename);
                this.spinnerService.hide();
                }
              });
            } else {
              this.pdf.save(filename);
              this.spinnerService.hide();
            }
          });
        } else {
          this.pdf.save(filename);
          this.spinnerService.hide();
        }


// End New things added on site




                        });


                      }


                    });

                  }
            });
          }

        } else {
        const contentDataURL2 = canvas2.toDataURL('image/png');
        this.pdf.addPage();
        this.pdf.addImage(contentDataURL2, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
        const data3 = document.getElementById('pfd_page_2');
        if (data3) {
          html2canvas(data3).then(canvas3 => {
            this.pdf.addPage();
            const contentDataURL3 = canvas3.toDataURL('image/png');
            this.imgHeight = canvas3.height * 191 / canvas3.width;
            this.pdf.addImage(contentDataURL3, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');

            console.log('pfd_page_2 this.imgHeight ', this.imgHeight );

            const data4 = document.getElementById('pfd_page_3');
            if (data4) {
              html2canvas(data4).then(canvas4 => {
                this.pdf.addPage();
                const contentDataURL4 = canvas4.toDataURL('image/png');
                this.imgHeight = canvas4.height * 191 / canvas4.width;

                console.log('pfd_page_3 this.imgHeight ', this.imgHeight );

                this.pdf.addImage(contentDataURL4, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                const data5 = document.getElementById('pfd_page_4');
                if (data5) {
                  html2canvas(data5).then(canvas5 => {
                    this.pdf.addPage();
                    const contentDataURL5 = canvas5.toDataURL('image/png');
                    this.imgHeight = canvas5.height * 191 / canvas5.width;

                    console.log('pfd_page_4 this.imgHeight ', this.imgHeight );

                    this.pdf.addImage(contentDataURL5, 'PNG', 10, 10, 191, this.imgHeight, '', 'SLOW');
                    this.pdf.save(filename);
                    this.spinnerService.hide();
                  });
                } else {
                this.pdf.save(filename);
                this.spinnerService.hide();
                }
              });
            } else {
              this.pdf.save(filename);
              this.spinnerService.hide();
            }
          });
        } else {
          this.pdf.save(filename);
          this.spinnerService.hide();
        }

        }

      });

    });
  }
  captureScreen111111(filename = 'Report.pdf') {
    this.setprint = true;
    const data = document.getElementById('contentToConvert');
    const data2 = document.getElementById('kwik_header');


    html2canvas(data2).then(canvas => {
      this.imgWidth = 150;
      this.pageHeight = 580;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const conts645 = this.img_service.conts645;
      this.pdf = new jspdf();
      this.position = 0;
      this.pdf.addImage(conts645, 'PNG', 25, 100, this.imgWidth, 100, '', 'SLOW');
    });


    html2canvas(data).then(canvas => {
      this.imgWidth = 191;
      this.pageHeight = 280;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      this.pdf.addPage();
      this.pdf.addImage(contentDataURL, 'PNG', 10, this.position, this.imgWidth, this.imgHeight, '', 'SLOW');

      this.heightLeft -= this.pageHeight;

      while (this.heightLeft >= 0) {
        this.position = this.heightLeft - this.imgHeight;
        this.pdf.addPage();
        this.pdf.addImage(contentDataURL, 'PNG', 10, this.position, this.imgWidth, this.imgHeight, '', 'SLOW');
        this.heightLeft -= this.pageHeight;
      }
      this.pdf.save(filename);
      this.setprint = false;
    });
  }


  captureScreenexportuser(filename = 'Report.pdf') {
    this.setprint = true;
    const data = document.getElementById('contentToConvert');
    const data2 = document.getElementById('kwik_header');

   html2canvas(data2).then(canvas => {
      this.imgWidth = 150;
      this.pageHeight = 580;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const conts645 = this.img_service.conts645;
      this.pdf = new jspdf();
      this.position = 0;
      this.pdf.addImage(conts645, 'PNG', 25, 100, this.imgWidth, 100, '', 'SLOW');
    });


    html2canvas(data).then(canvas => {
      this.imgWidth = 191;
      this.pageHeight = 280;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      /*this.pdf = new jspdf();
      this.position = 0;*/
      this.pdf.addPage();
      this.pdf.addImage(contentDataURL, 'PNG', 10, this.position, this.imgWidth, this.imgHeight, '', 'SLOW');

      this.heightLeft -= this.pageHeight;

      while (this.heightLeft >= 0) {
        this.position = this.heightLeft - this.imgHeight;
        this.pdf.addPage();
        this.pdf.addImage(contentDataURL, 'PNG', 10, this.position, this.imgWidth, this.imgHeight, '', 'SLOW');
        this.heightLeft -= this.pageHeight;
      }
      this.pdf.save(filename);
    });
  }



  print_fns() {
    this.window.print();
  }
  print_fnsABC() {
    this.setprint = true;
    const printdata = document.getElementById('contentToConvert');
    html2canvas(printdata).then(canvas => {
      this.imgWidth = 208;
      this.pageHeight = 295;
      this.imgHeight = canvas.height * this.imgWidth / canvas.width;
      this.heightLeft = this.imgHeight;

      // tslint:disable-next-line:max-line-length
      this.contentDataURLpdf = canvas.toDataURL('image/png');
      // tslint:disable-next-line:max-line-length
      this.popupWin = this.window.open('', '_blank', 'width=' + canvas.width + ',height=' + canvas.height + ',scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      // tslint:disable-next-line:max-line-length
      this.popupWin.document.write('<style> *{margin:0% 2%;padding: 0;}@media print {@page { margin: 0%;} img{width:96%;} .page-break { color:red; display: block;  page-break-before: always; } .print_varible{margin-top:275px;}}</style><p><img class="print_varible" src="/assets/images/Kwik_reportscover.png"></p><img class="page-break" onload="window.print()" src="' + this.contentDataURLpdf + '" >');
      this.popupWin.document.close();
    });
    this.setprint = false;
  }
  getUsepersonalData() {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getpersonalval', { 'id': c_user.id });
  }
  getUsepersonalDataForReport(id) {
    return this.network.post_http_request('user/getpersonalvalfromreport', { 'id': id });
  }

  getUserDetailsbyReportid(id) {
    return this.network.post_http_request('user/getuserdetailsbyreportid', { 'id': id });
  }

  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  setEstimatedLiving(data) {
    const lcome_sum = data.personal_expense.me.value.reduce((a, b) => a + b.value, 0);
    const monthly_income = data.personal_expense.mi.value.reduce((a, b) => a + b.value, 0);
    const Living_expense = [0, 0, 0, 0, 0];

    let k = 0;
    for (const res of data.personal_expense.pse.value) {
      Living_expense[k] = (res.value * lcome_sum) - (res.value * monthly_income);
      k++;
    }
    return Living_expense;
  }
  verfiyuser(body) {
    return this.network.post_http_request('user/verify', body);
  }
  isEmailRegisterd(body) {
    return this.network.post_http_request('user/isemailregisterd', body);
  }

  brdcrumbBack() {
    this.router.navigateByUrl(this.brcr_back);
  }
  brdcrumbNext() {
    this.router.navigateByUrl(this.brcr_next);
  }
  onKeydown(event, k, arr) {
    if ((event.code === 'F2' || event.key === 'F2') && k === 0) {
      if (document.activeElement['value']) {
        const elemval = (document.activeElement['value']).replace(/[^0-9.]*/g, '');
        for (const elmnt of arr) {
          elmnt.value = elemval;
        }
      }
    }
  }
  getAllReports() {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getAllReports', { 'id': c_user.id });
  }
  deleteReportById(id) {
     
    return this.network.post_http_request('user/deleteReports', { 'id': id });
  }
  getUserStatus() {
    const c_user = this.getUserObject();
    return this.network.post_http_request('user/getUserStatus', { 'id': c_user.id, 'token': c_user.token });
  }
  getVideoUrls() {
    return {
      // sing_up_trail: 'OX7bxAHZ05Q',
      sing_up_trail: 'Sm5vkB9BTgU',
      home_page: '0FzIs14E3YU',
      variable_selection: '1UtcAj9CUH8',
      dataentry: 'Wknt9u7HhVE',
      report: '8shl2K-n1HU',
      testimonial: 'l_3v06-dLeE'
    };
  }

  getAllUserPlans() {
    return this.network.get_http_request('user/getAllUserPlans');
  }


  checkreCaptcha(body , action) {

    return this.network.post_http_request('user/checkreCaptcha', { 'recaptcha': body , 'action' : action});

  }



}



