import { WINDOW } from '@ng-toolkit/universal';
import {
  Component,
  OnInit,
  AfterContentInit,
  AfterViewInit,
  NgZone,
  Inject,
  ViewChild,
  ElementRef,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  RouterModule,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import { CmsService } from '../cms.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GlobalsService } from '../services/globals.service';
import { Observable, of, Subject } from 'rxjs';
@Component({
  selector: 'app-case-study-single',
  templateUrl: './case-study-single.component.html',
  styleUrls: ['./case-study-single.component.css']
})
export class CaseStudySingleComponent implements OnInit, AfterViewInit, OnDestroy {
  processing = true;
  blog_data: any;
  blogslug: any;
  relatedblog: boolean;
  realted_data: any;
  blog_data_image: any;
  metatag: any;
  userSubscription: Subscription;
  invokeBlogsingle: Subject<any> = new Subject();

  @ViewChild('singlecontentContainer') singlecontentContainer: ElementRef;
  selected_country: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private routing: Router,
    private cms: CmsService,
    private meta: Meta,
    private zone: NgZone,
    private globals: GlobalsService
  ) {
    this.blogslug = {
      url: ''
    };
    this.relatedblog = false;
    this.blog_data_image = '/assets/images/05.png';
    this.blog_data = {
      image: '',
      tittle: 'Quisque libero metus con...',
      user_name: 'John Doe',
      content: [`<p></p>`],
      related_post: [
        ` <p>Pellentesque habitant morbi tristique senectus et netus et malesuada.</p>`
      ]
    };

    route.params.subscribe(val => {
      this.zone.runOutsideAngular(() => {
        const scrollToTop = window.setInterval(() => {
          const pos = window.pageYOffset;
          const top = document.getElementById('kwik_header');
          top.scrollIntoView();
          if (pos > 0) {
            window.scrollTo(0, pos - 20);
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 16);
      });

      // this.getBlogBySingle();
    });
  }

  ngOnInit() {
    // this.getBlogBySingle();
  }
  ngOnDestroy() {
   // this.userSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    this.userSubscription = this.route.params.subscribe(params => {
      this.getBlogBySingle();
    });
    // this.getBlogBySingle();
    // this.singlecontentContainer.nativeElement.innerHTML = this.blog_data.content;
  }

  getBlogBySingle() {
    this.blogslug.url = this.route.snapshot.paramMap.get('singlecasestudy');
    console.log('getBlogBySingle',this.blogslug.url);
    this.cms.getSingleblogmeta(this.blogslug).subscribe(page_content => {
      /* this.blog_data = JSON.parse(page_content); */
      this.meta.updateTag(JSON.parse(page_content));
    });



    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value ;
        this.processing = true;
        this.cms.getSingleCasestudybyCountry(this.blogslug.url , this.selected_country)
        .subscribe(page_content => {

            this.blog_data = JSON.parse(page_content);
            this.blog_data_image = this.blog_data.image;
            if (this.blog_data.related.status === 1) {
              this.relatedblog = true;
              this.realted_data = this.blog_data.related.content;
            } else {
             this.relatedblog = false;
            }
            this.processing = false;

        } );
      }
    });



    /* this.cms.getSingleblog(this.blogslug).subscribe(page_content => {
      this.blog_data = JSON.parse(page_content);
      this.blog_data_image = this.blog_data.image;
      if (this.blog_data.related.status === 1) {
        this.relatedblog = true;
        this.realted_data = this.blog_data.related.content;
      } else {
        this.relatedblog = false;
      }
    }); */
  }
}

