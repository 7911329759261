import { Component, OnInit, AfterViewInit  } from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SubscriptionService } from '../../services/subscription.service';
import { UserService } from '../../services/user.service';

import { CmsService } from '../../cms.service';
import { GlobalsService } from '../../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-upgrade-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.css']
})
export class UpgradeSubscriptionComponent implements OnInit , AfterViewInit  {
  clientTokenURL: any;
  createPurchaseURL: any;
  paymentResponse: any;
  chargeAmount = 55.55;
  message: any;
  screening_tool_coupon_code = '';
  profile: any;
  clickedButton: any;
  cmessage: any;
  selected_country: any;
  plans: any;
  constructor(private http: HttpClient, private router: Router , private subscription: SubscriptionService,
    private user: UserService,private cms: CmsService,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any, private globals: GlobalsService) {
    this.clientTokenURL = environment.API_URL + 'bt/getclienttoken';
    this.createPurchaseURL = environment.API_URL + 'bt/createpurchase';
    this.profile = {
      'first_name': '',
      'last_name': '',
      'user_email': '',
      'address': '',
      'city': '',
      'state': '',
      'post_code': '',
      'country': '',
      'user_phone': '',
      'subscriber': '1',
    };
    this.globals.plans.subscribe(value => {
      if (value) {
        this.plans = value ;
      }
    });

    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value ;
      }
    });


   }
  ngAfterViewInit () {
    this.user.getUserScopes().subscribe(
      data => {
        if ( data.plan_details[0].plan_status == 1 ) {
          this.router.navigate(['/user/kwik-look-main' ]);
       }
      }
    );
  }
  ngOnInit() {
    this.cms.getPlans().subscribe( data => {
      this.plans = data.results;
    });

  }
  onPaymentStatus(event) {
    // console.log(event);
  }


  hideHolder(e: any) {
    // console.log($(e.target));
    $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
    $(e.target).attr('placeholder', '');
    return;
  }
  showHolder(e: any) {
    const txtval = $(e.target).val();
            if (txtval === '') {
                $(e.target).attr('placeholder', $(e.target).data('placeholder'));
            }
  }
  CheckCouponValid(e: any, plan_id: any , coupon: any , plan: any ) {

    this.clickedButton = plan_id;
     if ( coupon) {
       this.subscription.checkCouponValid(plan, coupon).subscribe(
         data => {
           if (data.status === 1) {
             this.router.navigate(['/user/subscription-payment/' + plan + '/' + coupon]);
           } else {
             this.cmessage = data.message;
           }
         },
         //   error => { }
       );
     } else {
       this.router.navigate(['/user/subscription-payment/' + plan]);
     }
  }

}
