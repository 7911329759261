import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CmsService } from '../cms.service';
import { UserService } from '../services/user.service';
import { SubscriptionService } from '../services/subscription.service';
import { Title } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  urlslug: any;
  userlogged = false;
  hideheader = false;
  page_content: any;
  c_user: any;
  sub: any;
  brcr: string;
  show_company = '';
  selected_country = '';
  myItem: any;

  countries: { [key: string]: string};
  sel_country: any;
  plan: any;
  coupon: any;
  createRegisterPurchaseURL: any;
  createSignUpURL: any ;
  reg_coupon: any;
  shouldShow: any = false;
  constructor(private activatedRoute: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private cms: CmsService,
    private titleService: Title,
    private user: UserService,
    private subscription: SubscriptionService,
    private globals: GlobalsService,
    private router: Router) {


    this.user.setFiscalVariables();
    this.createRegisterPurchaseURL = environment.API_URL + 'bt/subscription/screening_tool_sg';
    this.createSignUpURL           = 'screening_tool_sg';

    this.globals.routePlan.subscribe(value => {
      if (value) {
        this.plan = value.plan_id ;
        this.coupon =  value.coupon ;
      }
    });

    this.globals.registerPlanCoupon.subscribe(value => {
      if (value) {
        this.reg_coupon = value ;
      }
    });

    this.globals.createRegisterPurchaseURL.subscribe(value => {
      if (value) {
        this.createRegisterPurchaseURL = value.registerPurchaseURL ;
        this.createSignUpURL = value.registerSignUpURL ;
      }
    });
  }
  removeShow() {
    console.log('in');
    const element = document.getElementById('navbarTogglerDemo01');
    element.classList.remove('show');
  }
  /*selectChangeHandler (event: any) {
    this.selected_country = event.target.value;
    localStorage.removeItem('current_country');
    localStorage.setItem('current_country', this.selected_country);
    console.log(this.selected_country);
  }
*/
selectChangeHandler (event: any) {

  this.selected_country = event.target.value;
  this.localStorage.removeItem('kw_lang');
  this.localStorage.setItem('kw_lang',this.selected_country);
  this.globals.setCountry(this.selected_country);

  Object.keys(this.countries).forEach(key => {
  const value = this.countries[key];
    if ( key === this.sel_country) {
      this.globals.setcountrynameGlobal(value);
    }
  });
  /*for (const [key, value] of Object.entries(this.countries)) {
      if( key === this.sel_country){
        this.globals.setcountrynameGlobal(value);
      }
  }*/
  this.cms.getPlans().subscribe( data => {
    this.globals.setPlans(data.results);
  });

  if (this.router.url.indexOf('user/subscription-payment') > -1) {
    const list    = this.plan.split('_');
    list.pop();
    this.plan    = list.join('_') + '_' + this.selected_country.toLowerCase();
    this.subscription.getPlanDetails(this.plan, this.coupon).subscribe( data => {
        if (data.status === 1) {
          this.globals.setPlanDetails(data.results[0], data.results[0].onetime_fee, data.discount, data.is_valid_coupon);
          this.globals.setPurchaseURL( this.plan , this.coupon );
          let url = '/user/subscription-payment/' + this.plan;
          if (this.coupon) {
            url += '/' + this.coupon;
          }
          this.router.navigate([url]);
        }
    });
  }
  this.globals.setRegisterPurchaseURL(  this.selected_country.toLowerCase() , this.selected_country , 1 );

}



selectChangeHandlernew (event: any) {
 // console.log('new header country function', event);
    this.selected_country = event;
    this.localStorage.removeItem('kw_lang');
    this.localStorage.setItem('kw_lang', this.selected_country);
    this.globals.setCountry(this.selected_country);

    Object.keys(this.countries).forEach(key => {
      const value = this.countries[key];
      //  console.log('values', key);
      if ( key === this.sel_country) {
        this.globals.setcountrynameGlobal(value);
      }
    });



    /*for (const [key, value] of Object.entries(this.countries)) {
        if( key === this.sel_country){
          this.globals.setcountrynameGlobal(value);
        }
    }*/
    this.cms.getPlans().subscribe( data => {
      this.globals.setPlans(data.results);
    });
    if (this.router.url.indexOf('user/subscription-payment') > -1) {
      const list    = this.plan.split('_');
      list.pop();
      this.plan    = list.join('_') + '_' + this.selected_country.toLowerCase();
      this.subscription.getPlanDetails(this.plan, this.coupon).subscribe( data => {
          if (data.status === 1) {
            this.globals.setPlanDetails(data.results[0], data.results[0].onetime_fee, data.discount, data.is_valid_coupon);
            this.globals.setPurchaseURL( this.plan , this.coupon );
            let url = '/user/subscription-payment/' + this.plan;

            if (this.coupon) {
              url += '/' + this.coupon;
            }
            this.router.navigate([url]);
          }
      });
    }
    this.globals.setRegisterPurchaseURL( this.selected_country.toLowerCase() , this.selected_country , 1);
    this.globals.setRegisterCoupon(this.reg_coupon);

  }


  ngOnInit() {

    this.myItem = localStorage.getItem('current_country');
     // console.log('CODE', myItem);
  // const  myItem = localStorage.getItem('name');
   // console.log('kkkk',myItem);

    this.sub = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
      const temp = this.activatedRoute.root;


      if (
        typeof (temp.children[0]) !== undefined &&
        typeof (temp.children[0].children) !== undefined && temp.children[0].children.length > 0 &&
        typeof (temp.children[0].children[0].data) !== undefined &&
        typeof (temp.children[0].children[0].data['value']) !== undefined &&
        typeof (temp.children[0].children[0].data['value'].brcr) !== undefined) {
        this.brcr = this.activatedRoute.root.children[0].children[0].data['value'].brcr;
        let brc_tmp = '';
        if (this.activatedRoute.root.children[0].children[0].data['value'].breadcrumb) {
          brc_tmp = this.activatedRoute.root.children[0].children[0].data['value'].breadcrumb;
        }
        if (this.brcr) {
          this.titleService.setTitle('Kwik Look - Dashboard - ' + this.brcr);
        } else if (brc_tmp) {
          this.titleService.setTitle('Kwik Look - Home - ' + brc_tmp);
        }
      //  console.log('brc_tmp', temp.children[0].data['value'].breadcrumb, this.brcr);
      }



    });

   // console.log('userdetails',  this.user.);



    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
      this.c_user = this.user.getUserObject();



      if (this.c_user) {
        this.userlogged = true;
        this.user.getUserStatus().subscribe(userstatus => { // checking user logout or not
          if (userstatus.status === 0) {
             this.onLogout();
          }
         });
      }
      this.urlslug = this.activatedRoute.root.children[0]['_routerState'].snapshot.url;

      console.log('url slug is ', this.urlslug);

      if(this.urlslug === '/free-trial'){
          this.hideheader = true;
      }else {
        this.hideheader = false;
      }

      if (this.urlslug === '/login' || this.urlslug === '/register') { this.urlslug = ''; }
    });

    this.cms.getCmsByPage('cms/header')
      .subscribe(page_content => {
        this.page_content = page_content;
    });
    this.globals.current_country.subscribe(value => {
      if (value) {
      this.sel_country = value;
      }
      });


    this.globals.getCountries().subscribe(data => {
      this.countries  =   data.countries;
      this.sel_country  =   data.c_country;



      if (!this.localStorage.getItem('kw_lang') )  {
        this.localStorage.setItem('kw_lang', this.sel_country) ;


        Object.keys(this.countries).forEach(key => {
          const value = this.countries[key];
            if ( key === this.sel_country) {
              this.globals.setcountrynameGlobal(value);
            }
          });

      /*  for (const [key, value] of Object.entries(this.countries)) {
            if( key === this.sel_country){
                this.globals.setcountrynameGlobal(value);
            }
        }*/
      } else {
        this.sel_country  = this.localStorage.getItem('kw_lang');

        Object.keys(this.countries).forEach(key => {
          const value = this.countries[key];
            if ( key === this.sel_country) {
              this.globals.setcountrynameGlobal(value);
            }
          });

       /* for (const [key, value] of Object.entries(this.countries)) {
          if( key === this.sel_country){
              this.globals.setcountrynameGlobal(value);
          }
        }*/


      }


      if (!this.sel_country) {
        this.globals.setcountrynameGlobal('Singapore');
        this.globals.setCountry('SG');
        this.localStorage.setItem('kw_lang', 'SG');
    }

      this.globals.setCountry(this.sel_country);
      this.globals.setRegisterPurchaseURL( this.sel_country.toLowerCase()   , this.sel_country , 1 );
    });

  }
  /* saveVariableData() {
    if (this.user.varSelection) {
      this.user.saveUserVaiableData(this.user.varSelection);
    }
  } */
  ngOnDestroy() {
   // this.sub.unsubscribe();
  }
  onLogout() {
    this.user.logout();
    this.userlogged = false;
    this.router.navigate(['/']);
  }

}
