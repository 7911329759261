import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
 
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/report.service';
import { Router } from '@angular/router';
import { SensitiveY1Service } from '../../services/sensitive-y1.service';
@Component({
  selector: 'app-report-base-y1-to-y5',
  templateUrl: './report-base-y1-to-y5.component.html',
  styleUrls: ['./report-base-y1-to-y5.component.css']
})
export class ReportBaseY1ToY5Component implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: false,
  };
  
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0], label: 'TOTAL REVENUE' },
    { data: [0, 0, 0, 0, 0], label: 'TOTAL COSTS' }
  ];
  barChartColors:Array<any> = [
    { 
      backgroundColor: '#008000',
      borderColor: '#008000',
      pointBackgroundColor: '#008000',
      pointBorderColor: '#008000',
      pointHoverBackgroundColor: '#008000',
      pointHoverBorderColor: '#008000'
    },
    {
      backgroundColor: '#FF0000',
      borderColor: '#FF0000',
      pointBackgroundColor: '#FF0000',
      pointBorderColor: '#FF0000',
      pointHoverBackgroundColor: '#FF0000',
      pointHoverBorderColor: '#FF0000'
    },
   
  ];
  chartOptions = {
    responsive: true,
    legend: {
      onClick: null,
      labels: {
        display: true,
        boxWidth: 10
      },
      position: 'bottom'
    },
  };
  barChartLabels = ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'];
  buttonClicked = 2;
  report_data: any = {};
  living_expense = [0, 0, 0, 0, 0];
  fisc_inputs = { service_charge: 0 };
  base_case = {
    cf_b_tax: [0, 0, 0, 0, 0],
    corp_tax: [0, 0, 0, 0, 0],
    total_cost: [0, 0, 0, 0, 0],
    cf_a_tax: [0, 0, 0, 0, 0],
    ccf: [0, 0, 0, 0, 0],
    break_even_year: '',
    break_even_month: 'NA',
  };
  hidelinechart: boolean;
 
  chartData: any;
//  barChartData: any;
  chartLabels: any;
  color_arr: any;
  constructor(private router: Router, private user: UserService, private report_service: ReportService,
     private sensitive_y1: SensitiveY1Service) {

    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: {
          display: true,
          boxWidth: 10
        },
        position: 'bottom'
      },
    };
    
    this.chartData = [
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cum Cash Flow After Tax', fill: false },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Cash Flow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];

    
  // this.barChartData  = [
  //   {data:  [0,0,0,0,0], label: 'Total Revenue'},
  //   {data: [0, 0, 0, 0, 0], label: 'Total Costs'}
  // ];

    this.chartLabels = ['June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.color_arr = [{
      // tslint:disable-next-line:max-line-length
      backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }];

     
  }


  ngOnInit() {



    this.user.brcr_back = '/user/report-base/y1';
    this.user.brcr_next = '/user/report-sensitive/y1';
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    this.report_data.revenue = {
      sales: [],
      tota_sales: [0, 0, 0, 0, 0],
      service_charge: [0, 0, 0, 0, 0],
      tota_sal_service_charge: [0, 0, 0, 0, 0],
      is_service_charge: false,
      other_revenue: [0, 0, 0, 0, 0],
      total_revenue: [0, 0, 0, 0, 0]
    };
    this.report_data.cogs = { value: [], total: [0, 0, 0, 0, 0] };
    this.report_data.fixed_cost = { 
      value: [],
      total: [0, 0, 0, 0, 0],
      general_fixed_costs : { value: [] , total :[0,0,0,0,0]},
      it_fixed_costs: { value: [] , total :[0,0,0,0,0]},
      other_fixed_costs: { value: [] , total :[0,0,0,0,0]},
     };
    this.report_data.variable_cost = { value: [], total: [0, 0, 0, 0, 0],
      manpower_variable_costs : { value: [] , total :[0,0,0,0,0]},
      mss_variable_costs : { value: [] , total :[0,0,0,0,0]},
      other_variable_costs : { value: [] , total :[0,0,0,0,0]},
    };

    if (this.user.fiscal_inputs['sensitives']) {
      this.doNgInitAfterGettingFiscals();
    } else {
      this.user.setFiscalVariables().subscribe(
        data => {
          this.user.setFiscalDataFromResponse(data);
          this.report_service.setFiscalDataFromResponse(data);
          this.doNgInitAfterGettingFiscals();
        }
      );
    }


 
        
 
  }
  doNgInitAfterGettingFiscals() {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.user.getUserVaiableData().subscribe(
      data => {
        if (Object.keys(data).length) {
          this.setRevemueReportData(data);
          this.setCOGSReportData(data);
          this.setFixedCostReportData(data);
          this.setVariableCostReportData(data);
          this.calculateBaseCase(data);
          this.calculateTotal(data);
          this.calculateBreakEvenMonthYear(data);

          this.report_data.cogs.enable_cost = data.cogs.cost.value;
          this.report_data.cogs.enable_unitcost = data.cogs.unitcost.value;
          


          // this.barChartData[0].data  = this.report_data.revenue.total_revenue;
          // this.barChartData[1].data  = this.base_case.total_cost;


        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );
    this.user.getUsepersonalData().subscribe(
      data => {
        this.living_expense = this.user.setEstimatedLiving(data);

     


      }
    );


   


  }

  shareReport() {
    this.user.shareUserReport(this.report_data, 'base_y5').subscribe(
      data => {
        if (data.status) {
          this.user.report_notfn = data.slug;
          this.router.navigate(['/report/shared-successfully']);
        }
      }
    );
  }
  calculateTotal(data) {
    let count = 0;
    for (const ps of data.revenue.ps) {
      this.report_data.revenue.sales[count].status = ps.value;
      this.report_data.cogs.value[count].status = ps.value;
      count++;
    }

    
  }
  calculateBreakEvenMonthYear(data) {
    // base_case.ccf;
    let count = 0;
    let last_neg = 0;
    let break_even_year: any = 0;
    let break_even_month: any = 0;
    const y1_report = this.sensitive_y1.getSensitiveYearOneReport(data);


    for (const val of this.base_case.ccf) {
      if (val < 0) {
        break_even_year++;
        last_neg = count;
      }
      count++;
    }

    this.base_case.break_even_year = '' + break_even_year;
    if (break_even_year > 4) {
      this.base_case.break_even_year = 'More than 5 Years';
    }

    let inc_index = 1;
    if (inc_index > 4) {
      inc_index = 0;
    }



    if (break_even_year <= 4 && break_even_year >= 1) {
      const a = this.base_case.ccf[last_neg];
      const b = this.base_case.cf_a_tax[last_neg + inc_index];
      let breakeven_mnth = Math.round((a / b) * 12);
      if (breakeven_mnth < 0) {
        breakeven_mnth = breakeven_mnth * (-1);
      }
      this.base_case.break_even_month = '' + breakeven_mnth;
    } else if (break_even_year === 0) {

      this.base_case.break_even_month = '' + this.CalculateBreakEvenArray(y1_report.base_case.ccf_result);

    } else if ( break_even_year < 0  || isNaN(break_even_year)) {
      this.base_case.break_even_year = 'NA';
    }


    break_even_month  = this.base_case.break_even_month;
    if ( break_even_month > 12) {
      this.base_case.break_even_month =  '12';
    } else if  ( break_even_month < 0  || isNaN(break_even_month)) {
      this.base_case.break_even_month = 'NA';
    }



  }


  CalculateBreakEvenArray(ListofArray) {

    let  nums = 0;
    for (const val of ListofArray) {
      if (val < 0) {
        nums++;
      }
    }

    return nums;
  }

  /* setEstimatedLiving(data) {
    const lcome_sum = data.personal_expense.me.value.reduce((a, b) => a + b.value, 0);
    const monthly_income = data.personal_expense.mi.value.reduce((a, b) => a + b.value, 0);
    const Living_expense = [0, 0, 0, 0, 0];

    let k = 0;
    for (const res of data.personal_expense.pse.value) {
      Living_expense[k] = (res.value * lcome_sum) - (res.value * monthly_income);
      k++;
    }
    this.living_expense = Living_expense;
  } */
  getFixedCostParamValue(arr, key, y1array) {
    const result = [];
    let i = 1;
    result[0] = this.otherArraySum(y1array);
    for (const res of arr) {
      result[i] = res[key];
      i++;
    }
    return result;
  }
  calculateBaseCase(data) {

    console.log('before tax ',data);
    const Base_caseReportService = this.report_service.calculateTAXY1Y5(data);

    console.log('All basecase section', Base_caseReportService ,this.report_data.cogs.total);
    this.base_case.cf_b_tax = Base_caseReportService.cf_b_tax;
   // this.base_case.corp_tax = Base_caseReportService.corp_tax;
    this.base_case.corp_tax = Base_caseReportService.corp_rounded;
    this.base_case.cf_a_tax = Base_caseReportService.cf_a_tax;
    this.base_case.ccf = Base_caseReportService.ccf;
    this.base_case.total_cost = Base_caseReportService.Calc_Total_Costs_Y1Y5;
    this.chartLabels = ['Y1', 'Y2', 'Y3', 'Y4', 'Y5'];
    this.chartData[0].data = this.base_case.ccf;
    this.chartData[1].data = this.base_case.cf_a_tax;
 

    
    this.barChartData = [
     { data: this.report_data.revenue.total_revenue, label: 'TOTAL REVENUE' },
     { data:  Base_caseReportService.Calc_Total_Costs_Y1Y5, label: 'TOTAL COSTS' }
   ];  
   

    this.report_data.base_case = this.base_case;

 
   
  } 
  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }
  addPrevCOGSData(data, temp_obj, ps) {
    if (data.cogs.type === '2' || data.cogs.type === 2) {
      if (ps.revenue_cost && ps.revenue_cost.monthly_cost) {
        for (const res of ps.revenue_cost.monthly_cost) {
          temp_obj.val[0] += this.numOr0(res.value);
          this.report_data.cogs.total[0] += this.numOr0(res.value);
        }
      }

    } else {
      if (ps.revenue_cost && ps.revenue_cost.sales) {
        for (const sales of ps.revenue_cost.sales) {
          temp_obj.val[0] += this.numOr0(sales.value) * this.numOr0(ps.revenue_cost.unit_monthly_cost);
          this.report_data.cogs.total[0] += this.numOr0(sales.value) * this.numOr0(ps.revenue_cost.unit_monthly_cost);
        }
      }

    }
    return temp_obj;
  }
  setCOGSReportData(data) {
    if (data.cogs.type === '2' || data.cogs.type === 2) {
      for (const ps of data.revenue.ps) {
        let temp_obj = { key: ps.key, val: [0, 0, 0, 0, 0] };
        let count = 1;
        if (ps.revenue_cost_y5 && ps.revenue_cost_y5.yearly_cost) {
          for (const res of ps.revenue_cost_y5.yearly_cost) {
            temp_obj.val[count] = this.numOr0(res.value);
            this.report_data.cogs.total[count] += this.numOr0(res.value);
            count++;
          }
        }

        temp_obj = this.addPrevCOGSData(data, temp_obj, ps);
        this.report_data.cogs.value.push(temp_obj);
      }
    } else {
      for (const ps of data.revenue.ps) {

        let temp_obj = { key: ps.key, val: [0, 0, 0, 0, 0] };
        let count = 1;
        if (ps.revenue_cost_y5 && ps.revenue_cost_y5.sales) {
          for (const sales of ps.revenue_cost_y5.sales) {
            const sum = this.numOr0(sales.value) * this.numOr0(ps.revenue_cost_y5.unit_yearly_cost[count - 1].value);
            temp_obj.val[count] = sum;
            this.report_data.cogs.total[count] += sum;
            count++;
          }
        }

        temp_obj = this.addPrevCOGSData(data, temp_obj, ps);
        this.report_data.cogs.value.push(temp_obj);
      }
    }
  }

  setVariableCostReportDataForObject(var_cost, data) {
    const temp_obj = { status: data.variablecost[var_cost].value, key: data.variablecost[var_cost].key, val: [0, 0, 0, 0, 0] };
    let count = 1;
    // const tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    let tem_sum = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
      tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    }
    this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);

    if(var_cost == 'op_partners'){
      this.report_data.variable_cost.manpower_variable_costs.total[0] += this.numOr0(tem_sum);
    }   else{
      this.report_data.variable_cost.other_variable_costs.total[0] += this.numOr0(tem_sum);
    }

    console.log('manpower_variable_costs' ,var_cost  ,data.variablecost[var_cost].variable_cost.val)
    temp_obj.val[0] = tem_sum;
    // this.report_data.variable_cost.total[0] += tem_sum;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
      for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        this.report_data.variable_cost.total[count] += vcval;
        if(var_cost == 'op_partners'){
          this.report_data.variable_cost.manpower_variable_costs.total[count] += vcval;
        }  else  {
          this.report_data.variable_cost.other_variable_costs.total[count] += vcval;
        }
        count++;
      }
     
    }
    
    if(var_cost == 'op_partners'){
       this.report_data.variable_cost.manpower_variable_costs.value.push(temp_obj);
    }   else{
      this.report_data.variable_cost.other_variable_costs.value.push(temp_obj);
    }


    this.report_data.variable_cost.value.push(temp_obj);
  }


  setVariableCostReportDataForZeroObject(var_cost, data) {
    let status_bol = data.variablecost[var_cost].value;
    if (!data.variablecost['salaries'].value &&  !data.variablecost['op_partners'].value) {
      status_bol = false;
    }
    const temp_obj = { status: status_bol, key: data.variablecost[var_cost].key, val: [0, 0, 0, 0, 0] };
    let count = 1;
    // const tem_sum = this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
    let tem_sum = 0;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
      tem_sum = data.variablecost[var_cost].variable_cost.value;
    }
  //  this.report_data.variable_cost.total[0] += 0;
    temp_obj.val[0] = tem_sum;
    // this.report_data.variable_cost.total[0] += tem_sum;
    if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
      for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
        const vcval = this.numOr0(vc.value);
        temp_obj.val[count] += vcval;
        // this.report_data.variable_cost.total[count] += 0;
        count++;
      }
    }

    this.report_data.variable_cost.value.push(temp_obj);
  }






  setVariableCostReportDataForArray(var_cost2, data) {
    for (const var_cost of data.variablecost[var_cost2]) {
      const temp_obj = { status: var_cost.value, key: var_cost.key, val: [0, 0, 0, 0, 0] };
      let count = 1;
      // const tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      let tem_sum = 0;
      if (var_cost.variable_cost) {
        tem_sum = this.otherArraySum(var_cost.variable_cost.val);
      }
      this.report_data.variable_cost.total[0] += this.numOr0(tem_sum);
      if(var_cost2 == 'salaries') {
        this.report_data.variable_cost.manpower_variable_costs.total[0] += this.numOr0(tem_sum);
      } else if(var_cost2 == 'sales' || var_cost2 == 'marketing' || var_cost2 == 'service') {
        this.report_data.variable_cost.mss_variable_costs.total[0] += this.numOr0(tem_sum);
      } else{
        this.report_data.variable_cost.other_variable_costs.total[0] += this.numOr0(tem_sum);
      }
      temp_obj.val[0] = tem_sum;
      // this.report_data.variable_cost.total[0] += tem_sum;
      if (var_cost.variable_cost_y5) {
        for (const vc of var_cost.variable_cost_y5.value) {
          const vcval = this.numOr0(vc.value);
          temp_obj.val[count] += vcval;
          this.report_data.variable_cost.total[count] += vcval;

          if(var_cost2 == 'salaries'){
            this.report_data.variable_cost.manpower_variable_costs.total[count] += vcval;
          } else if(var_cost2 == 'sales' || var_cost2 == 'marketing' || var_cost2 == 'service') {
            this.report_data.variable_cost.mss_variable_costs.total[count] += vcval;
          } else {
            this.report_data.variable_cost.other_variable_costs.total[count] += vcval;
          }

          count++;
        }
      }


      if(var_cost2 == 'salaries'){
        this.report_data.variable_cost.manpower_variable_costs.value.push(temp_obj);
      } else if(var_cost2 == 'sales' || var_cost2 == 'marketing' || var_cost2 == 'service') {
        this.report_data.variable_cost.mss_variable_costs.value.push(temp_obj);
      } else {
        this.report_data.variable_cost.other_variable_costs.value.push(temp_obj);
      }
      this.report_data.variable_cost.value.push(temp_obj);
    }
  }

  setVariableCostReportData(data) {
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners' , 'historical' ];
    const var_cos_arr = ['salaries',  'marketing', 'uv', 'other'];

   
    const var_data_thrid: any = {};
    let i = 0;
    const  NewfirstYearsales = [0, 0, 0, 0, 0];

    // var_data_thrid.sales  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    //   { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    //    { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.sales  = [];
    for (let x = 0; x < data.revenue.ps.length; x++) {
      var_data_thrid.sales.push({ value: 0 });
    }
    var_data_thrid.total  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.variable_cost_y5 = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];


    console.log(' ERROR--------', data.revenue.ps.length);
    for (const rev_totnew of data.revenue.ps) {
      let sum = 0;

      for (const rev_totnewmonth of rev_totnew.revenue_cost.sales) {
          sum = sum + rev_totnewmonth.value;
      }

      NewfirstYearsales[i] = sum;
      console.log('ERROR--------', i);
      var_data_thrid.sales[i].value = sum;
      i++;
    }

    var_data_thrid.total[0].value = this.otherArraySum(var_data_thrid.sales);
    for (const revenue_costall of data.revenue.ps) {
      let k = 1;
      for (const revenue_cost_single of revenue_costall.revenue_cost_y5.sales) {
      //  console.log('newelvee -', k , revenue_cost_single);
        var_data_thrid.total[k].value += revenue_cost_single.value;
        k++;
      }
    }



// this.otherArraySum(
  let tppcdollar_value = false;
  let tppcdollar_key = 'Third Party Payment Charges $';
  let variable_cost_value = 0;
  // console.log('Newfirstyearsales', var_data_thrid);
  //  console.log('Newfirstyearsales', NewfirstYearsales);
    console.log('Third Party Payment Charges',data.variablecost);
    if (typeof data.variablecost.tppcdollar !== 'undefined') {
      if (data.variablecost.tppcdollar.variable_cost_y5.value) {
        var_data_thrid.variable_cost_y5 = data.variablecost.tppcdollar.variable_cost_y5.value;
      }
      if (data.variablecost.tppcdollar.value) {
        tppcdollar_value = data.variablecost.tppcdollar.value;
        tppcdollar_key = data.variablecost.tppcdollar.key;
      }
      if (data.variablecost.tppcdollar.variable_cost.value) {
         variable_cost_value = data.variablecost.tppcdollar.variable_cost.value;
      }
    }



    this.setVariableCostReportDataForObject('op_partners', data);
    this.setVariableCostReportDataForArray('salaries', data);
    // if (typeof data.variablecost.tot_emp_at_yearEnd !== 'undefined') {
    // this.setVariableCostReportDataForZeroObject('tot_emp_at_yearEnd', data);
    // }
    this.setVariableCostReportDataForArray('marketing', data);
    this.setVariableCostReportDataForArray('sales', data);
    this.setVariableCostReportDataForArray('service', data);
   // this.setVariableCostReportDataForObject('logistics', data);


    // Third party charge
    let tp_type = 1;
    if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2 ) {
      tp_type = 2;
    }


    const tppc_obj = {
      status: data.variablecost.tppc.value,
      key: data.variablecost.tppc.key,
      type: tp_type,
      val: [0, 0, 0, 0, 0],
      total: [0, 0, 0, 0, 0],
    };


    const tppc_dollar = {
      status: tppcdollar_value,
      key: tppcdollar_key,
      type: tp_type,
      val: [0, 0, 0, 0, 0],
      total: [0, 0, 0, 0, 0],
    };

    let tppc_count = 0;


    for (const rev_tot of this.report_data.revenue.total_revenue) {
      // const tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
      let tppc_sum = 0;
      let tppc_sum2 = 0;


      if (tppc_count === 0) {
      tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
      } else {
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
      }


      if (tppc_count === 0) {

       /* tppc_sum2 = ( (NewfirstYearsales[0] +
          NewfirstYearsales[1] +
          NewfirstYearsales[2]) * data.variablecost.tppcdollar.variable_cost.value ); */
          tppc_sum2 = ( (var_data_thrid.total[tppc_count].value ) * variable_cost_value );

      }  else {
      //  tppc_sum = Math.round((rev_tot / 100) * var_data_thrid.variable_cost_y5[tppc_count - 1].value);
     /*     tppc_sum2 = ( (var_data_thrid.sales1[tppc_count - 1].value +
          var_data_thrid.sales2[tppc_count - 1].value +
          var_data_thrid.sales3[tppc_count - 1].value ) * var_data_thrid.variable_cost_y5[tppc_count - 1].value );
      */
     tppc_sum2 = ( (var_data_thrid.total[tppc_count].value ) * var_data_thrid.variable_cost_y5[tppc_count - 1].value );


        }

     

     // console.log('sssssss', var_data_thrid.total[tppc_count - 1].value * data.variablecost.tppcdollar.variable_cost.value);


      /* Jobin old code
       if (tppc_count === 0 && data.variablecost.tppc.variable_cost) {
        if ( tppc_obj.type === 2 ) {
          tppc_sum = data.variablecost.tppc.variable_cost.value;
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        }
      } else if (data.variablecost.tppc && data.variablecost.tppc.variable_cost_y5) {
        // console.log('data.variablecost.tppc.variable_cost_y5', data.variablecost.tppc.variable_cost_y5);
        if ( tppc_obj.type === 2 ) {
          tppc_sum = data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value;
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
        }
      }*/


      tppc_dollar.val[tppc_count] = tppc_sum2;
      console.log('dfsasdsd tppc_dollar' ,tppc_dollar , tppc_sum2);
     // tppc_dollar.total += tppc_sum2;





      tppc_obj.val[tppc_count] = tppc_sum;
      this.report_data.variable_cost.total[tppc_count] += tppc_sum + tppc_sum2;
      this.report_data.variable_cost.other_variable_costs.total[tppc_count] += tppc_sum + tppc_sum2; // new pahse 5
      tppc_count++;
    }
    console.log('dfsasdsd tppc_dollar' ,tppc_dollar , tppc_obj);
    // console.log('tppc_dollar', var_data_thrid);
    // console.log('var_data_thrid', var_data_thrid);
    // console.log('var_data_thrid.variable_cost_y5', var_data_thrid.variable_cost_y5);
    this.report_data.variable_cost.value.push(tppc_obj);
    this.report_data.variable_cost.value.push(tppc_dollar);


    this.report_data.variable_cost.other_variable_costs.value.push(tppc_obj);  // new pahse 5
    this.report_data.variable_cost.other_variable_costs.value.push(tppc_dollar); // new pahse 5
    // Third party charge ends

    this.setVariableCostReportDataForObject('logistics', data);
    this.setVariableCostReportDataForObject('cps', data);
    this.setVariableCostReportDataForObject('tad', data);
    this.setVariableCostReportDataForArray('uv', data);
    this.setVariableCostReportDataForObject('bte', data);
    this.setVariableCostReportDataForArray('other', data);
    this.setVariableCostReportDataForObject('historical', data);
  }


  setFixedCostReportData(data) {
    // const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc', 'citd'];
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'citd', 'wis', 'ed', 'uf', 'mvc' , 'historical' ];
   
    for (const fixd_cost of fc_keys) {
      const temp_obj = {
        key: data.fixedcost[fixd_cost].key,
        status: data.fixedcost[fixd_cost].value,
        val: [0, 0, 0, 0, 0]
      };
      let count = 1;
      // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      let tem_sum = 0;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
        tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      }
      this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);


      // Sub-Total General & It Fixed Costs
      if(fixd_cost == 'wis'){
        this.report_data.fixed_cost.it_fixed_costs.total[0] += this.numOr0(tem_sum);
      } else {
        this.report_data.fixed_cost.general_fixed_costs.total[0] += this.numOr0(tem_sum);
      }
      // End Sub-Total General & It Fixed Costs


      console.log('general_total_cots ',fixd_cost,this.numOr0(tem_sum) , this.report_data.fixed_cost.general_fixed_costs.total[0]);
      temp_obj.val[0] = tem_sum;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
        for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
          temp_obj.val[count] = this.numOr0(res.value);
          this.report_data.fixed_cost.total[count] += this.numOr0(res.value);


          // Sub-Total General & It Fixed Costs
          if(fixd_cost == 'wis'){
            this.report_data.fixed_cost.it_fixed_costs.total[count] += this.numOr0(res.value);
          } else {
            this.report_data.fixed_cost.general_fixed_costs.total[count] += this.numOr0(res.value);
            
          }
          // End Sub-Total General & It Fixed Costs
          count++;
        }
      }


      if(fixd_cost == 'wis'){
        this.report_data.fixed_cost.it_fixed_costs.value.push(temp_obj);
      } else {
        this.report_data.fixed_cost.general_fixed_costs.value.push(temp_obj);
      }
      this.report_data.fixed_cost.value.push(temp_obj);
    }


    const fc_array_keys = ['citd','other', 'otherCE'];
    for (const key of fc_array_keys ) {
      for (const other of data.fixedcost[key]) {

        if(other.value){


        const temp_obj = {
          key: other.key,
          status: other.value,
          val: [0, 0, 0, 0, 0]
        };
        let count = 1;
        // const tem_sum = this.otherArraySum(other.fixed_cost.fc);
        let tem_sum = 0;
        if (other.fixed_cost) {
          tem_sum = this.otherArraySum(other.fixed_cost.fc);
        }
        this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
        if(key =='citd' ){
          this.report_data.fixed_cost.it_fixed_costs.total[0] += this.numOr0(tem_sum);
        } else {
          this.report_data.fixed_cost.other_fixed_costs.total[0] += this.numOr0(tem_sum);
        }

        temp_obj.val[0] = tem_sum;
        if (other.fixed_cost_y5) {
          for (const fcy5 of other.fixed_cost_y5.val) {
            temp_obj.val[count] = this.numOr0(fcy5.value);
            this.report_data.fixed_cost.total[count] += this.numOr0(fcy5.value);

            if(key =='citd' ){
              this.report_data.fixed_cost.it_fixed_costs.total[count] += this.numOr0(fcy5.value);
            } else {
              this.report_data.fixed_cost.other_fixed_costs.total[count] += this.numOr0(fcy5.value);
            }


            count++;
          }
        }


        if(key =='citd' ){
          this.report_data.fixed_cost.it_fixed_costs.value.push(temp_obj);
          ;
        } else {
          this.report_data.fixed_cost.other_fixed_costs.value.push(temp_obj);
        }

        

        this.report_data.fixed_cost.value.push(temp_obj);
        }
      }
    }

    // const fc_keys1 = ['historical'];

    // for (const fixd_cost of fc_keys1) {
    //   const temp_obj = {
    //     key: data.fixedcost[fixd_cost].key,
    //     status: data.fixedcost[fixd_cost].value,
    //     val: [0, 0, 0, 0, 0]
    //   };
    //   let count = 1;
    //   // const tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
    //   let tem_sum = 0;
    //   if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
    //     tem_sum = this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
    //   }
    //   this.report_data.fixed_cost.total[0] += this.numOr0(tem_sum);
    //   temp_obj.val[0] = tem_sum;
    //   if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
    //     for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
    //       temp_obj.val[count] = this.numOr0(res.value);
    //       this.report_data.fixed_cost.total[count] += this.numOr0(res.value);
    //       count++;
    //     }
    //   }

    //   this.report_data.fixed_cost.value.push(temp_obj);
    // }
  }
  setRevemueReportData(data) {
    this.report_data.revenue.other_status = data.revenue.other.value;
    this.report_data.revenue.is_service_charge = data.revenue.sc.value;
    this.report_data.revenue.other = data.revenue.other.revenue_cost_y5;
    const pss = data.revenue.ps;
    for (const pro_ser of pss) {
      // const y5 = pro_ser.revenue_cost_y5.sales;
      let y5 = [];
      if (pro_ser.revenue_cost_y5) {
        y5 = pro_ser.revenue_cost_y5.sales;
      }
      const temp_obj = { key: pro_ser.key, val: [0, 0, 0, 0, 0] };
      let count = 1;

      let tem_sum = 0;
      if (pro_ser.revenue_cost) {
        tem_sum = this.otherArraySum(pro_ser.revenue_cost.sales) * this.numOr0(pro_ser.revenue_cost.unit_price);
      }

      temp_obj.val[0] = tem_sum;
      this.report_data.revenue.tota_sales[0] += tem_sum;
      this.report_data.revenue.total_revenue[0] += tem_sum;
      if (this.report_data.revenue.is_service_charge) {
        // this.report_data.revenue.total_revenue[1] += (this.numOr0(tem_sum) * this.fisc_inputs.service_charge) / 100;
        this.report_data.revenue.total_revenue[0] += Math.round((this.numOr0(tem_sum) * this.fisc_inputs.service_charge) / 100);
      }

      for (const sal_val of y5) {
        temp_obj.val[count] = this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        this.report_data.revenue.tota_sales[count] += this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        this.report_data.revenue.total_revenue[count] += this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value;
        if (this.report_data.revenue.is_service_charge) {
          // other_revenue
          // tslint:disable-next-line:max-line-length
          const sc = Math.round((this.numOr0(sal_val.value) * pro_ser.revenue_cost_y5.unit_price[count - 1].value * this.fisc_inputs.service_charge) / 100);
          this.report_data.revenue.total_revenue[count] += sc;
        }
        count++;
      }
      this.report_data.revenue.sales.push(temp_obj);
    }
    let count2 = 1;
    const oth_sum = this.otherArraySum(data.revenue.other.revenue_cost);
    this.report_data.revenue.other_revenue[0] = oth_sum;
    this.report_data.revenue.total_revenue[0] += oth_sum;
    if (data.revenue.other && data.revenue.other.revenue_cost_y5) {
      for (const other of data.revenue.other.revenue_cost_y5) {
        this.report_data.revenue.other_revenue[count2] = other.value;
        this.report_data.revenue.total_revenue[count2] += this.numOr0(other.value);
        count2++;
      }
    }

  

  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }

  print_fns() {
    this.user.print_fns();
  }
  captureScreen() {


    this.user.captureScreenbaseyear5('ReportBaseYearOneToFive.pdf');

  }

  
  async captureScreenNew() {
    this.buttonClicked = 1;
    await this.user.delay(50);
    const val = await this.user.captureScreenNew('ReportBaseYearOneToFive.pdf');
    this.buttonClicked = val;
  }

  ArrayTotal(array){
    var sumNumber = array.reduce((acc, cur) => acc + Number(cur), 0)
   
    return sumNumber;
  }
  MultipleArrayHasValue(array){
    if(array){
      for (let prop of array){ // own properties, you might use
        // for (let prop in obj)
        let total = this.ArrayTotal(prop.val)
        if(total > 0){
          return 1;
        }
      }
    }
    return 0;

  }


  AppendPercentage(value , divded){

    
    if(value != 0 && value > 0 && divded !=0){
      let Percentage =  ((value*1 / divded*1)*100);
      return  Math.round(Percentage) + '%';
    } else if(divded == 0 || divded == '0'){
      return 'NA';
    } else {
      return '0%';
    } 
      
    }
}
