import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-why-start-ups-fail',
  templateUrl: './why-start-ups-fail.component.html',
  styleUrls: ['./why-start-ups-fail.component.css']
})
export class WhyStartUpsFailComponent implements OnInit {
  metatag: any;
  constructor(private meta: Meta) {

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Kwik Look: In order to build a successful startup, it is important to understand why other startups have failed, and try to avoid making similar mistakes.',
    };
    this.meta.updateTag(this.metatag);

   }

  ngOnInit() {
  }

}
