import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shared-successfully',
  templateUrl: './shared-successfully.component.html',
  styleUrls: ['./shared-successfully.component.css']
})
export class SharedSuccessfullyComponent implements OnInit {
  report_notfn = '';
  report_slug = '';
  copy_message = '';
  classmessages = '';
  constructor(private user: UserService, private router: Router) {
    if (this.user.report_notfn) {
      this.report_notfn = document.location.origin + '/report/' + (this.user.report_notfn);
      this.report_slug = '/report/' + (this.user.report_notfn);
      this.user.report_notfn = '';
    } else {
      this.router.navigate(['/user/kwik-look-main']);
    }
  }

  ngOnInit() {
  }
  copyURL(report_notfn) {
    this.fallbackCopyTextToClipboard(report_notfn);
  }
  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // document.body.appendChild(textArea);
    document.getElementById('c-icon').appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.copy_message = 'Copied URL to clipboard';
        this.classmessages = 'success';
      } else {
        this.copy_message = 'Could not copy URL';
        this.classmessages = 'error';
      }
      setTimeout(() => {
        this.copy_message = '';
        this.classmessages = '';
      }, 3000);
    } catch (err) {
      this.copy_message = 'Could not copy URL';
      this.classmessages = 'error';
      setTimeout(() => {
        this.copy_message = '';
        this.classmessages = '';
      }, 3000);
    }

    document.getElementById('c-icon').removeChild(textArea);
  }

}
