import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import {  UserService } from '../services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  pageScrollDuration: any;
  current_subscription: any;
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  metatag: any;
  constructor(private titleService: Title, private sanitizer: DomSanitizer, private userService: UserService, private meta: Meta,
    private zone: NgZone) {

    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Kwik Look is a simple online Cash Flow Evaluation tool that allows SME entrepreneurs to quickly understand the potential profitability of their new business idea.',
    };
    this.meta.updateTag(this.metatag);

  }

  ngOnInit() {
    this.titleService.setTitle('Kwik Look - Home');
  }
  gotobottom() {
    const el = document.getElementById('kwiklook');
    el.scrollIntoView();
    this.pageScrollDuration = 100;
  }
  ngAfterViewInit() {
   // this.setCurrentGif();
  }
  gifTabClicked(id) {
  //  this.setCurrentGif(id);
  }
  // setCurrentGif(current_slide = 0) {
  //   clearInterval(this.current_subscription);
  //   this.zone.runOutsideAngular(() => { this.current_subscription = setInterval(() => {
  //   const id = 'gif_sec_' + ((current_slide % 5) + 1);
  //   if (document.getElementById(id)) {
  //     document.getElementById(id).click();
  //   }
  //     current_slide++;
  //   }, 5000); });

  //   }

    
  closeModal() {
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.userService.getVideoUrls();
    this.video_url = 'https://www.youtube-nocookie.com/embed/' + vurls['home_page'] + '?autoplay=1';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }

}
