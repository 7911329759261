import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { Observable, of, Subject } from 'rxjs';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  page_content: any;
  metatag: any;
  selected_country: any;
  invokeEvent: Subject<any> = new Subject();
  constructor(private cms: CmsService, private meta: Meta,  private globals: GlobalsService) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Kwik Look: Your access to and usage of the Kwik Look Pte. Ltd. Website and Evaluation Tool, is governed by these Terms and Conditions.',
    };
    this.meta.updateTag(this.metatag);
   }

  ngOnInit() {


   /* this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value;
        this.cms.getcountrybasedpage(this.selected_country, 'tems_and_coditions')
        .subscribe(page_content => {
               this.cms.displayCmsContent(page_content);
        } );

      }
    });
    this.cms.getcountrybasedpage(this.selected_country, 'tems_and_coditions')
    .subscribe(page_content => {
        this.page_content = page_content;
    } );

   this.cms.invokeEvent.subscribe(value => {
       if (value) {
          this.page_content = value ;
        }
      });*/


   this.cms.getCmsByPage('cms/tems_and_coditions')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );


  }

}
