import { Component, OnInit } from '@angular/core';
import {CmsService} from '../cms.service';
import {ViewEncapsulation} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import { GlobalsService } from '../services/globals.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {
  value: string;
  userlist: any;
  userlistFull: any;
  userlistMaster: any;
  faq_cat = 1;
  page_content: any;
  metatag: any;
  country_details: any = {};
  invokeEvent2: Subject<any> = new Subject();
  selected_country: string;
  constructor(private cms: CmsService , private meta: Meta,  private globals: GlobalsService) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Answers frequently asked questions relating to Startups, Kwik Look Subscriptions, Kwik Look Evaluation Approach etc',
    };
    this.meta.updateTag(this.metatag);


  }
  ngOnInit() {
    /*this.cms.getAllFaq().subscribe(userlist => {
        this.userlistMaster = this.userlistFull = userlist ;
        this.showFAQResults(1);
    });*/


    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value;
       // console.log('current country ', this.selected_country);
        this.cms.getAllFaq(this.selected_country).subscribe(userlist => {
          this.cms.displayFaqContent(userlist);
        });

      }
    });

    this.globals.invokebreadcrumb.subscribe(value => {
      if (value) {
    //   console.log('current country', value);
        this.country_details.countryname = value;
      }
  });
    this.cms.invokeEvent2.subscribe(value => {
     // console.log('value', value);
      if (value) {
        this.userlistMaster = this.userlistFull = this.userlist = value ;
      this.showFAQResults(this.faq_cat);
      }
      });

    /*const myItem = localStorage.getItem('current_country');

if (!myItem) {
this.cms.getClientIp().subscribe( current_country_details => {
this.country_details = current_country_details;
for (const [key, value] of Object.entries(this.country_details)) {
this.country_details.countryname = value;
console.log('country1', this.country_details.countryname);
this.getcurrentcountry(this.country_details.countryname);
}
});
} else {
this.country_details.countryname = myItem;
this.getcurrentcountry(this.country_details.countryname);
}*/

    this.cms.getCmsByPage('cms/faq')
      .subscribe(page_content => { this.page_content = page_content; } );
  }

  getcurrentcountry(country) {

    this.cms.getAllFaq(country).subscribe(userlist => {
      this.userlistMaster = this.userlistFull = this.userlist = userlist ;
    // this.showFAQResults(1);
    });
  }



  showFAQResults(cat = 1) {
    this.faq_cat = cat;
    this.userlist = this.userlistFull.filter(s => {
      return (parseInt(s['category'], 10) === cat) ;
    });
  }
  SearchInFAQ(str) {
    str = str.toLocaleLowerCase();
    this.userlistFull = this.userlistMaster;
    this.userlistFull = this.userlistFull.filter(s => {
      return (s.question.toLocaleLowerCase().includes(str));
    });
    this.showFAQResults(this.faq_cat);
  }
}
