import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { Observable, of, Subject } from 'rxjs';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
page_content: any;
metatag: any;
selected_country: any;
invokeEventPrivacy: Subject<any> = new Subject();
constructor(private cms: CmsService, private meta: Meta, private globals: GlobalsService) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Our Privacy Policy explains what types of data Kwik Look Pte. Ltd. collects, what we do with the data we collect, if and how we share it, the choices which you have regarding our use of your personal data and how you may access and update it.',
    };
    this.meta.updateTag(this.metatag);
   }

  ngOnInit() {
    /*this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value;
        console.log('current country ', this.selected_country);
        this.cms.getcountrybasedpage(this.selected_country, 'privacy_and_policy')
        .subscribe(page_content => {
               this.cms.displayCmsContentprivacy(page_content);
        } );
      }
    });
    this.cms.getcountrybasedpage(this.selected_country, 'privacy_and_policy')
    .subscribe(page_content => {
        this.page_content = page_content;
    } );

   this.cms.invokeEventPrivacy.subscribe(value => {
      console.log('value PRIVACY', value);
        if (value) {
          this.page_content = value ;
        }
      });*/


  this.cms.getCmsByPage('cms/privacy_and_policy')
      .subscribe(page_content => {
          this.page_content = page_content;
      } );

  }

}
