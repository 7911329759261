import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ReportService } from '../../services/report.service';
import { SensitiveY1Service } from '../../services/sensitive-y1.service';
import { Year1ReportService } from '../../services/year1-report.service';
import { Y1calcService } from '../../services/senitives/y1calc.service';
@Component({
  selector: 'app-report-sensitives-year-one-five',
  templateUrl: './report-sensitives-year-one-five.component.html',
  styleUrls: ['./report-sensitives-year-one-five.component.css']
})
export class ReportSensitivesYearOneFiveComponent implements OnInit {
  buttonClicked = 2;
  hidelinechart: boolean;
  chartOptions: any;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  chartOptions2: any;
  chartData2: any;
  chartLabels2: any;
  chartData3: any;
  chartData4: any;
  chartColors: any;
  report_data: any = {};
  living_expense = [0, 0, 0, 0, 0];
  fisc_inputs = { service_charge: 10 };

  report_base: any = {};
  rep_sen_data_keys = ['base_case', 'sal_rev_a', 'sal_rev_m', 'sal_rev_acc_a', 'sal_rev_acc_m', 'cogs', 'staf_sal', 'renovat',
    'furnt_equp', 'logistics', 'citd', 'website_it_sw', 'marketing', 'spare1', 'sal_rev1', 'sal_rev2', 'spare12'];
  rep_sen_data_y5 = {
    base_case: {
      label: 'Base Case',
      value: '',
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_a: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_m: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_a: {
      label: 'Sales Revenue Accelerate',
      value: 1,
      unit: 'Month(s)',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_m: {
      label: 'Sales Revenue Delay',
      value: 3,
      unit: ' Months',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    cogs: {
      label: 'COGS',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    staf_sal: {
      label: 'Staff Salaries ',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    renovat: {
      label: 'Renovation ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    furnt_equp: {
      label: 'Furniture & Equipment ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    logistics: {
      label: 'Logistics ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    citd: {
      label: 'Customised IT Development ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    website_it_sw: {
      label: 'Website/IT/Software ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    marketing: {
      label: 'Marketing, Sales & Service ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare1: {
      label: 'Spare ',
      value: 1,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev1: {
      label: 'Sales Rev Delay ',
      value: 3,
      unit: 'Mths PLUS Cost inc of Reno & Furn/Equipmt',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev2: {
      label: 'Sales Rev Delay',
      value: 3,
      unit: 'Mths PLUS Cost Inc of Staff Salaries',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare12: {
      label: 'Spare ',
      value: 2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    }
  };
/*
  rep_sen_data_y5_cum_cashflow = {
    base_case: {
      label: 'Base Case',
      value: '',
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_a: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_m: {
      label: 'Sales Revenue',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_a: {
      label: 'Sales Revenue Accelerate',
      value: 1,
      unit: 'Month(s)',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev_acc_m: {
      label: 'Sales Revenue Delay',
      value: 3,
      unit: ' Months',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    cogs: {
      label: 'COGS',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    staf_sal: {
      label: 'Staff Salaries ',
      value: 0.2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    renovat: {
      label: 'Renovation ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    furnt_equp: {
      label: 'Furniture & Euipment ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    logistics: {
      label: 'Logistics ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    citd: {
      label: 'Customised IT Development ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    website_it_sw: {
      label: 'Website/IT/Software ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    marketing: {
      label: 'Marketing ',
      value: 0.5,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare1: {
      label: 'Spare ',
      value: 1,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev1: {
      label: 'Sales Rev Delay ',
      value: 3,
      unit: 'Mths PLUS Cost inc of Reno & Furn/Equipmt',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    sal_rev2: {
      label: 'Sales Rev Delay',
      value: 3,
      unit: 'Mths PLUS Cost Inc of Staff Salaries',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    },
    spare12: {
      label: 'Spare ',
      value: 2,
      unit: '',
      result: [0, 0, 0, 0, 0],
      ccf_result: [0, 0, 0, 0, 0]
    }
  };*/
  constructor(private user: UserService,
    private router: Router,
    private report_service: ReportService,
    private sensitive_y1: SensitiveY1Service,
    private y1_report: Year1ReportService,
    private y1_calc_service: Y1calcService,
  ) {
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };
    this.chartData = [
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];
    /*   this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May']; */
    this.chartLabels = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];

    this.chartOptions2 = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        onClick: null,
        labels: { display: true, boxWidth: 10 },
        position: 'bottom'
      },
    };
    this.chartData2 = [
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },
      { data: [5, 0, 5, 0, 0, 5, 0, 5, 0, 0], label: 'Cash Flow After Tax', fill: false },
      { data: [1, 0, 6, 0, 5, 0, 12, 0, 0, 15], label: 'Cum Cash Flow After Tax', fill: false, },

      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];
    /*  this.chartLabels2 = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May']; */
    this.chartLabels2 = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];

  // tslint:disable-next-line:no-unused-expression
  this.chartColors = [
    { // first color

      backgroundColor: 'rgba(255, 99, 132, .85)',
      borderColor: '#ff6384',
      pointBackgroundColor: '#ff6384',
      pointBorderColor: '#ff6384',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#ff6384'
    },
    { // second color
      backgroundColor: 'rgba(54, 162, 235, .85)',
      borderColor: '#36a2eb',
      pointBackgroundColor: '#36a2eb',
      pointBorderColor: '#36a2eb',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#36a2eb'
    },
    { // second color
      backgroundColor: 'rgba(255, 228, 161, .85)',
      borderColor: '#ffe4a1',
      pointBackgroundColor: '#ffe4a1',
      pointBorderColor: '#ffe4a1',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#ffe4a1'
    },
    { // second color
      backgroundColor: 'rgba(237, 239, 242, .85)',
      borderColor: '#edeff2',
      pointBackgroundColor: '#edeff2',
      pointBorderColor: '#edeff2',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#edeff2'
    },
    { // second color
      backgroundColor: 'rgba(147, 217, 217, .85)',
      borderColor: '#93d9d9',
      pointBackgroundColor: '#93d9d9',
      pointBorderColor: '#93d9d9',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#93d9d9'
    },
    { // second color
      backgroundColor: 'rgba(151, 187, 205, .85)',
      borderColor: '#97bbcd',
      pointBackgroundColor: '#97bbcd',
      pointBorderColor: '#97bbcd',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#97bbcd'
    },
    { // second color
      backgroundColor: 'rgba(0, 98, 179, .85)',
      pointBackgroundColor: '#0062b3',
      pointBorderColor: '#0062b3',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#0062b3'
    },
    { // second color
      backgroundColor: 'rgba(255, 101, 0, .85)',
      borderColor: '#ff6500',
      pointBackgroundColor: '#ff6500',
      pointBorderColor: '#ff6500',
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#ff6500'
    }
    ];


  }

  ngOnInit() {
    this.user.brcr_back = '/user/report-sensitive/y1';
    this.user.brcr_next = '/user/valuation';
  //  this.user.brcr_back = '/user/report-sensitive/y1';
  // this.user.brcr_next = '/user/kwik-look-main';
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        if (data.scope < 2) {
          this.router.navigate(['/user/my-subscription']);
        }
      }
    );
    if (this.user.fiscal_inputs['sensitives']) {
      this.doNgInitAfterGettingFiscals();
    } else {
      this.user.setFiscalVariables().subscribe(
        data => {
          this.user.setFiscalDataFromResponse(data);
          this.report_service.setFiscalDataFromResponse(data);
          this.doNgInitAfterGettingFiscals();
        }
      );
    }
  }
  doNgInitAfterGettingFiscals() {
    if (this.user.fiscal_inputs['sensitives']) {
      for (const val of this.user.fiscal_inputs['sensitives']) {
        if (this.rep_sen_data_y5[val.sensitive_key]) {
          this.rep_sen_data_y5[val.sensitive_key].value = this.numOr0(val.fiscal_value);
        }

      }
    }
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.user.getUserVaiableData().subscribe(
      data => {
        // console.log(data);
        if (Object.keys(data).length) {
          const y1senrepdata = this.y1_calc_service.getY1SensitiveReport(data);
           console.log('y1senrepdata', y1senrepdata);
          const bc_arr = this.findBasecase(data);

          this.calculateSalesRevenueAdjustments(data);
          this.calculateRenovationAdjustments(data);
          this.calculateWebsiteAdjustments(data);
          this.calculateLogisticsAdjustments(data);
          this.calculateFurnitureAdjustments(data);
          this.calculateMarketingAdjustments(data);
          this.calculateStaffSalAdjustments(data);
          this.calculateCOGSAdjustments(data);
          this.SalesRevenueAccelerate(data);
          this.SalesRevenueDelay(data);
         this.SalesRevenueDelayPlusRenoFurn(data);
          this.SalesRevenueDelayPlusStaffSal(data);


          this.customizedItPhase2(data);
          this.setCompareWithBaseCaseValue();

          this.calculateCumCashflow(data);
          this.checkPropertyIsEnablesInVarSel(data); // enable variable
          this.setGraphData();

          this.setExposureAndBreakevenValue(data, y1senrepdata);

        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }

      }
    );
    this.user.getUsepersonalData().subscribe(data => { this.living_expense = this.user.setEstimatedLiving(data); }
    );
  }

  checkPropertyIsEnablesInVarSel(data) {
    this.rep_sen_data_y5.staf_sal['var_sel_disable'] = false;
    this.rep_sen_data_y5.renovat['var_sel_disable'] = false;
    this.rep_sen_data_y5.logistics['var_sel_disable'] = false;
    this.rep_sen_data_y5.website_it_sw['var_sel_disable'] = false;
    this.rep_sen_data_y5.marketing['var_sel_disable'] = false;
    this.rep_sen_data_y5.cogs['var_sel_disable'] = false;
    this.rep_sen_data_y5.base_case['var_other_sel_disable'] = false;
    this.rep_sen_data_y5.base_case['var_sales_sel_disable'] = false;
    this.rep_sen_data_y5.base_case['var_sel_disable'] = false;
    this.rep_sen_data_y5.base_case['citd'] = false;
    this.rep_sen_data_y5.citd['var_sel_disable'] = false;

    for (const sal of data.variablecost.salaries) {  // Staff Salaries
      if (sal.value === true) {
        this.rep_sen_data_y5.staf_sal['var_sel_disable'] = true;
      }
    }

    if (data.fixedcost.renovation.value === true) { // Renovation
      this.rep_sen_data_y5.renovat['var_sel_disable'] = true;
    }

    for (const sal of data.fixedcost.citd) {  // customised it
      if (sal.value === true) {
        this.rep_sen_data_y5.citd['var_sel_disable'] = true;
      }
    }
    /*if (data.fixedcost.citd.value === true || data.fixedcost.citd.length > 0) { // citd
      this.rep_sen_data_y5.citd['var_sel_disable'] = true;
    }*/

    if (data.fixedcost.fe.value === true) { // Furniture / Equipment
      this.rep_sen_data_y5.furnt_equp['var_sel_disable'] = true;
    }

    if (data.variablecost.logistics.value === true) { // Logistics
      this.rep_sen_data_y5.logistics['var_sel_disable'] = true;
    }

    if (data.fixedcost.wis.value === true) { // website / it
      this.rep_sen_data_y5.website_it_sw['var_sel_disable'] = true;
    }

    /* display Marketing sales or service */
    for (const sal of data.variablecost.marketing) {  // Marketing
      if (sal.value === true) {
        this.rep_sen_data_y5.marketing['var_sel_disable'] = true;
      }
    }
    for (const sal of data.variablecost.sales) {  // Sales
      if (sal.value === true) {
        this.rep_sen_data_y5.marketing['var_sel_disable'] = true;
      }
    }
    for (const sal of data.variablecost.service) {  // Service
      if (sal.value === true) {
        this.rep_sen_data_y5.marketing['var_sel_disable'] = true;
      }
    }
    /*end display Marketing sales or service */

    if (data.cogs.cost.value === true || data.cogs.unitcost.value === true) { // cogs
      this.rep_sen_data_y5.cogs['var_sel_disable'] = true;
    }
    if (data.revenue.other.value === true) { // Other revenue
      this.rep_sen_data_y5.base_case['var_other_sel_disable'] = true;

    }
    for (const sal of data.revenue.ps) {  // Sales: Products/Services
      if (sal.value === true) {
        this.rep_sen_data_y5.base_case['var_sales_sel_disable'] = true;
      }
    }
    if (this.rep_sen_data_y5.base_case['var_sales_sel_disable'] === true ||
      this.rep_sen_data_y5.base_case['var_other_sel_disable'] === true) {
      this.rep_sen_data_y5.base_case['var_sel_disable'] = true;
    }

    if (this.rep_sen_data_y5.base_case['var_sel_disable'] === true ||
      this.rep_sen_data_y5.renovat['var_sel_disable'] === true ||
      this.rep_sen_data_y5.furnt_equp['var_sel_disable'] === true) {
      this.rep_sen_data_y5.base_case['var_sale_reno_fur_disable'] = true;
    }

    if (this.rep_sen_data_y5.base_case['var_sel_disable'] === true ||
      this.rep_sen_data_y5.staf_sal['var_sel_disable'] === true) {
      this.rep_sen_data_y5.base_case['var_sale_staff_disable'] = true;
    }
    // console.log('reportsensiticve', this.rep_sen_data_y5);
  }

  shareReport() {
    this.user.shareUserReport(this.rep_sen_data_y5, 'sensitive_y5').subscribe(
      data => {
        if (data.status) {
          this.user.report_notfn = data.slug;
          this.router.navigate(['/report/shared-successfully']);
        }
      }
    );
  }
  getDelayedSales(data_copy) {
    const data = JSON.parse(JSON.stringify(data_copy));
    const acc_dur = this.rep_sen_data_y5.sal_rev_acc_m.value;
    let p = 0;
   // console.log ('before data_copy', data );
    // const report_data1 = this.y1_report.getYear1BaseReportData(data);
    for (const ps of data.revenue.ps) {
      let q = 0;
      let param1 = 0;
      let param2 = 0;

      for (const sales of ps.revenue_cost.sales) {
      //  console.log('sdsdds', sales.value);
        param1 += this.numOr0(sales.value); // total sales first year
        if (data_copy.revenue.ps[p].revenue_cost.sales[q - acc_dur]) {
          // tslint:disable-next-line:max-line-length
          data_copy.revenue.ps[p].revenue_cost.sales[q].value =  data.revenue.ps[p].revenue_cost.sales[q - acc_dur].value;

          // tslint:disable-next-line:max-line-length
          param2 += this.numOr0(data.revenue.ps[p].revenue_cost.sales[q - acc_dur].value); // Base Case Inputs are Over Written with Sensitivity Inputs

        } else {
          data_copy.revenue.ps[p].revenue_cost.sales[q].value = 0; // sales.value
        }
        q++;
      }



    //   q = 0;
    //   for (const sales of ps.revenue_cost_y5.sales) {
    //     let val = 0;
    //     if (q === 0) {
    //       val = param2;
    //       // continue;
    //       // (param1 - param2) + ((sales.value / 12) * (12 - acc_dur));
    //     } else if (q === 1) {

    //       const  multiply = (( 12 + acc_dur * -1) / 12);
    //       val = ((param1) - param2 + (
    //         (data.revenue.ps[p].revenue_cost_y5.sales[q - 1].value * multiply )));

    //      //  console.log('param1 param2 values3 ------------------------>', val );

    //      } else {

    //       const  multiply = (( 12 + acc_dur * -1) / 12);
    //       val = ((data.revenue.ps[p].revenue_cost_y5.sales[q].value) * multiply) +
    //        (data.revenue.ps[p].revenue_cost_y5.sales[q - 1].value * (acc_dur / 12) );

    //     }
    //     console.log('-------------->', p , q , val);
    // data_copy.revenue.ps[p].revenue_cost_y5.sales[q].value = this.makeRoundvalue(val);
    //   q++;

    // ----------------------------------------------------


    q = 0;
    for (const sales of ps.revenue_cost_y5.sales) {
      let val = 0;
      if (q === 0) {

        const  multiply = (( 12 + acc_dur * -1) / 12);
        val = ((param1) - param2 + (
          (data.revenue.ps[p].revenue_cost_y5.sales[q].value * multiply )));

       //  console.log('param1 param2 values3 ------------------------>', val );

       } else {

        const  multiply = (( 12 + acc_dur * -1) / 12);
        val = ((data.revenue.ps[p].revenue_cost_y5.sales[q].value) * multiply) +
         (data.revenue.ps[p].revenue_cost_y5.sales[q - 1].value * (acc_dur / 12) );

      }
     // console.log('-------------->', p , q , val);
  data_copy.revenue.ps[p].revenue_cost_y5.sales[q].value = val;
    q++;

    // -------------------------------------------------------


  //      console.log('elvee revenue_revenue_cost_y5', data_copy.revenue.ps[p].revenue_cost_y5.sales);


      }
       p++;
    }
    // console.log('after data_copy -------------->', data_copy);
    return data_copy;
  }

  rowSumMDArray(arr) {
    const result = [0, 0, 0, 0, 0];
    arr.forEach(function (value, i) {
      // console.log( 'value', arr );
      value.forEach(function (elem, j) {
        result[j] += elem;
      });
    });
    return result;
  }

  SalesRevenueDelay(data) {
    const data_copy = this.getDelayedSales(JSON.parse(JSON.stringify(data)));

   // console.log('<<<<<<<<<<<<<<<<<<<< data_copy', data_copy);

    const base_case = this.report_service.calculateTAXY1Y5(data_copy);

    this.rep_sen_data_y5['sal_rev_acc_m'].result = base_case.with_deci_cf_a_tax;
  }
  makeRoundvalue(value) {
    return Math.round(value);
  }
  SalesRevenueDelayPlusRenoFurn(data) {
    let data_copy = this.getDelayedSales(JSON.parse(JSON.stringify(data)));
    data_copy = this.getFurnitureAdjustedData(data_copy);
    data_copy = this.getRenovationAdjustedData(data_copy);

    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['sal_rev1'].result = base_case.with_deci_cf_a_tax;
  }
  SalesRevenueDelayPlusStaffSal(data) {
    let data_copy = this.getDelayedSales(JSON.parse(JSON.stringify(data)));
    data_copy = this.getStafAdjustedSal(data_copy);

    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['sal_rev2'].result = base_case.with_deci_cf_a_tax;
  }
  setExposureAndBreakevenValue(data, y1senrepdata) {
    const y1_report = this.sensitive_y1.getSensitiveYearOneReport(data);

    for (const key of this.rep_sen_data_keys) {

      console.log ('YYYYYYYYYYYYYYYYYYYY', this.rep_sen_data_y5[key].ccf_result);
      let breakeven_yr: any = 0;
      let last_neg = 0;
      let count = 0;
      let breakeven_mnth: any = '0';

      for (const val of this.rep_sen_data_y5[key].ccf_result) {


        if (val < 0) {
          last_neg = count;
          breakeven_yr++;
        }
        count++;
      }
      if (breakeven_yr <= 4 && breakeven_yr >= 1) {
        let cnt_step = 0;
        if (this.rep_sen_data_y5[key].result[last_neg + 1]) {
          cnt_step = 1;
        }
        breakeven_mnth = Math.round(
          (this.rep_sen_data_y5[key].ccf_result[last_neg] / this.rep_sen_data_y5[key].result[last_neg + cnt_step]) * 12);
        if (this.rep_sen_data_y5[key].ccf_result[last_neg] < 0) {
          breakeven_mnth = breakeven_mnth * (-1);
        }
      } else if (breakeven_yr > 4) {
        breakeven_yr = 'More than 5 Years';
        breakeven_mnth  = 'NA';
      } else if (breakeven_yr === 0) {
        let res = 0;
    //    for (const val of y1senrepdata[key].ccf_result) { old jobin
        for (const val of y1_report[key].ccf_result) {
            if (val < 0) {
              res++;
            }
        }
        breakeven_mnth = res;
      }

      // console.log('y1_report cashcum', key , y1_report[key].ccf_result);
      // console.log('y1_report', key , this.rep_sen_data_y5[key].ccf_result);
    //  const min_val = Math.round(Math.min(Math.min(...y1_report[key].ccf_result), Math.min(...this.rep_sen_data_y5[key].ccf_result)));
    let  min_val_negative = 0;
    const min_val = Math.round(Math.min(Math.min(...y1_report[key].ccf_result), Math.min(...this.rep_sen_data_y5[key].ccf_result)));

    /* if (min_val === NaN) {
        min_val = 0;
      } */

      if (min_val > 0) {
        min_val_negative = 0;
      } else {
        min_val_negative = min_val;
      }

      if ( breakeven_mnth > 12) {
        breakeven_mnth =  12;
      } else if  ( breakeven_mnth < 0  || isNaN(breakeven_mnth)) {
        breakeven_mnth =  'NA';
      }

      console.log('min_val_negative', min_val_negative);
      // tslint:disable-next-line:max-line-length
      this.rep_sen_data_y5[key].exp_breakeven = [min_val_negative.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), breakeven_yr, breakeven_mnth];
    }
    console.log('setExposurenew_rep_sen_data_y5', this.rep_sen_data_y5);

  }
  setCompareWithBaseCaseValue() {
    const q = (this.rep_sen_data_y5['base_case'].result.reduce((a, b) => a + b, 0));
    for (const key of this.rep_sen_data_keys) {
      // console.log( key, this.rep_sen_data_y5[key]);
      const p = this.rep_sen_data_y5[key].result.reduce((a, b) => a + b, 0);
      this.rep_sen_data_y5[key]['compare_base_ratio'] = Math.round(p * 100 / q);
      if (!this.rep_sen_data_y5[key].compare_base_ratio) {
        this.rep_sen_data_y5[key].compare_base_ratio = 0;
      }
    }
  }
  SalesRevenueAccelerate(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    const data1 = JSON.parse(JSON.stringify(data_copy));
    const acc_dur = this.rep_sen_data_y5.sal_rev_acc_a.value;
    let p = 0;
    for (const ps of data1.revenue.ps) {
      let q = 0;
      const y2avg = data.revenue.ps[p].revenue_cost_y5.sales[0].value / 12;
      for (const sales of ps.revenue_cost.sales) {
        if (data1.revenue.ps[p].revenue_cost.sales[q + acc_dur]) {
          data_copy.revenue.ps[p].revenue_cost.sales[q].value =
            data1.revenue.ps[p].revenue_cost.sales[q + acc_dur].value;
        } else if ((q + acc_dur) > 11) {
          data_copy.revenue.ps[p].revenue_cost.sales[q].value = y2avg;
        } else {
          data_copy.revenue.ps[p].revenue_cost.sales[q].value = sales.value;
        }
        q++;
      }
      q = 0;
      for (const sales of ps.revenue_cost_y5.sales) {
        if (data_copy.revenue.ps[p].revenue_cost_y5.sales[q + 1]) {
          let val = ((data_copy.revenue.ps[p].revenue_cost_y5.sales[q].value) / 12 * 11);
          val += ((data_copy.revenue.ps[p].revenue_cost_y5.sales[q + 1].value) / 12 * 1);
          data_copy.revenue.ps[p].revenue_cost_y5.sales[q].value = val;
        }
        q++;
      }
      p++;
    }
    // console.log('delayed data -----', data_copy);
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['sal_rev_acc_a'].result = base_case.with_deci_cf_a_tax;
  }
  calculateCOGSAdjustments(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    let p = 0;
    for (const ps of data_copy.revenue.ps) {
      if ( this.numOr0(data.cogs.type) === 1 ) {
        data_copy.revenue.ps[p].revenue_cost.unit_monthly_cost = (ps.revenue_cost.unit_monthly_cost * (1 + rep_sen_data_y5.cogs.value));
      } else {
        let z = 0;
        for (const res of data_copy.revenue.ps[p].revenue_cost.monthly_cost) {
          data_copy.revenue.ps[p].revenue_cost.monthly_cost[z].value = (res.value) * (1 + rep_sen_data_y5.cogs.value);
          z++;
        }
        // data_copy.revenue.ps[p].revenue_cost.unit_monthly_cost = (ps.revenue_cost.unit_monthly_cost * (1 + rep_sen_data_y5.cogs.value));
      }
      let k = 0;
      for (const psy5 of ps.revenue_cost_y5.unit_yearly_cost) {
        data_copy.revenue.ps[p].revenue_cost_y5.unit_yearly_cost[k].value = this.numOr0(psy5.value) * (1 + rep_sen_data_y5.cogs.value);
        data_copy.revenue.ps[p].revenue_cost_y5.yearly_cost[k].value =
          data_copy.revenue.ps[p].revenue_cost_y5.yearly_cost[k].value *
          (1 + rep_sen_data_y5.cogs.value);
        k++;
      }
      p++;
    }
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['cogs'].result = base_case.with_deci_cf_a_tax;
  }

  setGraphData() {
    const repdat = this.rep_sen_data_y5;
    this.chartData3 = [];
    this.chartData4 = [];

    const chartData3constant = [
      { data: repdat.base_case.result, label: 'Base Case', fill: false, hidegraph: true },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_a.result, label: repdat.sal_rev_a.label + ' + ' + (repdat.sal_rev_a.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.sal_rev_m.result, label: repdat.sal_rev_m.label + ' - ' + (repdat.sal_rev_m.value * 100) + '%', fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_a.result, label: repdat.sal_rev_acc_a.label + repdat.sal_rev_acc_a.value + repdat.sal_rev_acc_a.unit, fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
    //  { data: repdat.sal_rev_acc_m.result, label: repdat.sal_rev_acc_m.label + ' -' + repdat.sal_rev_acc_m.value + repdat.sal_rev_acc_m.unit, fill: false, hidegraph: repdat.base_case['var_sel_disable'] },
    ];

    for (const singlerepadpated of chartData3constant) {
      if (singlerepadpated.hidegraph === true) {
        this.chartData3.push(singlerepadpated);  // chart variable
      }
    }



    const chartData4constant = [
      { data: repdat.base_case.result, label: 'Base Case', fill: false, hidegraph: true },
      // tslint:disable-next-line:max-line-length
      { data: repdat.cogs.result, label: repdat.cogs.label + '+' + (repdat.cogs.value * 100) + '%', fill: false, hidegraph: repdat.cogs['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.staf_sal.result, label: repdat.staf_sal.label + '+' + (repdat.staf_sal.value * 100) + '%', fill: false, hidegraph: repdat.staf_sal['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.renovat.result, label: repdat.renovat.label + '+' + (repdat.renovat.value * 100) + '%' + repdat.renovat.unit, fill: false, hidegraph: repdat.renovat['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.furnt_equp.result, label: repdat.furnt_equp.label + '+' + (repdat.furnt_equp.value * 100) + '%' + repdat.furnt_equp.unit, fill: false, hidegraph: repdat.furnt_equp['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.logistics.result, label: repdat.logistics.label + '+' + (repdat.logistics.value * 100) + '%' + repdat.logistics.unit, fill: false, hidegraph: repdat.logistics['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.citd.result, label: repdat.citd.label + '+' + (repdat.citd.value * 100) + '%' + repdat.citd.unit, fill: false, hidegraph: repdat.citd['var_sel_disable'] },
      // tslint:disable-next-line:max-line-length
      { data: repdat.marketing.result, label: repdat.marketing.label + '+' + (repdat.marketing.value * 100) + '%', fill: false, hidegraph: repdat.marketing['var_sel_disable'] },
    ];

    for (const singlerepadpated of chartData4constant) {
      if (singlerepadpated.hidegraph === true) {
        this.chartData4.push(singlerepadpated);  // chart variable
      }
    }
    // console.log('logsssss', this.chartData4);

  }

  findBasecase(data) {
    const base_case = this.report_service.calculateTAXY1Y5(data);
   // console.log('base_casecalculateTAXY1Y5', base_case);
    for (let i = 0; i < 5; i++) {

     // this.rep_sen_data_y5['base_case'].result[i] = Math.round(base_case.with_deci_cf_a_tax[i]);
      this.rep_sen_data_y5['base_case'].result[i] += base_case.with_deci_cf_a_tax[i];
    }
  }
  calculateWebsiteAdjustments(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    {
      let i = 0;
      if (data_copy.fixedcost && data_copy.fixedcost.wis && data_copy.fixedcost.wis.fixed_cost_y5) {
        for (const ps of data_copy.fixedcost.wis.fixed_cost_y5.val) {
          data_copy.fixedcost.wis.fixed_cost_y5.val[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.website_it_sw.value));
          i++;
        }
      }
    }
    {
      let i = 0;
      if (data_copy.fixedcost && data_copy.fixedcost.wis && data_copy.fixedcost.wis.fixed_cost) {
        for (const ps of data_copy.fixedcost.wis.fixed_cost.fc) {
          data_copy.fixedcost.wis.fixed_cost.fc[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.website_it_sw.value));
          i++;
        }
      }

    }
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['website_it_sw'].result = base_case.with_deci_cf_a_tax;
  }
  calculateLogisticsAdjustments(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    {
      let i = 0;
      if (data_copy.variablecost && data_copy.variablecost.logistics && data_copy.variablecost.logistics.variable_cost_y5) {
        for (const ps of data_copy.variablecost.logistics.variable_cost_y5.value) {
          data_copy.variablecost.logistics.variable_cost_y5.value[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.logistics.value));
          i++;
        }
      }

    }
    {
      let i = 0;
      if (data_copy.variablecost && data_copy.variablecost.logistics && data_copy.variablecost.logistics.variable_cost) {
        for (const ps of data_copy.variablecost.logistics.variable_cost.val) {
          data_copy.variablecost.logistics.variable_cost.val[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.logistics.value));
          i++;
        }
      }

    }
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['logistics'].result = base_case.with_deci_cf_a_tax;
  }
  getFurnitureAdjustedData(data_copy) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    {
      let i = 0;
      if (data_copy.fixedcost && data_copy.fixedcost.fe && data_copy.fixedcost.fe.fixed_cost_y5) {
        for (const ps of data_copy.fixedcost.fe.fixed_cost_y5.val) {
          data_copy.fixedcost.fe.fixed_cost_y5.val[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.furnt_equp.value));
          i++;
        }
      }

    }
    {
      let i = 0;
      if (data_copy.fixedcost && data_copy.fixedcost.fe && data_copy.fixedcost.fe.fixed_cost) {
        for (const ps of data_copy.fixedcost.fe.fixed_cost.fc) {
          data_copy.fixedcost.fe.fixed_cost.fc[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.furnt_equp.value));
          i++;
        }
      }

    }
    return data_copy;
  }
  calculateFurnitureAdjustments(data) {
    const data_copy = this.getFurnitureAdjustedData(JSON.parse(JSON.stringify(data)));
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['furnt_equp'].result = base_case.with_deci_cf_a_tax;
  }
  customizedItPhase2(data) {
    const data_copy = this.getCustomizedITAdjustedData(JSON.parse(JSON.stringify(data)));
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    console.log('base_case',base_case);
    this.rep_sen_data_y5['citd'].result = base_case.with_deci_cf_a_tax;
  }
  getCustomizedITAdjustedData(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    // {
    //   let i = 0;
    //   if (data_copy.fixedcost && data_copy.fixedcost.citd && data_copy.fixedcost.citd.fixed_cost_y5) {
    //     for (const ps of data_copy.fixedcost.citd.fixed_cost_y5.val) {
    //       data_copy.fixedcost.citd.fixed_cost_y5.val[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.citd.value));
    //       i++;
    //     }
    //   }

    // }
    // {
    //   let i = 0;
    //   if (data_copy.fixedcost && data_copy.fixedcost.citd && data_copy.fixedcost.citd.fixed_cost) {
    //     for (const ps of data_copy.fixedcost.citd.fixed_cost.fc) {
    //       data_copy.fixedcost.citd.fixed_cost.fc[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.citd.value));
    //       i++;
    //     }
    //   }

    // }

    let i = 0;

    if(data_copy.fixedcost.citd.length){ // 50% Of citd Value
        for (const ps of data_copy.fixedcost.citd) {
          console.log('currentcitddata_copy',ps);
          let j = 0;
          if (ps.fixed_cost) {
            for (const citd of ps.fixed_cost.fc) {

              // tslint:disable-next-line:max-line-length
              data_copy.fixedcost.citd[i].fixed_cost.fc[j].value = citd.value * (1 + this.numOr0(rep_sen_data_y5.citd.value));
              j++;
            }
          }

          j = 0;
          if (ps.fixed_cost_y5) {
            for (const citd of ps.fixed_cost_y5.val) {
              // tslint:disable-next-line:max-line-length
              data_copy.fixedcost.citd[i].fixed_cost_y5.val[j].value = citd.value * (1 + this.numOr0(rep_sen_data_y5.citd.value));
              j++;
            }
          }

          i++;
        }
    }






    console.log('current data_copy',data_copy);
    return data_copy;
  }
  calculateMarketingAdjustmentsOld(data) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    let i = 0;
    console.log('data_copy-----------------------', data_copy);
    for (const ps of data_copy.variablecost.marketing) {
      let j = 0;
      if (ps.variable_cost) {
        for (const marketing of ps.variable_cost.val) {
          // tslint:disable-next-line:max-line-length

          // tslint:disable-next-line:max-line-length
          console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD' + marketing.value + '*' + (1 + this.numOr0(rep_sen_data_y5.marketing.value)) + rep_sen_data_y5.marketing.value);
          // tslint:disable-next-line:max-line-length
          data_copy.variablecost.marketing[i].variable_cost.val[j].value = marketing.value * (1 + this.numOr0(rep_sen_data_y5.marketing.value));
          j++;
        }
      }
      console.log('data_copy-----------------------', data_copy);
      j = 0;
      if (ps.variable_cost_y5) {
        for (const marketing of ps.variable_cost_y5.value) {
          // tslint:disable-next-line:max-line-length
          data_copy.variablecost.marketing[i].variable_cost_y5.value[j].value = marketing.value * (1 + this.numOr0(rep_sen_data_y5.marketing.value));
          j++;
        }
      }

      i++;
    }

    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['marketing'].result = base_case.with_deci_cf_a_tax;
  }

  calculateMarketingAdjustments(data) {

    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const data_copy = JSON.parse(JSON.stringify(data));
    const year_total = [0, 0, 0, 0, 0];
    let i = 0;
    console.clear();

    for (const ps of data_copy.variablecost.marketing) {
      let j = 0;
      if (ps.variable_cost && ps.value ) {
        for (const marketing of ps.variable_cost.val) {
          year_total[0] += this.numOr0(marketing.value);
          j++;
        }
      }
      j = 0;
      if (ps.variable_cost_y5 && ps.value ) {
        for (const marketing of ps.variable_cost_y5.value) {
          year_total[j + 1] += this.numOr0(marketing.value);
          j++;
        }
      }
      i++;
    }
    for (const ps of data_copy.variablecost.sales) {
      let j = 0;
      if (ps.variable_cost && ps.value ) {
        for (const marketing of ps.variable_cost.val) {
          year_total[0] += this.numOr0(marketing.value);
          j++;
        }
      }
      j = 0;
      if (ps.variable_cost_y5 && ps.value ) {
        for (const marketing of ps.variable_cost_y5.value) {
          year_total[j + 1] += this.numOr0(marketing.value);
          j++;
        }
      }
      i++;
    }
    for (const ps of data_copy.variablecost.service) {
      let j = 0;
      if (ps.variable_cost && ps.value ) {
        for (const marketing of ps.variable_cost.val) {
          year_total[0] += this.numOr0(marketing.value);
          j++;
        }
      }
      j = 0;
      if (ps.variable_cost_y5 && ps.value ) {
        for (const marketing of ps.variable_cost_y5.value) {
          year_total[j + 1] += this.numOr0(marketing.value);
          j++;
        }
      }
      i++;
    }

    const base_case = this.report_service.calculateTAXY1Y5(data_copy, 2, rep_sen_data_y5.marketing.value);

    console.log('NEWWWWWWWWWWWWWWWWWWWWW', base_case);

    this.rep_sen_data_y5['marketing'].result = base_case.cf_a_tax_with_decimal;
    let k = 0;
    for ( const markTotal of year_total) {
      const ren_persnt = (markTotal * rep_sen_data_y5.marketing.value );

      rep_sen_data_y5.marketing.result[k] -= Math.round(ren_persnt);
      k++;
    }
  }


  calculateCumCashflow(data_copy) {

    // ------------------  New code added -----------------------------
    const ccf_result_new = [0, 0 , 0 , 0 , 0];
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);

    const ccfnew = 0;
    for (let i = 0; i < 5; i++) {
       ccf_result_new[i] = Math.round(ccfnew + base_case.with_deci_cf_a_tax[i]);
    }
  // ------------------ End new code added -----------------------------

    console.log('HHHHHHHHHHHHHH', base_case);
    for (const key of this.rep_sen_data_keys) {
      let total = 0;
      let i = 0;
      for (const val of this.rep_sen_data_y5[key].result) {

        console.log('VALLLLLLLLL',this.rep_sen_data_y5['marketing'].result);
        total += val;
       // this.rep_sen_data_y5[key].ccf_result[i] = ccf_result_new[i];
       if (key === 'staf_sal') {
        this.rep_sen_data_y5[key].ccf_result[i] += Math.round(total - 0.001);
       } else {
        this.rep_sen_data_y5[key].ccf_result[i] += Math.round(total);
       }

        i++;
      }

    }
    console.log('this.rep_sen_data_keystotal', this.rep_sen_data_y5);
  }



  AdddecimaltoArray(value) {
    let decimalresult = 0;
      decimalresult = parseFloat(value.toFixed(2));

    return decimalresult;
  }

  getStafAdjustedSal(data_copy) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    let i = 0;
    for (const ps of data_copy.variablecost.salaries) {
      let j = 0;
      if (ps.variable_cost) {
        for (const salaries of ps.variable_cost.val) {
          // tslint:disable-next-line:max-line-length
          data_copy.variablecost.salaries[i].variable_cost.val[j].value = salaries.value * (1 + this.numOr0(rep_sen_data_y5.staf_sal.value));
          j++;
        }
      }

      j = 0;
      if (ps.variable_cost_y5) {
        for (const salaries of ps.variable_cost_y5.value) {
          // tslint:disable-next-line:max-line-length
          data_copy.variablecost.salaries[i].variable_cost_y5.value[j].value = salaries.value * (1 + this.numOr0(rep_sen_data_y5.staf_sal.value));
          j++;
        }
      }

      i++;
    }
    return data_copy;
  }
  calculateStaffSalAdjustments(data) {
    const data_copy = this.getStafAdjustedSal(JSON.parse(JSON.stringify(data)));
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['staf_sal'].result = base_case.with_deci_cf_a_tax;
  }

  getRenovationAdjustedData(data_copy) {
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    {
      let i = 0;
      if (data_copy.fixedcost.renovation && data_copy.fixedcost.renovation.fixed_cost_y5) {
        for (const ps of data_copy.fixedcost.renovation.fixed_cost_y5.val) {
          data_copy.fixedcost.renovation.fixed_cost_y5.val[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.renovat.value));
          i++;
        }
      }
    }
    {
      let i = 0;
      if (data_copy.fixedcost.renovation && data_copy.fixedcost.renovation.fixed_cost) {
        for (const ps of data_copy.fixedcost.renovation.fixed_cost.fc) {
          data_copy.fixedcost.renovation.fixed_cost.fc[i].value = ps.value * (1 + this.numOr0(rep_sen_data_y5.renovat.value));
          i++;
        }
      }
    }
    return data_copy;
  }
  calculateRenovationAdjustments(data) {
    const data_copy = this.getRenovationAdjustedData(JSON.parse(JSON.stringify(data)));
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['renovat'].result = base_case.with_deci_cf_a_tax;
  }
  calculateSalesRevenueAdjustments(data) {
    let i = 0;
    const data_copy = JSON.parse(JSON.stringify(data));
    const data_copy2 = JSON.parse(JSON.stringify(data));
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    for (const ps of data_copy.revenue.ps) {
      let j = 0;
      for (let k = 0; k < 12; k++) {
        let s_inc = 0;
        if (data_copy.revenue.ps[i].revenue_cost && data_copy.revenue.ps && rep_sen_data_y5.sal_rev_a) {
          s_inc = (this.numOr0(data_copy.revenue.ps[i].revenue_cost.sales[k].value)) * this.numOr0(rep_sen_data_y5.sal_rev_a.value);
        }
        // const s_inc = (this.numOr0(data_copy.revenue.ps[i].revenue_cost.sales[k].value)) * this.numOr0(rep_sen_data_y5.sal_rev_a.value);
        if (data.revenue.sc.value) {
          // s_inc += Math.round( ( s_inc / 100 ) * this.fisc_inputs.service_charge );
        }
        if (data_copy.revenue.ps && data_copy.revenue.ps[i].revenue_cost) {
          data_copy.revenue.ps[i].revenue_cost.sales[k].value += s_inc;
          data_copy2.revenue.ps[i].revenue_cost.sales[k].value -= s_inc;
        }
      }
      if (ps.revenue_cost_y5) {
        for (const sales of ps.revenue_cost_y5.sales) {
          const s_inc = (this.numOr0(sales.value)) * this.numOr0(rep_sen_data_y5.sal_rev_a.value);
          if (data.revenue.sc.value) {
            // s_inc += Math.round( ( s_inc / 100 ) * this.fisc_inputs.service_charge );
          }
          // this.numOr0(ps.revenue_cost_y5.unit_price[i].value);
          data_copy.revenue.ps[i].revenue_cost_y5.sales[j].value += s_inc;
          data_copy2.revenue.ps[i].revenue_cost_y5.sales[j].value -= s_inc;
          j++;
        }
      }

      i++;
    }
    const base_case = this.report_service.calculateTAXY1Y5(data_copy);
    this.rep_sen_data_y5['sal_rev_a'].result = base_case.with_deci_cf_a_tax;
    const base_case2 = this.report_service.calculateTAXY1Y5(data_copy2);

    this.rep_sen_data_y5['sal_rev_m'].result = base_case2.with_deci_cf_a_tax;
  }
  calculateSalesRevenue(data) {
    let i = 0;
    const rep_sen_data_y5 = this.rep_sen_data_y5;
    const tppc = data.variablecost.tppc.variable_cost_y5.value;
    for (const ps of data.revenue.ps) {
      let j = 1;
      for (const sales of ps.revenue_cost_y5.sales) {
        const s_inc = (this.numOr0(sales.value)) * this.numOr0(rep_sen_data_y5.sal_rev_a.value) *
          this.numOr0(ps.revenue_cost_y5.unit_price[i].value);
        let sales_inc = s_inc;
        // sales_inc = (sales_inc - ((sales_inc * (tppc / 100))));
        if (data.cogs.type === '1') {
          sales_inc -= (ps.revenue_cost_y5.unit_yearly_cost[i].value) * (this.numOr0(sales.value) *
            this.numOr0(rep_sen_data_y5.sal_rev_a.value));
        }
        if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          sales_inc -=  tppc;
        } else {
          sales_inc -= (s_inc / 100) * tppc;
        }
        rep_sen_data_y5.sal_rev_a.result[j] += sales_inc;

        j++;
      }
      i++;
    }
  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  captureScreen() {
    this.user.captureScreen('ReportSensitivityYearOneToFive.pdf');
  }
  async captureScreenNew() {
    this.buttonClicked = 1;
    await this.user.delay(50);
    await this.user.captureScreenNew('ReportSensitivityYearOneToFive.pdf');
    this.buttonClicked = 2;
  }
  print_fns() {
    this.user.print_fns();
  }
}
