import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  constructor(private cms: CmsService) { }
  page_content: any;
  video_content: any;
  ngOnInit() {
    this.cms.getCmsByPage('cms/testimonials')
      .subscribe(page_content => { this.page_content = page_content; } );

    this.cms.getCmsByPage('cms/video_testimonials')
      .subscribe(video_content => { this.video_content = video_content; } );
  }

}
