import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ValidationService, AlertService, UserService } from '../services';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { GlobalsService } from '../services/globals.service';
import { SubscriptionService } from '../services/subscription.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'app-register-plan',
    templateUrl: './register-plan.component.html',
    styleUrls: ['./register-plan.component.css']
})
export class RegisterPlanComponent implements OnInit {
    submitted = false;
    comany_submited = false;
    plan: any;
    paymentPlan: any;
    selectedpaymentPlan: any;
    loading = false;
    token : string = '';
    recaptchaVerified = false;
    registerdisplay = false;
    payment_done = false;
    feildset1 = false;
    feildset2 = false;
    feildset3 = false;
    month_list = [];
    message: string;
    classmessages: string;
    emailverifyone: any;
    clientTokenURL: any;
    createPurchaseURL: any;
    paymentMethodNonce: any;
    initalvalue: any;
    allplanslist: any;
    industries = [];
    all_industries = [];
    all_countries = [];
    incorp_years = [];
    payment_title = 'Payment Details';
    btenabledStyle = {

        'padding': '0px 45px',
        'font-size': '16px',
        'color': '#0062b3',
        'background': '#fff',
        'height': '62px',
        'text-transform': 'uppercase',
        'border-radius': '50px',
        'border': '1px solid #fff',
        'cursor': 'pointer',
        'max-width': '166px',
        'margin-top': '30px',
        'display': 'inline-block',
        'line-height': '30px',
    };
    btdisabledStyle = {
        'padding': '0px 45px',
        'cursor': 'not-allowed',
        'font-size': '16px',
        'color': '#0062b3',
        'background': '#fff',
        'height': '62px',
        'text-transform': 'uppercase',
        'border-radius': '50px',
        'border': '1px solid #fff',
        'max-width': '166px',
        'margin-top': '30px',
        'display': 'inline-block',
        'line-height': '30px',
    };
    video_modal_src: any;
    autoplayvalue: boolean;
    video_url: string;
    metatag: any;
    createSignUpURL: any;
    selected_country: any;
    cmessage: any;
    couponStatus: boolean;
    plan_details: any;
    reg_coupon: any;

    constructor( private recaptchaV3Service: ReCaptchaV3Service,private route: ActivatedRoute, private http: HttpClient, private formBuilder: FormBuilder, private router: Router,
        private userService: UserService,
        private titleService: Title,
        private alertService: AlertService,
        private sanitizer: DomSanitizer,
        private meta: Meta,
        private globals: GlobalsService,
        private subscription: SubscriptionService) {
            this.plan_details = {
                'name': '',
                'subtitle': '',
                'onetime_text': '',
                'onetime_fee': '',
                'price': '',
                'currency_code': '',
                'currency_symbol': ''

            };
      // this.plan_details.name = 'sadsad';
            this.metatag = {
                name: 'description',
                // tslint:disable-next-line:max-line-length
                content: 'First Step in setting up a Kwik Look Screening Tool Account, to access the simple online Cash Flow Evaluation tool that allows SME entrepreneurs to quickly understand the potential profitability of their new business idea.',
              };
              this.meta.updateTag(this.metatag);
        this.initalvalue = 0;
        this.payment_title = 'Payment Details';
        this.userForm = this.formBuilder.group({
            'email': ['', [Validators.required, ValidationService.emailValidators], this.isEmailUnique.bind(this)],
            'password': ['', [Validators.required, ValidationService.passwordValidator]],
            'changepassword': ['', Validators.required],
            'CompanyName': ['', Validators.required],
            'companyindustry': ['', Validators.required],
            'country_sec': ['', Validators.required],
            'incorp_month': ['', Validators.required],
            'incorp_year': ['', Validators.required],
            'FirstName': ['', Validators.required],
            'LastName': ['', Validators.required],
            'token': ['', Validators.required],
            'plan': ['', Validators.required],
            'Cityoptional': '',
            'Address': ['', Validators.required],
            'StateProvinceoptional': '',
            'postalcode': ['', Validators.required],
            'phone': ['', Validators.required],
            'country2': ['Singapore', Validators.required],
            'other_country': [''],
            'selected_country': [''],
            'select_plan': [''],
            'coupon_code': [''] ,
            'is_applied': ['0'],
            'applied_coupon': ['']
        });
        this.emailverifyone = { email: '' };
        this.plan = this.route.snapshot.params.plan; // old code
        this.paymentPlan = this.route.snapshot.params.plan; // old code

         console.log('this.plan', this.plan);
         console.log('this.paymentPlan', this.paymentPlan);
         this.removeCoupon(this.plan); // new for removing coupon code

       /* this.subscription.getPlanDetails(this.plan, '').subscribe(data => {
             this.plan_details  =   data.results[0];
             console.log(this.plan_details);
        });*/
        this.globals.createRegisterPurchaseURL.subscribe(value => {
            if (value) {

        //        console.log('plan', value.registerSignUpURL);
                this.plan = value.registerSignUpURL;
                this.paymentPlan = value.registerSignUpURL;

        this.globals.registerPlanCoupon.subscribe( data => {
            if (data) {
                this.reg_coupon = data ;
            }
        });


        // elvee added new section

        if (this.initalvalue === 0 ) {
            this.userForm.get('select_plan').setValue('screening_tool');
            if (this.router.url.indexOf('business_viability_assessment') > -1) {
                this.userForm.get('select_plan').setValue('business_viability_assessment');
                this.globals.current_country.subscribe(cuurentcountry => {
                    if (value) {
                       this.paymentPlan =  this.plan = 'business_viability_assessment_' + cuurentcountry;
                     //  this.globals.setRegisterPurchaseURL( cuurentcountry.toLowerCase() , cuurentcountry , 5);
                    }
                });
            } else if (this.router.url.indexOf('evaluation_service') > -1) {
                this.userForm.get('select_plan').setValue('evaluation_service');
                this.globals.current_country.subscribe(cuurentcountry => {
                    if (value) {
                       this.paymentPlan =  this.plan = 'evaluation_service_' + cuurentcountry;
                    }
                });
            } else if (this.router.url.indexOf('evaluation_tool') > -1) {
                this.userForm.get('select_plan').setValue('evaluation_tool');
                this.globals.current_country.subscribe(cuurentcountry => {
                    if (value) {
                       this.paymentPlan =  this.plan = 'evaluation_tool_' + cuurentcountry;
                    }
                });
            }


        // elvee end new section


        this.subscription.getPlanDetails(this.plan, this.reg_coupon).subscribe(data => {
            this.plan_details  =   data.results[0];

            this.couponStatus    =    data.is_valid_coupon;
            if (this.couponStatus) {
                this.cmessage   =   'Coupon Applied. Discount of ' +
                this.plan_details.currency_code + this.plan_details.currency_symbol + data.discount
                + ' will be applied to the one time fee of ' + this.plan_details.currency_code + ' '
                + this.plan_details.currency_symbol + this.plan_details.onetime_fee;
            }

        });

    } else {
        this.onSelectPlanCountryChange(this.selectedpaymentPlan);
    }

        // elvee added new section

            }
        });


        this.clientTokenURL = environment.API_URL + 'bt/getclienttoken';
        this.createPurchaseURL = environment.API_URL + 'bt/subscription/' + this.plan + '/new/undefined';
        this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl('https://kwik-look.com/assets/images/logo.png');

        this.globals.current_country.subscribe(value => {
          if (value) {

            this.selected_country = value ;

          }
        });

    }
    ngOnInit() {

        this.userService.getAllIndustries().subscribe(industries => {
          this.all_industries = industries;
        });
        this.userService.getIncorpYearsANdCountries().subscribe(data => {
            this.all_countries = data.country;
            console.log('all countries', data);
            this.incorp_years = data.incorporation_year;
        });
        this.userService.getAllUserPlans().subscribe(data => {
            this.allplanslist = data;
        });

        this.month_list = this.userService.month_list;
        this.titleService.setTitle('Kwik Look - Register');
        let current_fs, next_fs, previous_fs; // fieldsets
        let left, opacity, scale; // fieldset properties which we will animate
        let animating; // flag to prevent quick multi-click glitches
        let fieldid;
        this.payment_done = false;
        this.paymentMethodNonce = '';

        this.globals.createRegisterPurchaseURL.subscribe(value => {
          if (value) {
            this.createPurchaseURL = value.registerPurchaseURL ;
            this.createSignUpURL = value.registerSignUpURL ;
          }
      });

        $('.next,.nextbtn').click(function () {

            fieldid = $(this).closest('fieldset').attr('id');

            console.log('fieldid----->',fieldid);

            /*   if (fieldid === 's2551') {
                   handleClick();
                  return false;
               } */

            if (animating) { return false; }
            animating = true;
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();


            console.log('next_fs----->',next_fs, 'current_fs',current_fs);


            // activate next step on progressbar using the index of next_fs
            $('#progressbar li').eq($('fieldset').index(next_fs)).addClass('active');
            // show the next fieldset
            next_fs.show();
            // hide the current fieldset with style
            current_fs.animate({ opacity: 1 }, {
                step: function (now, mx) {
                    // as the opacity of current_fs reduces to 0 - stored in "now"
                    // 1. scale current_fs down to 80%
                    scale = 1 - (1 - now) * 0.2;
                    // 2. bring next_fs from the right(50%)
                    left = (now * 50) + '%';
                    // 3. increase opacity of next_fs to 1 as it moves in
                    opacity = 1 - now;
                    //    current_fs.css({'position': 'absolute'});
                    // next_fs.css({'left': left, 'opacity': opacity});
                },
                duration: 50,
                complete: function () {
                    current_fs.hide();
                    animating = false;
                },
                // this comes from the custom easing plugin
                // easing: 'easeInOutBack'
            });
        });

        $('.previous').click(function () {
            if (animating) { return false; }
            animating = true;
            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();
            // de-activate current step on progressbar
            $('#progressbar li').eq($('fieldset').index(current_fs)).removeClass('active');
            // show the previous fieldset
            previous_fs.show();
            // hide the current fieldset with style
            current_fs.animate({ opacity: 1 }, {
                step: function (now, mx) {
                    // as the opacity of current_fs reduces to 0 - stored in "now"
                    // 1. scale previous_fs from 80% to 100%
                    scale = 0.8 + (1 - now) * 0.2;
                    // 2. take current_fs to the right(50%) - from 0%
                    left = ((1 - now) * 50) + '%';
                    // 3. increase opacity of previous_fs to 1 as it moves in
                    opacity = 1 - now;
                    // current_fs.css({'left': left});
                    // previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
                },
                duration: 800,
                complete: function () {
                    current_fs.hide();
                    animating = false;
                },
                // this comes from the custom easing plugin
                // easing: 'easeInOutBack'
            });
        });



        this.getRecaptchaToken('register');
        // throw new Error("Method not implemented.");
    }
    onCountryChange(event) {
      const newVal = event.target.value;
      this.industries = (this.all_industries).filter((a) => (a.country === newVal) );
    }
    // tslint:disable-next-line:member-ordering
    userForm: any; value: any;
    // tslint:disable-next-line:member-ordering
    userForm2: any;
    // tslint:disable-next-line:member-ordering
    countries: any;

 
    validateRecaptcha() {

        this.loading = true;
        
        this.userService.checkreCaptcha(this.token,'register').subscribe(
            data => {
      
              console.log('checkreCaptcha',data);
      
              if (data.status === 1) {
                // this.message = data.msg;
                // this.classmessages = 'success';
                // this.loading = false;
                // setTimeout(() => this.message = ''  , 3000);
                // this.submitted = false;
                this.recaptchaVerified = true;

                let current_fs, next_fs, previous_fs; // fieldsets
                let left, opacity, scale; // fieldset properties which we will animate
                let animating; // flag to prevent quick multi-click glitches
                let fieldid;
               
                  fieldid = $('.validatecaptcha').closest('fieldset').attr('id');

            console.log('fieldid----->',fieldid);

            /*   if (fieldid === 's2551') {
                   handleClick();
                  return false;
               } */

            if (animating) { return false; }
            animating = true;
            current_fs = $('.validatecaptcha').parent();
            next_fs = $('.validatecaptcha').parent().next();


            console.log('next_fs----->',next_fs, 'current_fs',current_fs);


            // activate next step on progressbar using the index of next_fs
            $('#progressbar li').eq($('fieldset').index(next_fs)).addClass('active');
            // show the next fieldset
            next_fs.show();
            // hide the current fieldset with style
            current_fs.animate({ opacity: 1 }, {
                step: function (now, mx) {
                    // as the opacity of current_fs reduces to 0 - stored in "now"
                    // 1. scale current_fs down to 80%
                    scale = 1 - (1 - now) * 0.2;
                    // 2. bring next_fs from the right(50%)
                    left = (now * 50) + '%';
                    // 3. increase opacity of next_fs to 1 as it moves in
                    opacity = 1 - now;
                    //    current_fs.css({'position': 'absolute'});
                    // next_fs.css({'left': left, 'opacity': opacity});
                },
                duration: 50,
                complete: function () {
                    current_fs.hide();
                    animating = false;
                },
                // this comes from the custom easing plugin
                // easing: 'easeInOutBack'
            });
                this.loading = false;

              } else {
                this.message = 'Your Response to the Captcha Appears to Be Invalid';
                this.classmessages = 'error';
                this.loading = false;
                this.recaptchaVerified = false;
                setTimeout(() => 
                window.location.reload()
                , 3000);
               // this.onReset();
                // setTimeout(() => this.message = ''  , 3000);
              }
              
            }
          );
    }

    getRecaptchaToken(action) {
     
    
        this.recaptchaV3Service.execute(action).subscribe(
          (token) => {
    
           console.log('token------>',action , token);
           this.token = token;
           this.recaptchaVerified = false;
    
          },
          (error) => {
    
            this.token = '';
            this.recaptchaVerified = false;
            console.log('error token------>');
    
          }
        );
    }

    isEmailUnique(control: FormControl) {
        this.emailverifyone.email = control.value;
        const q = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.userService.isEmailRegisterd(this.emailverifyone).subscribe(() => {
                    resolve(null);
                }, () => { resolve({ 'isEmailUnique': true }); });
            }, 1000);
        });
        return q;
    }
    get f() { return this.userForm.controls; }

    readRainbow(event) {
        this.value = event.target.id;
    }
    handleClick() {
        //  alert('sdsdsdsd');
    }
    hideHolder(e: any) {
        $(e.target).attr('data-placeholder', $(e.target).attr('placeholder'));
        $(e.target).attr('placeholder', '');
        return;
      }
      showHolder(e: any) {
        const txtval = $(e.target).val();
                if (txtval === '') {
                    $(e.target).attr('placeholder', $(e.target).data('placeholder'));
                }
      }
    onPaymentStatus(event) {

        this.payment_title = 'Processing Payment';
      //  alert(123);
        if (event.status === true) {
            this.paymentMethodNonce = event.paymentMethodNonce;
            this.payment_done = true;
            setTimeout(() => {
                this.onSubmit();
            }, 1000);
        } else {

            this.payment_title = 'Payment Details';
            this.paymentMethodNonce = false;
            this.payment_done = false; 
        }
    }
    onSubmit() {

        // console.log(this.userForm.value);
        if (!this.payment_done) {

            return;

        }
        this.message = '';
        this.classmessages = '';
        this.submitted = true;
        if (this.userForm.invalid) {
            alert('formerror');
            return;
        }

        this.loading = true;
        this.registerdisplay = true;
        this.userService.registerUser(this.userForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    if (data.id) {
                        // this.userService.setUserObject(data);
                        this.message = data.sucess;
                        this.classmessages = 'success';
                        setTimeout(() => this.router.navigate(['/user']), 30000);
                        this.globals.setRegisterCoupon('');

                    } else {
                        this.message = data.error;
                        this.loading = false;
                        this.classmessages = 'error';
                        $('.col-md-6.darkbule fieldset').hide();
                        $('.col-md-6.darkbule fieldset:first-child').show();
                        this.registerdisplay = false;
                        this.globals.setRegisterCoupon('');
                    }
                    this.payment_title = 'Payment Details';
                    setTimeout(() => this.message = '', 30000);
                    // this.alertService.success('Registration successful', true);
                    // this.router.navigate(['/login']);
                    // this.loading = false;
                    // this.submitted = false;
                },
                error => {
                    //  this.alertService.error(error);
                    this.message = error.error;
                    this.loading = false;
                    this.registerdisplay = false;
                    this.classmessages = 'error';
                    $('.col-md-6.darkbule fieldset').hide();
                    $('.col-md-6.darkbule fieldset:first-child').show();
                    this.payment_title = 'Payment Details';
                    setTimeout(() => this.message = '', 20000);
                    setTimeout(() => this.router.navigate(['/']), 5);
                    this.registerdisplay = false;
                    this.globals.setRegisterCoupon('');
                });
    }

    closeModal() {
        this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl('');
        this.autoplayvalue = false;
    }
    openModal() {
        const vurls = this.userService.getVideoUrls();
        this.video_url = 'https://www.youtube.com/embed/' + vurls['sing_up_trail'] + '?autoplay=1';
        this.video_modal_src = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
        this.autoplayvalue = true;
    }

    CheckCouponValid( plan: any   ) {


        const coupon    =   this.userForm.get('coupon_code').value;
        const email     =   this.userForm.get('email').value;
         if ( coupon) {
           this.subscription.checkCouponValid(plan, coupon, email).subscribe(
             data => {
               if ( data.status === 1 ) {
                    console.log('asdasdas', data);
                    this.globals.setRegisterCoupon(coupon);
                    this.userForm.patchValue({
                        'is_applied': 1,
                        'applied_coupon': coupon
                    });
                   this.couponStatus    =   true;
                 //  this.registerdisplay = true;
                   this.cmessage   =   'Coupon Applied. Discount of ' +
                   data.plan.country_code + ' ' + data.plan.currency_symbol + data.plan.discount
                   + ' will be applied to the one time fee of ' + data.plan.country_code + ' '
                   + data.plan.currency_symbol + data.plan.onetime_fee;
               } else {
                    this.couponStatus    =   false;
                    this.cmessage = data.message;
               }
             },
             //   error => { }
           );
         } else {
             this.couponStatus    =   false;
             this.registerdisplay = false;
             this.cmessage = 'Please add your coupon code.';
         }
    }
    removeCoupon(plan: any) {
        this.userForm.patchValue({
            'is_applied': 0,
            'applied_coupon': '',
            'coupon_code': ''
        });
        this.couponStatus    =   false;
        this.registerdisplay = false;
        this.cmessage   =  '';
        this.globals.setRegisterCoupon('');

    }

    isAppliedChange() {
        if (this.userForm.coupon_code !== this.userForm.applied_coupon) {

            this.userForm.patchValue({
                'is_applied': 0,
                'applied_coupon': ''
            });
        }
    }


    onSelectPlan($event) {

        if ( this.registerdisplay  === true) {
            return;
        }

        this.initalvalue = 1;


        this.removeCoupon($event.target.value);


    //    console.log('onSelectPlan', $event.target.value);
        this.userForm.get('select_plan').setValue($event.target.value);
        this.paymentPlan =  this.plan = $event.target.value + '_' + this.selected_country;
        this.selectedpaymentPlan = $event.target.value;
        this.globals.registerPlanCoupon.subscribe( data => {
            if (data) {
                this.reg_coupon = data ;
            }
        });
     //   console.log('coupon onSelectPlan', this.reg_coupon );


        this.subscription.getPlanDetails(this.plan, this.reg_coupon).subscribe(data => {
            this.plan_details  =   data.results[0];

           /* this.couponStatus    =    data.is_valid_coupon;
            if (this.couponStatus) {
                this.cmessage   =   'Coupon Applied. Discount of ' +
                this.plan_details.currency_code + this.plan_details.currency_symbol + data.discount
                + ' will be applied to the onetime fee of ' + this.plan_details.currency_code + ' '
                + this.plan_details.currency_symbol + this.plan_details.onetime_fee;
            } */

        });

    }


    // RemoveCouponAfterPlanchange() {
    //     this.userForm.patchValue({
    //         'is_applied': 0,
    //         'applied_coupon': '',
    //         'coupon_code': ''
    //     });
    //     this.couponStatus    =   false;
    //     this.cmessage   =  '';
    //     this.globals.setRegisterCoupon('');

    // }

    onSelectPlanCountryChange(plan) {

        if ( this.registerdisplay === true) {
            return;
        }

        this.initalvalue = 1;

        this.removeCoupon(plan);


        this.userForm.get('select_plan').setValue(plan);
        this.paymentPlan =  this.plan = plan + '_' +  this.selected_country;
        this.globals.registerPlanCoupon.subscribe( data => {
            if (data) {
                this.reg_coupon = data ;
            }
        });
    //    console.log('onSelectPlanCountryChange', this.reg_coupon );


        this.subscription.getPlanDetails(this.plan, this.reg_coupon).subscribe(data => {
            this.plan_details  =   data.results[0];

         /*   this.couponStatus    =    data.is_valid_coupon;
            if (this.couponStatus) {
                this.cmessage   =   'Coupon Applied. Discount of ' +
                this.plan_details.currency_code + this.plan_details.currency_symbol + data.discount
                + ' will be applied to the onetime fee of ' + this.plan_details.currency_code + ' '
                + this.plan_details.currency_symbol + this.plan_details.onetime_fee;
            } */

        });

    }

}
