import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-allsharedreport',
  templateUrl: './allsharedreport.component.html',
  styleUrls: ['./allsharedreport.component.css']
})
export class AllsharedreportComponent implements OnInit {
  report_data: any;

  constructor(private userService: UserService ,
    private spinnerService: Ng4LoadingSpinnerService,
    ) { }

  ngOnInit() {

   this.getReport();
 
  }

  getReport(){
    this.spinnerService.show();
    this.userService.getAllReports().subscribe(
      data => {
            if (data.status) {
              console.log('data-->',data);
              this.report_data = data.results;
               this.spinnerService.hide();
            }
      },
      error => {

      }
    );
  }
  deleteReport(id) {
    this.spinnerService.show();
    this.userService.deleteReportById(id).subscribe(
      data => {
        this.getReport();
      },
      error => {
        this.getReport();
        this.spinnerService.hide();
      }
    );


  }
}
