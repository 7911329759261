import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-kwik-form-breadcum',
  templateUrl: './kwik-form-breadcum.component.html',
  styleUrls: ['./kwik-form-breadcum.component.css']
})
export class KwikFormBreadcumComponent implements OnInit, OnDestroy {
  level = 0;
  per_exp = 0;
  sub: any;
  enter_values = 'ENTER VALUES';
  constructor(
    private activatedRoute: ActivatedRoute,
    private user: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.activatedRoute.data.subscribe(v => {
      if (v.b_lev) { this.level = parseInt(v.b_lev, 10); }
      if (v.brcr && v.b_lev > 2) {
        this.enter_values = v.brcr;
      }
      if (v.per_exp) {
        this.per_exp = 1;
      }
    });
  }
  back() {
    this.user.brdcrumbBack();
  }
  next() {
    this.user.brdcrumbNext();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


}
