import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective implements AfterViewInit, OnChanges {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    this.setDefaultValuesWithCommas();
  }
  ngOnChanges() {
    this.setDefaultValuesWithCommas();
  }
  setDefaultValuesWithCommas() {
    setTimeout(() => {
      const temp = this._el.nativeElement.value ? this._el.nativeElement.value : '';
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ;
    }, 100);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    const temp = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== temp) {
      // this.ngModelChange.emit(temp);
      // event.stopPropagation();
    }
    this.ngModelChange.emit(temp);
    setTimeout(() => {
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);

  }
}

@Directive({
  selector: '[appNumberOnlyWithCent]'
})
export class NumberOnlyWithCentDirective implements AfterViewInit, OnChanges {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    this.setDefaultValuesWithCommas();
  }
  ngOnChanges() {
    this.setDefaultValuesWithCommas();
  }
  setDefaultValuesWithCommas() {
    setTimeout(() => {
      const temp = this._el.nativeElement.value;
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initalValue = this._el.nativeElement.value;
    if ((initalValue.match(/\./g) || []).length > 1) {
      let t = 0;
      initalValue = initalValue.replace(/\./g, function (match) {
        t++;
        return (t >= 2) ? '' : match;
      });
    }

    let temp = initalValue.replace(/[^0-9.]*/g, '');
    const sp_ar = initalValue.split('.');
    if (sp_ar[1] && sp_ar[1].length > 2) {
      temp = ( Math.round( Number( temp ) * 100) / 100 ).toString();
    }
    this.ngModelChange.emit(temp);
    setTimeout(() => {
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);

  }
}


@Directive({
  selector: '[appNumberOnlyWithCent2]'
})
export class NumberOnlyWithCent2Directive implements AfterViewInit, OnChanges {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    this.setDefaultValuesWithCommas();
  }
  ngOnChanges() {
    this.setDefaultValuesWithCommas();
  }
  setDefaultValuesWithCommas() {
    setTimeout(() => {
      const temp = this._el.nativeElement.value;
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initalValue = this._el.nativeElement.value;
    if ((initalValue.match(/\./g) || []).length > 1) {
      let t = 0;
      initalValue = initalValue.replace(/\./g, function (match) {
        t++;
        return (t >= 1) ? '' : match;
      });
    }

    let temp = initalValue.replace(/[^0-9.]*/g, '');
    const sp_ar = initalValue.split('.');
    if (sp_ar[1] && sp_ar[1].length >= 2) {
      temp = ( Math.round( Number( temp ) * 10) / 10 ).toString();
    }
    this.ngModelChange.emit(temp);
    setTimeout(() => {
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);

  }
}





@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[InputvalueWithTwoDecimal]'
})
// tslint:disable-next-line:directive-class-suffix
export class InputvalueWithTwoDecimaldirective implements AfterViewInit, OnChanges {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    this.setDefaultValuesWithCommas();
  }
  ngOnChanges() {
    this.setDefaultValuesWithCommas();
  }
  setDefaultValuesWithCommas() {
    setTimeout(() => {
      const temp = this._el.nativeElement.value;
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initalValue = this._el.nativeElement.value;
    if ((initalValue.match(/\./g) || []).length > 1) {
      let t = 0;
      initalValue = initalValue.replace(/\./g, function (match) {
        t++;
        return (t >= 1) ? '' : match;
      });
    }

    let temp = initalValue.replace(/[^0-9.]*/g, '');
    const sp_ar = initalValue.split('.');
    if (sp_ar[1] && sp_ar[1].length >= 2) {
    //  temp = ( Math.round( Number( temp ) * 10) / 10 ).toString();
    temp = parseFloat(temp).toFixed(2);
    }
    this.ngModelChange.emit(temp);
    setTimeout(() => {
      this._el.nativeElement.value = temp.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }, 100);

  }
}
