import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService, AlertService, UserService } from '../services';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { GlobalsService } from '../services/globals.service';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: any;
  page_content: any;
  submitted: any = false;
  loading: any = false;
  token : string = '';
  recaptchaVerified = false;
  message: string;
  classmessages: string;
  metatag: any;
  industries: any;
  selected_country: any = 'SG';
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private cms: CmsService, private formBuilder: FormBuilder,
    private userService: UserService, private router: Router, private alertService: AlertService, private meta: Meta ,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private globals: GlobalsService
  ) {
    this.contactForm = this.formBuilder.group({
      'conct_firstname': ['', Validators.required],
      'conct_lastname': ['', Validators.required],
      'conct_email': ['', [Validators.required, ValidationService.emailValidators]],
      'conct_phone': [''],
      'conct_startup': ['', Validators.required],
      'conct_business': ['', Validators.required],
      'conct_message': ['', Validators.required],
      'conct_industry': ['', Validators.required]

    });
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'Feel free to reach out and share your questions! The Kwik Look team has more than 25 years experience in evaluating multi million dollar decisions for global corporations, and are now looking to bring this expertise to SME startups.',
    };
    this.meta.updateTag(this.metatag);

    this.globals.current_country.subscribe(value => {
      if (value) {
        this.selected_country = value;
        this.getIndustry(value);
      }
    });

  }
  get f() { return this.contactForm.controls; }

  ngOnInit() {
    this.cms.getCmsByPage('cms/contact')
    .subscribe(page_content => {
        this.page_content = page_content;
    });


    this.getRecaptchaToken('submit');

  }

  getIndustry(country) {
    let country_name = 'Singapore';
    if ( country === 'SG') {
      country_name = 'Singapore';
    } else if ( country === 'AU' ) {
      country_name = 'Australia';
    } else if ( country === 'MY' ) {
      country_name = 'Malaysia';
    }
    this.userService.getAllIndustries().subscribe(industries => {
      this.industries = (industries).filter((a) => (a.country === country_name) );
    });
  }


  getRecaptchaToken(action) {
     
    
    this.recaptchaV3Service.execute(action).subscribe(
      (token) => {

       console.log('token------>',token);
       this.token = token;
       this.recaptchaVerified = false;

      },
      (error) => {

        this.token = '';
        this.recaptchaVerified = false;
        console.log('error token------>');

      }
    );
  }

  onSubmit() {
    // console.log('contact form', this.contactForm);
    this.submitted = true;

    

    console.log('this.token',this.token);






    if (this.contactForm.invalid || !this.token) {
        return;
    }
    this.loading = true;


    this.userService.checkreCaptcha(this.token,'submit').subscribe(
      data => {

        console.log('checkreCaptcha',data);

        if (data.status === 1) {
          // this.message = data.msg;
          // this.classmessages = 'success';
          // this.loading = false;
          // setTimeout(() => this.message = ''  , 3000);
          // this.submitted = false;
          // this.recaptchaVerified = true;

          this.userService.contactUser(this.contactForm.value).subscribe(
            data => {
              if (data.status === 1) {
                this.message = data.msg;
                this.classmessages = 'success';
                this.loading = false;
                setTimeout(() => this.message = ''  , 3000);
                this.submitted = false;
                this.onReset();
      
              } else {
                this.message = data.msg;
                this.classmessages = 'error';
                this.loading = false;
                setTimeout(() => this.message = ''  , 3000);
              }
            }
          );


        } else {
          this.message = 'Your Response to the Captcha Appears to Be Invalid';
          this.classmessages = 'error';
          this.loading = false;
          this.recaptchaVerified = false;
          this.onReset();
          // setTimeout(() => this.message = ''  , 3000);
        }
      }
    );
  

      // if(this.recaptchaVerified){
       
      // }
    

  }
  onReset() {

    this.contactForm.reset({
      'conct_firstname': '',
      'conct_lastname': '',
      'conct_email': '',
      'conct_phone': '',
      'conct_industry': '',
      'conct_startup': '',
      'conct_business': '',
      'conct_message': ''
    });
  }
}
