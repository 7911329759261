import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router , CanActivateChild } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private user: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
 /*   if (this.user.getUserObject()) {
        return true;
    }
    this.router.navigate(['/login']);
    return false;*/
    return true;
  }
}


@Injectable({
  providedIn: 'root'
})
export class OnlyLoggedInUsersGuard  implements CanActivate {
  constructor(private router: Router, private user: UserService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.getUserObject()) {
        return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})

class AlwaysAuthChildrenGuard implements CanActivateChild {
  canActivateChild() {
    // console.log('AlwaysAuthChildrenGuard');
    return true;
  }
}
