import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

  constructor(private user: UserService, private router: Router) {
    if (this.user.plan_notfn) {
      this.user.plan_notfn = '';
    } else {
      this.router.navigate(['/user/kwik-look-main']);
    }
  }

  ngOnInit() {
  }

}
