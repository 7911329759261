import { Component, OnInit } from '@angular/core';
import { CmsService } from '../cms.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-evaluation-concepts',
  templateUrl: './evaluation-concepts.component.html',
  styleUrls: ['./evaluation-concepts.component.css']
})
export class EvaluationConceptsComponent implements OnInit {
  page_content: any;
  metatag: any;
  constructor(private cms: CmsService, private meta: Meta) {
    this.metatag = {
      name: 'description',
      // tslint:disable-next-line:max-line-length
      content: 'The Kwik Look website provides a simple explanation of concepts such as Cash Flow, Maximum Cash Exposure, Break Even Period, Value Creating Investment Decisions etc',
    };
    this.meta.updateTag(this.metatag);
  }

  ngOnInit() {
    this.cms.getCmsByPage('cms/evaluation_concepts')
      .subscribe(page_content => {
        this.page_content = page_content;
      });
  }

}
