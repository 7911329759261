import { Component, OnInit , AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GlobalsService } from '../services/globals.service';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';
import {NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
// import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  profile: any;
  countries: any;
  sel_country: any;
  plan_details: any;
  sub_url: any;
  editprofilevalue = true;
  edit_company = false;
  message: string;
  profile_status = '';
  message_company: string;
  classmessages: string;
  selected_month: string;
  form: FormGroup;
  month_list = [];
  user_scope = 0;
  industries = [];
  all_industries = [];
  all_countries = [];
  incorp_years = [];
  programs = [];

  next_url = '/user/kwik-look-main';
  maxDate: any;
  private genders: string[];
  phasetwovariable: any;
  editcompanynew: any;
   cities: any;
  orders: any;
  company_form: FormGroup;
  selectedItems: any;
  dropdownSettings: any;
  dataList: any;
  profilevariable: boolean;
  companyvariable: boolean;
  profile_not_completed: boolean;

  constructor( @Inject(WINDOW) private window: Window,
  @Inject(LOCAL_STORAGE) private localStorage: any,
  private router: Router, private user: UserService, builder: FormBuilder,
    private globals: GlobalsService,
    private config: NgbDatepickerConfig, private calendar: NgbCalendar,
    private route: ActivatedRoute,
     // private datePipe: DatePipe
    ) {

      this.companyvariable = false;
      this.profilevariable = false;
      this.profile_not_completed = false;



      this.company_form = new FormGroup({
        company_name: new FormControl('', Validators.required),
        company_industry: new FormControl('', [Validators.required, Validators.min(1)] ),
        incorp_month: new FormControl('', Validators.required),
        incorp_year: new FormControl('', Validators.required),
        businessdevstage: new FormControl('', Validators.required),
        company_country: new FormControl('', Validators.required),
        hear_about: new FormControl('', Validators.required),
        incorporatedyrs: new FormControl(''),
        startup: new FormControl('', Validators.required),
        startuptype: new FormControl('', Validators.required),
        PermissionToShare: new FormControl('', Validators.required),
       // techusage: new FormControl(this.cities),
       // musicPreferences: new FormArray(formControls),
        multicheckbox: new FormGroup({
          checkbox1: new FormControl(false),
          checkbox2: new FormControl(false),
          checkbox3: new FormControl(false),
          checkbox4: new FormControl(false)
        } )
      });

    this.sub_url = this.route.snapshot.params.sub;
    // console.log('sub_url', this.sub_url);
    this.genders =  ['Male', 'Female', 'Others'];
    this.editprofilevalue = true;
    if (this.user.profile_not_completed) {
      this.user.profile_not_completed = false;
      this.profile_status = 'Please complete your profile.';
      this.next_url = '/user/upgrade-subscription';
    }




    this.profile = {
      'first_name': '',
      'last_name': '',
      'gender': this.genders[0],
      'dob': { 'year': 2019,
        'month': 6,
        'day': 11},
      'user_email': '',
      'address': '',
      'city': '',
      'state': '',
      'post_code': '',
      'country': 'Singapore',
      'other_country': '',
      'user_phone': '',
      'subscriber': '1',
    };

    this.editcompanynew = {
      'startup': '',
      'startuptype': '',
      'hear': '',
      'incorp_year_value': ''
    };
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear() - 10,
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.user.getAllIndustries().subscribe(industries => {
      this.all_industries = industries;
    });


    this.globals.profileStatus.subscribe( status => {
      this.profile_not_completed = status;
      console.log('222', this.profile_not_completed);
    });

  }

  ngAfterViewInit() {


    const c_user = this.user.getUserObject();

    if ( ( this.profile_not_completed )) {

        document.getElementById('triggermodal').click();

     }

  }
  modalclick() {
    if (!this.editcompanynew.startup || !this.editcompanynew.startuptype ||
      !this.editcompanynew.hear  || !this.editcompanynew.permissiontoshare || this.profilevariable) {
          document.getElementById('profileeditid').click();
      }
  }
  ngOnInit() {


    this.globals.getStartupPlatformPrograms().subscribe(programs => {
      this.programs = programs;
    });
    // console.log('user details', this.user);
    // document.getElementById('profileeditid').click();

    this.plan_details = this.user.getUserObject();
    this.globals.getCountries().subscribe( data => {
      this.countries  =   data.countries;
      this.sel_country  =   data.c_country;
    });



    this.user.getAllIndustries().subscribe(industries => {
      this.all_industries = industries;
    });
    this.user.getIndustries().subscribe(industries => {
      this.industries = industries;
    });
    this.user.getIncorpYearsANdCountries().subscribe(data => {
      this.all_countries = data.country;
      this.incorp_years = data.incorporation_year;
      this.setCompanyProfileForm();
    });
    this.user.getUserScopes().subscribe(
      data => {

        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;

      }
    );
    this.month_list = this.user.month_list;
    this.user.getUserProfileData().subscribe(
      data => {
        if (data.status === 1) {
          this.profile = data.results;
          console.log('profile data', this.profile);

          if (!(data.results.address &&
            data.results.country &&
            data.results.first_name &&
            data.results.gender &&
            data.results.last_name &&
            data.results.post_code &&
            data.results.user_phone &&
            data.results.dob.month)
            ) {

             this.profilevariable = true;
            }


// this.profile.subscribe = false;
          const birthYear   =   Number(this.profile.dob.year);
          const birthMonth  =   Number(this.profile.dob.month);
          const birthDay    =   Number(this.profile.dob.day);
          this.profile.dob  = {
            year: birthYear,
            month: birthMonth,
            day: birthDay
          };

        }
      },
      //   error => { }
    );
  }

  setCompanyProfileForm() {
    const c_user = this.user.getUserObject();
    this.user.getCompanyDetails({ 'cid': c_user.cid }).subscribe(
      data => {
        const company_det = { name: '', indus: '', month: '', year: '', country: '' , hear: '', startup: '', startuptype: '',
        incorp_year_value: '', businessdevstage: '', PermissionToShare: '', techusage: '' };
        if (data.status && data.results && data.results.length) {
          company_det.name = c_user.company;
          company_det.indus = data.results[0].catid;
          company_det.month = data.results[0].sel_month;
          this.selected_month = data.results[0].sel_month;
          company_det.year = data.results[0].sel_year;
          company_det.country = data.results[0].company_country;
          company_det.hear = data.results[0].hear_about;
          company_det.startup = data.results[0].startup;
          company_det.startuptype = data.results[0].startuptype;
          company_det.incorp_year_value = data.results[0].incorp_year_value;
          company_det.businessdevstage = data.results[0].businessdevstage;
          company_det.PermissionToShare = data.results[0].permission_to_share;
          company_det.techusage = JSON.parse(data.results[0].techusage);
          if (data.results[0].company_country === 'us') {
            company_det.country = 'US';
          } else if (data.results[0].company_country === 'uk') {
            company_det.country = 'UK';
          } else if (data.results[0].company_country === 'eu') {
            company_det.country = 'EU';
          } else {
            company_det.country =  this.capitalize_word(company_det.country);
          }
          this.editcompanynew.startuptype = company_det.startuptype;
        this.editcompanynew.startup = company_det.startup;
        this.editcompanynew.hear = company_det.hear;
        this.editcompanynew.permissiontoshare = company_det.PermissionToShare;
        if (this.editcompanynew.startup || this.editcompanynew.startuptype ||
           this.editcompanynew.hear ||  this.editcompanynew.permissiontoshare  ) { // phase two edit
              this.phasetwovariable = false;

        }


        }
      //  const formControls = this.musicPreferences.map(control => new FormControl(false));
        this.company_form = new FormGroup({
          company_name: new FormControl(company_det.name , Validators.required),
          company_industry: new FormControl(company_det.indus, [Validators.required, Validators.min(1)] ),
          incorp_month: new FormControl(company_det.month, Validators.required),
          incorp_year: new FormControl(company_det.year, Validators.required),
          company_country: new FormControl(company_det.country , Validators.required ),
          hear_about: new FormControl( company_det.hear, Validators.required ),
          incorporatedyrs: new FormControl(company_det.incorp_year_value),
          businessdevstage: new FormControl(company_det.businessdevstage, Validators.required),
          startup: new FormControl( company_det.startup, Validators.required ),
          startuptype: new FormControl( company_det.startuptype, Validators.required ),
          PermissionToShare: new FormControl(company_det.PermissionToShare, Validators.required),
         // techusage: new FormControl(this.cities),
         // musicPreferences: new FormArray(formControls),
          multicheckbox: new FormGroup({
            checkbox1: new FormControl(false),
            checkbox2: new FormControl(false),
            checkbox3: new FormControl(false),
            checkbox4: new FormControl(false)
          })
        });

        if (company_det.techusage) {

          if (company_det.PermissionToShare === 'yes') {

            this.company_form.patchValue({
              multicheckbox:  company_det.techusage
            });

          }



        }


        this.industries = (this.all_industries).filter((a) => ((a.country).toLowerCase() === (company_det.country).toLowerCase()));

        // console.log('profilevariable', this.profilevariable);
        // if (!data.results[0].hear_about || !data.results[0].startup || !data.results[0].permission_to_share || this.profilevariable) {
        //       document.getElementById('profileeditid').click();
        //       document.getElementById('triggermodal').click();
        // }

      //   if (!company_det.hear || !company_det.startup || !company_det.PermissionToShare || this.profilevariable) {
      //     document.getElementById('profileeditid').click();
      //    // document.getElementById('triggermodal').click();
      // }


      }
    );
  }
  capitalize_word(str) {
    str = str.split(' ');
    for (let i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(' ');
  }
  onCountryChange(event) {
    this.company_form.patchValue({
      company_industry: 0
    });
    const newVal = event.target.value;
    this.industries = (this.all_industries).filter((a) => (a.country === newVal));
  }
  saveProfiledata() {
    // console.log('all profile variables', this.profile);
  }
  save_company() {

    if (this.company_form.valid) {

      this.edit_company = false;
      const c_user = this.user.getUserObject();
      const fdata = this.company_form.value;
      const body = { 'data': fdata, 'id': c_user.id  };
      this.user.saveCompanyDetailsFromProfile(body).subscribe(data => {
        if (data.msg) {
          this.message_company = data.msg;
          if (data.status) {
            this.user.CompanyDetails = '';
            this.classmessages = 'success';
            this.user.saveIncorpMonth(fdata.incorp_month);
            if (c_user.company !== fdata.company_name) {
              c_user.company = fdata.company_name;
              this.user.setUserObject(c_user);
            }

  // ------------------------------------------

  this.user.getCompanyDetails({ 'cid': c_user.cid }).subscribe(
    data1 => {
       console.log('this.editcompanynew.startupdata', data1 );
      this.editcompanynew.hear = data1.results[0].hear_about;
      this.editcompanynew.startup = data1.results[0].startup;
      this.editcompanynew.startuptype = data1.results[0].startuptype;
      this.editcompanynew.incorp_year_value = data1.results[0].incorp_year_value;
      this.editcompanynew.permissiontoshare = data1.results[0].permission_to_share;





      if (this.editcompanynew.startup || this.editcompanynew.startuptype ||
        this.editcompanynew.hear ) { // phase two edit
        this.phasetwovariable = false;
      }

    }
  );
  // --------------------------------------------
            // set form again with new values
            this.setCompanyProfileForm();

            this.companyvariable  = true;
            this.setProfileStatus();

          } else {
            this.classmessages = 'error';
          }
          setTimeout(() => this.message_company = '', 2500);
        }
      });


    } else {
      return false;
    }


    //  console.log('this.editcompanynew.startup', this.editcompanynew.startup );
    //  console.log('this.editcompanynew.hear', this.editcompanynew.hear );
    //  console.log(' this.editcompanynew.incorp_year_value',  this.editcompanynew.incorp_year_value );
    //  console.log('phasetwovariable', this.phasetwovariable );


  }
  setProfileStatus() {
    if (this.profilevariable && this.companyvariable) {
      this.profile_not_completed = false;
    } else {
      this.profile_not_completed = true;
    }
    this.globals.setProfileStatus( this.profile_not_completed);
    this.localStorage.setItem('kw_profile_not_completed', this.profile_not_completed ) ;

  }
  onSubmit() {
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.profile));

    this.user.SaveUserProfileData(this.profile).subscribe(
      data => {
        if (data.status === 1) {
          this.editprofilevalue = true;
          this.profile = data.results;
          // ---------- new country based Language selections

          Object.keys(this.countries).forEach(key => {
            const value = this.countries[key];

              if ( value === this.profile.country) {
                this.globals.setcountrynameGlobal(value);
                this.localStorage.setItem('kw_lang', key) ;
                this.globals.setCountry(key);
              }
            });

          /*  if (this.profile.country === 'Other') {
              this.globals.setcountrynameGlobal('SG');
              this.localStorage.setItem('kw_lang', 'SG') ;
              this.globals.setCountry('SG');
            }
*/
          // ---------- End new country based Language selection

          if ((this.sub_url) && (this.plan_details.plan_details.plan_status !== 1)) {
            this.next_url = '/user/upgrade-subscription';
          }

          this.message = data.message;
          this.classmessages = 'success';

          this.profilevariable  = true;
          this.setProfileStatus();
          setTimeout(() => {
             this.router.navigate([this.next_url]);
          }, 3000);

        } else {
          this.message = data.message;
          this.classmessages = 'error';
          this.editprofilevalue = true;
        }
      }
    );
  }
  editprofile() {
    this.editprofilevalue = false;



    if (!this.editcompanynew.startup || !this.editcompanynew.startuptype ||
      !this.editcompanynew.hear  || !this.editcompanynew.permissiontoshare) { // phase two edit
      this.phasetwovariable = true;
      this.edit_company = true;
    }

   // console.log('phasetwovariable', this.phasetwovariable);
   // console.log('edit_company', this.edit_company);

  }
  editcompany() {
    this.edit_company = true;

  }

  get f() { return this.company_form.controls; }
}
