import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Year1ReportService } from '../../services/year1-report.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-report-base-year1',
  templateUrl: './report-base-year1.component.html',
  styleUrls: ['./report-base-year1.component.css']
})
export class ReportBaseYear1Component implements OnInit {
  buttonClicked = 2;
  c_user: any;
  user_scope = 0;
  report_data: any = {};
  chartOptions: any;
  month_order: any;
  chartData: any;
  chartLabels: any;
  color_arr: any;
  hidelinechart: boolean;
  video_modal_src: any;
  autoplayvalue: boolean;
  video_url: string;
  fisc_inputs = { service_charge: 10 };
  fixed_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true
    }
  };
  total_cost_breakdown = {
    pieChartLabels: [], pieChartData: [], pieChartType: 'pie', pieChartOptions: {
      'backgroundColor': [],
      'maintainAspectRatio': true,
    }
  };
  hidetrailvideo: boolean;




  // line graph section


  // end line graph section
  constructor(private router: Router, private user: UserService, private y1_report: Year1ReportService,
    private sanitizer: DomSanitizer) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
    this.hidelinechart = false;
    this.chartOptions = {
      responsive: true,
      legend: {
        onClick: null,
        labels: {
          display: true,
          boxWidth: 10
        },
        position: 'bottom'
      },
    };

    this.chartData = [
      { data: [], label: 'Cash Flow After Tax', fill: false },
      { data: [], label: 'Cum Cash Flow After Tax', fill: false, },
      //   { data: [45, 67, 800, 500, 89, 465, 215, 125, 315, 89], label: 'Account C', fill: false, }
    ];

    this.chartLabels = ['Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];

    this.color_arr = [{
      // tslint:disable-next-line:max-line-length
      backgroundColor: ['#ffe29a', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#86c7f3', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    }];

    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['report'] + '/';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
  }

  ngOnInit() {
    this.chartLabels = this.month_order = this.user.getMonthOrder();
    this.user.brcr_back = '/user/personal-expenses';
    this.user.brcr_next = '/user/report-base/y5';
    this.user.getUserScopes().subscribe(
      data => {
        this.user.setScopeUserObject(data);
        this.user_scope = data.scope;
        if (data.scope === 0) {
          this.router.navigate(['/user/my-subscription']);
        } else if (data.scope === 1) {
          this.user.brcr_back = '/user/variable-cost/y1';
        }
      }
    );
    this.c_user = this.user.getUserObject();

if (this.c_user.plan_details.slug === 'free_trial' || this.c_user.plan_details.slug === '') {
  this.hidetrailvideo = false;
}

    this.report_data.revenue = {};
    this.report_data.revenue.sales_product_service = [];
    this.report_data.revenue.total_sales_revenue = { value: [] };
    this.report_data.revenue.total_revenue = { arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 };
    this.report_data.cogs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.fixed_costs = { value: [], other: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.variable_costs = { value: [], total: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 } };
    this.report_data.base_case = {
      cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      cum_cash_flow: { val: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], tot: 0 },
      max_exp: 9999999,
      max_exp_month:  0,
      break_even_period: 'More than 1 year'
    };
    this.user.getUserVaiableData().subscribe(
      data => {
        console.log('getYear1BaseReportData', data);
        if (Object.keys(data).length) {
          this.report_data = this.y1_report.getYear1BaseReportData(data);

          console.log('this.report_data', this.report_data);
          this.breakDownFixedCostGraphData();
          this.breakDownTotalCostGraphData();
          this.chartData[0].data = this.report_data.base_case.cash_flow.val;
          this.chartData[1].data = this.report_data.base_case.cum_cash_flow.val;
          this.hidelinechart = true;
          this.report_data.revenue.other_status = data.revenue.other.value;
          /* this.setRevemueReportData(data);
          this.setCOGSReportData(data);
          this.setFixedCostReportData(data);
          this.setVariableCostReportData(data);
          this.breakDownFixedCostGraphData();
          this.breakDownTotalCostGraphData();
          this.setCashFlowData(); */
        } else {
          this.router.navigateByUrl('/user/variable-selection');
        }
      }
    );

  }
  breakDownTotalCostGraphData() {
    this.total_cost_breakdown.pieChartData.push(
      this.report_data.cogs.total.tot,
      this.report_data.variable_costs.total.tot,
      this.report_data.fixed_costs.total.tot
    );
    this.total_cost_breakdown.pieChartLabels.push('Total COGS', 'Total Variable Costs', 'Total Fixed Costs');
    this.total_cost_breakdown.pieChartOptions.backgroundColor.push('#FFC236', '#4071C1', '#F57F3B');
  }
  breakDownFixedCostGraphData() {
    let count = 0;
    console.log('this.report_data', this.report_data);
    for (const val of this.report_data.fixed_costs.value) {
      if (val.status) {
        this.fixed_cost_breakdown.pieChartData.push(val.total);
        this.fixed_cost_breakdown.pieChartLabels.push(val.key);
        this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
      }
      count++;
    }

    /* Start new code arya */
    const fc_other_array = {
      'citd': {
        name : 'Customised IT Development',
        total : 0,
        status : false
      },
      'other': {
          name : 'Other',
          total : 0,
          status : false
      },
      'otherCE': {
          name : 'Other Capital Expenditure',
          total : 0,
          status : false
      }
    };

    console.log('this.report_data.fixed_costs.other', this.report_data.fixed_costs.other);
    for (const val of this.report_data.fixed_costs.other) {
      fc_other_array[val.type]['total'] += val.total;
      if (val.status === true) {
        fc_other_array[val.type]['status'] = true;
      }
    }
    for (const key in fc_other_array) {
      if (fc_other_array.hasOwnProperty(key)) {
        if (fc_other_array[key]['status']) {
          // code here
          this.fixed_cost_breakdown.pieChartData.push(fc_other_array[key]['total']);
          this.fixed_cost_breakdown.pieChartLabels.push(fc_other_array[key]['name']);
          this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
          count++;
        }
      }
    }

    /* End new code arya*/

    /* old code
    for (const val of this.report_data.fixed_costs.other) {

      console.log('TESR', val);
      this.fixed_cost_breakdown.pieChartData.push(val.total);
      this.fixed_cost_breakdown.pieChartLabels.push(val.key);
      this.fixed_cost_breakdown.pieChartOptions.backgroundColor.push(this.color_arr[count]);
      count++;
    }*/
  }
  MathRoundCust(x) {
    return this.y1_report.MathRoundCust(x);
  }
  otherArraySum(x) {
    return this.y1_report.otherArraySum(x);
  }
  MathRoundCustCOGS(x) {
    return this.y1_report.MathRoundCustCOGS(x);
  }
  MathRoundCustFixCost(x) {
    return this.y1_report.MathRoundCustFixCost(x);
  }
  MathRoundCustVarCost(x) {
    return this.y1_report.MathRoundCustVarCost(x);
  }
  shareReport() {
    this.user.shareUserReport(this.report_data, 'base_y1').subscribe(
      data => {
        if (data.status) {
          this.user.report_notfn = data.slug;
          this.router.navigate(['/report/shared-successfully']);
        }
      }
    );
  }
  save_report() {
    this.user.saveUserRreport(this.report_data, 'base_y1').subscribe(
      data => {
        alert('Your data has been saved successfully.');
      }
    );
  }
  captureScreen() {
    this.user.captureScreen('ReportBaseYearOne.pdf');
  }
  async captureScreenNew() {
    this.buttonClicked = 1;
    await this.user.delay(50);
    await this.user.captureScreenNew('ReportBaseYearOne.pdf');
    this.buttonClicked = 2;
  }
  print_fns() {
    this.user.print_fns();
  }

  closeModal() {
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.autoplayvalue = false;
  }
  openModal() {
    const vurls = this.user.getVideoUrls();
    this.video_url = 'https://www.youtube.com/embed/' + vurls['report'] + '?autoplay=1';
    this.video_modal_src =  this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    this.autoplayvalue = true;
  }
  AppendPercentage(value , divded){

    
    if(value != 0 && value > 0 && divded !=0){
      let Percentage =  ((value*1 / divded*1)*100);
      return  Math.round(Percentage) + '%';
    } else if(divded == 0 || divded == '0'){
      return 'NA';
    } else {
      return 0;
    } 
      
    }

}
