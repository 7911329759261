import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trial-period-expired',
  templateUrl: './trial-period-expired.component.html',
  styleUrls: ['./trial-period-expired.component.css']
})
export class TrialPeriodExpiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
