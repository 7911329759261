import { Injectable, ɵConsole } from '@angular/core';
import { UserService } from './user.service';
import { TaxService } from './tax.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {


  fisc_inputs = { service_charge: 0 };
  constructor(private user: UserService, private _tax: TaxService) {
    if (this.user.fiscal_inputs['service_charge']) {
      this.fisc_inputs.service_charge = this.user.fiscal_inputs['service_charge'];
    }
  }
  setFiscalDataFromResponse(data) {
    if (data.status) {
      this.fisc_inputs['service_charge'] = this.numOr0(data.results['service_charge']);
    }
  }
  getTotalFixedCost(data) {
    const result1 = [0, 0, 0, 0, 0];
    const fc_keys = ['bsm', 'rd', 'rent', 'renovation', 'fe', 'wis', 'ed', 'uf', 'mvc' ,'historical'];
    for (const fixd_cost of fc_keys) {
      let count = 1;
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost) {
        result1[0] += this.otherArraySum(data.fixedcost[fixd_cost].fixed_cost.fc);
      }
      if (data.fixedcost[fixd_cost] && data.fixedcost[fixd_cost].fixed_cost_y5) {
        for (const res of data.fixedcost[fixd_cost].fixed_cost_y5.val) {
          result1[count] += this.numOr0(res.value);
          count++;
        }
      }

    }
    const keys = ['citd' ,'other', 'otherCE'];
    for (const key of keys) {
      for (const other of data.fixedcost[key]) {
        let count = 1;
        if (other.fixed_cost) {
          result1[0] += this.otherArraySum(other.fixed_cost.fc);
        }
        if (other.fixed_cost_y5) {
          for (const fcy5 of other.fixed_cost_y5.val) {
            result1[count] += this.numOr0(fcy5.value);
            count++;
          }
        }
      }
    }
    return result1;
  }
  getTotalVariableCost(data, tot_rev) {
    const result1 = [0, 0, 0, 0, 0];
    const var_cos_ob_arr = ['logistics', 'cps', 'tad', 'bte', 'op_partners' ,'historical'];
    const var_cos_arr = ['salaries', 'marketing', 'sales', 'service', 'uv', 'other'];
    for (const var_cost of var_cos_ob_arr) {
      let count = 1;
      if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost) {
        result1[0] += this.otherArraySum(data.variablecost[var_cost].variable_cost.val);
      }
      if (data.variablecost[var_cost] && data.variablecost[var_cost].variable_cost_y5) {
        for (const vc of data.variablecost[var_cost].variable_cost_y5.value) {
          const vcval = this.numOr0(vc.value);
          result1[count] += vcval;
          count++;
        }
      }

    }
    // Third party charge
    let tppc_count = 0;


 // elvee edited new code for thrid party

    const var_data_thrid: any = {};
    let i = 0;
    const  NewfirstYearsales = [0, 0, 0, 0, 0];

    // var_data_thrid.sales  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    // { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 },
    // { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];

    var_data_thrid.sales  = [];
    for (let x = 0; x < data.revenue.ps.length; x++) {
      var_data_thrid.sales.push({ value: 0 });
    }
    var_data_thrid.total  =  [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    var_data_thrid.variable_cost_y5 = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];

    for (const rev_totnew of data.revenue.ps) {
      let sum = 0;
      for (const rev_totnewmonth of rev_totnew.revenue_cost.sales) {
          sum = sum + rev_totnewmonth.value;
      }
      NewfirstYearsales[i] = sum;
      var_data_thrid.sales[i].value = sum;
      i++;
    }

    var_data_thrid.total[0].value = this.otherArraySum(var_data_thrid.sales);
    for (const revenue_costall of data.revenue.ps) {
      let k = 1;
      for (const revenue_cost_single of revenue_costall.revenue_cost_y5.sales) {
      //  console.log('newelvee -', k , revenue_cost_single);
        var_data_thrid.total[k].value += revenue_cost_single.value;
        k++;
      }
    }



// this.otherArraySum(
  // console.log('Newfirstyearsales', var_data_thrid);
  //  console.log('Newfirstyearsales', NewfirstYearsales);
    let variable_cost_value = 0;
    if (typeof data.variablecost.tppcdollar !== 'undefined') {
      if (typeof data.variablecost.tppcdollar.variable_cost_y5 !== 'undefined') {
        var_data_thrid.variable_cost_y5 = data.variablecost.tppcdollar.variable_cost_y5.value;
      }

      if (data.variablecost.tppcdollar.variable_cost.value) {
        variable_cost_value = data.variablecost.tppcdollar.variable_cost.value;
     }
  }




    let tppc_sum = 0;

    for (const rev_tot of tot_rev) {
      let tppc_sum2 = 0;
     // tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);


      if (tppc_count === 0) {
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        } else {
          tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
        }


      if (tppc_count === 0) {
        tppc_sum2 = ( (var_data_thrid.total[tppc_count].value ) * variable_cost_value );

     /*  tppc_sum2 = ( (NewfirstYearsales[0] +
          NewfirstYearsales[1] +
          NewfirstYearsales[2]) * data.variablecost.tppcdollar.variable_cost.value );*/
      }  else {
      //  tppc_sum = Math.round((rev_tot / 100) * var_data_thrid.variable_cost_y5[tppc_count - 1].value);

      tppc_sum2 = ( (var_data_thrid.total[tppc_count].value ) * var_data_thrid.variable_cost_y5[tppc_count - 1].value );

      /*tppc_sum2 = ( (var_data_thrid.sales1[tppc_count - 1].value +
          var_data_thrid.sales2[tppc_count - 1].value +
          var_data_thrid.sales3[tppc_count - 1].value ) * var_data_thrid.variable_cost_y5[tppc_count - 1].value );
      */
        }
// End elvee edited new code for thrid party

     /* old jobin code

     if (tppc_count === 0 && data.variablecost.tppc && data.variablecost.tppc.variable_cost) {
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost.value);
        if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppc_sum = data.variablecost.tppc.variable_cost.value;
        }
      } else if (data.variablecost.tppc.variable_cost_y5 && data.variablecost.tppc.variable_cost_y5.value) {
        tppc_sum = Math.round((rev_tot / 100) * data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value);
        if ( data.variablecost.tppc.type && data.variablecost.tppc.type === 2) {
          tppc_sum = data.variablecost.tppc.variable_cost_y5.value[tppc_count - 1].value;
        }
      }
*/


      result1[tppc_count] += tppc_sum + tppc_sum2;
      tppc_count++;
    }

    for (const var_cost2 of var_cos_arr) {
      for (const var_cost of data.variablecost[var_cost2]) {
        let count = 1;
        if (var_cost.variable_cost) {
          result1[0] += this.otherArraySum(var_cost.variable_cost.val);
        }
        if (var_cost.variable_cost_y5) {
          for (const vc of var_cost.variable_cost_y5.value) {
            const vcval = this.numOr0(vc.value);
            result1[count] += vcval;
            count++;
          }
        }

      }
    }
    return result1;
  }
  getCashflowTotalCost(result) {
   // console.log('totlacogss', result);
    const cfto = { total_cost: [0, 0, 0, 0, 0], cashflow: [0, 0, 0, 0, 0] };
    for (let i = 0; i < 5; i++) {
      const gross_profit = result.tota_rev[i] - result.tota_cogs[i];
      const operating_expense = result.tota_fc[i] + result.tota_vc[i];
      // console.log('gross_profit', gross_profit);
      // console.log('operating_expense', operating_expense);
      cfto.cashflow[i] += gross_profit - operating_expense;
      cfto.total_cost[i] += operating_expense + result.tota_cogs[i];
    }
    return cfto;
  }
  getReportDataBaseY5FromData(data) {
    let i = 0;
    const result = {
      tota_rev: [0, 0, 0, 0, 0],
      tota_cogs: [0, 0, 0, 0, 0],
      tota_fc: [0, 0, 0, 0, 0],
      tota_vc: [0, 0, 0, 0, 0],
      total_cost: [],
      cashflow: [],
    };

    console.log('SERVICE CHARGE', this.fisc_inputs.service_charge);
    for (const ps of data.revenue.ps) {
      if (!ps.revenue_cost) {
        break;
      }
      let j = 1;
      let tem_sum = 0;
      if (ps.revenue_cost) {
        tem_sum = this.otherArraySum(ps.revenue_cost.sales) * this.numOr0(ps.revenue_cost.unit_price);
      }
      // result.tota_rev[0] += this.otherArraySum(ps.revenue_cost.sales) * this.numOr0(ps.revenue_cost.unit_price);
      result.tota_rev[0] += tem_sum;
      if (data.revenue.sc.value) {
        // tslint:disable-next-line:max-line-length
        // result.tota_rev[0] += Math.round((this.otherArraySum(ps.revenue_cost.sales) * this.fisc_inputs.service_charge) / 100) * this.numOr0(ps.revenue_cost.unit_price);
        result.tota_rev[0] += Math.round((this.numOr0(tem_sum) * this.fisc_inputs.service_charge) / 100);
      }
      if (data.cogs.type === '2' || data.cogs.type === 2) {
      //  result.tota_cogs[0] += ps.revenue_cost.monthly_cost[0].value;
      result.tota_cogs[0] += this.otherArraySum(ps.revenue_cost.monthly_cost);
      } else {
        result.tota_cogs[0] += this.otherArraySum(ps.revenue_cost.sales) * this.numOr0(ps.revenue_cost.unit_monthly_cost);
      }
      if (ps.revenue_cost_y5 && ps.revenue_cost_y5.sales) {
        for (const sales of ps.revenue_cost_y5.sales) {

          if (ps.revenue_cost_y5 && sales.value && ps.revenue_cost_y5.unit_price[j - 1] && ps.revenue_cost_y5.unit_price[j - 1]) {

            result.tota_rev[j] += (this.numOr0(sales.value)) * this.numOr0(ps.revenue_cost_y5.unit_price[j - 1].value);

          }
          if (data.revenue.sc.value) {
            // tslint:disable-next-line:max-line-length
            if (sales.value && ps.revenue_cost_y5 && ps.revenue_cost_y5.unit_price[j - 1]) {
              // tslint:disable-next-line:max-line-length
              // result.tota_rev[j] += Math.round(((this.numOr0(sales.value)) * this.fisc_inputs.service_charge) / 100) * this.numOr0(ps.revenue_cost_y5.unit_price[j - 1].value);
              const sc = Math.round((this.numOr0(sales.value) *
              ps.revenue_cost_y5.unit_price[j - 1].value * this.fisc_inputs.service_charge) / 100);
              result.tota_rev[j] += sc;
            }
          }
          if (data.cogs.type === '2' || data.cogs.type === 2) {
           // jobin old code  result.tota_cogs[j] += this.numOr0(ps.revenue_cost_y5.yearly_cost[i].value);
        //    console.log('resulttota_cogs :- i j', i , ps.revenue_cost_y5.yearly_cost);
              result.tota_cogs[j] += 0;
          } else {
            if (sales.value && ps.revenue_cost_y5 && ps.revenue_cost_y5.unit_yearly_cost && ps.revenue_cost_y5.unit_yearly_cost[j - 1]) {
              result.tota_cogs[j] += (this.numOr0(sales.value)) * this.numOr0(ps.revenue_cost_y5.unit_yearly_cost[j - 1].value);
            }

          }
          j++;
        }
      }

      i++;
    }

// ---------New case of Cogs------------------------
if (data.cogs.type === '2' || data.cogs.type === 2) {
  for (const ps of data.revenue.ps) {
    let count = 1;
    if (ps.revenue_cost_y5 && ps.revenue_cost_y5.yearly_cost) {
      for (const res of ps.revenue_cost_y5.yearly_cost) {
       result.tota_cogs[count] += this.numOr0(res.value);
        count++;
      }
    }

  }
}

// --------- End case of cogs case -----------------


    let count2 = 1;
    result.tota_rev[0] += this.otherArraySum(data.revenue.other.revenue_cost);
    if (data.revenue.other && data.revenue.other.revenue_cost_y5) {
      for (const other of data.revenue.other.revenue_cost_y5) {
        result.tota_rev[count2] += Math.round(this.numOr0(this.numOr0(other.value)));
        count2++;
      }
    }

    result.tota_fc = this.getTotalFixedCost(data);
    result.tota_vc = this.getTotalVariableCost(data, result.tota_rev);
    //  console.log('variable total cost ', result.tota_vc );
    //  console.log('totalCogscosts', result.tota_cogs);
    const cfto = this.getCashflowTotalCost(result);
   //  console.log('getCashflowTotalCost', cfto );
    result.total_cost = cfto.total_cost;
    result.cashflow = cfto.cashflow;
    return result;
  }
  getRentRenoFur(data) {
    // Rental Deposit
    const repdata = data.fixedcost.rd;
    let Calc_FC_Rent_Dep_Y1Y5 = [0, 0, 0, 0, 0];
    if (repdata.fixed_cost_y5 && repdata.fixed_cost_y5.val && repdata.fixed_cost.fc && repdata.fixed_cost) {
      Calc_FC_Rent_Dep_Y1Y5 = this.getFixedCostParamValue(repdata.fixed_cost_y5.val, 'value', repdata.fixed_cost.fc);
    }
    // Renovation1
    const rendata = data.fixedcost.renovation;
    let Calc_FC_Renov1_Y1Y5 = [0, 0, 0, 0, 0];
    if (rendata.fixed_cost_y5 && rendata.fixed_cost) {
      Calc_FC_Renov1_Y1Y5 = this.getFixedCostParamValue(rendata.fixed_cost_y5.val, 'value', rendata.fixed_cost.fc);
    }
    // Furniture/Equipment1
    const fueq = data.fixedcost.fe;
    let Calc_FC_Furniture_Equip1_Y1Y5 = [0, 0, 0, 0, 0];
    if (fueq.fixed_cost_y5 && fueq.fixed_cost) {
      Calc_FC_Furniture_Equip1_Y1Y5 = this.getFixedCostParamValue(fueq.fixed_cost_y5.val, 'value', fueq.fixed_cost.fc);
    }
    // Vehicle
    const veh = data.fixedcost.mvc;
    let Calc_FC_Vehicle_Y1Y5 = [0, 0, 0, 0, 0];
    if (veh.fixed_cost_y5 && veh.fixed_cost) {
      Calc_FC_Vehicle_Y1Y5 = this.getFixedCostParamValue(veh.fixed_cost_y5.val, 'value', veh.fixed_cost.fc);
    }
    // customized_it
    const citd = data.fixedcost.citd;
    let Calc_citd_Vehicle_Y1Y5 = [0, 0, 0, 0, 0];
    if (citd.fixed_cost_y5 && citd.fixed_cost) {
      Calc_citd_Vehicle_Y1Y5 = this.getFixedCostParamValue(citd.fixed_cost_y5.val, 'value', citd.fixed_cost.fc);
    }

    const capital_allowance_parameters = []; // fill with actual values
    capital_allowance_parameters[0] = Calc_FC_Renov1_Y1Y5; // renovation
    capital_allowance_parameters[1] = Calc_FC_Furniture_Equip1_Y1Y5; // furniture_equipment
    capital_allowance_parameters[2] = Calc_citd_Vehicle_Y1Y5; // customized_it
    capital_allowance_parameters[3] = Calc_FC_Vehicle_Y1Y5; // motor_vehicle
    let count = 4;
    for (const oce of data.fixedcost.otherCE) {
      capital_allowance_parameters[count] = this.getFixedCostParamValue(oce.fixed_cost_y5.val, 'value', oce.fixed_cost.fc);
      count++;
    }
    if (data.fixedcost.citd.length) {
      for (const oce of data.fixedcost.citd) {
        capital_allowance_parameters[count] = this.getFixedCostParamValue(oce.fixed_cost_y5.val, 'value', oce.fixed_cost.fc);
        count++;
      }
    }



    return { ca: capital_allowance_parameters, rent_deposit: Calc_FC_Rent_Dep_Y1Y5 };
  }
  calculateTAXY1Y5(data, report_type = 1, marketing_sensitivity = 0) {

    const report_data = this.getReportDataBaseY5FromData(data);

    const rent_reno = this.getRentRenoFur(data);

    /* NEW CODE ARYA */
    if (report_type === 2) {
      const year_total = [0, 0, 0, 0, 0];
      let i = 0;
      /** calculate marketing 50%
       *  Add marketing 50% to total cost
      */
      /**Marketing total */
      for (const ps of data.variablecost.marketing) {
        let j = 0;
        if (ps.variable_cost && ps.value ) {
          for (const marketing of ps.variable_cost.val) {
            year_total[0] += this.numOr0(marketing.value);
            j++;
          }
        }
        j = 0;
        if (ps.variable_cost_y5 && ps.value ) {
          for (const marketing of ps.variable_cost_y5.value) {
            year_total[j + 1] += this.numOr0(marketing.value);
            j++;
          }
        }
        i++;
      }
      /**Sales total */
      for (const ps of data.variablecost.sales) {
        let j = 0;
        if (ps.variable_cost && ps.value ) {
          for (const marketing of ps.variable_cost.val) {
            year_total[0] += this.numOr0(marketing.value);
            j++;
          }
        }
        j = 0;
        if (ps.variable_cost_y5 && ps.value ) {
          for (const marketing of ps.variable_cost_y5.value) {
            year_total[j + 1] += this.numOr0(marketing.value);
            j++;
          }
        }
        i++;
      }
      /**Service total */
      for (const ps of data.variablecost.service) {
        let j = 0;
        if (ps.variable_cost && ps.value ) {
          for (const marketing of ps.variable_cost.val) {
            year_total[0] += this.numOr0(marketing.value);
            j++;
          }
        }
        j = 0;
        if (ps.variable_cost_y5 && ps.value ) {
          for (const marketing of ps.variable_cost_y5.value) {
            year_total[j + 1] += this.numOr0(marketing.value);
            j++;
          }
        }
        i++;
      }
      /**Calculate 50% and add to totalcost */
      let k = 0;
      for ( const markTotal of year_total) {
        const ren_persnt = (markTotal * marketing_sensitivity);
        report_data.total_cost[k] += Math.round(ren_persnt);
        k++;
      }
    }
    /* NEW CODE ARYA END*/

    const tax = this._tax.getTax(rent_reno.ca, rent_reno.rent_deposit, report_data.total_cost, report_data.tota_rev);
    const roundedtax = this._tax.getTax(rent_reno.ca, rent_reno.rent_deposit, report_data.total_cost, report_data.tota_rev);

    // console.log('tax_rate', tax );
    // console.log('report_data', report_data );
    // console.log('rent_reno', rent_reno );

    const base_case = { corp_rounded: roundedtax, corp_tax: tax, ccf: [], cf_a_tax: [],
       with_deci_cf_a_tax: [], cf_b_tax: report_data.cashflow, cf_a_tax_with_decimal:[], Calc_Total_Costs_Y1Y5 : report_data.total_cost ? report_data.total_cost :[0,0,0,0,0] };

    let count = 0;
    let ccf = 0;
   // console.log('--------->base case', report_data);
    for (const cf of report_data.cashflow) {

      const cf_a_tax = this.AdddecimaltoArray(base_case.cf_b_tax[count]) - this.AdddecimaltoArray(base_case.corp_tax[count]);
      const cf_a_taxwithdecimal = this.AdddecimaltoArray(base_case.cf_b_tax[count]) - this.AdddecimaltoArray(base_case.corp_tax[count]);

      if (count < 5) {
      //  console.log('report_data.cashflow', base_case.cf_a_tax[count]);
    //  old  ccf += base_case.cf_a_tax[count];
      // old  base_case.ccf[count] = ccf;

     // base_case.cf_a_tax[count] = cf_a_tax;

        base_case.corp_rounded[count] =   Math.round(base_case.corp_rounded[count]); // new added for rounded tax

        base_case.cf_a_tax[count] = Math.round(cf_a_tax);
        base_case.cf_a_tax_with_decimal[count] = cf_a_tax;

        base_case.with_deci_cf_a_tax[count] = this.AdddecimaltoArray(cf_a_taxwithdecimal);
        ccf += cf_a_taxwithdecimal;
     //  ccf = Math.round(ccf) + cf_a_taxwithdecimal;
       //  base_case.with_deci_cf_a_tax[count] =  ccf += cf_a_taxwithdecimal;
       // ccf += base_case.cf_a_tax[count];

        base_case.ccf[count] = Math.round(ccf);

      //  base_case.ccf[count] = ccf;
      }
     // console.log('base case', base_case.ccf);
      count++;
    }
    console.log('report_data.cashflow123', base_case);
    return base_case;
  }
  
  AdddecimaltoArray(value) {
    let decimalresult = 0;
      decimalresult = parseFloat(value.toFixed(2));

    return decimalresult;
  }
  calculateTAXY1Y5OLD(data) {
    const report_data = this.getReportDataBaseY5FromData(data);
    const base_case = { corp_tax: [], ccf: [], cf_a_tax: [], cf_b_tax: [] };
    const Calc_Total_Rev_Y1Y5 = report_data.tota_rev;
    // Rental Deposit
    const repdata = data.fixedcost.rd;
    let Calc_FC_Rent_Dep_Y1Y5 = [0, 0, 0, 0, 0];
    if (repdata.fixed_cost_y5 && repdata.fixed_cost_y5.val && repdata.fixed_cost.fc && repdata.fixed_cost) {
      Calc_FC_Rent_Dep_Y1Y5 = this.getFixedCostParamValue(repdata.fixed_cost_y5.val, 'value', repdata.fixed_cost.fc);
    }
    // Renovation1
    const rendata = data.fixedcost.renovation;
    let Calc_FC_Renov1_Y1Y5 = [0, 0, 0, 0, 0];
    if (rendata.fixed_cost_y5 && rendata.fixed_cost) {
      Calc_FC_Renov1_Y1Y5 = this.getFixedCostParamValue(rendata.fixed_cost_y5.val, 'value', rendata.fixed_cost.fc);
    }
    // Furniture/Equipment1
    const fueq = data.fixedcost.fe;
    let Calc_FC_Furniture_Equip1_Y1Y5 = [0, 0, 0, 0, 0];
    if (fueq.fixed_cost_y5 && fueq.fixed_cost) {
      Calc_FC_Furniture_Equip1_Y1Y5 = this.getFixedCostParamValue(fueq.fixed_cost_y5.val, 'value', fueq.fixed_cost.fc);
    }
    // Vehicle
    const veh = data.fixedcost.mvc;
    let Calc_FC_Vehicle_Y1Y5 = [0, 0, 0, 0, 0];
    if (veh.fixed_cost_y5 && veh.fixed_cost) {
      Calc_FC_Vehicle_Y1Y5 = this.getFixedCostParamValue(veh.fixed_cost_y5.val, 'value', veh.fixed_cost.fc);
    }
    const Calc_Total_Costs_Y1Y5 = report_data.total_cost; // Total Costs
    const Calc_Total_Rev_Other_Y1Y5 = [0, 0, 0, 0, 0];  // Total Other Revenue
    const Calc_CF_Before_Tax_Y1Y5 = report_data.cashflow;
    const Calc_Taxable_Income = []; // cash flow before tax
    const Calc_CA_Total = [0, 0, 0, 0, 0];   // Total Capital Allowance
    base_case.cf_b_tax = Calc_CF_Before_Tax_Y1Y5; // Cashflow Before Tax
    for (let ii = 0; ii < Calc_Total_Rev_Y1Y5.length; ii++) {
      Calc_CA_Total[ii] = this.Calc_CA_Total(Calc_FC_Renov1_Y1Y5, Calc_FC_Furniture_Equip1_Y1Y5, Calc_FC_Vehicle_Y1Y5, ii);
      Calc_Taxable_Income[ii] = Math.round(
        (this.numOr0(Calc_Total_Rev_Y1Y5[ii]) + this.numOr0(Calc_Total_Rev_Other_Y1Y5[ii])) -
        this.numOr0(Calc_Total_Costs_Y1Y5[ii]) +
        this.numOr0(Calc_FC_Rent_Dep_Y1Y5[ii]) +
        this.numOr0(Calc_FC_Renov1_Y1Y5[ii]) +
        this.numOr0(Calc_FC_Furniture_Equip1_Y1Y5[ii]) +
        this.numOr0(Calc_FC_Vehicle_Y1Y5[ii]) -
        this.numOr0(Calc_CA_Total[ii])
      ); // Taxable Income Before Start-Up Exemptions
    }

    const Calc_Taxable_Income_YA_Adj = [0, 0, 0, 0, 0]; // Calc_Taxable_Income_YA_Adj
    let Calc_Taxable_Incomedup = [];
    Calc_Taxable_Incomedup = Calc_Taxable_Income;
    const Calc_Taxable_Income_YA = [0, 0, 0, 0, 0];
    let countnew = 0;
    for (const singleTaxableIncomeAdj of Calc_Taxable_Incomedup) {
      if (countnew <= 1) {
        Calc_Taxable_Income_YA[countnew] = 0;
      } else {
        Calc_Taxable_Income_YA[countnew] = Calc_Taxable_Incomedup[countnew - 2];
      }
      countnew++;
    }
    // -------------------------------------------------------------------------------------------------------------
    // -------Calc_Taxable_Incomedup will now have the 2 shifter value ---------------------------------------------
    // -------------------------------------------------------------------------------------------------------------
    let count = 0;
    let minus_count = 0;
    for (const input of Calc_Taxable_Income_YA) {
      if (input < 0) {
        minus_count += input;
        Calc_Taxable_Income_YA_Adj[count] = 0;
      } else {
        let tmp_res = input;
        if (minus_count < 0) {
          tmp_res = minus_count + tmp_res;
        }
        if (tmp_res < 0) {
          minus_count = tmp_res;
          tmp_res = 0;
        } else {
          minus_count = 0;
        }
        Calc_Taxable_Income_YA_Adj[count] = tmp_res;
      }
      count++;
    }
    // -------------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------------
    // ------- is adjusted based on previous year value. ---------------------------------------------
    // -------------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------------


    // console.log('Calc_Taxable_Income_YA_Adj', Calc_Taxable_Income_YA_Adj);
    const _Calc_Taxable_Income_After_Exemptions = [0, 0, 0, 0, 0];
    // let kcount = 0;
    let parameters = [];
    parameters[0] = [];
    parameters[0][0] = [100000, 100000, 100000, 100000, 100000, 100000, 100000];
    parameters[0][1] = [100, 100, 75, 75, 75, 75, 75];
    parameters[0][2] = 100000;
    parameters[1] = [];
    parameters[1][0] = [200000, 200000, 200000, 200000, 200000, 200000, 200000];
    parameters[1][1] = [50, 50, 50, 50, 50, 50, 50];
    parameters[1][2] = 100000;
    parameters[2] = [];
    parameters[2][0] = [300000, 300000, 300000, 300000, 300000, 300000, 300000];
    parameters[2][1] = [50, 50, 0, 0, 0, 0, 0];
    parameters[2][2] = 100000;
    if (this.user.fiscal_inputs['taxfreeallowance']) {
      parameters = this.user.fiscal_inputs['taxfreeallowance'];
    }

    let i = 0;
    for (let Income of Calc_Taxable_Income_YA_Adj) {
      if (Income === 0) { i++; continue; }

      for (const params of parameters) {
        if (Income === 0) { break; }
        if (Income > this.numOr0(params[2])) {
          let ded_amnt = this.numOr0(params[2]) - (((this.numOr0(params[2])) / 100) * this.numOr0(params[1][i]));
          if (ded_amnt > this.numOr0(params[0][i])) {
            ded_amnt = this.numOr0(params[0][i]);
          }
          _Calc_Taxable_Income_After_Exemptions[i] += ded_amnt;
          Income -= this.numOr0(params[2]);
        } else {
          let ded_amnt = Income - ((Income / 100) * this.numOr0(params[1][i]));
          if (ded_amnt > this.numOr0(params[0][i])) {
            ded_amnt = this.numOr0(params[0][i]);
          }
          _Calc_Taxable_Income_After_Exemptions[i] += ded_amnt;
          Income = 0;
        }
      }
      _Calc_Taxable_Income_After_Exemptions[i] += Income;
      i++;
    }
    const Calc_Taxable_Income_After_Exemptions = _Calc_Taxable_Income_After_Exemptions;

    // console.log('Calc_Taxable_Income_YA_Adj', Calc_Taxable_Income_After_Exemptions);

    //  Tax Payable Prior to Rebate    Calc_Tax_Payable_Prior_Rebate
    const Calc_Tax_Payable_Prior_Rebate = [];
    let Input_Corp_Tax_Rate = [17, 17, 17, 17, 17, 17, 17];
    if (this.user.fiscal_inputs['Input_Corp_Tax_Rate']) {
      Input_Corp_Tax_Rate = this.user.fiscal_inputs['Input_Corp_Tax_Rate'];
    }
    count = 0;
    for (const SingleTaxIncomeAfterExemption of Calc_Taxable_Income_After_Exemptions) {
      Calc_Tax_Payable_Prior_Rebate[count] = Math.round(((SingleTaxIncomeAfterExemption * this.numOr0(Input_Corp_Tax_Rate[count])) / 100));
      count++;
    }
    // end Tax Payable Prior to Rebate  Calc_Tax_Payable_Prior_Rebate
    // Tax  Payable After  Rebate Calc_Tax_Payable_After_Rebate
    const Calc_Tax_Payable_After_Rebate = [];
    let Input_Corp_Tax_Rebate_Rate = [40, 20, 20, 20, 20, 20, 20];
    if (this.user.fiscal_inputs['Input_Corp_Tax_Rebate_Rate']) {
      Input_Corp_Tax_Rebate_Rate = this.user.fiscal_inputs['Input_Corp_Tax_Rebate_Rate'];
    }
    let Input_Corp_Tax_Rebate_Cap = [15000, 10000, 10000, 10000, 10000, 10000, 10000, 10000];
    if (this.user.fiscal_inputs['Input_Corp_Tax_Rebate_Cap']) {
      Input_Corp_Tax_Rebate_Cap = this.user.fiscal_inputs['Input_Corp_Tax_Rebate_Cap'];
    }
    count = 0;
    for (const SingleTaxPayPriorrebate of Calc_Tax_Payable_Prior_Rebate) {
      let rebate_discount = Math.round(((SingleTaxPayPriorrebate * this.numOr0(Input_Corp_Tax_Rebate_Rate[count])) / 100));
      if (this.numOr0(Input_Corp_Tax_Rebate_Cap[count]) < rebate_discount) {
        rebate_discount = this.numOr0(Input_Corp_Tax_Rebate_Cap[count]);
      }
      Calc_Tax_Payable_After_Rebate[count] = Math.round(SingleTaxPayPriorrebate - rebate_discount);

      if (count < 5) {
        base_case.corp_tax[count] = Calc_Tax_Payable_After_Rebate[count];
      }
      count++;
    }
    // End  Tax  Payable After  Rebate
    // Cashflow After Tax (With Working Capital)
    const Calc_CF_After_Tax_Y1Y5 = [];
    count = 0;
    let ccf = 0;
    for (const SingleYearBeforetax of Calc_CF_Before_Tax_Y1Y5) {
      Calc_CF_After_Tax_Y1Y5[count] = SingleYearBeforetax - Calc_Tax_Payable_After_Rebate[count];
      if (count < 5) {
        ccf += Calc_CF_After_Tax_Y1Y5[count];
        base_case.ccf[count] = ccf;
        base_case.cf_a_tax[count] = Math.round(Calc_CF_After_Tax_Y1Y5[count]);

      }
      count++;
    }
    return base_case;
  }
  Calc_CA_Total(Calc_FC_Renov1_Y1Y5, Calc_FC_Furniture_Equip1_Y1Y5, Calc_FC_Vehicle_Y1Y5, i) {
    let singleYearCalc_CA_Total;
    const singleYearCalc_CA_Total2 = [0, 0, 0, 0, 0];
    if (i === 0) {
      singleYearCalc_CA_Total2[i] = 0;
      return 0;
    } else if (i === 1) {
      singleYearCalc_CA_Total2[i] = 0;
      return 0;
    } else {
      let totalithYearFurniture = 0;
      const singleYeartotalithYearFurniture = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const singleYeartotalithYearVehicle = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const singleYeartotalithYearRenovation = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let totalithYearVehicle = 0;

      // Revonation
      let Revonvationlv = 2;
      const Revonvationchangingyear = 3;
      let Input_CA_Reno_Pct_Qualify = 80;
      if (this.user.fiscal_inputs['Input_CA_Reno_Pct_Qualify']) {
        Input_CA_Reno_Pct_Qualify = this.numOr0(this.user.fiscal_inputs['Input_CA_Reno_Pct_Qualify']);
      }
      let Input_CA_Reno_Allow_Period = 3;
      if (this.user.fiscal_inputs['Input_CA_Reno_Allow_Period']) {
        Input_CA_Reno_Allow_Period = this.numOr0(this.user.fiscal_inputs['Input_CA_Reno_Allow_Period']);
      }
      let Input_CA_Reno_Allow_Cap = 300000;
      if (this.user.fiscal_inputs['Input_CA_Reno_Allow_Cap']) {
        Input_CA_Reno_Allow_Cap = this.numOr0(this.user.fiscal_inputs['Input_CA_Reno_Allow_Cap']);
      }

      for (let sales of Calc_FC_Renov1_Y1Y5) {
        totalithYearFurniture = 0;
        sales = this.numOr0(sales);
        if (sales > 0) {
          let new_al_qf = ((sales * this.numOr0(Input_CA_Reno_Pct_Qualify)) / 100);
          if (new_al_qf > Input_CA_Reno_Allow_Cap) {
            new_al_qf = Input_CA_Reno_Allow_Cap;
          }
          totalithYearFurniture = new_al_qf / this.numOr0(Input_CA_Reno_Allow_Period);
          for (let k = 0; k < Revonvationchangingyear; k++) {
            singleYeartotalithYearRenovation[(Revonvationlv + k)] =
              this.numOr0(singleYeartotalithYearRenovation[(Revonvationlv + k)]) + Math.round(totalithYearFurniture);
          }
        }
        Revonvationlv++;
      }
      // End Revonation

      // Furniture / Equipment
      let Furniturelv = 2;
      let Furniturechangingyear = 3;
      if (this.user.fiscal_inputs['Furniturechangingyear']) {
        Furniturechangingyear = this.numOr0(this.user.fiscal_inputs['Furniturechangingyear']);
      }
      for (let sales of Calc_FC_Furniture_Equip1_Y1Y5) {
        totalithYearFurniture = 0;
        sales = this.numOr0(sales);
        if (sales > 0) {
          totalithYearFurniture = Math.round(sales / Furniturechangingyear);

          for (let k = 0; k < Furniturechangingyear; k++) {
            singleYeartotalithYearFurniture[(Furniturelv + k)] =
              this.numOr0(singleYeartotalithYearFurniture[(Furniturelv + k)]) + Math.round(totalithYearFurniture);
          }
        }
        Furniturelv++;
      }
      // Furniture / Equipment Ends

      // Motor Vehicles (Cash)
      let lv = 2;
      let changingyear = 3;
      if (this.user.fiscal_inputs['changingyear']) {
        changingyear = this.numOr0(this.user.fiscal_inputs['changingyear']);
      }
      for (let sales of Calc_FC_Vehicle_Y1Y5) {
        totalithYearVehicle = 0;
        sales = this.numOr0(sales);
        if (sales > 0) {
          totalithYearVehicle = Math.round(sales / changingyear);
          for (let k = 0; k < changingyear; k++) {
            // tslint:disable-next-line:max-line-length
            singleYeartotalithYearVehicle[(lv + k)] = this.numOr0(singleYeartotalithYearVehicle[(lv + k)]) + Math.round(totalithYearVehicle);
          }
        }
        lv++;
      }

      singleYearCalc_CA_Total = singleYeartotalithYearRenovation[i] + singleYeartotalithYearFurniture[i] + singleYeartotalithYearVehicle[i];
      return singleYearCalc_CA_Total;
    }

  }
  numOr0(n) {
    return parseFloat(n) ? parseFloat(n) : 0;
  }
  getFixedCostParamValue(arr, key, y1array) {
    const result = [];
    let i = 1;
    result[0] = this.otherArraySum(y1array);
    for (const res of arr) {
      result[i] = res[key];
      i++;
    }
    return result;
  }
  otherArraySum(x) {
    if (Array.isArray(x)) {
      return (x.map(a => a.value).reduce((a, b) => this.numOr0(a) + this.numOr0(b)));
    } else {
      return 0;
    }

  }
}
